import cn from "classnames/bind";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { findItem, selectItem, isItemActive, onFeatureRedirect } from "./AppHeaderCommons";
import { Layout, Menu, Avatar } from "antd";
import AppHeaderExtraMenu from "./ExtraMenu";
import ClientPlanBadge from "../AppSidebar/ClientPlanBadge";
import UpgradePlanButton from "./UpgradePlanButton";
import TrialTimeLeftCircle from "./TrialTimeLeftCircle";
import { LimitsCounter } from "../LimitsCounter/LimitsCounter";
import SubscriptionModal from "../SubscriptionModal/SubscriptionModal";
import { Dropdown, Icon, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import AppHeaderKnowledgeBaseWidget from "./AppHeaderKBWidget";
import Notifications from "./notifications";

import { meSelector } from "@store/me/selectors";
import { toggleOpenFiltersPanelAction } from "@store/filters/actions";
import { getNotificationsAction } from "@store/notifications/actions";

import s from "./AppHeader.module.less";

import { AppHeaderIcon } from "./helper";
import { isTiktokApp } from "@utils/isTiktokApp";
import { useTrialSubscription } from "@utils/useTrialSubscription";
import { IAppHeaderProps, IMenuItem, IAppSubfeature } from "./AppHeader.type";

import { Logo, LockIcon, UserFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);

const AppHeader: React.FC<IAppHeaderProps> = ({
  menu,
  extra,
  basename,
  avatarUrl,
  selectedKey,
  defaultSelectedKey,
  hideSubMenu = false,
  onPreRedirect = () => Promise.resolve(),
  onLogoClick,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isTrialSubscription = useTrialSubscription();

  const layoutContainer = document.getElementById("layout-content");

  const [offset, setOffset] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuSelectedItems, setMenuSelectedItems] = useState<string[]>([]);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(
    location.search === "?subscription-modal=true",
  );

  const me = useSelector(meSelector);

  const commentVolumeLimit = useMemo(
    () => me?.client?.subscription?.limits?.comment_volume_limit ?? -1,
    [me?.client?.subscription?.limits?.comment_volume_limit],
  );

  const commentVolumeUsed = useMemo(
    () => me?.client?.subscription?.limits?.comment_volume_used ?? -1,
    [me?.client?.subscription?.limits?.comment_volume_used],
  );

  const commentVolumeStatus = useMemo(
    () => me?.client?.subscription?.limits?.comment_volume_status,
    [me?.client?.subscription?.limits?.comment_volume_status],
  );

  const showLimitsCounter = useMemo(
    () =>
      isTiktokApp() && commentVolumeLimit >= 0 && commentVolumeUsed >= 0 && !!commentVolumeStatus,
    [commentVolumeLimit, commentVolumeUsed, commentVolumeStatus],
  );

  const toggleFiltersPanel = useCallback(
    (open: boolean) => dispatch(toggleOpenFiltersPanelAction(open)),
    [dispatch],
  );

  useEffect(() => {
    if (layoutContainer) {
      layoutContainer.onscroll = () => {
        setOffset(layoutContainer.scrollTop);
      };
    }
  }, [layoutContainer]);

  useEffect(() => {
    setMenuSelectedItems(selectItem(menu, selectedKey || ""));
  }, [selectedKey, menu]);

  useEffect(() => {
    dispatch(getNotificationsAction());
  }, [dispatch]);

  const onSubscriptionModalOpen = () => {
    setShowSubscriptionModal(true);
  };

  const renderFeature = (item: IMenuItem) => (
    <div className={s.bbFeatureLabelWrapper}>
      <Tooltip title={item.is_disabled && item.tooltip ? item.tooltip : ""} placement="bottom">
        {item.label}
      </Tooltip>
    </div>
  );

  const isFeatureSelected = (item: IMenuItem) => {
    return window.location.pathname.includes(item.id);
  };

  const onFeatureClick = (id: string) => {
    const item = menu.find(menuItem => menuItem.id === id);

    if (!item) return;

    if (!isItemActive(item)) {
      toggleFiltersPanel(true);
      onFeatureRedirect(onPreRedirect, item, item.basename === basename);
    }
  };

  const onSubfeatureClick = (subfeature: IAppSubfeature) => {
    if (!subfeature.is_disabled && !isItemActive(subfeature)) {
      toggleFiltersPanel(true);
      onFeatureRedirect(onPreRedirect, subfeature, subfeature.basename === basename);
    }
  };

  const renderSubfeature = (subfeature: IAppSubfeature) => (
    <Menu.Item
      id={subfeature.id}
      key={subfeature.id}
      disabled={subfeature.is_disabled}
      onClick={() => onSubfeatureClick(subfeature)}
      className={cx(s.bbMenuSubfeature, {
        [s.bbMenuSubfeatureDisabled]: subfeature.is_disabled,
      })}
    >
      <Tooltip
        placement="bottom"
        title={subfeature.is_disabled && subfeature.tooltip ? subfeature.tooltip : ""}
      >
        {subfeature.label}
        {subfeature.is_disabled ? (
          <Icon _size="md" icon={LockIcon} className={s.bbMenuSubfeatureIcon} />
        ) : null}
      </Tooltip>
    </Menu.Item>
  );

  const renderMenuMain = (feature: IMenuItem, hasDivider?: boolean) => {
    return (
      <Fragment key={feature.id}>
        {hasDivider && <div className={s.bbAppHeaderFeatureDivider} />}
        <li
          key={feature.id}
          onClick={!feature.is_disabled ? () => onFeatureClick(feature.id) : undefined}
          className={cx(s.bbAppHeaderFeature, {
            [s.bbAppHeaderFeatureSelected]: isFeatureSelected(feature),
            [s.bbAppHeaderFeatureDisabled]: feature.is_disabled,
          })}
        >
          <div className={s.bbAppHeaderFeatureContent}>
            <span
              className={cx(s.bbAppHeaderFeatureIcon, {
                [s.bbAppHeaderFeatureSelectedIcon]: isFeatureSelected(feature),
              })}
            >
              {AppHeaderIcon(feature.id)}
            </span>
            <span className={s.bbAppHeaderFeatureTitle}>{renderFeature(feature)}</span>
          </div>
        </li>
      </Fragment>
    );
  };

  return (
    <React.Fragment>
      <Layout.Header
        className={classNames(s.bbAppHeader, {
          [s.bbAppFormNoSubMenu]: offset !== 0 && !hideSubMenu,
        })}
      >
        <div className={s.bbAppHeaderWrapper}>
          <div className={s.bbAppHeaderLogoWrapper} onClick={onLogoClick}>
            <Logo />
          </div>
          <Menu mode="horizontal" className={s.bbAppHeaderMenu} selectedKeys={menuSelectedItems}>
            {menu ? menu.map(item => renderMenuMain(item, item.basename === "managed")) : null}
          </Menu>
          <div className={s.bbAppHeaderRightSection}>
            <AppHeaderKnowledgeBaseWidget />
            <Notifications />
            <div className={s.bbMyAccountMenuWrapper}>
              {isTrialSubscription && (
                <div className={s.bbTrialSubscription}>
                  <div className={s.bbTrialCounters}>
                    {showLimitsCounter && commentVolumeStatus ? (
                      <LimitsCounter
                        limit={commentVolumeLimit || 0}
                        used={commentVolumeUsed || 0}
                        status={commentVolumeStatus}
                      />
                    ) : (
                      <TrialTimeLeftCircle
                        withTooltip
                        trialPeriodEnd={me?.client?.subscription?.period_end || ""}
                        stonlyTarget="app-global__header--trial-days"
                      />
                    )}
                  </div>
                  <UpgradePlanButton
                    data-stonly-target="app-global__header--upgrade-plan"
                    onClick={onSubscriptionModalOpen}
                  />
                </div>
              )}

              {!!extra && (
                <div data-stonly-target="app-global__my-account-menu">
                  <Dropdown
                    open={isMenuOpen}
                    setOpen={setIsMenuOpen}
                    placement="bottomRight"
                    triggerMode="hover"
                    menuClassName={s.bbMyAccountExtraMenu}
                    trigger={
                      <div className={s.bbMyAccountExtraMenuTrigger}>
                        <Avatar
                          size={28}
                          className={s.bbMyAccountExtraMenuAvatar}
                          src={avatarUrl || null}
                          icon={
                            <span className={s.bbMyAccountExtraMenuAvatarIcon}>
                              <UserFilledIcon />
                            </span>
                          }
                        />
                      </div>
                    }
                  >
                    <AppHeaderExtraMenu
                      extra={extra}
                      basename={basename}
                      selectedKey={selectedKey}
                      defaultSelectedKey={defaultSelectedKey}
                      onSubscriptionModalOpen={onSubscriptionModalOpen}
                    />
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout.Header>
      {findItem(menu, selectedKey || "") && offset === 0 && !hideSubMenu ? (
        <header className={s.bbAppHeaderSubmenuWrapper}>
          <div className={s.bbAppHeaderSubmenuWrapperInner}>
            {selectedKey ? (
              <Menu
                mode="horizontal"
                data-cy="app-header-submenu"
                className={s.bbAppHeaderSubmenu}
                selectedKeys={selectedKey?.split(",")}
                defaultSelectedKeys={defaultSelectedKey?.split(",")}
              >
                {menu
                  ? findItem(menu, selectedKey || "")?.subfeatures?.map(sub =>
                      renderSubfeature(sub),
                    )
                  : null}
              </Menu>
            ) : null}
            {!!me?.client?.client_plan && <ClientPlanBadge type={me.client.client_plan} />}
          </div>
        </header>
      ) : null}
      <SubscriptionModal
        isVisible={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
      />
    </React.Fragment>
  );
};

export default AppHeader;

import React from "react";

import s from "./InternalNotes.module.less";

import { getUserInitials } from "@bbdevcrew/bb_ui_kit_fe";
import { IInternalNotesMenuProps } from "./InternalNotes.types";

export const InternalNotesMenu: React.FC<IInternalNotesMenuProps> = ({
  options,
  onSelect,
  position,
}) => {
  return (
    <ul
      className={s.bbNotesMentionList}
      style={{
        top: position.top,
        left: position.left,
      }}
    >
      {options.map(option => (
        <li key={option.id} onClick={() => onSelect(option.label, option.id)}>
          {option.picture ? (
            <img alt="user_pic" src={option.picture} className={s.bbMentionOptionAvatar} />
          ) : (
            <div className={s.bbMentionOptionAvatar}>{getUserInitials(option.label)}</div>
          )}
          {option.label}
        </li>
      ))}
    </ul>
  );
};

import React, { FC } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { Empty } from "antd";
import { Card, Loading, numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";
import { PerformanceCardPill } from "../../../../insights/_common/performanceCard/Pill";

import s from "./CommentsByPlatformWidget.module.less";

import { getPlatformIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { IOverviewPerformanceItem } from "@store/dashboard/types";

interface ICommentsByPlatformWidgetProps {
  title: string;
  loading: boolean;
  subTitle: string;
  headerIcon: JSX.Element;
  headerIconType: "neutral" | "danger";
  data: IOverviewPerformanceItem[];
  showCalculatedPercentage?: boolean;
}

const CommentsByPlatformWidget: FC<ICommentsByPlatformWidgetProps> = ({
  data,
  loading,
  title,
  subTitle,
  headerIcon,
  headerIconType,
  showCalculatedPercentage = true,
}) => {
  const { t } = useTranslation();

  const total = data.find(item => item.id === "total");
  const platformsData = data.filter(item => item.id !== "total");

  return (
    <Card>
      {loading ? (
        <div className={s.bbCommentsByPlatformLoading}>
          <Loading isLoading />
        </div>
      ) : data.length ? (
        <div className={s.bbCommentsByPlatform}>
          <div className={s.bbCommentsByPlatformHeader}>
            <div className={s.bbCommentsByPlatformTitle}>{title}</div>
            <div
              className={classnames(
                s.bbCommentsByPlatformHeaderIcon,
                s[`bbCommentsByPlatformHeaderIcon-${headerIconType}`],
              )}
            >
              {headerIcon}
            </div>
            <div className={s.bbCommentsByPlatformWrapper}>
              <span className={s.bbCommentsByPlatformHeaderValue}>
                {numberFormatter(total?.count || 0)}
              </span>
              {showCalculatedPercentage && (
                <span className={s.bbCommentsByPlatformHeaderPercentage}>
                  ({total?.percentage_of_total}%)
                </span>
              )}
              <PerformanceCardPill type="total" percentageValue={total?.change_percentage || 0} />
            </div>
          </div>
          <div className={s.bbDivider}></div>
          <div className={s.bbCommentsByPlatformSubtitle}>{subTitle}</div>
          <div>
            {platformsData.map(item => (
              <div className={s.bbPlatform} key={item.id}>
                <span className={s.bbPlatformIcon}>{getPlatformIcon(item.id)}</span>
                <span className={s.bbPlatformCount}>{item.count}</span>
                <span className={s.bbPlatformPercentage}>({item.percentage_of_total}%)</span>
                <PerformanceCardPill type="total" percentageValue={item?.change_percentage || 0} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("components:comments:tableEmpty")}
          imageStyle={{
            height: 40,
          }}
        />
      )}
    </Card>
  );
};

export default CommentsByPlatformWidget;

import cn from "classnames/bind";
import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { Avatar, Grid } from "antd";
import { Tooltip, IAxisTick } from "@bbdevcrew/bb_ui_kit_fe";
import { PostPreviewTooltip } from "@components/_common/PostPreviewTooltip/PostPreviewTooltip";

import s from "./CommentBreakdown.module.less";

import {
  cropText,
  Countries,
  PostTypeIcon,
  platformIconName,
  getPlatformIconBasicColor,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Languages } from "@utils/languages";
import { generatePostItem } from "./helpers";
import { IBreakdownUnit } from "@store/aggregations/types";

interface IChartLabelProps {
  type: string;
  tooltipLabel: string;
  chartLabelWidth: number;
  breakdownUnit: IBreakdownUnit;
  labelTickProps: IAxisTick;
}

const cx = cn.bind(s);

const ChartLabel: FC<IChartLabelProps> = ({
  type,
  tooltipLabel,
  breakdownUnit,
  labelTickProps,
  chartLabelWidth,
}) => {
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const labelMargin = 12;
  const maxTextLength = screens.md ? 13 : 8;
  const itemId = labelTickProps.payload.value;
  const item = breakdownUnit.items?.find(unit => unit.id === itemId);

  const showAssetDetails = ["asset_name", "campaign_name", "adset_name", "ad_name"].includes(type);
  const showPostDetails = type === "post_id";
  const postDetails = item?.details;

  const tooltipTitle = `${t("components:breakdown:filter")} ${tooltipLabel?.toLowerCase()}`;

  const getBreakdownChartIcon = (_type: string, value: string) => {
    let Icon = "";

    switch (_type) {
      case "platform_type":
        return (
          <span className={cx(`bbPlatformIcon-${platformIconName(value)}`)}>
            {getPlatformIconBasicColor(platformIconName(value))}
          </span>
        );
      case "post_type":
        Icon = PostTypeIcon[value as keyof typeof PostTypeIcon];
        break;
      case "language":
        Icon = Languages[value as keyof typeof Languages];
        break;
      case "ad_targeting":
        Icon = Countries[value as keyof typeof Countries];
        break;
      case "post_id":
        return (
          item?.details?.platform_type && (
            <span className={cx(`bbPlatformIcon-${platformIconName(item.details.platform_type)}`)}>
              {getPlatformIconBasicColor(platformIconName(item.details.platform_type))}
            </span>
          )
        );
    }
    return <>{Icon ? <Icon /> : null}</>;
  };

  return (
    <foreignObject
      x={5}
      y={labelTickProps.y - labelMargin}
      cursor="pointer"
      className={s.bbBreakdownChartLabel}
      style={{ width: chartLabelWidth }}
    >
      <div className={s.bbBreakdownChartLabelContainer}>
        <div
          className={classNames(s.bbBreakdownChartLabelContent, {
            [s.bbBreakdownChartLabelContentPost]: showPostDetails,
          })}
        >
          <g transform={`translate(${labelTickProps.x},${labelTickProps.y - labelMargin})`}>
            <div className={s.bbChartLabel} style={{ width: chartLabelWidth }}>
              {getBreakdownChartIcon(
                type,
                type === "platform_type" ? platformIconName(itemId) : itemId,
              )}
              {showPostDetails && (
                <PostPreviewTooltip
                  placement="top"
                  post={generatePostItem(item?.details, item?.id)}
                >
                  <Avatar
                    size={32}
                    shape="square"
                    style={{ fontSize: "12px" }}
                    src={postDetails?.picture}
                    className={s.bbBreakdownChartLabelPostImage}
                  >
                    {t("generic:noImage")}
                  </Avatar>
                </PostPreviewTooltip>
              )}
              <Tooltip placement="topLeft" title={tooltipTitle}>
                <span className={s.bbChartLabelText}>
                  {type === "ad_targeting" ? item?.id + " - " : null}
                  {cropText(item?.label || "", maxTextLength)}
                </span>
              </Tooltip>
            </div>

            {showAssetDetails ? (
              <tspan textAnchor="left" x="0" dy="20" className={s.bbDetails}>
                {item?.details?.asset_type}
              </tspan>
            ) : null}
          </g>
        </div>
      </div>
    </foreignObject>
  );
};

export default ChartLabel;

import { useTranslation } from "react-i18next";
import React, { FC, useContext, useEffect } from "react";

import { Button, Tooltip, ItemCard } from "@bbdevcrew/bb_ui_kit_fe";

import { IAIPromptListProps } from "../types";
import { IItemAction } from "@bbdevcrew/bb_ui_kit_fe";
import AIPromptsContext from "../context/AIPromptsContext";
import { IAIPrompt } from "@store/repliesAIPrompts/types";

import s from "./AIPromptsModal.module.less";

import { PlusIcon, HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AIPromptList: FC<IAIPromptListProps> = ({ aIPrompts, onViewChange, onPromptClick }) => {
  const { t } = useTranslation();

  const { fetchAIPrompts, deleteAIPrompt, setDefaultAIPrompt } = useContext(AIPromptsContext);

  useEffect(() => {
    fetchAIPrompts();
  }, [fetchAIPrompts]);

  const createSetDefaultAction = (id: string): IItemAction => ({
    key: "set_default",
    label: t("components:AIPrompts:edit:setAsDefault"),
    onActionClick: () => {
      setDefaultAIPrompt(id);
    },
  });

  const getAIItemsActions = (aIPrompt: IAIPrompt): IItemAction[] => {
    const list: IItemAction[] = [];

    list.push(
      {
        key: "edit",
        label: t("generic:edit"),
        onActionClick: () => {
          onViewChange("edit", aIPrompt);
        },
      },
      {
        key: "delete",
        label: t("generic:delete"),
        onActionClick: () => {
          aIPrompt?.id && deleteAIPrompt(aIPrompt.id);
        },
        confirmationText: t("components:AIPrompts:list:deletePrompt"),
      },
    );

    if (!aIPrompt.is_default && aIPrompt?.id) {
      list.splice(1, 0, createSetDefaultAction(aIPrompt?.id));
    }

    return list;
  };

  const getAIPresetsActions = (aIPrompt: IAIPrompt): IItemAction[] => {
    if (aIPrompt.is_default) {
      return [];
    } else {
      return [createSetDefaultAction(aIPrompt?.id as string)];
    }
  };

  return (
    <>
      <div className={s.bbAiPromptHeader}>
        <div className={s.bbAiPromptHeaderTitle}>
          {t("components:AIPrompts:list:title")}
          <Tooltip title={t("components:AIPrompts:list:titleTooltip")}>
            <span className={s.bbAiPromptHeaderHelp}>
              <HelpIcon />
            </span>
          </Tooltip>
        </div>
        <div className={s.bbAiPromptHeaderActions}>
          <Button
            _type="primary"
            onClick={() => onViewChange("create")}
            className={s.bbAiPromptHeaderCreateBtn}
          >
            <PlusIcon />
          </Button>
        </div>
      </div>
      <div className={s.bbAiPromptsBody}>
        {aIPrompts?.presets.map(aIPrompt => (
          <ItemCard
            key={aIPrompt.id}
            name={aIPrompt.name}
            isDefault={aIPrompt.is_default}
            actions={getAIPresetsActions(aIPrompt)}
            onItemClick={() => onPromptClick && onPromptClick(aIPrompt.id)}
            tooltipProps={{ title: t("components:AIPrompts:list:itemTooltip") }}
          />
        ))}
        {aIPrompts?.items.map(aIPrompt => (
          <ItemCard
            key={aIPrompt.id}
            name={aIPrompt.name}
            isDefault={aIPrompt.is_default}
            actions={getAIItemsActions(aIPrompt)}
            onItemClick={() => onPromptClick && onPromptClick(aIPrompt.id)}
            tooltipProps={{ title: t("components:AIPrompts:list:itemTooltip") }}
          />
        ))}
      </div>
    </>
  );
};

export default AIPromptList;

import React, { FC, useMemo } from "react";

import { Row } from "antd";
import ReviewsWidget from "@components/insights/overview/reviewsWidget";
import Breadcrumbs from "../../_common/breadcrumbs";

import s from "./Reviews.module.less";

import { useDashboardData } from "@utils/useDashboardData";
import { OverviewComponentType } from "@store/modules/types";

export const Reviews: FC = () => {
  const components = useMemo<OverviewComponentType[]>(() => ["review_widgets"], []);
  const { data, isFetching } = useDashboardData(components);

  return (
    <>
      <Breadcrumbs />
      {data?.review_widgets ? (
        <div className={s.bbReviews}>
          <Row gutter={[28, 28]}>
            <ReviewsWidget data={data.review_widgets} loading={isFetching} />
          </Row>
        </div>
      ) : null}
    </>
  );
};

import {
  InputChipQueryLabelsEnum,
  InputChipQueryOperatorsEnum,
} from "../../inputChip/InputChipQueryConfig";
import { SelectOptionSize } from "@bbdevcrew/bb_ui_kit_fe";

export interface IContextMenuOption {
  id: InputChipQueryOperatorsEnum;
  label: InputChipQueryLabelsEnum;
}

/**
 * HARD HACK.
 * Since we use `size` prop on select to force options rendering,
 * we have to force select height in case there is 1 option.
 *
 * Ref: if select has only 1 option, it's set by default, which is not what we want
 */
export function hackOneOptionHeight(_size: SelectOptionSize) {
  if (_size === "sm") {
    return { height: "49px" };
  }
  if (_size === "md") {
    return { height: "57px" };
  }
  if (_size === "lg") {
    return { height: "69px" };
  }

  return {};
}

export function isLastChipOperator(allChips: string[]) {
  const lastChip = allChips[allChips.length - 1] || "";

  if (InputChipQueryLabelsEnum[lastChip as keyof typeof InputChipQueryLabelsEnum]) {
    return true;
  }

  return false;
}

/**
 * Rules for options generation:
 * 1. If there are no values, `NOT` and `ONLY` are the only options.
 * 2. If there are values AND last value is `operator`, either:
 *    - Return empty array if last operator is `ONLY`
 *    - Return only the `ONLY` operator if last operator is something else
 * 3. If there are values AND last value is `keyword`:
 *    - If `ONLY` operator is already included, show only `OR` option
 *    - Otherwise, show all options (`AND`, `OR`, `AND_NOT`, `OR_NOT`)
 */
export function getContextMenuOptions(allChips: string[]): IContextMenuOption[] {
  if (allChips.length === 0) {
    return [
      { id: InputChipQueryOperatorsEnum.NOT, label: InputChipQueryLabelsEnum.__NOT__ },
      { id: InputChipQueryOperatorsEnum.ONLY, label: InputChipQueryLabelsEnum.__ONLY__ },
    ];
  }

  if (isLastChipOperator(allChips)) {
    const lastChipIsOR = [
      InputChipQueryOperatorsEnum.OR,
      InputChipQueryOperatorsEnum.OR_NOT,
    ].includes(allChips[allChips.length - 1] as InputChipQueryOperatorsEnum);
    return lastChipIsOR
      ? [{ id: InputChipQueryOperatorsEnum.ONLY, label: InputChipQueryLabelsEnum.__ONLY__ }]
      : [];
  }

  const onlyOperatorIncluded = allChips.includes(InputChipQueryOperatorsEnum.ONLY);

  return onlyOperatorIncluded
    ? [{ id: InputChipQueryOperatorsEnum.OR, label: InputChipQueryLabelsEnum.__OR__ }]
    : [
        { id: InputChipQueryOperatorsEnum.AND, label: InputChipQueryLabelsEnum.__AND__ },
        { id: InputChipQueryOperatorsEnum.OR, label: InputChipQueryLabelsEnum.__OR__ },
        { id: InputChipQueryOperatorsEnum.AND_NOT, label: InputChipQueryLabelsEnum.__AND__NOT__ },
        { id: InputChipQueryOperatorsEnum.OR_NOT, label: InputChipQueryLabelsEnum.__OR__NOT__ },
        { id: InputChipQueryOperatorsEnum.ONLY, label: InputChipQueryLabelsEnum.__ONLY__ },
      ];
}

import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import s from "./BreakdownChartLegend.module.less";

import { useTiktokEnabled } from "@utils/useTiktokEnabled";

import { CommentIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const BreakdownChartLegend: FC = () => {
  const { t } = useTranslation();
  const isTiktokEnabled = useTiktokEnabled();

  return (
    <div className={s.bbBreakdownLegend}>
      <div className={s.bbBreakdownLegendComments}>
        <div className={s.bbComments}>
          <CommentIcon />
        </div>
        <div>{t("components:breakdown:count")}</div>
      </div>
      <div className={s.bbBreakdownLegendSentiments}>
        <div className={s.bbLegendItemWrapper}>
          <div className={s.bbLegendNegative} />
          {t("components:postTrends:charts:negative")}
        </div>

        <div className={s.bbLegendItemWrapper}>
          <div className={s.bbLegendPositive} />
          {t("components:postTrends:charts:positive")}
        </div>

        <div className={s.bbLegendItemWrapper}>
          <div className={s.bbLegendUserTags} />
          {t("components:postTrends:charts:user_tags")}
        </div>

        <div className={s.bbLegendItemWrapper}>
          <div className={s.bbLegendNeutral} />
          {t("components:postTrends:charts:neutral")}
        </div>

        {isTiktokEnabled && (
          <div className={s.bbLegendItemWrapper}>
            <div className={s.bbLegendNotApplicable} />
            {t("components:postTrends:charts:not_applicable")}
          </div>
        )}
      </div>
    </div>
  );
};

import React from "react";

import { Divider } from "@bbdevcrew/bb_ui_kit_fe";
import { NotificationsItem } from "./NotificationsItem";

import s from "./Notifications.module.less";

import { INotificationsSectionProps } from "./Notifications.types";

export const NotificationsSection: React.FC<INotificationsSectionProps> = ({
  title,
  notifications,
  noDivider,
}) => {
  if (notifications.length === 0) return null;

  return (
    <>
      {!noDivider && <Divider className={s.bbNotificationsDivider} />}
      <div className={s.bbNotificationsSection}>
        <span className={s.bbNotificationsSectionTitle}>{title}</span>
        <div className={s.bbNotificationsItems}>
          {notifications.map((notification, index) => (
            <NotificationsItem key={index} {...notification} />
          ))}
        </div>
      </div>
    </>
  );
};

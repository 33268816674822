import React, { ChangeEvent, FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { position } from "caret-pos";
import { useSelector } from "react-redux";

import { OutsideClickDetector, TextareaNew } from "@bbdevcrew/bb_ui_kit_fe";

import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./InternalNotes.module.less";

import { IInternalNotesInputProps, IInternalNotesMenuOption } from "./InternalNotes.types";
import { InternalNotesMenu } from "./InternalNotesMenu";
import { getDropdownPosition, mentionHandler } from "./InternalNotes.helpers";

export const InternalNotesInput: FC<IInternalNotesInputProps> = ({
  onSearch,
  value,
  onChange,
  onSelect,
}) => {
  const { t } = useTranslation();

  const [mentionQuery, setMentionQuery] = useState("");
  const [mentionVisible, setMentionVisible] = useState(false);
  const [mentionPosition, setMentionPosition] = useState({ top: 0, left: 0 });

  const textareaRef = useRef<HTMLDivElement>(null);
  const textarea = textareaRef.current?.querySelector("textarea");

  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  const dropdownOptions = (autocompleteOptions.user || [])
    .filter(option => option.label.toLowerCase().includes(mentionQuery.toLowerCase()))
    .sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
    ) as IInternalNotesMenuOption[];

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const textareaValue = e.target.value;
    onChange(textareaValue);

    const cursorPosition = e.target.selectionStart;
    const textBeforeCursor = textareaValue.slice(0, cursorPosition);
    const atIndex = textBeforeCursor.lastIndexOf("@");
    const query = atIndex !== -1 ? textBeforeCursor.slice(atIndex + 1) : null;

    if (query?.match(/^[a-zA-Z0-9]*$/)) {
      setMentionQuery(query);
      onSearch(query || "");
      setMentionVisible(true);
      textarea && setMentionPosition(getDropdownPosition(textarea));
    } else {
      setMentionVisible(false);
    }
  };

  const onMentionSelect = (label: string, id: string) => {
    if (!textarea) return;

    const { newValue, caretPosition, start, end } = mentionHandler(
      value,
      mentionQuery,
      label,
      textarea,
    );

    onSelect({ label, id, start, end });
    onChange(newValue);
    setMentionVisible(false);
    setMentionQuery("");

    if (textarea) {
      textarea.focus();
      setTimeout(() => {
        position(textarea, caretPosition);
      });
    }
  };

  return (
    <div ref={textareaRef}>
      <TextareaNew
        className={s.bbNotesInput}
        placeholder={t("components:comments:activity:internalNotes:placeholder")}
        onInput={handleInputChange}
        value={value}
        spellCheck={false}
      />
      {mentionVisible && (
        <OutsideClickDetector onOutsideClick={() => setMentionVisible(false)}>
          <InternalNotesMenu
            options={dropdownOptions}
            onSelect={onMentionSelect}
            position={mentionPosition}
          />
        </OutsideClickDetector>
      )}
    </div>
  );
};

import { action } from "typesafe-actions";

import { OPEN_ATTACHMENT, CLOSE_ATTACHMENT } from "./actionTypes";

import { IAttachment, IPrivateAttachment } from "@bbdevcrew/bb_ui_kit_fe";

export const openAttachmentAction = (attachment: IAttachment | IPrivateAttachment) =>
  action(OPEN_ATTACHMENT, attachment);

export const closeAttachmentAction = () => action(CLOSE_ATTACHMENT);

import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import {
  ICaptionsAIPrompt,
  ICaptionsAIPromptsResponse,
  CaptionsAIPromptsActionsType,
} from "./types";
import { RootState } from "..";
import {
  createCaptionsPromptSuccessAction,
  createCaptionsPromptFailureAction,
  updateCaptionsPromptSuccessAction,
  updateCaptionsPromptFailureAction,
  getCaptionsPromptsSuccessAction,
  getCaptionsPromptsFailureAction,
  deleteCaptionsPromptSuccessAction,
  deleteCaptionsPromptFailureAction,
  setCaptionsPromptAsDefaultFailureAction,
  setCaptionsPromptAsDefaultSuccessAction,
  getCaptionsPromptAISuggestionSuccessAction,
  getCaptionsPromptAISuggestionFailureAction,
} from "./actions";
import {
  CREATE_CAPTIONS_AI_PROMPT,
  UPDATE_CAPTIONS_AI_PROMPT,
  DELETE_CAPTIONS_AI_PROMPT,
  GET_CAPTIONS_AI_PROMPTS,
  SET_CAPTIONS_AI_PROMPT_AS_DEFAULT,
  GET_CAPTIONS_PROMPT_AI_SUGGESTION,
} from "./actionTypes";

import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { promptCaptions, promptCaptionsList } from "@utils/paths";

const normalizeDataForPutRequest = (payload: ICaptionsAIPrompt): ICaptionsAIPrompt => {
  return {
    ...payload,
    id: undefined,
    brand_name: payload.brand_name || "",
    brand_industry: payload.brand_industry || "",
    platform: payload.platform || "",
    hashtags: payload.hashtags || "",
    voice_tone: payload.voice_tone || "",
    goal: payload.goal || "",
    length: payload.length || "",
    language: payload.language || "",
    additional_data: payload.additional_data || "",
  };
};

export const createAIPrompt = (
  action$: Observable<CaptionsAIPromptsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(CREATE_CAPTIONS_AI_PROMPT)),
    switchMap(a => {
      return ajax
        .post<ICaptionsAIPrompt>(
          `${promptCaptions}`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => createCaptionsPromptSuccessAction(data)),
          catchError(e => handleError(e, createCaptionsPromptFailureAction)),
        );
    }),
  );

export const updateAIPrompt = (
  action$: Observable<CaptionsAIPromptsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(UPDATE_CAPTIONS_AI_PROMPT)),
    switchMap(a => {
      return ajax
        .put<ICaptionsAIPrompt>(
          `${promptCaptions}/${a.payload.id}`,
          normalizeDataForPutRequest(a.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => updateCaptionsPromptSuccessAction(data)),
          catchError(e => handleError(e, updateCaptionsPromptFailureAction)),
        );
    }),
  );

export const getAIPrompts = (
  action$: Observable<CaptionsAIPromptsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_CAPTIONS_AI_PROMPTS)),
    switchMap(a => {
      return ajax
        .post<ICaptionsAIPromptsResponse>(
          `${promptCaptionsList}`,
          {
            query: a?.payload?.query,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => {
            return getCaptionsPromptsSuccessAction(data);
          }),
          catchError(e => handleError(e, getCaptionsPromptsFailureAction)),
        );
    }),
  );

export const deleteAIPrompt = (
  action$: Observable<CaptionsAIPromptsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_CAPTIONS_AI_PROMPT)),
    switchMap(a => {
      return ajax
        .delete<ICaptionsAIPrompt>(
          `${promptCaptions}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteCaptionsPromptSuccessAction(a.payload)),
          catchError(e => handleError(e, deleteCaptionsPromptFailureAction)),
        );
    }),
  );

export const setPromptAsDefault = (
  action$: Observable<CaptionsAIPromptsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(SET_CAPTIONS_AI_PROMPT_AS_DEFAULT)),
    switchMap(a => {
      return ajax
        .patch<ICaptionsAIPrompt>(
          `${promptCaptions}/${a.payload}/default`,
          {
            id: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => setCaptionsPromptAsDefaultSuccessAction(a.payload)),
          catchError(e => handleError(e, setCaptionsPromptAsDefaultFailureAction)),
        );
    }),
  );

export const promptAISuggestion = (
  action$: Observable<CaptionsAIPromptsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_CAPTIONS_PROMPT_AI_SUGGESTION)),
    switchMap(a => {
      return ajax
        .post<{ text: string }>(
          `${promptCaptions}/ai-suggestion`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getCaptionsPromptAISuggestionSuccessAction(data.text)),
          catchError(e => handleError(e, getCaptionsPromptAISuggestionFailureAction)),
        );
    }),
  );

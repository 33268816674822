import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { TagCountBadge } from "./TagCountBadge";
import { Chip, Help, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import CustomTags from "../CommentCard/customTags/CustomTagsDropdown";
import BrandBastionTags from "../CommentCard/brandBastionTags/BBTags";
import CustomTagsList from "../CommentCard/customTags/CustomTagsList";
import CommentTagsList from "../CommentCard/commentTags/CommentTagsList";
import AiHighlightTags from "../CommentCard/aiHighlightTags/AiHighlightTags";

import { canManageBBTagsSelector } from "@store/me/selectors";
import { deleteCustomTagFromCommentAction } from "@store/customTags/actions";
import { deleteCommentTagAction, postCommentTagAction } from "@store/commentTags/actions";

import { IReplyData } from "@store/replies/types";
import { IAiTag, IComment, ICommentTag } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Tags.module.less";

interface ICommentDetailsTagsProps {
  comment?: IComment;
  replyData: IReplyData;
  showMessageTags: boolean;
}

const MAX_SHOW_TAGS = 3;
const MAX_HEIGHT_TAGS_CONTAINER = 70;

const CommentDetailsTags: FC<ICommentDetailsTagsProps> = ({
  comment,
  replyData,
  showMessageTags,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const [maxShowTags, setMaxShowTags] = useState(MAX_SHOW_TAGS);

  const canManageBBTag = useSelector(canManageBBTagsSelector);
  const messageTagsExist =
    !!replyData.message_ai_tags?.length ||
    !!replyData.message_tags?.length ||
    !!replyData.message_custom_tags?.length;

  const handleDeleteCustomTag = (tagId?: string) => {
    if (tagId && comment?.id) {
      dispatch(
        deleteCustomTagFromCommentAction({
          id: tagId,
          comment_id: comment.id,
        }),
      );
    }
  };

  const handleDeleteBBTag = (tagId: string) => {
    if (comment?.id) {
      dispatch(deleteCommentTagAction({ tagId, commentId: comment.id }));
    }
  };

  const handleAddBBTag = (tag: ICommentTag) => {
    if (comment?.id) {
      dispatch(postCommentTagAction({ tagId: tag.id, commentId: comment.id }));
    }
  };
  const allTags = [
    ...(comment?.ai_tags || []),
    ...(comment?.tags || []),
    ...(comment?.custom_tags_by_automation || []),
    ...(comment?.custom_tags_by_user || []),
  ];

  useEffect(() => {
    if (!containerRef.current) return;
    if (containerRef.current.clientHeight > MAX_HEIGHT_TAGS_CONTAINER) {
      setMaxShowTags(prev => prev - 1);
    }
  }, [allTags.length]);

  const visibleTags = allTags.slice(0, maxShowTags);
  const overflowTags = allTags.slice(maxShowTags);

  return (
    <>
      <div className={s.bbConversationTagsWrapper}>
        <div className={s.bbConversationTagsTitle}>
          <div className={s.bbConversationTagsTitleLeft}>
            {t("components:reply:conversationTags")}
            <Help
              iconClassName={s.bbConversationTagsHelp}
              tooltipProps={{
                title: t("components:reply:conversationTagsTooltip"),
              }}
            />
          </div>
          <CustomTags
            commentId={comment?.id || ""}
            customTagsByUser={comment?.custom_tags_by_user || []}
            customTagsAutomated={comment?.custom_tags_by_automation || []}
            hasTags={!!(comment?.tags?.length || comment?.ai_tags?.length)}
            hasTriggerLabel={false}
          />
        </div>

        <div className={s.bbConversationTags} ref={containerRef}>
          {!!comment?.ai_tags?.length && (
            <AiHighlightTags
              tags={comment.ai_tags.filter(tag =>
                new Set(visibleTags.map(vTag => vTag.id)).has(tag.id),
              )}
            />
          )}

          {!!comment?.tags?.length && (
            <CommentTagsList
              tags={comment.tags.filter(tag =>
                new Set(visibleTags.map(vTag => vTag.id)).has(tag.id),
              )}
            />
          )}

          <CustomTagsList
            customTagsAutomated={(comment?.custom_tags_by_automation || []).filter(tag =>
              new Set(visibleTags.map(vTag => vTag.id)).has(tag.id),
            )}
            customTagsByUser={(comment?.custom_tags_by_user || []).filter(tag =>
              new Set(visibleTags.map(vTag => vTag.id)).has(tag.id),
            )}
            onDeleteTag={handleDeleteCustomTag}
          />

          {canManageBBTag && (
            <BrandBastionTags
              tags={comment?.tags || []}
              onAddTag={handleAddBBTag}
              onDeleteTag={handleDeleteBBTag}
            />
          )}
          {!!overflowTags.length && (
            <Tooltip
              title={overflowTags.map((tag, idx) => (
                <span key={idx} className={s.bbTagLabel}>
                  {tag.label}
                </span>
              ))}
            >
              <Chip _size="xs" _type="grey" text={`+${overflowTags.length}`} />
            </Tooltip>
          )}
        </div>
      </div>

      {showMessageTags && messageTagsExist && (
        <div className={s.bbMessageTagsWrapper}>
          <div className={s.bbMessageTagsTitle}>
            {t("components:reply:messageTags")}
            <Help
              iconClassName={s.bbConversationTagsHelp}
              tooltipProps={{
                title: t("components:reply:messageTagsTooltip"),
              }}
            />
          </div>

          <div className={s.bbMessageTags}>
            {!!replyData.message_ai_tags?.length && (
              <AiHighlightTags
                tags={replyData?.message_ai_tags as IAiTag[]}
                customAction={TagCountBadge}
              />
            )}

            {!!replyData?.message_tags?.length && (
              <CommentTagsList tags={replyData?.message_tags || []} customAction={TagCountBadge} />
            )}

            {!!replyData?.message_custom_tags?.length && (
              <CustomTagsList
                customTagsByUser={replyData?.message_custom_tags}
                customAction={TagCountBadge}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CommentDetailsTags;

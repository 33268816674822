import { ActionType } from "typesafe-actions";
import { IRepliesState } from "@store/replies/types";
import {
  performRepliesItemsActionSuccessAction,
  performRepliesParentActionSuccessAction,
} from "@store/replies/actions";

export const handleRepliesItemState = (
  state: IRepliesState,
  action: ActionType<typeof performRepliesItemsActionSuccessAction>,
) => {
  if (action.payload?.commentAction?.action.includes("star")) {
    return {
      ...state,
      data: state.data && {
        ...state.data,
        items: state.data.items.map(item => {
          if (item.id === action.payload.commentAction.id) {
            return {
              ...item,
              is_starred: !!action.payload.commentAction.value,
            };
          }
          return item;
        }),
      },
    };
  }

  if (action.payload?.commentAction?.action.includes("hide")) {
    return {
      ...state,
      data: state.data && {
        ...state.data,
        items: state.data.items.map(item => {
          if (item.id === action.payload.commentAction.id) {
            return {
              ...item,
              is_hidden: !!action.payload.commentAction.value,
            };
          }
          return item;
        }),
      },
    };
  }

  if (
    action.payload?.commentAction?.action.includes("cancel") ||
    action.payload?.commentAction?.action.includes("uncancel")
  ) {
    return {
      ...state,
      data: state.data && {
        ...state.data,
        items: state.data.items.map(item => {
          if (item.id === action.payload.commentAction.id) {
            return {
              ...item,
              is_cancelled: !!action.payload.commentAction.value,
            };
          }
          return item;
        }),
      },
    };
  }

  if (action.payload?.commentAction?.action.includes("ignore")) {
    return {
      ...state,
      data: state.data && {
        ...state.data,
        items: state.data.items.map(item => {
          if (item.id === action.payload.commentAction.id) {
            return {
              ...item,
              is_ignored: !!action.payload.commentAction.value,
            };
          }
          return item;
        }),
      },
    };
  }

  return {
    ...state,
  };
};

export const handleRepliesParentState = (
  state: IRepliesState,
  action: ActionType<typeof performRepliesParentActionSuccessAction>,
) => {
  if (action.payload?.commentAction?.action.includes("star")) {
    return {
      ...state,
      data: state.data && {
        ...state.data,
        parent: state.data.parent && {
          ...state.data.parent,
          is_starred: !!action.payload.commentAction.value,
        },
      },
    };
  }

  if (action.payload?.commentAction?.action.includes("hide")) {
    return {
      ...state,
      data: state.data && {
        ...state.data,
        parent: state.data.parent && {
          ...state.data.parent,
          is_hidden: !!action.payload.commentAction.value,
        },
      },
    };
  }

  if (action.payload?.commentAction?.action.includes("ignore")) {
    return {
      ...state,
      data: state.data && {
        ...state.data,
        parent: state.data.parent && {
          ...state.data.parent,
          is_ignored: !!action.payload.commentAction.value,
        },
      },
    };
  }
  return {
    ...state,
  };
};

export const extractBracketedText = (text: string) => text.split(/(\{\{.*?\}\})/);

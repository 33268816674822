import { IMenuItem } from "./AppHeader.type";
import { removeItemUrlSearch } from "@bbdevcrew/bb_ui_kit_fe";
import { PreRedirectFnType } from "../AppLayout/AppLayout.types";

export const findItem = (menu: IMenuItem[], key: string) =>
  menu.find(m => m.subfeatures?.find(sub => sub.id === key));

export const selectItem = (menu: IMenuItem[], key: string) => {
  const parentFeature = findItem(menu, key)?.basename;

  if (!parentFeature) return [];

  return [parentFeature, key];
};

export const isItemActive = (item: IMenuItem) => location.href.includes(item.id);

const resolvePreRedirect = async (onPreRedirect: PreRedirectFnType, isSameBase: boolean) => {
  // Resolve "pre-redirect" functions, if any
  try {
    await onPreRedirect(!isSameBase);
  } catch (err) {
    console.error(err);
  }
};

const filterParams = (queryString: string) => {
  const query = queryString.startsWith("?") ? queryString.slice(1) : queryString;
  const params = new URLSearchParams(query);
  const filteredParams = new URLSearchParams();
  const id = params.get("id");
  const savedFilter = params.get("saved_filter");

  if (params.has("id") && id) filteredParams.set("id", id);
  if (params.has("saved_filter") && savedFilter) filteredParams.set("saved_filter", savedFilter);

  const filteredQueryString = filteredParams.toString();

  return filteredQueryString ? `${filteredQueryString}` : "";
};

const redirectToFeature = (feature: IMenuItem) => {
  const { url } = feature;

  removeItemUrlSearch("tab");
  const isAbsPath = url?.startsWith("https://");
  const redirectUrl = isAbsPath ? url : `${window.location.origin}${url}`;
  const queryParams =
    "preserveFilters" in feature && feature.preserveFilters
      ? filterParams(`${localStorage.getItem("query") || ""}`)
      : "";

  const noExistingQueryParams =
    redirectUrl && Array.from(new URL(redirectUrl).searchParams).length === 0;
  window.location.href =
    redirectUrl + (!!queryParams ? `${noExistingQueryParams ? "?" : "&"}${queryParams}` : "");
};

export const onFeatureRedirect = async (
  onPreRedirect: PreRedirectFnType,
  item: IMenuItem,
  isSameBase: boolean,
) => {
  resolvePreRedirect(onPreRedirect, isSameBase);
  redirectToFeature(item);
};

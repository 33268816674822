import { useTranslation } from "react-i18next";
import React, { FC } from "react";

import { Avatar } from "antd";
import { PostPreviewTooltip } from "../PostPreviewTooltip/PostPreviewTooltip";

import s from "./PostView.module.less";

import { getPostSubtext } from "@bbdevcrew/bb_ui_kit_fe";
import { IPostPreviewTooltipProps } from "../CommentDetail/CommentDetail.type";

const PostViewTooltip: FC<IPostPreviewTooltipProps> = ({ post }) => {
  const { t } = useTranslation();

  return (
    <PostPreviewTooltip post={post}>
      <div className={s.bbPostViewTooltip}>
        {!!post.picture && (
          <Avatar size={40} shape="square" src={post.picture} className={s.bbPostViewTooltipImage}>
            {t("generic:noImage")}
          </Avatar>
        )}

        <div className={s.bbPostViewTooltipInfo}>
          <span className={s.bbPostViewTooltipName}>{post.author_name}</span>
          <span>{getPostSubtext(post, "DD MMM, YYYY")}</span>
          <span>{post.message}</span>
        </div>
      </div>
    </PostPreviewTooltip>
  );
};

export default PostViewTooltip;

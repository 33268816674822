import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect } from "react";

import { Avatar } from "antd";
import { Loading, CommentCardBody } from "@bbdevcrew/bb_ui_kit_fe";

import {
  getCommentSelector,
  getCommentByIdFetchingSelector,
  fetchingCommentsAttachmentsSelector,
  fetchedCommentsAttachmentsSelector,
} from "@store/comments/selectors";
import {
  fetchedRepliesAttachmentsSelector,
  fetchingRepliesAttachmentsSelector,
} from "@store/replies/selectors";
import { getCommentByIdAction } from "@store/comments/actions";
import { openedAttachmentSelector } from "@store/attachment/selectors";
import { closeAttachmentAction, openAttachmentAction } from "@store/attachment/actions";

import s from "./ReplyingCommentPreview.module.less";

import {
  cropText,
  IAttachment,
  extractDate,
  IPrivateAttachment,
  getPlatformIconBasicColor,
  formatTime,
  getCommentSourceInfo,
} from "@bbdevcrew/bb_ui_kit_fe";
import { COMMENT_MAX_LENGTH } from "../../CommentsGrid/CommentsGridItem";

import { LoadingOutlined } from "@ant-design/icons";

interface IReplyingCommentPreviewProps {
  commentId: string;
}

const ReplyingCommentPreview: FC<IReplyingCommentPreviewProps> = ({ commentId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const comment = useSelector(getCommentSelector);
  const commentLoading = useSelector(getCommentByIdFetchingSelector);
  const openedAttachment = useSelector(openedAttachmentSelector);
  const isFetchingRepliesAttachments = useSelector(fetchingRepliesAttachmentsSelector);
  const fetchedRepliesAttachments = useSelector(fetchedRepliesAttachmentsSelector);
  const isFetchingCommentsAttachments = useSelector(fetchingCommentsAttachmentsSelector);
  const fetchedCommentsAttachments = useSelector(fetchedCommentsAttachmentsSelector);

  const onAttachmentOpen = (attachment: IAttachment | IPrivateAttachment) => {
    dispatch(openAttachmentAction(attachment));
  };

  const onAttachmentClose = () => dispatch(closeAttachmentAction());

  const getCommentById = useCallback(
    (id: string) => dispatch(getCommentByIdAction(id)),
    [dispatch],
  );

  useEffect(() => {
    getCommentById(commentId);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!!comment && !commentLoading && (
        <div className={s.bbReplyingCommentPreview}>
          <>
            <div>
              <div className={classNames(s[`bbCommentSentimentBg-${comment.sentiment}`])}>
                <div className={s.bbContainerSentiment}></div>
              </div>
              <div>
                <div className={s.bbUsername}>{comment.username}</div>

                <div className={s.bbDateWrapper}>
                  <span className={s.bbCommentDate}>
                    {extractDate(comment.created_time_pretty)}
                  </span>
                  <span>{t("generic:at")}</span>{" "}
                  <span>{formatTime(comment.created_time_pretty)}</span>
                </div>

                <div className={s.bbCommentSourceInfo}>
                  {getCommentSourceInfo(comment.comment_phrase || "")}
                </div>
                <CommentCardBody
                  showSeeMore={false}
                  comment={comment}
                  maxLengthComment={COMMENT_MAX_LENGTH}
                  onAttachmentOpen={onAttachmentOpen}
                  onAttachmentClose={onAttachmentClose}
                  openedAttachment={openedAttachment}
                  isFetchingRepliesAttachments={isFetchingRepliesAttachments}
                  fetchedRepliesAttachments={fetchedRepliesAttachments}
                  isFetchingCommentsAttachments={isFetchingCommentsAttachments}
                  fetchedCommentsAttachments={fetchedCommentsAttachments}
                />
              </div>
            </div>
            {comment.post && (
              <div className={s.bbReplyingCommentPostPreview}>
                <div>
                  <div className={s.bbReplyingCommentPostPreviewWrapper}>
                    <div>
                      <Avatar
                        size={72}
                        shape="square"
                        style={{ fontSize: "12px" }}
                        src={comment.post?.picture || ""}
                        className={s.bbReplyingCommentPostImagePreview}
                      >
                        {t("generic:noImage")}
                      </Avatar>
                    </div>

                    <div className={s.bbReplyingCommentPostPreviewDetails}>
                      <div className={s.bbReplyingCommentPostPreviewDetailsWrapper}>
                        <div className={s.bbReplyingCommentPostPreviewPlatform}>
                          {comment.post.platform_type.id &&
                            getPlatformIconBasicColor(comment.post.platform_type.id)}
                        </div>
                        <div>
                          <div className={s.bbReplyingCommentPostAuthor}>
                            {comment.post.author_name}
                          </div>
                          <div className={s.bbReplyingCommentPostDate}>
                            {extractDate(comment.post?.created_time_pretty)}
                          </div>
                        </div>
                      </div>
                      <div className={s.bbReplyingCommentPostMessage}>
                        {cropText(comment.post.message, 10)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      )}
      {commentLoading && (
        <div className={s.bbReplyingCommentPreviewLoading}>
          <Loading
            isLoading
            type="spinning"
            tip={t("pages:loading:spinText")}
            indicator={<LoadingOutlined />}
          />
        </div>
      )}
    </>
  );
};

export default ReplyingCommentPreview;

import { t } from "../../../../App";
import React, { useEffect, useState } from "react";

import { Trans } from "react-i18next";
import { EyeIcon, ITableColumn, SortType, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ProfilesTable.module.less";

import { ISimpleTableColumn } from "@bbdevcrew/bb_ui_kit_fe";
import {
  IProfilesSort,
  ProfilesTableItemType,
  ProfileTableSectionType,
  UseTableLogicProps,
} from "./ProfilesTable.types";

import {
  AdIcon,
  UserIcon,
  OrganicIcon,
  InformationIcon,
  FacebookRoundColorIcon,
  InstagramRoundColor2Icon,
  TiktokRoundColor2Icon,
  TwitterRoundColorIcon,
  YoutubeRoundColorIcon,
  LinkedinRoundColorIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { MagnetIcon } from "@assets/index";

const TOOLTIP_WIDTH = 300;
export const DEFAULT_PAGE_SIZE = 50;
export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const getColumnPrefix = (content: React.ReactNode) => (
  <span className={s.bbColumnPrefix}>{content}</span>
);

export const initialSort: IProfilesSort = {
  order: "asc",
  field: "platform",
};

export const PlatformTypeIconRound = {
  facebook: FacebookRoundColorIcon,
  instagram: InstagramRoundColor2Icon,
  twitter: TwitterRoundColorIcon,
  tiktok: TiktokRoundColor2Icon,
  linkedin: LinkedinRoundColorIcon,
  youtube: YoutubeRoundColorIcon,
};

const columnsIconMap: Record<string, JSX.Element | undefined> = {
  followers_count: <UserIcon />,
  percentage: <UserIcon />,
  growth: <UserIcon />,
  total_impressions: <EyeIcon />,
  organic_impressions: <OrganicIcon />,
  paid_impressions: <AdIcon />,
  er_impressions: <MagnetIcon />,
};

export const getPlatformIcon = (platformTypeId: string) => {
  const Icon = PlatformTypeIconRound[platformTypeId as keyof typeof PlatformTypeIconRound];
  return Icon ? <Icon /> : null;
};

export const getColSpanMaps = (type: ProfileTableSectionType) => {
  if (type === "followers") return [5, 5, 7, 7];
  else if (type === "impressions-platform") return [12, 4, 4, 4];
  else if (type === "engagements") return [8, 7, 9];
  return [18, 6];
};

const getProfilesColumns = (type: string) => {
  if (type === "followers") return ["platform", "followers_count", "percentage", "growth"];
  else if (type === "impressions-platform")
    return ["name", "total_impressions", "organic_impressions", "paid_impressions"];
  else if (type === "engagements") return ["platform", "er_impressions", "engagements"];
  return ["platform", "total_impressions"];
};

const hasColumnTootip = (key: string) =>
  ["followers_count", "total_impressions", "er_impressions", "engagements"].includes(key);

export const getProfilesColumnsWithKeys = (type: ProfileTableSectionType): ISimpleTableColumn[] => {
  return getProfilesColumns(type).map((key, index) => ({
    name: hasColumnTootip(key) ? "" : t(`components:listen:profiles:tables:${type}:columns:${key}`),
    id_name: key,
    colSpan: getColSpanMaps(type)[index],
    ...(columnsIconMap[key] && { prefix: getColumnPrefix(columnsIconMap[key]) }),
    ...(hasColumnTootip(key) && {
      suffix: (
        <div
          style={{
            display: "flex",
          }}
        >
          <span>{t(`components:listen:profiles:tables:${type}:columns:${key}`)}</span>
          <Tooltip
            placement="top"
            showArrow={false}
            overlayInnerStyle={{
              minWidth: TOOLTIP_WIDTH,
            }}
            title={
              <Trans
                i18nKey={`components:listen:profiles:tables:${type}:tooltips:${key}`}
                components={{
                  br: <br />,
                  strong: <strong />,
                }}
              />
            }
          >
            <div className={s.bbHeaderColIcon}>
              <InformationIcon />
            </div>
          </Tooltip>
        </div>
      ),
    }),
  }));
};

const getSortedData = <T extends ProfilesTableItemType>(
  data: T[],
  page: number,
  size: number,
  sort?: IProfilesSort,
): T[] => {
  if (!sort) {
    return data.slice((page - 1) * size, page * size);
  }

  return [...data]
    .sort((a, b) => {
      const valueA = a[sort.field as keyof typeof a];
      const valueB = b[sort.field as keyof typeof b];

      if (valueA && !valueB) return sort.order === "asc" ? 1 : -1;
      if (!valueA && valueB) return sort.order === "asc" ? -1 : 1;

      if (valueA && valueB) {
        if (sort.order === "asc") {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      }

      return 0;
    })
    .slice((page - 1) * size, page * size);
};

export const useProfilesTableLogic = <T extends ProfilesTableItemType>({
  data,
}: UseTableLogicProps<T>) => {
  const [sort, setSort] = useState<IProfilesSort>(initialSort);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [tableData, setTableData] = useState<T[]>([]);

  useEffect(() => {
    const sorted = getSortedData(data, currentPage, pageSize, sort);
    setTableData(sorted);
  }, [data, sort, currentPage, pageSize]);

  const onSort = (column: ITableColumn, _sort?: SortType) => {
    if (!_sort) {
      setSort(initialSort);
    } else {
      setSort({
        order: _sort,
        field: column.id_name,
      });
    }
  };

  const onChangePage = (newPage: number, newSize?: number) => {
    setCurrentPage(newPage);
    if (newSize) {
      setPageSize(newSize);
    }
  };

  return {
    tableData,
    sort,
    currentPage,
    pageSize,
    onSort,
    onChangePage,
  };
};

import cn from "classnames/bind";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import s from "./BreakdownChartTooltip.module.less";

import { TooltipProps } from "recharts";

export const BreakdownChartTooltip: FC<TooltipProps<string, string>> = ({ active, payload }) => {
  const bars = payload;
  const cx = cn.bind(s);
  const { t } = useTranslation();
  const totalKey = t("components:postTrends:charts:total");

  const getTotal = () => {
    if (bars?.length) {
      const bar = bars[0];

      if (bar.payload[totalKey]) return bar.payload[totalKey];
      else {
        const barValues: string[][] = Object.entries(bar.payload);
        const sentiments: number[] = barValues
          .filter(([key]) => key !== "id" && key !== "label")
          .map(([_, val]) => parseFloat(val)); // eslint-disable-line

        return sentiments.reduce((a, b) => a + b);
      }
    }
  };

  const total = getTotal();

  const getPercentage = (val: string) => {
    const value = parseFloat(val);

    return total ? +((value * 100) / total).toFixed(2) : 0;
  };

  return (
    <React.Fragment>
      {active && bars?.length ? (
        <div
          className={cx(s.bbBreakdownChartTooltip, {
            [s.bbTooltipLarge]: bars.length > 5,
          })}
        >
          <div className={s.bbItemName}>{bars[0].payload.label}</div>
          <div className={s.bbStatsContainer}>
            {bars.map(bar => (
              <div className={s.bbStatContainer} key={bar.name}>
                <div className={s.bbStatSquare} style={{ background: bar.color }}></div>
                <div className={s.bbStatItem}>
                  <div className={s.bbStatName}>{bar.name}</div>
                  <div>
                    {bar.value} ({bar.value ? getPercentage(bar.value.toString()) : 0}
                    %)
                  </div>
                </div>
              </div>
            ))}
            <div className={s.bbStatContainer}>
              <div className={s.bbStatItem}>
                <div className={s.bbStatName}>{totalKey}</div>
                <div>{total}</div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

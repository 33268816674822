import React, { FC } from "react";

import { extractDate } from "@bbdevcrew/bb_ui_kit_fe";
import { IDateTickProps } from "./TimelineChart.types";

const DateTick: FC<IDateTickProps> = ({ data, tick }) => {
  const { x, y, payload } = tick;
  const { value, offset } = payload;
  const time = data?.[payload.index]?.hour;

  return (
    <g>
      <text y={y + 15} x={x + offset}>
        {value && value !== "auto" ? extractDate(value) : ""}
      </text>

      {time ? (
        <text y={y + 30} x={x + offset} fontSize={15}>
          {time}
        </text>
      ) : null}
    </g>
  );
};

export default DateTick;

import classNames from "classnames";
import { useSelector } from "react-redux";
import React, { FC, useState, useEffect, MouseEvent } from "react";

import { Tooltip, ChipAction, Chip } from "@bbdevcrew/bb_ui_kit_fe";

import {
  deleteCustomTagFromCommentSuccessfulSelector,
  deletingCustomTagFromReplyIdSelector,
} from "@store/customTags/selectors";

import s from "./CustomTags.module.less";

import { ICustomTag } from "@bbdevcrew/bb_ui_kit_fe";
import { maxChipCount } from "../commentTags/commentTagsConfig";

import { LoadingOutlined } from "@ant-design/icons";
import { CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ICustomTagsListProps {
  // eslint-disable-next-line
  customAction?: FC<any>;
  customTagsByUser?: ICustomTag[];
  customTagsAutomated?: ICustomTag[];
  onDeleteTag?: (tagId: string) => void;
}

const CustomTagsList: FC<ICustomTagsListProps> = ({
  customTagsAutomated,
  customTagsByUser,
  customAction: CustomActionComponent,
  onDeleteTag,
}) => {
  const [deletingCustomTagId, setDeletingCustomTagId] = useState<string | null>(null);

  const customTagFromCommentDeleted = useSelector(deleteCustomTagFromCommentSuccessfulSelector);
  const customTagFromReplyDeletedId = useSelector(deletingCustomTagFromReplyIdSelector);

  const newlyAddedCustomTags = customTagsByUser?.filter(tag => tag.newlyAdded);
  const customTags = customTagsByUser?.filter(tag => !tag.newlyAdded);

  useEffect(() => {
    if (customTagFromCommentDeleted || customTagFromReplyDeletedId) {
      setDeletingCustomTagId(null);
    }
  }, [customTagFromCommentDeleted, customTagFromReplyDeletedId]);

  const deleteTag = (event: MouseEvent<HTMLButtonElement>, tagId?: string) => {
    event.preventDefault();

    if (tagId) {
      setDeletingCustomTagId(tagId);

      onDeleteTag?.(tagId);
    }
  };

  const renderActionIcon = (tag: ICustomTag) => (
    <>
      {!!CustomActionComponent ? (
        <CustomActionComponent tag={tag} />
      ) : !!onDeleteTag ? (
        <>
          {tag.id === deletingCustomTagId || !tag.id ? (
            <LoadingOutlined spin className={s.bbLoading} />
          ) : (
            <CloseIcon />
          )}
        </>
      ) : null}
    </>
  );

  const renderTags = (tags: ICustomTag[]) => (
    <>
      {tags.slice(0, maxChipCount).map(tag => {
        const { id, label, tooltip } = tag;

        return (
          <Tooltip title={tooltip} key={id}>
            <span
              className={classNames(s.bbTagWrapper, {
                [s.bbTagWrapperWithTooltip]: tooltip,
              })}
            >
              <ChipAction
                key={id || label}
                _size="xs"
                _type="grey"
                text={label}
                actionIcon={renderActionIcon(tag)}
                onActionClick={
                  !CustomActionComponent && !!onDeleteTag ? e => deleteTag(e, id) : undefined
                }
              />
            </span>
          </Tooltip>
        );
      })}
      {tags?.length > maxChipCount && (
        <Tooltip
          title={tags.slice(maxChipCount).map((tag, idx) => (
            <span key={idx} className={s.bbTagLabel}>
              {tag.label}
            </span>
          ))}
        >
          <Chip
            _size="xs"
            _type="grey"
            className={s.bbChipCount}
            text={`+${tags?.length - maxChipCount}`}
          />
        </Tooltip>
      )}
    </>
  );

  return !!customTagsByUser?.length || !!customTagsAutomated?.length ? (
    <>
      {renderTags([
        ...(customTags || []),
        ...(customTagsAutomated || []),
        ...(newlyAddedCustomTags || []),
      ])}
    </>
  ) : null;
};

export default CustomTagsList;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AssignUser from "@components/_common/AssignUser/AssignUser";

import {
  usersSelector,
  getUsersPendingSelector,
  getUsersSuccessfulSelector,
} from "@store/users/selectors";
import { meSelector } from "@store/me/selectors";
import { getUsersAction } from "@store/users/actions";
import { assignCommentUserAction } from "@store/comments/actions";

interface IAssignUserProps {
  value?: string[];
  assigningCommentId: string;
  triggerClassName?: string;
  triggerPlaceholder?: string;
  hideTriggerPlaceholder?: boolean;
}

function AssignUserContainer({
  value = [],
  assigningCommentId,
  triggerClassName,
  triggerPlaceholder,
  hideTriggerPlaceholder = false,
}: IAssignUserProps) {
  const dispatch = useDispatch();

  const me = useSelector(meSelector);
  const users = useSelector(usersSelector);
  const usersFetching = useSelector(getUsersPendingSelector);
  const usersFetched = useSelector(getUsersSuccessfulSelector);

  const onAssignUserChange = (assignedUsers: string[]) => {
    dispatch(
      assignCommentUserAction(assigningCommentId, { assigned_users: assignedUsers }, me?.id),
    );
  };

  useEffect(() => {
    if (!usersFetched && !usersFetching) dispatch(getUsersAction());
  }, [usersFetched, usersFetching, dispatch]);

  return (
    <>
      {me?.id && (
        <AssignUser
          users={users}
          value={value}
          currentUserId={me.id}
          onChange={onAssignUserChange}
          triggerClassName={triggerClassName}
          triggerPlaceholder={triggerPlaceholder}
          hideTriggerPlaceholder={hideTriggerPlaceholder}
        />
      )}
    </>
  );
}

export default AssignUserContainer;

import { concatMap, map, of } from "rxjs";
import { StateObservable } from "redux-observable";

import { RootState } from "..";
import { PlatformType } from "@bbdevcrew/bb_ui_kit_fe";
import { isTiktokApp } from "@utils/isTiktokApp";
import { getCurrentModuleName } from "@utils/paths";
import { REGISTER_TOKEN } from "../onboardingPlatforms/actionTypes";
import { IRegisterTokenAPIResponse, IRegisterTokenPayload } from "./types";
import { getOnboardingPlatformAction, registerTokenSuccessAction } from "./actions";

export const getRegisterUri = (platformId: string) => {
  if (platformId === "linkedin" || platformId === "trustpilot")
    return window.location.origin + window.location.pathname + `?social_platform=${platformId}`;

  return window.location.origin;
};

export const getRegisterTokenSuccessAction = (a: {
  type: typeof REGISTER_TOKEN;
  payload: {
    tokenPayload: IRegisterTokenPayload;
    platformId: PlatformType;
  };
}) => {
  return getCurrentModuleName() === "login"
    ? concatMap((data: IRegisterTokenAPIResponse) =>
        of(
          registerTokenSuccessAction(data, a.payload.platformId),
          getOnboardingPlatformAction(a.payload.platformId),
        ),
      )
    : map((data: IRegisterTokenAPIResponse) =>
        registerTokenSuccessAction(data, a.payload.platformId),
      );
};

export const getUserAccessToken = (state$: StateObservable<RootState>) => {
  if (isTiktokApp() && getCurrentModuleName() === "login") {
    return localStorage.getItem("ttAccessToken") || state$.value.user?.access_token;
  }

  return getCurrentModuleName() === "login" || window.location.pathname === "/"
    ? state$.value.user?.access_token
    : state$.value.auth.session.accessToken?.jwtToken || localStorage.getItem("accessToken");
};

import { Trans } from "react-i18next";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import { Button, Modal, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import {
  meSelector,
  patchMeClientFetchingSelector,
  patchMeClientSuccessfulSelector,
} from "@store/me/selectors";
import { toggleOpenAIModalAction } from "@store/aiInsights/actions";
import { patchMeClientAction } from "@store/me/actions";

import s from "./GenerateAIButton.module.less";

import { AIInsightsModalStateEnum } from "@store/aiInsights/types";

import GenAITermsImg from "@assets/genAITerms.png";
import { MagicSparklesIcon } from "@bbdevcrew/bb_ui_kit_fe";

const FEATURES_LIST_NUMBER = 3;

const cx = classNames.bind(s);

export const GenerateAIButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openAITermsModal, setOpenAITermsModal] = useState(false);

  const me = useSelector(meSelector);
  const fetchingPatchMe = useSelector(patchMeClientFetchingSelector);
  const fetchedPatchMe = useSelector(patchMeClientSuccessfulSelector);

  const updateMeClient = useCallback(data => dispatch(patchMeClientAction(data)), [dispatch]);

  const openAiModal = useCallback(
    () => dispatch(toggleOpenAIModalAction(AIInsightsModalStateEnum.Expanded)),
    [dispatch],
  );

  const chatAvailable = !!me?.client?.chat_available;
  const isAdmin = me?.is_admin;
  const brandName = me?.client?.name;
  const chatEnabled = !!me?.client?.chat_enabled;
  const isButtonDisabledForUsers = !isAdmin && !chatEnabled;

  const onClose = () => setOpenAITermsModal(false);

  const onGetStarted = useCallback(() => {
    onClose();
    openAiModal();
  }, [openAiModal]);

  const handleConsentAction = () => {
    updateMeClient({ chat_enabled: true });
  };

  const onGenerateAIClick = () => {
    if (isButtonDisabledForUsers) return;
    if (!chatEnabled) {
      setOpenAITermsModal(true);
    } else {
      onGetStarted();
    }
  };

  useEffect(() => {
    if (fetchedPatchMe && chatEnabled) onGetStarted();
  }, [fetchedPatchMe, onGetStarted, chatEnabled]);

  return (
    <>
      {chatAvailable && (
        <Tooltip title={isButtonDisabledForUsers ? t("components:aiInsights:disabledTooltip") : ""}>
          <Button
            className={cx(s.bbGenerateAIButton, {
              [s.bbGenerateAIButtonDisabled]: isButtonDisabledForUsers,
            })}
            _type="primary"
            _size="sm"
            onClick={onGenerateAIClick}
            data-cy="generate-ai-button"
          >
            <MagicSparklesIcon />
            {t("components:aiInsights:button")}
          </Button>
        </Tooltip>
      )}
      <Modal
        title={t("components:aiInsights:termsModal:title", { brandName })}
        open={openAITermsModal}
        width={800}
        centered
        confirmLoading={fetchingPatchMe}
        confirmLabel={t("components:aiInsights:termsModal:confirmLabel")}
        onCancel={onClose}
        onOk={handleConsentAction}
        noPadding
        cancelButtonProps={{
          _type: "secondary",
        }}
      >
        <div className={s.bbGenerateAIModalContent} data-cy="generate-ai-terms-modal">
          <img src={GenAITermsImg} className={s.bbGenerateAIModalPic} />
          <div className={s.bbGenerateAIModalTexts}>
            <p>{t("components:aiInsights:termsModal:sentence1")}</p>
            <ul>
              {Array.from({ length: FEATURES_LIST_NUMBER }).map((_, index) => (
                <li key={index}>
                  <Trans
                    i18nKey={`components:aiInsights:termsModal:feature${index + 1}`}
                    components={{ strong: <strong /> }}
                  />
                </li>
              ))}
            </ul>
            <p className={s.bbGenerateAIModalAgreement}>
              <Trans
                i18nKey="components:aiInsights:termsModal:getStartedAgreement"
                components={{
                  a: (
                    <a
                      href="https://info.brandbastion.com/app/gen-ai-terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                  brandName,
                }}
              />
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames/bind";

import { Col, Empty } from "antd";
import { Card, Loading } from "@bbdevcrew/bb_ui_kit_fe";
import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";

import s from "./ReviewsWidget.module.less";

import {
  getPlatformIcon,
  isFacebookPlatform,
  isGoogleBusinessPlatform,
  isTrustpilotPlatform,
} from "@bbdevcrew/bb_ui_kit_fe";
import {
  getPercentageType,
  getReviewTypes,
  sortedRatingDistribution,
} from "./ReviewWidget.helpers";
import { getBreakdownChartBarColor } from "@components/_common/BreakdownChart/helpers";
import { IReviewsWidgetComparisonProps } from "./ReviewsWidget.types";

const cx = cn.bind(s);

export const PlatformComparison: FC<IReviewsWidgetComparisonProps> = ({ data = [], loading }) => {
  const { t } = useTranslation();
  const hasFacebookAsset = data.some(
    item => !!item.platform?.type && isFacebookPlatform(item.platform.type.toLowerCase()),
  );
  const hasStarredReviewAssets = data.some(
    item =>
      !!item.platform?.type &&
      (isGoogleBusinessPlatform(item.platform.type.toLowerCase()) ||
        isTrustpilotPlatform(item.platform.type.toLowerCase())),
  );

  return (
    <Col xs={24} xxl={24}>
      <Card
        className={s.bbCard}
        headerTitle={t("components:reviewWidget:comparison:headerTitle")}
        headerSubTitle={t("components:reviewWidget:comparison:headerSubTitle")}
      >
        {loading ? (
          <div className={s.bbReviewWidgetLoading}>
            <Loading isLoading />
          </div>
        ) : data?.length ? (
          <div className={s.bbReviewPlatform}>
            {data.map((item, index) => (
              <div key={index} className={s.bbReviewPlatformItem}>
                <div className={s.bbReviewPlatformName}>
                  <span>{getPlatformIcon(item.platform.type.toLowerCase())}</span>
                  <span>{item.platform.label}</span>
                </div>
                <div className={s.bbReviewPlatformChartWrapper}>
                  <div
                    className={s.bbReviewPlatformChartBar}
                    style={{
                      width: `${item.percentage && data.length > 1 ? item.percentage : 100}%`,
                    }}
                  >
                    {sortedRatingDistribution(item.distribution || [])
                      .filter(i => !!i.percentage)
                      .map((distributionItem, dIndex, arr) => {
                        const firstItem = dIndex === 0;
                        const lastItem = dIndex === arr.length - 1;
                        return (
                          <div
                            key={dIndex}
                            className={cx({
                              [s.bbReviewPlatformChartBarFirst]: firstItem,
                              [s.bbReviewPlatformChartBarLast]: lastItem,
                            })}
                            style={{
                              backgroundColor: getBreakdownChartBarColor(distributionItem.type),
                              width: `${distributionItem.percentage}%`,
                            }}
                          />
                        );
                      })}
                  </div>
                  <div className={s.bbReviewPlatformLabel}>
                    <span className={s.bbReviewPlatformValue}>{item.reviewCount}</span>
                    {!!item.percentage && (
                      <span className={s.bbReviewPlatformPercentage}>({item.percentage}%)</span>
                    )}
                    <PerformanceCardPill
                      type={getPercentageType(
                        item.percentageChange?.display,
                        item.percentageChange?.value,
                      )}
                      percentageValue={item.percentageChange?.value || 0}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className={s.bbReviewComparisonLegend}>
              {getReviewTypes(hasFacebookAsset, hasStarredReviewAssets).map((item, index) => (
                <div className={s.bbReviewComparisonLegendItem} key={index}>
                  <div
                    className={s.bbReviewComparisonLegendItemMark}
                    style={{
                      background: getBreakdownChartBarColor(item.type),
                    }}
                  />
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Empty
            className={s.bbReviewWidgetEmpty}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("components:empty:description")}
          />
        )}
      </Card>
    </Col>
  );
};

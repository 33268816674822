import React from "react";
import classNames from "classnames/bind";

import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";
import { Col } from "antd";
import { ColumnWithGrowth } from "./ProfilesTableRow.helpers";

import s from "./ProfilesTable.module.less";

import { formatter } from "@components/_common/CardStat/helpers";
import { ProfilesTablePrefixProps } from "./ProfilesTable.types";

export const ProfilesTablePrefix = ({ value, type }: ProfilesTablePrefixProps) => {
  return (
    <div className={classNames(s.bbProfilesTableRow, s.bbProfilesTablePrefix)}>
      {type === "followers" && (
        <>
          <Col span={5}>{value?.asset_name}</Col>
          <Col span={5}>
            {value?.followers_count != null ? formatter(value?.followers_count) : ""}
          </Col>
          <Col span={7}>{value?.percentage != null ? `${value.percentage}%` : ""}</Col>
          <Col span={7}>
            {!!value?.growth && <PerformanceCardPill percentageValue={Number(value.growth)} />}
          </Col>
        </>
      )}
      {type === "impressions-platform" && (
        <>
          <Col span={12}>{value?.name}</Col>
          <Col className={s.bbPrefixColumn} span={4}>
            <ColumnWithGrowth
              value={value?.total_impressions}
              growth={value?.total_impression_growth}
            />
          </Col>
          <Col className={s.bbPrefixColumn} span={4}>
            <ColumnWithGrowth
              value={value?.organic_impressions}
              growth={value?.organic_impression_growth}
            />
          </Col>
          <Col className={s.bbPrefixColumn} span={4}>
            <ColumnWithGrowth
              value={value?.paid_impressions}
              growth={value?.paid_impression_growth}
            />
          </Col>
        </>
      )}
      {type === "impressions-profile" && (
        <>
          <Col span={18}>{value?.asset_name}</Col>
          <Col span={6} className={s.bbImpressionColumn}>
            <ColumnWithGrowth value={value?.total_impressions} growth={value?.growth} />
          </Col>
        </>
      )}
      {type === "engagements" && (
        <>
          <Col span={8}>{value?.asset_name}</Col>
          <Col className={s.bbPrefixColumn} span={7}>
            <ColumnWithGrowth value={value?.er_impressions} growth={value?.er_impression_growth} />
          </Col>
          <Col className={s.bbPrefixColumn} span={9}>
            <ColumnWithGrowth value={value?.engagements} growth={value?.engagements_growth} />
          </Col>
        </>
      )}
    </div>
  );
};

import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Col, Row } from "antd";
import { CardList } from "./cardList/CardList";
import { PermissionList } from "./PermissionList";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import CrawlingAdCommentsToggle from "./CrawlingAdCommentsToggle";

import s from "./AssetTabs.module.less";

import {
  IPlatformAsset,
  SelectDeselectType,
  IOnboardingPlatformItem,
  IOnboardingPlatformPermissions,
} from "./AssetTabs.type";
import { PlatformType } from "@bbdevcrew/bb_ui_kit_fe";

import { InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IPermissionsAndAssetsProps {
  permissions?: IOnboardingPlatformPermissions;
  hideMonitorToggler?: boolean;
  isWebhookEnabled?: boolean;
  selectedPlatform: PlatformType;
  selectedAssets?: IPlatformAsset[];
  platformTypes: IOnboardingPlatformItem[];
  disclaimerText?: string;
  onWebhookChange?: ({ target: { checked } }: { target: { checked: boolean } }) => boolean;
  onAssetSelect?: (type: SelectDeselectType, assets: IPlatformAsset[]) => void;
}

const PermissionsAndAssets: FC<IPermissionsAndAssetsProps> = ({
  permissions,
  platformTypes,
  selectedAssets,
  isWebhookEnabled,
  selectedPlatform,
  disclaimerText,
  hideMonitorToggler = false,
  onAssetSelect,
  onWebhookChange,
}) => {
  const onAssetSelectClick = (type: SelectDeselectType, assets: IPlatformAsset[]) => {
    onAssetSelect && onAssetSelect(type, assets);
  };
  const { t } = useTranslation();

  return (
    <>
      {!!platformTypes.length && (
        <Row align="stretch" gutter={[32, 32]} className={s.bbAssetCardRow}>
          {platformTypes.map((platformType, index) => {
            return (
              <Col
                xs={24}
                md={12}
                lg={10}
                xl={8}
                xxl={6}
                key={platformType.id}
                style={{ paddingBottom: "16px" }}
              >
                <>
                  <CardList
                    alert={platformType.alert}
                    platformType={platformType}
                    selectedAssets={selectedAssets}
                    onAssetSelect={(type, assets) => onAssetSelectClick(type, assets)}
                  />
                  {index === 0 && selectedPlatform == "facebook" && !hideMonitorToggler && (
                    <CrawlingAdCommentsToggle
                      isWebhookEnabled={isWebhookEnabled}
                      onWebhookChange={onWebhookChange}
                    />
                  )}
                </>
              </Col>
            );
          })}
        </Row>
      )}
      {!!disclaimerText && (
        <div className={s.bbAssetTabsDisclaimer}>
          {disclaimerText}
          <Tooltip title={t("pages:addAssets:disclaimerTooltip")} placement="top">
            <span className={s.bbAssetTabsDisclaimerIcon}>
              <InformationIcon />
            </span>
          </Tooltip>
        </div>
      )}
      {(selectedPlatform === "facebook" || permissions?.status !== "complete") &&
        permissions?.items &&
        permissions.items.length > 0 && (
          <PermissionList
            platform={selectedPlatform}
            permissions={permissions.items}
            isTokenMissing={permissions?.status === "token_missing"}
          />
        )}
    </>
  );
};

export default PermissionsAndAssets;

import cn from "classnames/bind";
import React from "react";
import { useSelector } from "react-redux";

import { Row, Col } from "antd";
import { Loading } from "@bbdevcrew/bb_ui_kit_fe";
import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";
import TikTokPreview from "./TikTokPreview";

import { fetchingTiktokTrendingHashtagVideosSelector } from "@store/tiktokHashtags/selectors";

import s from "./TiktokTrendingHashtags.module.less";

import { getCountriesFlags } from "@bbdevcrew/bb_ui_kit_fe";
import { MAX_SHOW_FLAG_COUNT } from "./TiktokTrendingHashtags.helpers";

import { TableRowProps } from "./TiktokTrendingHashtags.types";

const cx = cn.bind(s);

const TrendingHastagsTableRow = React.memo(({ data: row, index }: TableRowProps) => {
  const isFetchingVideos = useSelector(fetchingTiktokTrendingHashtagVideosSelector);

  return (
    <Row
      className={cx(s.bbTableRow, {
        [s.bbTableRowColored]: index % 2 === 1,
      })}
    >
      <Col className={s.bbColumn} span={2}>
        <div className={s.bbRankColumn}>
          <span>{row.rank.position}</span>
          {row.rank.change && (
            <span>
              {row.rank.change.type !== "NEW" ? (
                <PerformanceCardPill
                  percentageValue={
                    row.rank.change.type === "POSITIVE"
                      ? Number(row.rank.change.value)
                      : -Number(row.rank.change.value)
                  }
                  showPercentSymbol={false}
                />
              ) : (
                <span className={s.bbNewChip}>{row.rank.change.value}</span>
              )}
            </span>
          )}
        </div>
      </Col>
      <Col className={cx(s.bbColumn, s.bbHashtagColumn)} span={6}>
        {row.name}
      </Col>
      <Col className={s.bbColumn} span={8}>
        {isFetchingVideos ? (
          <Loading />
        ) : !!row.videos?.length ? (
          <TikTokPreview videos={row.videos} />
        ) : (
          "-"
        )}
      </Col>
      <Col className={s.bbColumn} span={2}>
        {row.numberOfPosts}
      </Col>
      <Col className={s.bbColumn} span={2}>
        {row.numberOfViews === "-1" ? "-" : row.numberOfViews}
      </Col>
      <Col className={s.bbColumn} span={4}>
        <span className={s.bbCountryFlag}>
          {getCountriesFlags(row.trendingCountries, MAX_SHOW_FLAG_COUNT)}
        </span>
      </Col>
    </Row>
  );
});

TrendingHastagsTableRow.displayName = "TrendingHastagsTableRow";
export default TrendingHastagsTableRow;

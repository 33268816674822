/* eslint-disable max-len */
import { TFunction } from "i18next";
import { PostCreationErrorsByPlatformType, IPostItem } from "./types";

export const TEXTAREA_MAX_CHAR_LENGTH_DEFAULT = 1000000;
export const TEXTAREA_MAX_CHAR_LENGTH_FACEBOOK = 63206;
export const TEXTAREA_MAX_CHAR_LENGTH_INSTAGRAM = 2200;
export const TEXTAREA_MAX_CHAR_LENGTH_TIKTOK = 4000;
export const TEXTAREA_MAX_CHAR_LENGTH_LINKEDIN = 3000;
export const TEXTAREA_MAX_CHAR_LENGTH_YOUTUBE = 5000;
export const TEXTAREA_MAX_CHAR_LENGTH_X = 280;

export const INSTAGRAM_MAX_HASHTAG_COUNT = 30;

export const MAX_UPLOAD_IMAGE_COUNT_FB = 10;
export const MAX_UPLOAD_IMAGE_COUNT_IG = 10;
export const MAX_UPLOAD_IMAGE_COUNT_LI = 20;
export const MAX_UPLOAD_IMAGE_COUNT_X = 4;

export const MAX_UPLOAD_VIDEO_COUNT_FB = 1;
export const MAX_UPLOAD_VIDEO_COUNT_IG = 10;
export const MAX_UPLOAD_VIDEO_COUNT_X = 4;
export const MAX_UPLOAD_VIDEO_COUNT_TT = 1;
export const MAX_UPLOAD_VIDEO_COUNT_LI = 1;
export const MAX_UPLOAD_VIDEO_COUNT_YT = 1;

export const MAX_UPLOAD_ATTACHMENT_COUNT_X = 4;

export const MAX_UPLOAD_DOCUMENT_COUNT_LI = 1;

export const MAX_UPLOAD_IMAGE_SIZE_FB = 3;
export const MAX_UPLOAD_IMAGE_SIZE_X = 5;
export const MAX_UPLOAD_IMAGE_SIZE_LI = 5;

export const MAX_UPLOAD_COVER_SIZE_IG = 10;

export const MAX_UPLOAD_GIF_SIZE_X = 15;

export const MAX_UPLOAD_VIDEO_SIZE_FB = 1;
export const MAX_UPLOAD_VIDEO_SIZE_TT = 4;
export const MAX_UPLOAD_VIDEO_SIZE_X = 512;
export const MAX_UPLOAD_VIDEO_SIZE_LI = 5;

export const MAX_UPLOAD_DOCUMENT_SIZE_LI = 100;

// seconds
export const MIN_UPLOAD_VIDEO_DURATION_TT = 3;
export const MAX_UPLOAD_VIDEO_DURATION_TT = 600;
export const MIN_UPLOAD_VIDEO_DURATION_LI = 3;
export const MAX_UPLOAD_VIDEO_DURATION_LI = 900;

export const LINK_IMAGE_FRAME_WIDTH = 240;
export const LINK_IMAGE_FRAME_RATIO = 1.91;
export const LINK_IMAGE_FRAME_HEIGHT = LINK_IMAGE_FRAME_WIDTH / LINK_IMAGE_FRAME_RATIO;

export const LOCAL_DATE_FORMAT = "YYYY-MM-DD HH:mm";
export const LINK_REGEX = /(https?:\/\/[^\s]+)/g;
export const X_LINK_REGEX = /\b(https?:\/\/)?[A-Za-z0-9.-]+\.[A-Za-z]{2,}(\/[^\s]*)?\b/gi;
export const YT_CHARS_REGEX = /^(?!.*[<>]).+$/;

export const MAX_UPLOAD_VIDEO_DURATION = {
  tiktok: MAX_UPLOAD_VIDEO_DURATION_TT,
  linkedin: MAX_UPLOAD_VIDEO_DURATION_LI,
};

export const PROPERTIES_TO_IGNORE_ON_SYNC: Array<keyof IPostItem> = [
  "publishing_type",
  "error",
  "creatorInfo",
  "tiktokOptions",
  "youtubeOptions",
];

export const FB_VALID_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "video/mp4",
  "video/m4v",
  "video/avi",
  "video/webm",
  "video/x-msvideo",
  "video/quicktime", // for .mov files (not supported by all browsers)
];
export const IG_VALID_FILE_TYPES = FB_VALID_FILE_TYPES;
export const TT_VALID_FILE_TYPES = [
  "video/mp4",
  "video/webm",
  "video/quicktime", // for .mov files (not supported by all browsers)
];
export const X_VALID_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "video/mp4",
  "video/quicktime", // for .mov files (not supported by all browsers)
];
export const IG_VALID_COVER_TYPES = ["image/jpeg", "image/png", "image/gif", "image/bmp"];
export const LI_VALID_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "video/mp4",
  "video/m4v",
  "video/avi",
  "video/webm",
  "video/x-msvideo",
  "video/quicktime",
  "application/pdf",
  "application/msword", // doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
  "application/vnd.ms-powerpoint", // ppt
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
];

export const getPostCreationErrors = (t: TFunction): PostCreationErrorsByPlatformType => ({
  facebook: {
    requiredFields: t("components:publish:postCreationModal:errors:facebook:requiredFields"),
    mediaCombination: t(
      "components:publish:postCreationModal:errors:facebook:mediaCombinationError",
    ),
    imageSize: t("components:publish:postCreationModal:errors:facebook:imageSizeExceeded"),
    videoSize: t("components:publish:postCreationModal:errors:facebook:videoSizeExceeded"),
    fileType:
      t("components:publish:postCreationModal:errors:fileFormatInvalid") +
      t("components:publish:postCreationModal:errors:facebook:allowedFileFormats"),
  },
  instagram: {
    requiredFields: t("components:publish:postCreationModal:errors:instagram:requiredFields"),
    mediaCombination: t(
      "components:publish:postCreationModal:errors:instagram:mediaCombinationError",
    ),
    imageSize: t("components:publish:postCreationModal:errors:instagram:imageSizeExceeded"),
    coverSize: t("components:publish:postCreationModal:errors:instagram:coverSizeExceeded"),
    videoSize: t("components:publish:postCreationModal:errors:facebook:videoSizeExceeded"),
    fileRatio: t("components:publish:postCreationModal:errors:instagram:aspectRatioError"),
    fileType:
      t("components:publish:postCreationModal:errors:fileFormatInvalid") +
      t("components:publish:postCreationModal:errors:instagram:allowedFileFormats"),
  },
  tiktok: {
    requiredFields: t("components:publish:postCreationModal:errors:tiktok:requiredFields"),
    mediaCombination: t("components:publish:postCreationModal:errors:tiktok:fileTypeError"),
    videoSize: t("components:publish:postCreationModal:errors:tiktok:videoSizeExceeded"),
    fileType: t("components:publish:postCreationModal:errors:tiktok:fileTypeError"),
    videoDuration: t("components:publish:postCreationModal:errors:tiktok:videoLengthError"),
    postingRestricted: t("components:publish:postCreationModal:errors:tiktok:postingRestricted"),
  },
  twitter: {
    requiredFields: t("components:publish:postCreationModal:errors:twitter:requiredFields"),
    mediaCombination: t(
      "components:publish:postCreationModal:errors:twitter:mediaCombinationError",
    ),
    imageSize: t("components:publish:postCreationModal:errors:twitter:imageSizeExceeded"),
    gifSize: t("components:publish:postCreationModal:errors:twitter:gifSizeExceeded"),
    videoSize: t("components:publish:postCreationModal:errors:twitter:videoSizeExceeded"),
  },
  linkedin: {
    requiredFields: t("components:publish:postCreationModal:errors:linkedin:requiredFields"),
    mediaCombination: t(
      "components:publish:postCreationModal:errors:linkedin:mediaCombinationError",
    ),
    imageSize: t("components:publish:postCreationModal:errors:linkedin:imageSizeExceeded"),
    videoSize: t("components:publish:postCreationModal:errors:linkedin:videoSizeExceeded"),
    videoDuration: t("components:publish:postCreationModal:errors:linkedin:videoLengthError"),
    documentSize: t("components:publish:postCreationModal:errors:linkedin:documentSizeError"),
  },
  youtube: {
    descriptionText: t("components:publish:postCreationModal:errors:youtube:descriptionText"),
  },
});

import {
  switchMap,
  map,
  filter,
  catchError,
  withLatestFrom,
  delay,
  retryWhen,
  delayWhen,
  scan,
  throttleTime,
  mergeMap,
  groupBy,
} from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { Observable, of, timer } from "rxjs";
import { StateObservable } from "redux-observable";

import { RootState } from "../";
import {
  addLinkedInReactionSuccess,
  deleteLinkedInReactionSuccess,
  linkedInReactionFailure,
} from "./actions";
import { LinkedInReactionActions } from "./types";
import { linkedInReactions } from "@utils/paths";
import { ADD_LINKEDIN_REACTION, DELETE_LINKEDIN_REACTION } from "./actionTypes";
import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";

export const addLinkedInReactionEpic = (
  action$: Observable<LinkedInReactionActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(ADD_LINKEDIN_REACTION)),
    groupBy(action => action.payload.commentId),
    mergeMap(group$ =>
      group$.pipe(
        throttleTime(5000),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
          const previousReaction = state.comments.previousReactions?.[action.payload.commentId];

          const headers = getHeaders({
            Authorization: state.auth.session.accessToken.jwtToken,
          });

          const deleteExistingReaction$ =
            previousReaction && previousReaction.is_sent
              ? ajax.delete(`${linkedInReactions}/${action.payload.commentId}`, headers).pipe(
                  delay(2000),
                  catchError(e =>
                    handleError(e, () => linkedInReactionFailure(action.payload.commentId)),
                  ),
                )
              : of(null);

          return deleteExistingReaction$.pipe(
            switchMap(() =>
              ajax
                .post(
                  `${linkedInReactions}/${action.payload.commentId}`,
                  { type: action.payload.reaction },
                  headers,
                )
                .pipe(
                  map(() =>
                    addLinkedInReactionSuccess(action.payload.commentId, action.payload.reaction),
                  ),
                  retryWhen(errors =>
                    errors.pipe(
                      scan((acc, error) => {
                        if (acc >= 3) {
                          throw new Error(error);
                        }
                        return acc + 1;
                      }, 0),
                      delayWhen(() => timer(2000)),
                    ),
                  ),
                  catchError(e =>
                    handleError(e, () => linkedInReactionFailure(action.payload.commentId)),
                  ),
                ),
            ),
          );
        }),
      ),
    ),
  );

export const deleteLinkedInReactionEpic = (
  action$: Observable<LinkedInReactionActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_LINKEDIN_REACTION)),
    switchMap(a => {
      return ajax
        .delete(
          `${linkedInReactions}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteLinkedInReactionSuccess(a.payload)),
          catchError(e => handleError(e, () => linkedInReactionFailure(a.payload))),
        );
    }),
  );

import { action } from "typesafe-actions";
import {
  GET_ONBOARDING_PLATFORMS,
  GET_ONBOARDING_PLATFORMS_SUCCESS,
  GET_ONBOARDING_PLATFORMS_FAILURE,
  GET_ONBOARDING_PLATFORM,
  GET_ONBOARDING_PLATFORM_SUCCESS,
  GET_ONBOARDING_PLATFORM_FAILURE,
  REGISTER_TOKEN,
  REGISTER_TOKEN_SUCCESS,
  REGISTER_TOKEN_FAILURE,
  DELETE_TOKEN,
  DELETE_TOKEN_SUCCESS,
  DELETE_TOKEN_FAILURE,
  GET_TWITTER_REGISTER_TOKEN_URL,
  GET_TWITTER_REGISTER_TOKEN_URL_SUCCESS,
  GET_TWITTER_REGISTER_TOKEN_URL_FAILURE,
} from "./actionTypes";

import {
  IDeleteTokenPayload,
  IRegisterTokenPayload,
  ITwitterUrlResponse,
  IRegisterTokenAPIResponse,
} from "./types";
import { IOnboardingPlatform } from "@components/_common/AssetTabs/AssetTabs.type";
import { PlatformType } from "@bbdevcrew/bb_ui_kit_fe";

export const getOnboardingPlatformsAction = () => action(GET_ONBOARDING_PLATFORMS);
export const getOnboardingPlatformsSuccessAction = (platforms: IOnboardingPlatform[]) =>
  action(GET_ONBOARDING_PLATFORMS_SUCCESS, platforms);
export const getOnboardingPlatformsFailureAction = () => action(GET_ONBOARDING_PLATFORMS_FAILURE);

// Specific platform
export const getOnboardingPlatformAction = (platform: PlatformType) =>
  action(GET_ONBOARDING_PLATFORM, platform);
export const getOnboardingPlatformSuccessAction = (
  platform: IOnboardingPlatform,
  platformId: PlatformType,
) => action(GET_ONBOARDING_PLATFORM_SUCCESS, { platform, platformId });
export const getOnboardingPlatformFailureAction = () => action(GET_ONBOARDING_PLATFORM_FAILURE);

// Platform register token
export const registerTokenAction = (
  tokenPayload: IRegisterTokenPayload,
  platformId: PlatformType,
) => action(REGISTER_TOKEN, { tokenPayload, platformId });
export const registerTokenSuccessAction = (
  data: IRegisterTokenAPIResponse,
  platformId: PlatformType,
) => action(REGISTER_TOKEN_SUCCESS, { data, platformId });
export const registerTokenFailureAction = () => action(REGISTER_TOKEN_FAILURE);

// Delete token
export const deleteTokenAction = (data: IDeleteTokenPayload) => action(DELETE_TOKEN, data);
export const deleteTokenSuccessAction = (platformId: PlatformType) =>
  action(DELETE_TOKEN_SUCCESS, platformId);
export const deleteTokenFailureAction = () => action(DELETE_TOKEN_FAILURE);

// Get Twitter token url
export const getTwitterRegisterTokenUrlAction = (platformId: PlatformType) =>
  action(GET_TWITTER_REGISTER_TOKEN_URL, platformId);
export const getTwitterRegisterTokenUrlSuccessAction = (data: ITwitterUrlResponse) =>
  action(GET_TWITTER_REGISTER_TOKEN_URL_SUCCESS, data);
export const getTwitterRegisterTokenUrlFailureAction = () =>
  action(GET_TWITTER_REGISTER_TOKEN_URL_FAILURE);

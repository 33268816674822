import { platformIconName, getPlatformIconBasicColor } from "@bbdevcrew/bb_ui_kit_fe";

export interface IAssetTabProps {
  id: string;
  label: string;
  description?: string;
  isActive?: boolean;
}

export const generateLabel = (id: string, _label: string) => {
  if (id === "facebook") {
    const delimiter = _label.includes("+") ? " + " : " & ";
    return _label.split(delimiter).map(label => ({
      label,
      PlatformIcon: getPlatformIconBasicColor(platformIconName(label.toLowerCase())),
    }));
  }

  return [{ label: _label, PlatformIcon: getPlatformIconBasicColor(platformIconName(id)) }];
};

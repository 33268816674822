import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip, CircleProgressChart } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TrialTimeLeftCircle.module.less";

import { daysFromNow } from "@bbdevcrew/bb_ui_kit_fe";

interface ITrialTimeLeftCircleProps {
  withTooltip?: boolean;
  trialPeriodEnd: string;
  stonlyTarget?: string;
}

const TEXT_COLOR = "#13151B";
const DANGER_COLOR = "#D23031";
const WARNING_COLOR = "#E36B00";
const SUCCESS_COLOR = "#00A35A";
const BACKGROUND_COLOR = "#D9D9D9";

const TRIAL_PERIOD = 15;

const TrialTimeLeftCircle = ({
  trialPeriodEnd,
  withTooltip,
  stonlyTarget,
}: ITrialTimeLeftCircleProps) => {
  const { t } = useTranslation();
  const { trialDaysLeft, trialPercentage, progressStrokeColor, progressNumberColor } =
    useMemo(() => {
      const daysLeft = daysFromNow(new Date(trialPeriodEnd));
      const percentage = (daysLeft / TRIAL_PERIOD) * 100;

      let progressColor = WARNING_COLOR;
      let progressTextColor = TEXT_COLOR;

      if (daysLeft < 4) {
        progressColor = DANGER_COLOR;
        progressTextColor = DANGER_COLOR;
      } else if (daysLeft > 7) progressColor = SUCCESS_COLOR;

      return {
        trialDaysLeft: daysLeft,
        progressStrokeColor: progressColor,
        progressNumberColor: progressTextColor,
        trialPercentage: Math.ceil(percentage),
      };
    }, [trialPeriodEnd]);

  const stonlyAttribute = stonlyTarget
    ? { "data-stonly-target": `${stonlyTarget}-${trialDaysLeft}` }
    : {};

  return (
    <Tooltip title={withTooltip && t("header:trialEndsText", { count: trialDaysLeft })}>
      <div className={s.bbTrialTimeLeftCircle} {...stonlyAttribute}>
        <CircleProgressChart
          width={30}
          progressStrokeWidth={2}
          backgroundStrokeWidth={2}
          infoNumber={trialDaysLeft}
          percentage={trialPercentage}
          backgroundStrokeColor={BACKGROUND_COLOR}
          progressNumberColor={progressNumberColor}
          progressStrokeColor={progressStrokeColor}
        />
      </div>
    </Tooltip>
  );
};

export default TrialTimeLeftCircle;

import { Auth } from "aws-amplify";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import SignIn from "./SignIn";
import { Col, Grid, Row } from "antd";
import CookieBanner from "../cookieBanner/CookieBanner";

import { logItinAPI } from "@store/apiLogger/actions";
import { signUserUpSuccessAction } from "@store/user/actions";
import { cognitoCookies } from "@store/user/selectors";

import { IFormValues } from "./SignIn.type";
import { isNotAnExceptionPage } from "../../../App";
import { getCurrentModuleName } from "@utils/paths";
import { IAuthenticatorProps } from "aws-amplify-react/lib-esm/Auth/Authenticator";
import { addToast, ICognitoCookies, setCognitoCookies } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SignInView.module.less";
import globalStyles from "../index.module.less";

import Logo from "@assets/LogoNoBeta.svg";
import { TrustedByIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { SignInCover as CoverImg } from "../SignInCover/SignInCover";

const { useBreakpoint } = Grid;

const SignInView = ({ authState, onStateChange }: IAuthenticatorProps) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const queryParams = queryString.parse(window.location.search);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    code: "",
  });

  const userCookies = useSelector(cognitoCookies);

  const setCookies = (cookies: ICognitoCookies) => {
    setCognitoCookies(cookies);

    Auth.currentUserInfo()
      .then(user => {
        if (user && user.attributes && user.attributes.email_verified === false) {
          onStateChange && onStateChange("confirmSignUp", user);
        }
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    if (userCookies && authState === "signIn") {
      setCookies(userCookies);
    }
    // eslint-disable-next-line
  }, [userCookies, authState]);

  const goToSignup = () => {
    window.history.pushState(null, "", "/login/signup" + window.location.search);
    onStateChange && onStateChange("signUp");
  };

  useEffect(() => {
    if (window.location.pathname.includes("signup")) {
      onStateChange && onStateChange("signUp");
      setTimeout(goToSignup, 0);
    }
    // eslint-disable-next-line
  }, []);

  // If redirected from the below platforms, restore accessToken and switch to signUp state
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (
      authState !== "signUp" &&
      (queryParams.social_platform === "twitter" ||
        queryParams.social_platform === "linkedin" ||
        queryParams.social_platform === "trustpilot" ||
        queryParams.social_platform === "tiktok" ||
        queryParams.social_platform === "tt_ads") &&
      accessToken
    ) {
      dispatch(
        signUserUpSuccessAction({
          access_token: accessToken,
        }),
      );

      onStateChange && onStateChange("signUp");
    }
  }, [queryParams, authState, onStateChange, dispatch]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, dataset } = e.target;
    const { prop } = dataset;

    setFormData({
      ...formData,
      [prop as string]: value,
    });
  };

  const signInChallengeFlow = (user: {
    challengeName: string;
    attributes: { email_verified: boolean; terms_accepted?: boolean };
  }) => {
    dispatch(logItinAPI(user));

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      onStateChange && onStateChange("requireNewPassword", user);
    } else if (
      user.challengeName === "SMS_MFA" ||
      user.challengeName === "SOFTWARE_TOKEN_MFA" ||
      (user.attributes && user.attributes.email_verified === false)
    ) {
      onStateChange && onStateChange("confirmSignIn", user);
    } else if (user.challengeName === "MFA_SETUP") {
      onStateChange && onStateChange("TOTPSetup", user);
    } else {
      onStateChange && onStateChange("signedIn");
    }
  };

  const login = async (values: IFormValues, callback?: () => void) => {
    await Auth.signIn(values.username, values.password)
      .then(async user => {
        callback?.();
        signInChallengeFlow(user);
      })
      .catch(err => {
        callback?.();

        if (err.code === "UserNotConfirmedException") {
          onStateChange && onStateChange("confirmSignUp");
          return;
        }

        if (err.code === "PasswordResetRequiredException") {
          onStateChange && onStateChange("forgotPassword", values);
          return;
        }

        addToast({
          type: "danger_accent",
          title: t("generic:error"),
          message: t("pages:signin:error"),
        });
      });
  };

  return authState === "signIn" &&
    !userCookies &&
    isNotAnExceptionPage() &&
    (getCurrentModuleName() === "login" || window.location.pathname === "/") ? (
    <>
      <Row className={s.bbSignInView}>
        <Col xs={24} xl={16} className={s.bbSignInContent}>
          <div className={globalStyles.bbInfoSectionLogo}>
            <Logo />
          </div>
          <SignIn
            onFinish={login}
            handleSignUp={goToSignup}
            handleInputChange={handleInputChange}
            handleForgotPassword={() => onStateChange && onStateChange("forgotPassword")}
          />
        </Col>
        {screens.xl ? (
          <Col xl={8} xs={12} className={globalStyles.bbInfoSection}>
            <div className={globalStyles.bbInfoSectionTrustedBy}>
              <TrustedByIcon />
            </div>
            <div>
              <CoverImg />
            </div>
          </Col>
        ) : null}
      </Row>
      <CookieBanner />
    </>
  ) : null;
};

export default SignInView;

import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useState, useEffect, useCallback } from "react";

import { AlertDanger } from "@bbdevcrew/bb_ui_kit_fe";
import AssetTabs from "../../_common/AssetTabs/AssetTabs";

// actions
import {
  deleteTokenAction,
  getOnboardingPlatformAction,
  getOnboardingPlatformsAction,
  getTwitterRegisterTokenUrlAction,
  registerTokenAction,
} from "@store/onboardingPlatforms/actions";
import {
  deselectAssets,
  selectAssets,
  setPlatformTypeAlert,
  getOnboardingPlatformTypesAction,
} from "@store/onboardingPlatformTypes/actions";

// selectors
import {
  fetchedPlatformSelector,
  fetchedPlatformsSelector,
  fetchingPlatformSelector,
  onboardingPlatformsSelector,
  registerTokenPendingSelector,
  registeredTokenSelector,
  twitterRegisterTokenUrlSelector,
} from "@store/onboardingPlatforms/selectors";
import {
  fetchedOnboardingPlatformTypesSelector,
  fetchingOnboardingPlatformTypesSelector,
  missingFBOrganicSelector,
  missingTwitterOrganicSelector,
  onboardingPlatformTypesSelector,
  postedOnboardingPlatformTypeAssetsSelector,
  selectedAssetsSelector,
} from "@store/onboardingPlatformTypes/selectors";

import s from "./AssetTabsWrapper.module.less";

// utils, types
import {
  PlatformType,
  isLinkedinPlatform,
  isTiktokPlatform,
  isTwitterPlatform,
  getNormalizedTiktokPlatformId,
  isTrustpilotPlatform,
} from "@bbdevcrew/bb_ui_kit_fe";
import { isTiktokApp } from "@utils/isTiktokApp";
import { IPlatformAsset } from "../../_common/AssetTabs/AssetTabs.type";
import { IRegisterTokenPayload } from "@store/onboardingPlatforms/types";
import {
  getCodeFromUrl,
  getNormalizedTiktokIdFromUrl,
  isRedirectFromLinkedinPlatform,
  isRedirectFromTiktokPlatform,
  isRedirectFromTrustpilotPlatform,
  isRedirectFromTwitterPlatform,
  urlCodeExists,
} from "@components/settings/addAssets/helpers";

interface IAssetTabsWrapperProps {
  title: string;
  showDisclaimer?: boolean;
  setWebhookEnabled?: (enabled: boolean) => void;
}

export const AssetTabsWrapper: FC<IAssetTabsWrapperProps> = ({
  title,
  showDisclaimer = false,
  setWebhookEnabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryParams = queryString.parse(window.location.search);

  const [showAlert, setShowAlert] = useState(true);
  const [isWebhookEnabled, setIsWebhookEnabled] = useState(true);
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformType>();

  const platforms = useSelector(onboardingPlatformsSelector);
  const platformsFetched = useSelector(fetchedPlatformsSelector);
  const platformTypes = useSelector(onboardingPlatformTypesSelector);
  const platformLoading = useSelector(fetchingPlatformSelector);
  const platformFetched = useSelector(fetchedPlatformSelector);
  const twitterRegisterUrl = useSelector(twitterRegisterTokenUrlSelector);
  const platformDetailsFetched = useSelector(fetchedOnboardingPlatformTypesSelector);
  const platformDetailsLoading = useSelector(fetchingOnboardingPlatformTypesSelector);
  const postedAssets = useSelector(postedOnboardingPlatformTypeAssetsSelector);
  const allSelectedAssets = useSelector(selectedAssetsSelector);
  const missingFBOrganicAssets = useSelector(missingFBOrganicSelector);
  const missingTwitterOrganicAssets = useSelector(missingTwitterOrganicSelector);
  const registeredToken = useSelector(registeredTokenSelector);
  const registeringToken = useSelector(registerTokenPendingSelector);

  const getSelectedPlatformData = useCallback(
    () => platforms.find(({ id }) => id === selectedPlatform),
    [platforms, selectedPlatform],
  );

  const onPlatformTabChange = (platformId: PlatformType) => setSelectedPlatform(platformId);

  const onPlatformLoginSuccess = useCallback(
    (
      platformType: PlatformType,
      accessToken?: string,
      authorizationCode?: string,
      shouldRelogin?: boolean,
    ) => {
      if (shouldRelogin) {
        dispatch(deleteTokenAction({ social_platform: platformType }));
      } else {
        const payload: IRegisterTokenPayload = {
          access_token: accessToken,
          authorization_code: authorizationCode,
          app_id: getSelectedPlatformData()?.app_id || "",
        };

        dispatch(registerTokenAction(payload, platformType));
      }
    },
    [dispatch, getSelectedPlatformData],
  );

  const onSocialBtnLoginClick = (platformId: PlatformType, authorizationUrl?: string) => {
    if (isTwitterPlatform(platformId)) dispatch(getTwitterRegisterTokenUrlAction(platformId));
    else if (
      (isLinkedinPlatform(platformId) ||
        isTiktokPlatform(platformId) ||
        isTrustpilotPlatform(platformId)) &&
      authorizationUrl
    ) {
      // Preserve already selected assets before redirect
      localStorage.setItem("allSelectedAssets", JSON.stringify(allSelectedAssets));
      localStorage.setItem("redirectApp", "login");
      window.open(authorizationUrl, "_self");
    }
  };

  const restorePreservedAssets = useCallback(() => {
    const preservedAssets = localStorage.getItem("allSelectedAssets");

    if (preservedAssets) {
      dispatch(selectAssets(JSON.parse(preservedAssets)));
      localStorage.removeItem("allSelectedAssets");
    }
  }, [dispatch]);

  const onWebhookChange = ({ target: { checked } }: { target: { checked: boolean } }) => {
    setIsWebhookEnabled(checked);
    setWebhookEnabled?.(checked);
    return !checked;
  };

  const onAssetSelect = (type: "select" | "deselect", assets: IPlatformAsset[]) => {
    if (type === "select") {
      if (assets.find(asset => asset.asset_platform === "facebook_ads")) {
        setIsWebhookEnabled(true);
        setWebhookEnabled?.(true);
      }

      dispatch(selectAssets(assets));
    } else dispatch(deselectAssets(assets));
  };

  useEffect(() => {
    dispatch(getOnboardingPlatformsAction());
  }, [dispatch]);

  useEffect(() => {
    if (registeredToken) {
      setShowAlert(true);
    }
  }, [registeredToken]);

  // Load platform details (initially and on tab change)
  const currentSelectedPlatform =
    selectedPlatform || (platforms.length ? platforms[0].id : "facebook");
  useEffect(() => {
    if (platformsFetched) {
      setSelectedPlatform(currentSelectedPlatform);
      dispatch(getOnboardingPlatformAction(currentSelectedPlatform));
    }
  }, [currentSelectedPlatform, platformsFetched, selectedPlatform, dispatch]);

  // Fetch assets for initial platform (if all permissions granted)
  useEffect(() => {
    if (platformFetched && !platformDetailsFetched && !platformDetailsLoading) {
      const { id, permissions } = getSelectedPlatformData() || {};

      if (id && permissions?.status === "complete") {
        dispatch(getOnboardingPlatformTypesAction(id));
      }
    }
  }, [
    dispatch,
    getSelectedPlatformData,
    platformFetched,
    platformDetailsFetched,
    platformDetailsLoading,
  ]);

  // Refetch assets after successful asset add
  useEffect(() => {
    if (postedAssets) {
      const { id, permissions } = getSelectedPlatformData() || {};

      if (id && permissions?.status === "complete") {
        dispatch(getOnboardingPlatformTypesAction(id));
      }
    }
  }, [dispatch, getSelectedPlatformData, postedAssets]);

  useEffect(() => {
    if (twitterRegisterUrl && selectedPlatform === "twitter") {
      // Preserve already selected assets before redirect
      localStorage.setItem("allSelectedAssets", JSON.stringify(allSelectedAssets));
      localStorage.setItem("redirectApp", "login");
      window.open(twitterRegisterUrl, "_self");
    }
  }, [twitterRegisterUrl, selectedPlatform, allSelectedAssets]);

  const getAccessTokenFromUrl = useCallback(() => {
    return queryParams.oauth_token
      ? typeof queryParams.oauth_token === "string"
        ? queryParams.oauth_token
        : queryParams.oauth_token[0] || undefined
      : undefined;
  }, [queryParams]);

  const getOauthVerifierFromUrl = useCallback(() => {
    return queryParams.oauth_verifier
      ? typeof queryParams.oauth_verifier === "string"
        ? queryParams.oauth_verifier
        : queryParams.oauth_verifier[0] || undefined
      : "";
  }, [queryParams]);

  // When redirecting from platform external login pages:
  // 1. Restore preserved assets
  // 2. Call onPlatformLoginSuccess callback
  // 3. Reload the page
  useEffect(() => {
    const reloadAndClearSearch = () => {
      isTiktokApp()
        ? window.history.replaceState({ search: "" }, "", "/login/tiktok")
        : window.history.replaceState({ search: "" }, "", "/login/signup");
    };

    if (queryParams.social_platform && localStorage.getItem("redirectApp") === "login") {
      const socialPlatformQueryParam = getNormalizedTiktokPlatformId(
        queryParams.social_platform as string,
      );
      setSelectedPlatform(socialPlatformQueryParam as PlatformType);
      restorePreservedAssets();

      // If token is in query AND platform not authed, register the token
      const shouldBeAuthed =
        (platformFetched && getSelectedPlatformData()?.permissions?.status === "incomplete") ||
        getSelectedPlatformData()?.permissions?.status === "token_missing";

      if (
        queryParams.oauth_token &&
        queryParams.oauth_verifier &&
        isRedirectFromTwitterPlatform(queryParams)
      ) {
        if (shouldBeAuthed) {
          const accessToken = getAccessTokenFromUrl();
          const verifier = getOauthVerifierFromUrl();

          onPlatformLoginSuccess("twitter", accessToken, verifier);
          reloadAndClearSearch();
        }
      } else if (isRedirectFromTiktokPlatform(queryParams) && urlCodeExists(queryParams)) {
        if (shouldBeAuthed) {
          const normalizedPlatformId = getNormalizedTiktokIdFromUrl(queryParams);

          setSelectedPlatform(normalizedPlatformId);
          onPlatformLoginSuccess(normalizedPlatformId, undefined, getCodeFromUrl(queryParams));
          reloadAndClearSearch();
        }
      } else if (isRedirectFromLinkedinPlatform(queryParams) && urlCodeExists(queryParams)) {
        if (shouldBeAuthed) {
          setSelectedPlatform("linkedin");
          onPlatformLoginSuccess("linkedin", undefined, getCodeFromUrl(queryParams));
          reloadAndClearSearch();
        }
      } else if (isRedirectFromTrustpilotPlatform(queryParams) && urlCodeExists(queryParams)) {
        if (shouldBeAuthed) {
          setSelectedPlatform("trustpilot");
          onPlatformLoginSuccess("trustpilot", undefined, getCodeFromUrl(queryParams));
          reloadAndClearSearch();
        }
      }

      if (queryParams.denied) {
        reloadAndClearSearch();
      }
    }
  }, [
    dispatch,
    queryParams,
    platformFetched,
    getAccessTokenFromUrl,
    getOauthVerifierFromUrl,
    restorePreservedAssets,
    onPlatformLoginSuccess,
    getSelectedPlatformData,
  ]);

  // Set missing organic asset alert
  useEffect(() => {
    if (selectedPlatform === "facebook" || selectedPlatform === "twitter") {
      const missingOrganic =
        selectedPlatform === "facebook" ? missingFBOrganicAssets : missingTwitterOrganicAssets;

      dispatch(
        setPlatformTypeAlert(
          `${selectedPlatform}_ads`,
          missingOrganic
            ? {
                message: t(`components:addAssets:${selectedPlatform}:missingOrganicAlertMessage`),
              }
            : undefined,
        ),
      );
    }
  }, [t, dispatch, selectedPlatform, missingFBOrganicAssets, missingTwitterOrganicAssets]);

  return (
    <>
      {!!getSelectedPlatformData()?.missing_permissions?.length && showAlert && (
        <AlertDanger
          size="large"
          align="center"
          icon={<></>}
          className={s.bbAssetTabsAlert}
          onClose={() => setShowAlert(false)}
          message={t("pages:addAssets:missingPermissionAlert")}
        />
      )}
      {!!selectedPlatform && (
        <AssetTabs
          platforms={platforms}
          title={title}
          disclaimerText={
            showDisclaimer
              ? t(
                  "pages:signup:step3:" +
                    (selectedPlatform === "twitter" ? "disclaimerTwitter" : "disclaimer"),
                )
              : undefined
          }
          platformTypes={platformTypes}
          onAssetSelect={onAssetSelect}
          onTabChange={onPlatformTabChange}
          onWebhookChange={onWebhookChange}
          isWebhookEnabled={isWebhookEnabled}
          selectedAssets={allSelectedAssets}
          selectedPlatform={selectedPlatform}
          onLoginClick={onSocialBtnLoginClick}
          onLoginSuccess={onPlatformLoginSuccess}
          isLoading={platformLoading || platformDetailsLoading || registeringToken}
          hidePermissionsAndAssets={selectedPlatform === "google_business" && !platformTypes.length}
        />
      )}
    </>
  );
};

import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import * as actions from "./actions";

import { RootState } from "..";
import { getAuthAPIHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { INotificationsResponse, NotificationsActions } from "./types";
import { updateUserNotificationsStatus, userNotifications } from "@utils/paths";

export const getNotifications = (
  action$: Observable<NotificationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getNotificationsAction)),
    switchMap(() =>
      ajax.get<INotificationsResponse>(userNotifications, getAuthAPIHeaders(state$)).pipe(
        map(({ response }) => actions.getNotificationsSuccessAction(response)),
        catchError(e => handleError(e, actions.getNotificationsFailureAction)),
      ),
    ),
  );

export const markAllAsRead = (
  action$: Observable<NotificationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.markAllAsReadAction)),
    switchMap(() => {
      const ids = state$.value.notifications.notifications.map(({ id }) => id);

      return ajax
        .patch<INotificationsResponse>(
          updateUserNotificationsStatus,
          { ids, status: "read" },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(() => actions.markAsReadSuccessAction()),
          catchError(e => handleError(e, actions.markAsReadFailureAction)),
        );
    }),
  );

export const markAsRead = (
  action$: Observable<NotificationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.markAsReadAction)),
    switchMap(({ payload: id }) =>
      ajax
        .patch<INotificationsResponse>(
          updateUserNotificationsStatus,
          { ids: [id], status: "read" },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(() => actions.markAsReadSuccessAction()),
          catchError(e => handleError(e, actions.markAsReadFailureAction)),
        ),
    ),
  );

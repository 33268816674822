import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip, ChipAction } from "@bbdevcrew/bb_ui_kit_fe";

import { IMessageTag, IMessageTags } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./MessageTags.module.less";

interface IMessageTagsProps {
  tags?: IMessageTags;
}

const TooltipTag = ({ tag, key }: { tag: IMessageTag; key: string }) => (
  <li key={key}>
    {tag.label} ({tag.count})
  </li>
);

const MessageTags: FC<IMessageTagsProps> = ({ tags }) => {
  const { t } = useTranslation();

  const tooltip = (
    <div className={s.bbMessageTags}>
      {t("components:comments:messageTags:tooltip")}
      <ul className={s.bbMessageTagsList}>
        {tags?.ai_tags?.map(tag => <TooltipTag key={tag.id} tag={tag} />)}
        {tags?.managed_tags?.map(tag => <TooltipTag key={tag.id} tag={tag} />)}
        {tags?.custom_tags?.map(tag => <TooltipTag key={tag.id} tag={tag} />)}
      </ul>
    </div>
  );

  return (
    <Tooltip title={tooltip}>
      <span className={s.bbMessageTagChip}>
        <ChipAction
          _size="xs"
          _type="grey"
          actionIcon={null}
          text={t("components:comments:messageTags:label", {
            count: tags?.count,
          })}
        />
      </span>
    </Tooltip>
  );
};

export default MessageTags;

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import React, { FC, useMemo, useState, useEffect, ChangeEvent } from "react";

import { ValidationToast } from "./ValidationToast";
import { KeywordsTooltipContent } from "./KeywordsTooltip";
import { IKeywordsDropdownProps, KeywordsDropdown } from "./KeywordsDropdown";
import { Icon, Input, Tooltip, Dropdown, SelectedExcludedIndicator } from "@bbdevcrew/bb_ui_kit_fe";

import {
  pickKeywords,
  hasOpenNesting,
  flattenKeywords,
  unflattenKeywords,
  InputChipQueryLabelsEnum,
  sanitizeNestedUnderscores,
  KeywordQueryType,
} from "../../inputChip/InputChipQueryConfig";
import {
  isChipValid,
  isChipPhrase,
  isChipPhraseEmpty,
  NESTING_COMMAND_KEYS,
} from "../../inputChip/InputChipConfig";
import {
  addToast,
  DropdownMenuPlacementType,
  generateKeywordsInputValueState,
} from "@bbdevcrew/bb_ui_kit_fe";
import { IInjectedCustomProps } from "../../Filters.type";
import { FilterTagValueType } from "./KeywordsTooltip";

import s from "./Keywords.module.less";

import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IKeywordsProps extends IInjectedCustomProps {
  id?: string;
  maxChipCount?: number;
  menuClassName?: string;
  inputClassName?: string;
  indicatorClassName?: string;
  showTranslationSwitch?: boolean;
  limited?: boolean;
  disabled?: boolean;
  placeholder?: string;
  DropdownVariation?: FC<IKeywordsDropdownProps>;
  dropdownPlacement?: DropdownMenuPlacementType;
  dropdownMatchSelectWidth?: boolean;
  onChange?: (value: KeywordQueryType | string) => void;
}

const Keywords: FC<IKeywordsProps> = ({
  id,
  placeholder,
  disabled = false,
  menuClassName = "",
  inputClassName = "",
  indicatorClassName = "",
  dropdownPlacement,
  dropdownMatchSelectWidth,
  DropdownVariation = KeywordsDropdown,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    customprops: { form, name },
  } = props;

  const [inputChipValue, setInputChipValue] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { selected, excluded } = useMemo(() => {
    return generateKeywordsInputValueState(
      t,
      pickKeywords(flattenKeywords(form.getFieldValue(name))),
    );
    // eslint-disable-next-line
  }, [form.getFieldValue(name)]);

  const setDropdownOpenState = (open: boolean) => {
    setIsDropdownOpen(open);
  };

  const onDropdownClick = () => {
    if (disabled) return;
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    setInputChipValue(event.target.value.trim());
  };

  const successNotification = (operationsDone: string[]) => {
    addToast({
      type: "success_accent",
      title: t("components:filters:keywords:validation_toast:title"),
      message: <ValidationToast operationsDone={operationsDone} />,
    });
  };

  useEffect(() => {
    if (isDropdownOpen === false) {
      // Prepare for API on dropdown close
      const value: string[] = form.getFieldValue(name) || [];

      if (inputChipValue) {
        const lastValue = value[value.length - 1];

        // on close dropdown
        if (!value.includes(inputChipValue.trim())) {
          if (
            isChipValid(inputChipValue) &&
            !isChipPhraseEmpty(inputChipValue) &&
            !isChipPhrase(inputChipValue)
          ) {
            form.setFieldsValue({ [name]: inputChipValue });
            onChange?.(inputChipValue);
          }
        }

        // Remove dangling operator
        if (InputChipQueryLabelsEnum[lastValue as keyof typeof InputChipQueryLabelsEnum]) {
          value.splice(value.length - 1);

          // Notify user
          successNotification(["dangling_operator"]);
        }

        // Remove redundant operators ["word", "(", "__AND__" , "wordy", ")"] ==> ["word", "(", "wordy", ")"]
        if (NESTING_COMMAND_KEYS.every(item => value.includes(item))) {
          const openingParantesisIndex = value.indexOf(NESTING_COMMAND_KEYS[0]);
          const closingParantesisIndex = value.indexOf(NESTING_COMMAND_KEYS[1]);

          if (
            InputChipQueryLabelsEnum[
              value[openingParantesisIndex + 1] as keyof typeof InputChipQueryLabelsEnum
            ]
          ) {
            value.splice(value.indexOf(value[openingParantesisIndex + 1]), 1);

            successNotification(["redundant_operator"]);
          }

          // Remove iterative operators ["word", "(", "wordy", "__AND__" , ")", "__AND__" ] ==> ["word", "(", "wordy", ")"]
          if (
            !!InputChipQueryLabelsEnum[
              value[closingParantesisIndex - 1] as keyof typeof InputChipQueryLabelsEnum
            ] &&
            !!InputChipQueryLabelsEnum[
              value[closingParantesisIndex + 1] as keyof typeof InputChipQueryLabelsEnum
            ]
          ) {
            value.splice(value.indexOf(value[closingParantesisIndex - 1]), 1);
            value.splice(value.indexOf(value[closingParantesisIndex + 1]), 1);

            successNotification(["iterative_operator"]);
          }
        }

        // Warn about dangling bracket
        if (lastValue === NESTING_COMMAND_KEYS[0]) {
          successNotification(["dangling_bracket"]);
        }
        // Warn about unclosed bracket
        else if (hasOpenNesting(value)) {
          successNotification(["auto_closing_brackets"]);
        }

        form.setFieldsValue({
          [name]: unflattenKeywords(value.map(keyword => keyword.trim())),
        });
        onChange?.(unflattenKeywords(value.map(keyword => keyword.trim())));
        setInputChipValue("");
      } else {
        onChange?.(value);
      }
    }
    // eslint-disable-next-line
  }, [isDropdownOpen]);

  const onClear = () => {
    form.setFieldsValue({ [name]: [] });
    onChange?.([]);
  };

  const defaultPlaceholder = t("components:filters:keywords:placeholder");

  return (
    <Dropdown
      open={isDropdownOpen}
      placement={dropdownPlacement}
      setOpen={setDropdownOpenState}
      matchTriggerWidth={dropdownMatchSelectWidth}
      menuClassName={classNames({ [s.bbKeywordsMenu]: !dropdownMatchSelectWidth }, menuClassName)}
      disabled={disabled}
      trigger={
        <Tooltip
          placement="topLeft"
          title={KeywordsTooltipContent(
            (sanitizeNestedUnderscores(form.getFieldValue(name)) || []) as FilterTagValueType,
          )}
          overlayInnerStyle={{
            minWidth: 120,
            maxHeight: "90vh",
            overflow: "hidden",
          }}
        >
          <div
            id="keywords-trigger"
            data-cy="keywords-trigger"
            onClick={onDropdownClick}
            className={s.bbKeywordsTrigger}
          >
            <Input
              _size="sm"
              id={id || "keywords-trigger"}
              data-testid="keywords-input"
              placeholder={`${selected ? "" : placeholder || defaultPlaceholder}`}
              wrapperClassName={classNames(
                s.bbKeywordsTriggerWrapper,
                {
                  [s.bbKeywordsTriggerWrapperFocused]: isDropdownOpen,
                },
                inputClassName,
              )}
              suffixEl={
                <>
                  <Icon
                    _size="sm"
                    icon={CloseIcon}
                    onClick={onClear}
                    className={s.bbKeywordsTriggerIconClose}
                  />
                  <Icon
                    _size="sm"
                    className={s.bbKeywordsTriggerIconChevron}
                    icon={isDropdownOpen ? ChevronUpIcon : ChevronDownIcon}
                  />
                </>
              }
            />

            {!!selected && (
              <SelectedExcludedIndicator
                excluded={excluded}
                selected={selected}
                className={indicatorClassName}
              />
            )}
          </div>
        </Tooltip>
      }
    >
      <DropdownVariation
        {...props}
        onChangeInputValue={onChangeInputValue}
        name={name}
        form={form}
      />
    </Dropdown>
  );
};

export default Keywords;

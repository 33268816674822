import { IComment } from "@bbdevcrew/bb_ui_kit_fe";

export const REPLY_MAX_LENGTH = 100;

export interface IBrandReplyProps {
  comment: IComment;
  onReplyClick?: (commentId: string) => void;
}

export interface IBrandReplyMessageProps {
  message: string;
  onClickReply: () => void;
}

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  addToast,
  Button,
  CheckIcon,
  CloseIcon,
  debounce,
  Loading,
  SearchInput,
} from "@bbdevcrew/bb_ui_kit_fe";
import { LoadingOutlined } from "@ant-design/icons";

import {
  fetchingIntegrationUsersListSelector,
  integrationUsersListSelector,
  linkedIntegrationUserToTicketSelector,
  unlinkedIntegrationUserFromTicketSelector,
} from "@store/integrations/selectors";
import {
  clearIntegrationUserLinkStateAction,
  getIntegrationUsersListAction,
  linkIntegrationUserToTicketAction,
  unlinkIntegrationUserToTicketAction,
} from "@store/integrations/actions";

import s from "./ExistingZendeskUsersList.module.less";

import { ExistingZendeskUsersListProps } from "./ExistingZendeskUsersList.type";
import { IIntegrationUser } from "@store/integrations/types";

import { LinkIcon } from "@bbdevcrew/bb_ui_kit_fe";

const ExistingZendeskUsersList = ({ ticket, closeModal }: ExistingZendeskUsersListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const integrationUsers = useSelector(integrationUsersListSelector);
  const fetchingIntegrationUsersList = useSelector(fetchingIntegrationUsersListSelector);
  const linkedIntegrationUserToTicket = useSelector(linkedIntegrationUserToTicketSelector);
  const unlinkedIntegrationUserFromTicket = useSelector(unlinkedIntegrationUserFromTicketSelector);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getIntegrationUsersList = useCallback(
    debounce(
      () => dispatch(getIntegrationUsersListAction({ ticketId: ticket.id, keyword: searchTerm })),
      1000,
    ),
    [dispatch, searchTerm, ticket.id],
  );

  const linkUser = (user: IIntegrationUser) =>
    dispatch(
      linkIntegrationUserToTicketAction({
        ticketId: ticket.id,
        linked_user: { id: user.id, name: user.name },
      }),
    );

  const unlinkUser = () => dispatch(unlinkIntegrationUserToTicketAction({ ticketId: ticket.id }));

  useEffect(() => {
    getIntegrationUsersList();
  }, [getIntegrationUsersList]);

  useEffect(() => {
    if (linkedIntegrationUserToTicket) {
      addToast({
        type: "success_accent",
        title: t("components:comments:zendeskTicket:linkUserModal:toastMessages:successLink"),
      });
      closeModal();
      dispatch(clearIntegrationUserLinkStateAction());
    }
  }, [closeModal, dispatch, linkedIntegrationUserToTicket, t]);

  useEffect(() => {
    if (unlinkedIntegrationUserFromTicket) {
      addToast({
        type: "success_accent",
        title: t("components:comments:zendeskTicket:linkUserModal:toastMessages:successUnlink"),
      });
      getIntegrationUsersList();
      dispatch(clearIntegrationUserLinkStateAction());
    }
  }, [getIntegrationUsersList, unlinkedIntegrationUserFromTicket, t, dispatch]);

  return (
    <>
      <SearchInput
        searchTerm={searchTerm}
        handleSetSearchTerm={setSearchTerm}
        placeholder={t("components:comments:zendeskTicket:linkUserModal:tab1:searchPlaceholder")}
      />
      <div className={s.bbLinkZendeskUsersListContent}>
        {fetchingIntegrationUsersList ? (
          <Loading
            isLoading
            type="spinning"
            size="small"
            indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
            className={s.bbSharePostSelectorModalContentLoading}
          />
        ) : (
          <ul className={s.bbLinkZendeskUsersListList}>
            {integrationUsers.map(user => (
              <li className={s.bbLinkZendeskUsersListItem} key={user.id}>
                <div className={s.bbLinkZendeskUsersListItemContent}>
                  <div className={s.bbLinkZendeskUsersListItemTextsWrapper}>
                    <span className={s.bbLinkZendeskUsersListItemName}>{user.name}</span>
                    <span className={s.bbLinkZendeskUsersListItemEmail}>{user.email}</span>
                  </div>
                  {user.is_linked ? (
                    <div className={s.bbLinkZendeskUsersListItemLinkedWrapper}>
                      <span className={s.bbLinkZendeskUsersListItemLinkedFlag}>
                        <CheckIcon />
                        {t("components:comments:zendeskTicket:linked")}
                      </span>
                      <Button
                        _size="sm"
                        _type="danger-subtle"
                        className={s.bbLinkZendeskUsersListItemUnlinkButton}
                        onClick={unlinkUser}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  ) : (
                    <Button
                      _size="sm"
                      _type="secondary"
                      className={s.bbLinkZendeskUsersListItemLinkButton}
                      onClick={() => linkUser(user)}
                    >
                      <LinkIcon />
                      {t("components:comments:zendeskTicket:linkUserModal:tab1:linkUser")}
                    </Button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default ExistingZendeskUsersList;

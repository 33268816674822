import cn from "classnames/bind";
import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CardHeader.module.less";

import { platformIconName, getPlatformIconBasicColor } from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);

interface ICardHeaderProps {
  id: string;
  title: string;
  allSelected?: boolean;
  onSelectAllToggle?: (event: MouseEvent) => void;
}

export const CardHeader: FC<ICardHeaderProps> = ({ id, title, allSelected, onSelectAllToggle }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(s.bbAssetTabCardHeader, `bbAssetTabCardHeader-${platformIconName(id)}`)}>
      {getPlatformIconBasicColor(platformIconName(id))}
      <h3 className={s.bbAssetTabCardHeaderTitle}>{title}</h3>
      <Button _type="link" onClick={onSelectAllToggle}>
        {t(allSelected ? "generic:deselectAll" : "generic:selectAll")}
      </Button>
    </div>
  );
};

import React from "react";

import s from "./PublishingPost.module.less";

import { IPublishingPost } from "@store/publishings/types";

import { HeartIcon, CommentIcon, ReplyIcon, ThumbsUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IPublishingPostMetricsProps {
  post: IPublishingPost;
}

export default function PublishingPostMetrics({ post }: IPublishingPostMetricsProps) {
  return (
    <div className={s.bbPostMetrics}>
      <div className={s.bbPostComments}>
        <CommentIcon />
        {post?.comment_metrics?.total_comments || 0}
      </div>
      <div className={s.bbPostLikes}>
        {post.platform_type.id === "facebook" ? <ThumbsUpIcon /> : <HeartIcon />}
        {post.post_metrics?.total_reactions || 0}
      </div>
      {post.platform_type.id === "facebook" ? (
        <div className={s.bbPostShares}>
          <ReplyIcon />
          {post.post_metrics?.total_shares || 0}
        </div>
      ) : null}
    </div>
  );
}

import dayjs from "dayjs";
import React, { FC } from "react";

import { DATE_FORMAT } from "./helpers";
import PostView from "../PostView/PostView";
import { CommentPhrase } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./MessageCard.module.less";

import { RecommendationType, ICommentTooltips } from "@bbdevcrew/bb_ui_kit_fe";

interface IMessageCardHeaderProps {
  date: string;
  author: string;
  commentPhrase?: string;
  recommendationType?: RecommendationType;
  commentTooltips?: ICommentTooltips;
}

const MessageCardHeader: FC<IMessageCardHeaderProps> = ({
  date,
  author,
  commentPhrase,
  recommendationType,
  commentTooltips,
}) => {
  return (
    <div className={s.bbMessageCardHeader}>
      {!!author && (
        <span className={s.bbMessageCardHeaderAuthor} data-testid="author">
          {author}
        </span>
      )}
      {!!date && (
        <span className={s.bbMessageCardHeaderDate} data-testid="date">
          {dayjs(date).format(DATE_FORMAT)}
        </span>
      )}
      {!!commentPhrase && (
        <div className={s.bbMessageCardHeaderPhrase}>
          <CommentPhrase
            PostView={PostView}
            showPostViewTooltip={false}
            commentPhrase={commentPhrase || ""}
            recommendationType={recommendationType}
            editedTooltip={commentTooltips?.edit}
          />
        </div>
      )}
    </div>
  );
};

export default MessageCardHeader;

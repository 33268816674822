import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { filterDataSelector } from "@store/filters/selectors";

import { postShortenedSearchesAction } from "@store/shortenedSearches/actions";

import { LightbulbIcon } from "@assets/index";
import { AlertTriangleIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const AI_ICONS_ACTIONS_MAP = {
  generate_analysis_general_insights: <LightbulbIcon />,
  generate_analysis_crisis_management: <AlertTriangleIcon />,
};

// Get 'id' from URL or create a new search if 'saved_filter' is present and modal is open.
export const useGetSearchesIdForSavedFilters = (open: boolean) => {
  const dispatch = useDispatch();
  const filters = useSelector(filterDataSelector);

  const urlParams = new URLSearchParams(window.location.search);
  const searchId = urlParams.get("id");
  const savedFilterId = urlParams.get("saved_filter");

  useEffect(() => {
    if (!searchId && savedFilterId && open) {
      dispatch(postShortenedSearchesAction({ filters: filters || {} }));
    }
  }, [searchId, savedFilterId, dispatch, filters, open]);

  return searchId;
};

import React from "react";
import { t } from "../../../../App";

import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";

import s from "./ProfilesTable.module.less";

export const ColumnWithGrowth = ({
  value,
  growth,
}: {
  value?: number;
  growth?: number | string;
}) => {
  return !!growth ? (
    <>
      {value != null ? value.toLocaleString("en-US") : ""}
      {<PerformanceCardPill percentageValue={Number(growth)} className={s.bbColumnPercentPill} />}
    </>
  ) : (
    <span className={s.bbColumnNotAvailable}>{t("components:listen:profiles:not_available")}</span>
  );
};

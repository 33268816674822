import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { CheckoutActionsType } from "./types";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "..";
import * as actions from "./actions";
import { GET_CHECKOUT_URL, GET_BILLING_URL } from "./actionTypes";

import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { stripe } from "@utils/paths";

export const getCheckoutUrlEpic = (
  action$: Observable<CheckoutActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(GET_CHECKOUT_URL)),
    switchMap(() => {
      return ajax
        .get<{ url: string }>(
          `${stripe}/checkout`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getCheckoutUrlActionSuccess(data.url)),
          catchError(e => handleError(e, actions.getCheckoutUrlActionFailure)),
        );
    }),
  );
};

export const getBillingUrlEpic = (
  action$: Observable<CheckoutActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(GET_BILLING_URL)),
    switchMap(() => {
      return ajax
        .get<{ url: string }>(
          `${stripe}/billing`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getBillingUrlActionSuccess(data.url)),
          catchError(e => handleError(e, actions.getBillingUrlActionFailure)),
        );
    }),
  );
};

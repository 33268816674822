import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { HubspotActionsType, IHubspotState } from "./types";
import { switchMap, map, filter } from "rxjs/operators";

import { SUBMIT } from "./actionTypes";

import { submitHubspotFormSuccess } from "./actions";
import { submit } from "@utils/paths";

import { getHeaders } from "@utils/headers";
import { parseCookies } from "@bbdevcrew/bb_ui_kit_fe";
import { IStep1CallbackData } from "@components/login/signupSteps/step1/Step1.type";
import { IFormStep2 } from "@components/login/signupSteps/step2/Step2.type";

interface IHubspotFormData {
  fields: { name: string; value: string }[];
  context: {
    hutk?: string;
    pageUri: string;
    pageName: string;
  };
}

const normalizeHSFormData = (data: IStep1CallbackData | IFormStep2) => {
  const { hubspotutk } = parseCookies(document.cookie) || {};
  const normalized: IHubspotFormData = {
    fields: [],
    context: {
      pageUri: window.location.href,
      pageName: "BrandBastion Sign Up",
    },
  };

  if (!!hubspotutk) {
    normalized.context.hutk = hubspotutk;
  }

  for (const key in data) {
    if (!key.includes("password")) {
      normalized.fields.push({
        name: key,
        value: data[key as keyof typeof data],
      });
    }
  }

  return normalized;
};

export const HSdataEpic = (action$: Observable<HubspotActionsType>) =>
  action$.pipe(
    filter(isOfType(SUBMIT)),
    switchMap(a => {
      return ajax
        .post<IHubspotState>(
          `${submit}/${process.env.HUBSPOT_PORTAL_ID}/${process.env.HUBSPOT_FORM_ID}`,
          normalizeHSFormData(a.payload),
          getHeaders({}),
        )
        .pipe(
          map(e => e.response),
          map(response => submitHubspotFormSuccess(response)),
        );
    }),
  );

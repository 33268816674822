import { useTranslation } from "react-i18next";
import React, { FC, useMemo, MouseEvent } from "react";

import { CommentAction } from "@bbdevcrew/bb_ui_kit_fe";

import { CommentClickFunctionType } from "../CommentActions.type";
import { IComment } from "@bbdevcrew/bb_ui_kit_fe";

import { FlagIcon, FlagFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IActionFlagProps {
  label?: string;
  className?: string;
  comment: IComment;
  onFlagClick?: CommentClickFunctionType;
}

const ActionFlag: FC<IActionFlagProps> = ({ label, comment, className = "", onFlagClick }) => {
  const { t } = useTranslation();

  const tooltipTitle: string | JSX.Element = useMemo(() => {
    if (comment.moderation?.is_flagged) {
      if (comment.tooltips?.moderation_flag) {
        return (
          <>
            <div>{comment.tooltips?.moderation_flag}</div>
            <div>{t("components:comments:tooltips:clickToRemoveFlagged")}</div>
          </>
        );
      } else return t("components:comments:tooltips:flagRemove");
    } else {
      return t("components:comments:tooltips:flag");
    }
  }, [comment, t]);

  const onClickFlag = (event: MouseEvent) => {
    event.preventDefault();

    onFlagClick && onFlagClick(comment.id, !comment.moderation?.is_flagged);
  };

  return (
    <CommentAction
      type="primary"
      className={className}
      onClick={onClickFlag}
      active={!!comment.moderation?.is_flagged}
      loading={comment.actionsProcessing?.includes("flag")}
      tooltipProps={{ title: tooltipTitle }}
    >
      {comment.moderation?.is_flagged ? <FlagFilledIcon /> : <FlagIcon />}
      {label}
    </CommentAction>
  );
};

export default ActionFlag;

import { action } from "typesafe-actions";
import {
  ADD_LINKEDIN_REACTION,
  ADD_LINKEDIN_REACTION_SUCCESS,
  DELETE_LINKEDIN_REACTION,
  DELETE_LINKEDIN_REACTION_SUCCESS,
  LINKEDIN_REACTION_FAILURE,
} from "./actionTypes";
import { LinkedInReactionType } from "@bbdevcrew/bb_ui_kit_fe";

export const addLinkedInReaction = (commentId: string, reaction: LinkedInReactionType) =>
  action(ADD_LINKEDIN_REACTION, { commentId, reaction });
export const addLinkedInReactionSuccess = (commentId: string, reaction: LinkedInReactionType) =>
  action(ADD_LINKEDIN_REACTION_SUCCESS, { commentId, reaction });

export const deleteLinkedInReaction = (commentId: string) =>
  action(DELETE_LINKEDIN_REACTION, commentId);
export const deleteLinkedInReactionSuccess = (commentId: string) =>
  action(DELETE_LINKEDIN_REACTION_SUCCESS, commentId);

export const linkedInReactionFailure = (commentId: string) =>
  action(LINKEDIN_REACTION_FAILURE, commentId);

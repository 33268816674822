import React, { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import {
  ActionsDropdown,
  Card,
  TabNavItem,
  Tooltip,
  SentimentBar,
  FollowersTag,
} from "@bbdevcrew/bb_ui_kit_fe";
import BlockUserConfirmationModal from "./BlockUserConfirmationModal";
import ShortcutsHint from "@components/_common/CommentDetail/ShortcutsHint";

import { blockUserAction, unblockUserAction } from "@store/replies/actions";

import s from "./ReplyModal.module.less";

import { IUserDetailsProps, CategoryType } from "./ReplyModal.type";
import {
  PostPlacementEnum,
  isInstagramPlatform,
  isFacebookPlatform,
  getPlatformIconBasicColor,
} from "@bbdevcrew/bb_ui_kit_fe";

import {
  HelpIcon,
  CheckIcon,
  SendIcon,
  CommentIcon,
  MentionIcon,
  StarIcon,
  HashIcon,
  MapPinIcon,
  DisabledIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

const COMMENT_CATEGORY_ICON_MAP = {
  comments: <CommentIcon />,
  private_messages: <SendIcon />,
  public_mentions: <MentionIcon />,
  reviews: <StarIcon />,
  hashtag_mentions: <HashIcon />,
};

const UserDetails: FC<IUserDetailsProps> = ({
  replyData,
  selectedCategory,
  onChangeCategory,
  onRefresh,
  comment,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isBlockUserConfirmationModalOpen, setIsBlockUserConfirmationModalOpen] = useState(false);
  const canBlockOrUnblockUser = replyData.author_detail.block_capability.can_block_unblock || false;
  const isBlockedAuthor = replyData.author_detail.block_capability.is_blocked || false;
  const { page_id: pageId, user_id: userId } = replyData.author_detail.block_capability;
  const canShowUserActions =
    canBlockOrUnblockUser && comment && isFacebookPlatform(comment?.platform_type.id);
  const showFollowers = comment && isInstagramPlatform(comment.platform_type.id);
  const authorActions = [
    isBlockedAuthor
      ? {
          id: "unblock_user",
          label: t("components:reply:userActions:unblockUser"),
          icon: <CheckIcon />,
          onClick: () => dispatch(unblockUserAction(pageId, userId)),
        }
      : {
          id: "block_user",
          label: t("components:reply:userActions:blockUser"),
          icon: <DisabledIcon />,
          onClick: () => setIsBlockUserConfirmationModalOpen(true),
        },
  ];
  const blockUser = () => {
    dispatch(blockUserAction(pageId, userId));
    setIsBlockUserConfirmationModalOpen(false);
  };

  return (
    <div className={s.bbUserDetails}>
      <div>
        <div className={s.bbUserDetailsTitle}>{t("components:reply:userDetails")}</div>
        <Card className={s.bbAuthorDetailsCard}>
          <div className={s.bbAuthorDetails}>
            <div className={s.bbAuthorInfo}>
              <div className={s.bbPlatformIcon}>
                {getPlatformIconBasicColor(replyData.author_detail.platform_type.id)}
              </div>
              <Tooltip title={replyData.author_detail.author_name}>
                <div
                  className={classNames(s.bbAuthorName, {
                    [s.bbAuthorNameWithActions]: canShowUserActions,
                  })}
                >
                  {replyData.author_detail.author_name}
                </div>
              </Tooltip>
            </div>
            {canShowUserActions && (
              <ActionsDropdown
                items={authorActions}
                triggerClass={s.bbAuthorActionsDropdown}
                triggerMode="click"
              />
            )}
          </div>
          {!!replyData.author_detail.author_location && (
            <div className={s.bbAuthorLocation}>
              <MapPinIcon />
              <span>{replyData.author_detail.author_location}</span>
            </div>
          )}
          {showFollowers && replyData.author_detail.followers && (
            <div className={s.bbAuthorTotalFollowers}>
              <FollowersTag
                followers={replyData.author_detail.followers.follower_tag}
                lastUpdatedAt={replyData.author_detail.followers.last_updated_at}
              />
            </div>
          )}
          {replyData.author_detail.author_total_reviews_count && (
            <div className={s.bbAuthorTotalReviews}>
              <span>{`
              ${replyData.author_detail.author_total_reviews_count} ${t(
                "components:reply:reviews:trustpilot:label",
              )}`}</span>
              <Tooltip title={t("components:reply:reviews:trustpilot:tooltip")}>
                <span className={s.bbAuthorTotalReviewsHelp}>
                  <HelpIcon />
                </span>
              </Tooltip>
            </div>
          )}
          <div className={s.bbAuthorTotalConversations}>
            {replyData.author_detail.comment_stats.total_comments}
            {replyData.post_placement === PostPlacementEnum.REVIEW
              ? t("components:reply:totalReviews")
              : t("components:reply:totalConversations")}
          </div>
          {replyData.author_detail.show_sentiment_bar && (
            <SentimentBar height={12} data={replyData.author_detail.comment_stats} />
          )}
        </Card>
        <div className={s.bbUserDetailsNavigation}>
          {replyData.author_detail.categories.map(category => {
            const categoryId = category.id as CategoryType;
            const icon = COMMENT_CATEGORY_ICON_MAP[categoryId];

            return (
              <TabNavItem
                key={category.id}
                icon={icon}
                label={category.label}
                count={category.count}
                isNotClickable={category.is_disabled}
                onClick={() => {
                  if (!category.is_disabled) {
                    onChangeCategory(category, comment);
                  }
                }}
                isActive={selectedCategory?.id === category.id}
              />
            );
          })}
        </div>
      </div>
      <div>
        <ShortcutsHint />
        {!!selectedCategory?.updated_information && (
          <div className={s.bbLastUpdated}>
            {selectedCategory.updated_information}{" "}
            <span onClick={onRefresh} className={s.bbLastUpdatedRefresh}>
              {t("generic:refresh")}
            </span>
          </div>
        )}
      </div>
      <BlockUserConfirmationModal
        isOpen={isBlockUserConfirmationModalOpen}
        onCancel={() => setIsBlockUserConfirmationModalOpen(false)}
        onConfirm={blockUser}
      />
    </div>
  );
};

export default UserDetails;

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  QuickAutomations,
  QuickAutomationsItem,
} from "../../../_common/QuickAutomations/QuickAutomations";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import s from "./QuickAutomationMenu.module.less";

import {
  UserPlusIcon,
  MentionIcon,
  ArchiveIcon,
  HelpIcon,
  EyeOffIcon,
  ReplyIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

const QuickAutomationMenu = () => {
  const { t } = useTranslation();

  const me = useSelector(meSelector);

  const autoActionConfig = me?.client?.auto_action_config;

  return (
    <QuickAutomations>
      <QuickAutomationsItem
        name="archive_hidden"
        checked={!!autoActionConfig?.archive_hidden}
        leftIcon={<EyeOffIcon />}
        rightIcon={<ArchiveIcon />}
        leftIconColorType="danger"
      >
        {t("components:quickAutomations:archive_hidden:label")}
      </QuickAutomationsItem>

      <QuickAutomationsItem
        name="archive_user_tags"
        checked={!!autoActionConfig?.archive_user_tags}
        leftIcon={<MentionIcon />}
        rightIcon={<ArchiveIcon />}
      >
        {t("components:quickAutomations:archive_user_tags:label")}
      </QuickAutomationsItem>

      <QuickAutomationsItem
        name="assign_replied"
        checked={!!autoActionConfig?.assign_replied}
        leftIcon={<ReplyIcon />}
        rightIcon={<UserPlusIcon />}
      >
        {t("components:quickAutomations:assign_replied:label")}
        <Tooltip title={t("components:quickAutomations:assign_replied:tooltip")}>
          <span className={s.bbQuickAutomationTooltip}>
            <HelpIcon />
          </span>
        </Tooltip>
      </QuickAutomationsItem>

      <QuickAutomationsItem
        name="assign_hidden"
        checked={!!autoActionConfig?.assign_hidden}
        leftIcon={<EyeOffIcon />}
        rightIcon={<UserPlusIcon />}
        leftIconColorType="danger"
      >
        {t("components:quickAutomations:assign_hidden:label")}
        <Tooltip title={t("components:quickAutomations:assign_hidden:tooltip")}>
          <span className={s.bbQuickAutomationTooltip}>
            <HelpIcon />
          </span>
        </Tooltip>
      </QuickAutomationsItem>
    </QuickAutomations>
  );
};

export default QuickAutomationMenu;

import React from "react";

import { Chip } from "@bbdevcrew/bb_ui_kit_fe";

import { TFunction } from "i18next";
import { IAIPrompt } from "@store/repliesAIPrompts/types";
import { IAIPromptSection } from "../../AIPrompts/aiPromptsSection/types";

export const getAIPromptSectionsConfig = (
  t: TFunction,
  initialValues?: IAIPrompt,
): IAIPromptSection[] => {
  return [
    {
      title: t("components:AIPrompts:edit:introSection:title"),
      description: (
        <>
          {t("components:AIPrompts:edit:introSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:edit:introSection:settingSentenceVariable")}
          />
          {t("components:AIPrompts:edit:introSection:settingSentence2")}
        </>
      ),
      fields: [
        {
          id: "brand_name",
          label: t("components:AIPrompts:edit:introSection:variable1:label"),
          maxCharLimit: 50,
          enabled: initialValues?.brand_name_enabled || false,
          rowNumber: 1,
          placeholder: t("components:AIPrompts:edit:introSection:variable1:placeholder"),
          errorMessage: t("components:AIPrompts:edit:introSection:variable1:error"),
        },
        {
          id: "brand_industry",
          label: t("components:AIPrompts:edit:introSection:variable2:label"),
          maxCharLimit: 50,
          enabled: initialValues?.brand_industry_enabled || false,
          rowNumber: 1,
          placeholder: t("components:AIPrompts:edit:introSection:variable2:placeholder"),
          errorMessage: t("components:AIPrompts:edit:introSection:variable2:error"),
        },
      ],
    },
    {
      title: t("components:AIPrompts:edit:toneOfVoiceSection:title"),
      description: (
        <>
          {t("components:AIPrompts:edit:toneOfVoiceSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:edit:toneOfVoiceSection:settingSentenceVariable")}
          />
          {t("components:AIPrompts:edit:toneOfVoiceSection:settingSentence2")}
        </>
      ),
      fields: [
        {
          id: "voice_tone",
          label: t("components:AIPrompts:edit:toneOfVoiceSection:variable:label"),
          maxCharLimit: 50,
          enabled: initialValues?.voice_tone_enabled || false,
          rowNumber: 4,
          placeholder: t("components:AIPrompts:edit:toneOfVoiceSection:variable:placeholder"),
          errorMessage: t("components:AIPrompts:edit:toneOfVoiceSection:variable:error"),
        },
      ],
    },
    {
      title: t("components:AIPrompts:edit:emojisSection:title"),
      description: (
        <>
          {t("components:AIPrompts:edit:emojisSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:edit:emojisSection:settingSentenceVariable")}
          />
          {t("components:AIPrompts:edit:emojisSection:settingSentence2")}
        </>
      ),
      inlineToggle: {
        id: "emoji",
        enabled: initialValues ? initialValues.emoji_enabled : true,
      },
    },
    {
      title: t("components:AIPrompts:edit:languageSection:title"),
      description: (
        <>
          {t("components:AIPrompts:edit:languageSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:edit:languageSection:settingSentenceVariable")}
          />
          {t("components:AIPrompts:edit:languageSection:settingSentence2")}
        </>
      ),
      fields: [
        {
          id: "language",
          label: t("components:AIPrompts:edit:languageSection:variable:label"),
          maxCharLimit: 50,
          enabled: initialValues?.language_enabled || false,
          rowNumber: 2,
          placeholder: t("components:AIPrompts:edit:languageSection:variable:placeholder"),
          errorMessage: t("components:AIPrompts:edit:languageSection:variable:error"),
        },
      ],
    },
    {
      title: t("components:AIPrompts:edit:additionalDetailsSection:title"),
      description: (
        <>
          {t("components:AIPrompts:edit:additionalDetailsSection:settingSentence1")}
          <Chip
            _size="xs"
            text={t("components:AIPrompts:edit:additionalDetailsSection:settingSentenceVariable")}
          />
        </>
      ),
      fields: [
        {
          id: "additional_data",
          label: t("components:AIPrompts:edit:additionalDetailsSection:variable:label"),
          maxCharLimit: 150,
          enabled: initialValues?.additional_data_enabled || false,
          rowNumber: 3,
          placeholder: t("components:AIPrompts:edit:additionalDetailsSection:variable:placeholder"),
          errorMessage: t("components:AIPrompts:edit:additionalDetailsSection:variable:error"),
        },
      ],
    },
  ];
};

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import sentimentStyles from "../../CommentCard/commentActions/CommentActions.module.less";

import { IUseCommentActionItemsProps } from "./types";
import { isMentionMessage } from "@components/_common/CommentsGrid/helpers";
import { SENTIMENT_ACTIONS } from "../../CommentCard/commentActions/helper";
import { isYoutubePlatform, PostPlacementEnum, IActionDropdownItem } from "@bbdevcrew/bb_ui_kit_fe";

import {
  AdIcon,
  SmileyIcon,
  PostIcon,
  UserIcon,
  ExportIcon,
  WarningOffIcon,
  ParentCommentIcon,
  ZendeskIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

export const useCommentExternalActionDropdownItems = ({
  actionFunctions,
  comment,
  showCommentLink,
  showCreateZendeskTicketOption,
}: IUseCommentActionItemsProps) => {
  const { t } = useTranslation();
  const isStoryComment = comment?.post_placement === PostPlacementEnum.STORY;
  const isDmComment = comment?.post_placement === PostPlacementEnum.DM;

  if (!comment) return [];

  const {
    onParentClick,
    onOpenClickReply,
    onOpenAdsClick,
    onOpenPostClick,
    onCreateZendeskTicketClick,
    filterAuthorsId,
    onDismissErrorClick,
    updateCommentSentiment,
  } = actionFunctions;
  const items: IActionDropdownItem[] = [];
  const isYoutubeComment = isYoutubePlatform(comment.platform.asset_platform || "");

  const onClickReply = () => onOpenClickReply && onOpenClickReply(comment.id);
  const onClickParent = () => onParentClick && onParentClick(comment.id);
  const onClickAds = () => onOpenAdsClick && onOpenAdsClick(comment.post_id);
  const onClickPost = () => onOpenPostClick && onOpenPostClick(comment.post_id);
  const onClickZendesk = () => onCreateZendeskTicketClick && onCreateZendeskTicketClick();
  const onClickFilterByAuthorId = () =>
    comment.author_id && filterAuthorsId && filterAuthorsId(comment.author_id);
  const onChangeSentiment = (sentiment: string) =>
    updateCommentSentiment && updateCommentSentiment(comment.id, sentiment);
  const onClickDismissError = () => onDismissErrorClick && onDismissErrorClick(comment.id);
  const onClickLink = () => window.open(comment.permalink, "_blank");

  if (comment.reply.is_sent && !isStoryComment && onOpenClickReply) {
    items.push({
      id: "thread",
      onClick: onClickReply,
      label: t("components:comments:actions:showThread"),
      icon: <ParentCommentIcon />,
    });
  }

  if (!!comment.parent && onParentClick) {
    items.push({
      id: "parent",
      onClick: onClickParent,
      label: t("components:comments:actions:showParent"),
      icon: <ParentCommentIcon />,
    });
  }

  if (comment.has_ads && onOpenAdsClick) {
    items.push({
      id: "ad",
      onClick: onClickAds,
      label: t("components:comments:actions:showAd"),
      icon: <AdIcon />,
    });
  }

  if (!!comment.post_id && onOpenPostClick && !isDmComment) {
    items.push({
      id: "post",
      onClick: onClickPost,
      label: t("components:comments:actions:showPost"),
      icon: <PostIcon />,
    });
  }

  items.push({
    id: "sentiment",
    label: t("components:comments:actions:changeSentiment"),
    icon: <SmileyIcon />,
    disabled:
      isYoutubeComment && comment.actions.sentiment_change_capability?.capability === "disabled",
    tooltipText: comment.actions.sentiment_change_capability?.tooltip,
    iconClassName: sentimentStyles.bbSentimentIconClass,
    subItems: SENTIMENT_ACTIONS.map(sentiment => ({
      id: sentiment,
      onClick: () => onChangeSentiment(sentiment),
      label: t(`components:comments:actions:sentiments:${sentiment}`),
      iconClassName: classNames(
        sentimentStyles.bbActionCircleClass,
        sentimentStyles[`bbActionCircleClass-${sentiment}`],
      ),
    })),
  });

  if (showCommentLink && comment.permalink && !isStoryComment && !isDmComment) {
    const commentLinkLabel =
      comment.post_type && isMentionMessage(comment.post_type)
        ? t("components:comments:actions:goToMention")
        : t("components:comments:actions:goToComment");

    items.push({
      id: "link",
      onClick: onClickLink,
      label: commentLinkLabel,
      icon: <ExportIcon />,
    });
  }

  if (comment.action_error?.user_message || comment.action_error?.platform_message) {
    items.push({
      id: "dismiss_errors",
      onClick: onClickDismissError,
      label: t("components:comments:actions:dismissError"),
      icon: <WarningOffIcon />,
    });
  }

  if (comment.author_id && filterAuthorsId) {
    items.push({
      id: "filter_id",
      onClick: onClickFilterByAuthorId,
      label: t("components:comments:actions:viewAllConversationsFromUser"),
      icon: <UserIcon />,
    });
  }

  if (showCreateZendeskTicketOption) {
    items.push({
      id: "create_zendesk_ticket",
      onClick: onClickZendesk,
      label: t("components:comments:actions:createZendeskTicket"),
      icon: <ZendeskIcon />,
    });
  }

  return items;
};

import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";

import { IInjectedCustomProps } from "../../Filters.type";
import { Tooltip, ISelectProps, SelectableTag } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import { isTTorYTEnabled } from "@utils/filters";
import { IAutocompleteOption } from "@store/autocomplete/types";

import s from "./SentimentSelect.module.less";

import {
  PositiveIcon,
  NegativeIcon,
  NeutralIcon,
  NAIcon,
  MentionIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

interface ISentimentSelectProps extends ISelectProps, IInjectedCustomProps {
  options: IAutocompleteOption[];
  onChange: (value: string[]) => void;
}

const SENTIMENT_ICONS = {
  positive: <PositiveIcon />,
  negative: <NegativeIcon />,
  neutral: <NeutralIcon />,
  user_tags: <MentionIcon />,
  not_applicable: <NAIcon />,
};

const SentimentSelect = (props: ISentimentSelectProps) => {
  const me = useSelector(meSelector);

  const {
    options,
    customprops: { form },
  } = props;

  const filteredOptions = isTTorYTEnabled(me)
    ? options
    : options.filter(option => option.value !== "not_applicable");

  const handleChange = (selectedSentiment: string, checked: boolean) => {
    const selectedTags = (form?.getFieldValue("sentiments") || []) as string[];
    const nextSelectedTags = checked
      ? [...selectedTags, selectedSentiment]
      : selectedTags.filter(type => type !== selectedSentiment);
    props.onChange?.(nextSelectedTags);
  };

  return (
    <div className={s.bbSentimentSelect} data-testid="sentiments">
      {filteredOptions.map(({ value, tooltip }) => {
        const icon = (
          <span className={classNames(s.bbSentimentOption, s[`bbSentimentOption-${value}`])}>
            {SENTIMENT_ICONS[value as keyof typeof SENTIMENT_ICONS]}
          </span>
        );
        const isChecked = (form?.getFieldValue("sentiments") || []).includes(value);

        return (
          <Tooltip title={tooltip} key={value}>
            <span>
              <SelectableTag
                checked={isChecked}
                defaultSelected={isChecked}
                data-testid={`app-filter-sentiment_type-${value}`}
                onChange={checked => handleChange(value, checked)}
                data-stonly-target={`app-global__filters--sentiment_type--${value}`}
              >
                {icon}
              </SelectableTag>
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default SentimentSelect;

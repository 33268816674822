import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useCallback, useEffect } from "react";

import PresetList from "./PresetList";
import SavedReplyList from "./SavedReplyList";
import SavedReplyEmptyView from "./SavedReplyEmptyView";
import { TabPane, TabsScrollable, ModalHeaderActions } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";
import { postSavedRepliesAction } from "@store/replies/actions";
import { getSavedRepliesSelector, searchedSavedRepliesSelector } from "@store/replies/selectors";

import { debounce } from "@bbdevcrew/bb_ui_kit_fe";
import { ModeType, ViewChangeFuncType } from "../Reply.types";
import { DEBOUNCE_WAIT_TIME, SavedReplyTabKeys } from "./helper";
import { ISavedReply } from "@store/replies/types";

export interface ISavedReplyListProps {
  mode: ModeType;
  commentId?: string;
  onViewChange: ViewChangeFuncType;
  onReplyClick?: (reply: ISavedReply) => void;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const SavedReplyListTabs: React.FC<ISavedReplyListProps> = ({
  mode,
  commentId,
  selectedTab,
  setSelectedTab,
  onViewChange,
  onReplyClick,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");

  const me = useSelector(meSelector);
  const savedReplies = useSelector(getSavedRepliesSelector);
  const searchedSavedReplies = useSelector(searchedSavedRepliesSelector);

  // eslint-disable-next-line
  const fetchSavedReplies = useCallback(
    debounce((params?: { query?: string; presetQuery?: string; commentId?: string }) => {
      dispatch(postSavedRepliesAction(params?.query, params?.presetQuery, params?.commentId));
    }, DEBOUNCE_WAIT_TIME),
    [dispatch],
  );

  const onChangeSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const onChangeTab = (tab: string) => {
    setSelectedTab(tab);
    setSearchValue("");
    fetchSavedReplies(mode === "modal" ? { commentId: commentId } : undefined);
  };

  useEffect(() => {
    switch (selectedTab) {
      case SavedReplyTabKeys.CustomReplies:
      case SavedReplyTabKeys.BBReplies:
        fetchSavedReplies({
          query: searchValue,
          commentId: mode === "modal" ? commentId : undefined,
        });
        break;
      case SavedReplyTabKeys.Presets:
        fetchSavedReplies({
          presetQuery: searchValue,
          commentId: mode === "modal" ? commentId : undefined,
        });
        break;
    }
  }, [searchValue, selectedTab, commentId, mode, fetchSavedReplies]);

  if (
    !searchedSavedReplies &&
    savedReplies?.items?.length === 0 &&
    savedReplies?.presets?.length === 0 &&
    savedReplies.internal_items?.length === 0
  )
    return <SavedReplyEmptyView tab={selectedTab} onViewChange={onViewChange} />;

  return (
    <>
      <ModalHeaderActions
        placeholder={t("generic:search")}
        title={t("components:reply:savedReplies:list:title")}
        onSearch={onChangeSearchValue}
        onAddClick={() => onViewChange("create", selectedTab)}
        hideButton={selectedTab === SavedReplyTabKeys.Presets}
      />
      <TabsScrollable
        activeKey={selectedTab}
        onChange={onChangeTab}
        defaultActiveKey={SavedReplyTabKeys.CustomReplies}
      >
        <TabPane
          tab={t("components:reply:savedReplies:list:customReplies")}
          tabKey={SavedReplyTabKeys.CustomReplies}
        >
          <SavedReplyList
            mode={mode}
            onViewChange={onViewChange}
            onReplyClick={onReplyClick}
            type={SavedReplyTabKeys.CustomReplies}
          />
        </TabPane>

        {!!savedReplies?.presets?.length && (
          <TabPane
            tab={t("components:reply:savedReplies:list:presets")}
            tabKey={SavedReplyTabKeys.Presets}
          >
            <PresetList onViewChange={onViewChange} onReplyClick={onReplyClick} />
          </TabPane>
        )}

        {me?.client?.bb_replies_enabled && (
          <TabPane
            tab={t("components:reply:savedReplies:list:bbReplies")}
            tabKey={SavedReplyTabKeys.BBReplies}
          >
            <SavedReplyList
              mode={mode}
              onViewChange={onViewChange}
              onReplyClick={onReplyClick}
              type={SavedReplyTabKeys.BBReplies}
            />
          </TabPane>
        )}
      </TabsScrollable>
    </>
  );
};

export default SavedReplyListTabs;

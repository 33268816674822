import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import {
  Card,
  Checkbox,
  Tooltip,
  Attachment,
  CommentCardHeader,
  CommentCardBody,
} from "@bbdevcrew/bb_ui_kit_fe";

import s from "./BatchModeCommentCard.module.less";

import { ICommentCardProps, isStoryAttachment } from "@bbdevcrew/bb_ui_kit_fe";
import { useBatchMode } from "@containers/BatchMode/BatchMode";

export default function BatchModeCommentCard({
  comment,
  isParent,
  maxLengthComment,
  userTimezone,
  headerPostView,
  withTags,
  onReplyClick,
  onTranslateClick,
  onAttachmentOpen,
  onAttachmentClose,
  openedAttachment,
  isFetchingRepliesAttachments,
  fetchedRepliesAttachments,
  isFetchingCommentsAttachments,
  fetchedCommentsAttachments,
}: ICommentCardProps) {
  const { t } = useTranslation();
  const { isBatchModeActive, addSelectedComment, selectedComments } = useBatchMode();

  const isSelectedCard = selectedComments?.some(
    selectedComment => selectedComment.id === comment.id,
  );

  const isMessageStoryAttachment =
    !!comment.attachments.length && isStoryAttachment(comment.attachments[0].description);

  return (
    <div
      data-cy="batch-mode-comment-card"
      className={classNames(s.bbCommentCard, s[`bbCommentCardSentiment-${comment.sentiment}`], {
        [s.bbCommentCardInfo]: !!comment.sentiment,
      })}
    >
      <Card>
        <CommentCardHeader
          comment={comment}
          onReplyClick={onReplyClick}
          timezone={userTimezone}
          headerPostView={headerPostView}
          batchModeCheckbox={
            isBatchModeActive ? (
              <Tooltip
                placement="top"
                title={t(
                  isSelectedCard
                    ? "containers:batchMode:card:unselect"
                    : "containers:batchMode:card:select",
                )}
              >
                <span>
                  <Checkbox
                    id={comment.id}
                    checked={isSelectedCard}
                    onClick={() => addSelectedComment(comment)}
                  />
                </span>
              </Tooltip>
            ) : undefined
          }
        />
        {isMessageStoryAttachment ? (
          <Attachment
            className={s.bbAttachments}
            isStoryAttachment
            attachments={comment.attachments || []}
            createdTime={comment.created_time}
            onAttachmentOpen={onAttachmentOpen}
            onAttachmentClose={onAttachmentClose}
            openedAttachment={openedAttachment}
            isFetchingRepliesAttachments={isFetchingRepliesAttachments}
            fetchedRepliesAttachments={fetchedRepliesAttachments}
            isFetchingCommentsAttachments={isFetchingCommentsAttachments}
            fetchedCommentsAttachments={fetchedCommentsAttachments}
          />
        ) : (
          <CommentCardBody
            comment={comment}
            isParent={isParent}
            onTranslateClick={onTranslateClick}
            maxLengthComment={maxLengthComment || 900}
            onAttachmentOpen={onAttachmentOpen}
            onAttachmentClose={onAttachmentClose}
            openedAttachment={openedAttachment}
            isFetchingRepliesAttachments={isFetchingRepliesAttachments}
            fetchedRepliesAttachments={fetchedRepliesAttachments}
            isFetchingCommentsAttachments={isFetchingCommentsAttachments}
            fetchedCommentsAttachments={fetchedCommentsAttachments}
          />
        )}

        {withTags && <div className={s.bbCommentCardTags}>{withTags(comment)}</div>}
      </Card>
    </div>
  );
}

import { TooltipProps } from "antd";
import { TFunction } from "i18next";

const EDIT_TOOLTIP_PLACEMENT = "top";

const EDIT_TOOLTIP_FONT_SIZE = 12;

const EDIT_TOOLTIP_WIDTH = "74px";

const EDIT_TOOLTIP_TEXT_ALIGNMENT = "center";

export const getEditTooltipConfig = (t: TFunction): TooltipProps => ({
  title: t("components:publish:calendar:editTooltip"),
  placement: EDIT_TOOLTIP_PLACEMENT,
  overlayInnerStyle: {
    fontSize: EDIT_TOOLTIP_FONT_SIZE,
    width: EDIT_TOOLTIP_WIDTH,
    textAlign: EDIT_TOOLTIP_TEXT_ALIGNMENT,
  },
});

export const TOOLTIP_ERROR_WIDTH = 360;

export const TOOLTIP_HIDDEN_CONFIG = { title: "" };

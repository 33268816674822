import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import s from "./PostInsights.module.less";

import { IPostInsightsProps } from "./PostInsights.types";

import { CommentIcon, HeartIcon, ShareIcon } from "@bbdevcrew/bb_ui_kit_fe";

const PostInsights: FC<IPostInsightsProps> = ({ commentsCount, likesCount, sharesCount }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbPostInsights}>
      <div className={s.bbPostInsightsItem}>
        <div className={s.bbPostInsightsItemName}>
          <CommentIcon />
          <span>{t("generic:comments")}</span>
        </div>
        <div className={s.bbPostInsightsItemCount}>{commentsCount}</div>
      </div>
      <div className={s.bbPostInsightsItem}>
        <div className={s.bbPostInsightsItemName}>
          <HeartIcon />
          <span>{t("generic:likes")}</span>
        </div>
        <div className={s.bbPostInsightsItemCount}>{likesCount}</div>
      </div>
      {sharesCount !== undefined && (
        <div className={s.bbPostInsightsItem}>
          <div className={s.bbPostInsightsItemName}>
            <ShareIcon />
            <span>{t("generic:shares")}</span>
          </div>
          <div className={s.bbPostInsightsItemCount}>{sharesCount}</div>
        </div>
      )}
    </div>
  );
};

export default PostInsights;

import React from "react";

import { t } from "../../../App";
import { formatter } from "../CardStat/helpers";
import { IPost } from "@bbdevcrew/bb_ui_kit_fe";

import {
  ReelIcon,
  ImageIcon,
  FileIcon,
  CameraIcon,
  CarouselImageIcon,
  CommentIcon,
  EyeIcon,
  HeartIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { MagnetIcon } from "@assets/index";

export const getPostMediaTypeIcon = (post: IPost) => {
  switch (post.post_media_type?.id) {
    case "image":
      return <ImageIcon />;
    case "video":
      return post.platform_type.id === "instagram" ? <ReelIcon /> : <CameraIcon />;
    case "multiimage":
      return <CarouselImageIcon />;
    case "pdf":
    case "doc":
    case "ppt":
      return <FileIcon />;
    default:
      return <ImageIcon />;
  }
};

export const getPostMediaTypeLabel = (post: IPost) => {
  switch (post.post_media_type?.id) {
    case "image":
      return t("components:postPreview:mediaLabel:image");
    case "video":
      return post.platform_type.id === "instagram"
        ? t("components:postPreview:mediaLabel:reel")
        : t("components:postPreview:mediaLabel:video");
    case "multiimage":
      return t("components:postPreview:mediaLabel:carousel");
    default:
      return t("components:postPreview:mediaLabel:image");
  }
};

export const formatPostMetric = (value?: number | string) => {
  if (!value || value === "N/A") return "-";

  const numberValue = Number(value);

  if (Number(numberValue) >= 1000000) {
    return (numberValue / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  return formatter(numberValue);
};

export const ICON_MAP: Record<string, React.ReactNode> = {
  comments: <CommentIcon />,
  reactions: <HeartIcon />,
  impressions: <EyeIcon />,
  engagements: <MagnetIcon />,
};

import classNames from "classnames/bind";
import React from "react";

import { Col } from "antd";
import { ColumnWithGrowth } from "./ProfilesTableRow.helpers";

import s from "./ProfilesTable.module.less";

import { getPlatformIcon } from "./ProfilesTable.helpers";
import { ImpressionsPlatformTableRowProps } from "./ProfilesTable.types";

const ImpressionsPlatformTableRow = React.memo(
  ({ data: row, index, columns }: ImpressionsPlatformTableRowProps) => {
    return (
      <>
        {!!columns.length && row && (
          <div
            data-cy="profiles-table-row"
            className={classNames(s.bbProfilesTableRow, {
              [s.bbProfilesTableRowColored]: index % 2 === 1,
            })}
          >
            <Col span={columns[0].colSpan} data-cy="profiles-column" className={s.bbColPlatform}>
              <div data-cy="profiles-platform" className={s.bbPlatformIconSmall}>
                {row.id && getPlatformIcon(row.id)}
              </div>
              {row.name}
            </Col>
            <Col className={s.bbColumn} data-cy="table-column" span={columns[1].colSpan}>
              <ColumnWithGrowth
                value={row.total_impressions}
                growth={row.total_impression_growth}
              />
            </Col>
            <Col className={s.bbColumn} data-cy="table-column" span={columns[2].colSpan}>
              <ColumnWithGrowth
                value={row.organic_impressions}
                growth={row.organic_impression_growth}
              />
            </Col>
            <Col className={s.bbColumn} data-cy="table-column" span={columns[3].colSpan}>
              <ColumnWithGrowth value={row.paid_impressions} growth={row.paid_impression_growth} />
            </Col>
          </div>
        )}
      </>
    );
  },
);

ImpressionsPlatformTableRow.displayName = "ImpressionsPlatformTableRow";

export default ImpressionsPlatformTableRow;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Menu } from "antd";
import { Dropdown } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Actions.module.less";

import { MenuInfo } from "rc-menu/lib/interface";

import { MoreActionsIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IMostCommentedPostActionsProps {
  showAdsOption?: boolean;
  onSeeAdsClick: () => void;
  onSeePostClick: () => void;
}

export const Actions = ({
  showAdsOption,
  onSeePostClick,
  onSeeAdsClick,
}: IMostCommentedPostActionsProps) => {
  const { t } = useTranslation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Dropdown
      open={isDropdownOpen}
      triggerMode="hover"
      setOpen={setIsDropdownOpen}
      placement="bottomRight"
      trigger={
        <div className={s.bbActionsTrigger} data-cy="dropdown-actions-trigger">
          <MoreActionsIcon />
        </div>
      }
    >
      <Menu>
        <Menu.Item
          onClick={(info: MenuInfo) => {
            info.domEvent.stopPropagation();
            onSeePostClick();
          }}
        >
          <span>{t("components:postTrends:menu:seePost")}</span>
        </Menu.Item>

        {showAdsOption && (
          <Menu.Item
            onClick={(info: MenuInfo) => {
              info.domEvent.stopPropagation();
              onSeeAdsClick();
            }}
          >
            <span>{t("components:postTrends:menu:seeAds")}</span>
          </Menu.Item>
        )}
      </Menu>
    </Dropdown>
  );
};

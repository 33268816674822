import { useTranslation } from "react-i18next";
import React, { FC } from "react";

import { Icon, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { getShortcutTooltipTitle } from "./helpers";

import s from "./ShortcutsHint.module.less";

import { KeyboardIcon, QuestionCircleIcon } from "@bbdevcrew/bb_ui_kit_fe";

const ShortcutsHint: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbShortcutsHintWrapper}>
      <div className={s.bbShortcutsHint}>
        <Icon _size="lg" icon={KeyboardIcon} className={s.bbShortcutsHintIcon} />
        <span className={s.bbShortcutsHintTitle}>
          {t("components:comments:details:shortcutTooltip:title")}
        </span>
        <Tooltip overlayInnerStyle={{ width: "340px" }} title={getShortcutTooltipTitle(t)}>
          <Icon _size="sm" icon={QuestionCircleIcon} className={s.bbShortcutsHintTooltipIcon} />
        </Tooltip>
      </div>
    </div>
  );
};

export default ShortcutsHint;

import { TFunction } from "i18next";
import { IPieChartUnit } from "../../../insights/sentimentDetails/SentimentDetails.type";
import { IOverviewPerformanceItem, IOverviewPerformanceTag } from "@store/dashboard/types";

import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

export const DEFAULT_CHART_COLORS = [
  "#1D9AAA",
  "#00123D",
  "#FF8849",
  "#B24D77",
  "#0049BE",
  "#B0EEC4",
  "#A56EFF",
  "#F5B7B7",
  "#ABC8FA",
  "#8E531F",
  "#C2C6D2",
  "#711516",
  "#FFB59C",
];

export const getReplySelectOptions = (t: TFunction) => ({
  REPLIED_BY_BB: t("components:overviewCare:select:replied_by_bb"),
  REPLIED_IN_PLATFORM: t("components:overviewCare:select:replied_in_platform"),
  REPLIED_BY_ALL: t("components:overviewCare:select:replied_by_all"),
});

export const normalizeRepliedStatsData = (data: IOverviewPerformanceItem[]) => {
  return data.map((item, index) => {
    return {
      name: item.name,
      value: item.count,
      color: DEFAULT_CHART_COLORS[index],
      rawName: item.id,
      change_percentage: item.change_percentage,
    } as IPieChartUnit;
  });
};

export const normalizeCareTagsStatsData = (data: IOverviewPerformanceTag[]) => {
  return data.map((item, index) => {
    return {
      ...item,
      color: DEFAULT_CHART_COLORS[index],
    };
  });
};

export const normalizeSafetyTagsStatsData = (data: IOverviewPerformanceTag[]) => {
  return data.map(item => {
    return {
      ...item,
      color: item.category === "universally_harmful" ? palette.colorRed700 : palette.colorRed400,
    };
  });
};

export const getSafetyCommentTagsLegendColor = (id: string) => {
  return id === "universally_harmful" ? palette.colorRed700 : palette.colorRed400;
};

export const isNotEmpty = (data?: IOverviewPerformanceItem[]) => {
  return data?.map(item => item.count).filter(c => c !== 0).length;
};

import { useTranslation } from "react-i18next";
import React, { FC, useCallback } from "react";

import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookLoginButton from "../SocialLoginButton/facebookLoginButton/FacebookLoginButton";

import { PlatformType } from "@bbdevcrew/bb_ui_kit_fe";
import { ReactFacebookLoginInfo, ReactFacebookFailureResponse } from "react-facebook-login";

interface IBBFacebookLoginProps {
  app_id: string;
  scopes: string[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  shouldRelogin: boolean;
  onLoginFail?: (id: PlatformType) => void;
  onLoginSuccess?: (
    id: PlatformType,
    accessToken: string,
    authorizationCode?: string,
    shouldRelogin?: boolean,
  ) => void;
}

const BBFacebookLogin: FC<IBBFacebookLoginProps> = ({
  app_id,
  scopes,
  shouldRelogin,
  isLoading,
  setIsLoading,
  onLoginFail,
  onLoginSuccess,
}) => {
  const { t } = useTranslation();

  const onFacebookLoginCallback = useCallback(
    (response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
      setIsLoading(false);

      if (!("userID" in response) || ("userID" in response && !response.userID)) {
        addToast({
          type: "warning",
          title: t("components:addAssets:cancelledLogin"),
          message: t("components:addAssets:cancelledLoginDescription"),
        });
        onLoginFail && onLoginFail("facebook");
      } else {
        onLoginSuccess &&
          onLoginSuccess("facebook", response.accessToken, undefined, shouldRelogin);
      }
    },
    // eslint-disable-next-line
    [shouldRelogin],
  );

  return (
    <FacebookLogin
      appId={app_id}
      // eslint-disable-next-line
      // @ts-ignore - Types dont contain `render` prop
      // eslint-disable-next-line
      render={(renderProps: { isSdkLoaded: boolean; onClick: Function }) =>
        renderProps.isSdkLoaded && (
          <FacebookLoginButton
            isLoading={isLoading}
            onClickLogin={() => {
              setIsLoading(true);
              renderProps.onClick();
            }}
          />
        )
      }
      disableMobileRedirect
      scope={scopes.join(",")}
      callback={onFacebookLoginCallback}
      onFailure={onFacebookLoginCallback}
    />
  );
};

export default BBFacebookLogin;

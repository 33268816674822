import React from "react";
import cx from "classnames";

import { Loading, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";

import s from "./Summary.module.less";

import { IManagedSummaryCardProps } from "../Transparency.types";
import { getPercentageType } from "@components/insights/overview/reviewsWidget";
import { getSummaryIcon } from "../Transparency.helpers";

import { UnlockIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ManagedSummaryCard = ({
  isLoading,
  title,
  tooltip,
  disabledReason,
  displayText,
  description,
  id,
  percentageChange,
  count,
}: IManagedSummaryCardProps) => {
  return (
    <>
      {isLoading ? (
        <div className={s.bbManagedSummaryCardLoading}>
          <Loading type="spinning" isLoading />
        </div>
      ) : (
        <>
          <div className={s.bbManagedSummaryCardHeader}>
            <div className={s.bbManagedSummaryCardTexts}>
              <span className={s.bbManagedSummaryCardTitle}>{title}</span>
              <span className={s.bbManagedSummaryCardDesc}>{description}</span>
            </div>
            <div
              className={cx(s.bbManagedSummaryCardIconWrapper, s[`bbManagedSummaryCardIcon${id}`])}
            >
              {getSummaryIcon(id)}
            </div>
          </div>
          <div className={s.bbManagedSummaryCardFooter}>
            {!disabledReason && <h3>{count?.toLocaleString()}</h3>}
            {!disabledReason && !!percentageChange && (
              <PerformanceCardPill
                percentageValue={percentageChange.value}
                type={getPercentageType(percentageChange.display, percentageChange.value)}
              />
            )}
            {!!disabledReason && (
              <Tooltip title={tooltip}>
                <div
                  className={s.bbManagedSummaryCardDisabled}
                  data-stonly-target={`app-managed__upgrade--${id}-issued`}
                >
                  <UnlockIcon />
                  <span>{displayText}</span>
                </div>
              </Tooltip>
            )}
          </div>
        </>
      )}
    </>
  );
};

import React, { FC } from "react";

import { CommentAction } from "@bbdevcrew/bb_ui_kit_fe";

import { TranslateSimpleIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ITranslateAllProps {
  label: string;
  className?: string;
  onClick?: () => void;
}

const TranslateAll: FC<ITranslateAllProps> = ({ label, className = "", onClick }) => {
  return (
    <CommentAction type="primary" className={className} onClick={onClick}>
      <TranslateSimpleIcon />
      {label}
    </CommentAction>
  );
};

export default TranslateAll;

import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CommentAction } from "@bbdevcrew/bb_ui_kit_fe";

import { CommentClickFunctionType } from "../CommentActions.type";
import { IComment, useKeyPress, keyCodes } from "@bbdevcrew/bb_ui_kit_fe";

import { StarIcon, StarFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IActionStarProps {
  label?: string;
  className?: string;
  comment: IComment;
  onStarClick?: CommentClickFunctionType;
  useShortcuts?: boolean;
}

const ActionStar: FC<IActionStarProps> = ({
  label,
  comment,
  useShortcuts,
  className = "",
  onStarClick,
}) => {
  const { t } = useTranslation();

  const tooltipTitle: string | JSX.Element = useMemo(() => {
    if (comment.reply.is_ignored) {
      return t("components:comments:tooltips:starDisabled");
    } else {
      if (comment.reply.is_starred) {
        if (comment.tooltips?.reply_star) {
          return (
            <>
              <div>{comment.tooltips?.reply_star}</div>
              <div>{t("components:comments:tooltips:clickToRemoveStarred")}</div>
            </>
          );
        } else return t("components:comments:tooltips:starRemove");
      } else return t("components:comments:tooltips:star");
    }
  }, [comment, t]);

  const onClickStar = () => {
    onStarClick && !comment.reply.is_ignored && onStarClick(comment.id, !comment.reply.is_starred);
  };

  if (useShortcuts) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useKeyPress(keyCodes.s, onClickStar);
  }

  return (
    <CommentAction
      className={className}
      onClick={onClickStar}
      active={comment.reply.is_starred}
      type={comment.reply.is_ignored ? "disabled" : "primary"}
      loading={comment.actionsProcessing?.includes("star")}
      tooltipProps={{ title: tooltipTitle }}
    >
      {comment.reply.is_starred ? <StarFilledIcon /> : <StarIcon />}
      {label}
    </CommentAction>
  );
};

export default ActionStar;

import { createAction } from "typesafe-actions";
import {
  DELETE_INTERNAL_NOTES,
  DELETE_INTERNAL_NOTES_FAILURE,
  DELETE_INTERNAL_NOTES_SUCCESS,
  GET_INTERNAL_NOTES,
  GET_INTERNAL_NOTES_FAILURE,
  GET_INTERNAL_NOTES_SUCCESS,
  POST_INTERNAL_NOTES,
  POST_INTERNAL_NOTES_FAILURE,
  POST_INTERNAL_NOTES_SUCCESS,
} from "./actionTypes";
import { IInternalNoteItem, IPostNotesPayload } from "./types";

export const getInternalNotesAction = createAction(GET_INTERNAL_NOTES)<string>();
export const getInternalNotesSuccessAction = createAction(GET_INTERNAL_NOTES_SUCCESS)<
  IInternalNoteItem[]
>();
export const getInternalNotesFailureAction = createAction(GET_INTERNAL_NOTES_FAILURE)();

export const postInternalNotesAction = createAction(POST_INTERNAL_NOTES)<IPostNotesPayload>();
export const postInternalNotesSuccessAction = createAction(POST_INTERNAL_NOTES_SUCCESS)<
  IInternalNoteItem[]
>();
export const postInternalNotesFailureAction = createAction(POST_INTERNAL_NOTES_FAILURE)();

export const deleteInternalNoteAction = createAction(DELETE_INTERNAL_NOTES)<string>();
export const deleteInternalNoteSuccessAction = createAction(
  DELETE_INTERNAL_NOTES_SUCCESS,
)<string>();
export const deleteInternalNoteFailureAction = createAction(DELETE_INTERNAL_NOTES_FAILURE)();

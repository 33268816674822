import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getShortenedSearchesAction,
  postShortenedSearchesAction,
  resetShortenedSearchesAction,
} from "@store/shortenedSearches/actions";
import { triggerFilteringAction } from "@store/filters/actions";
import { getPredefinedFiltersAction } from "@store/savedFilters/actions";
import {
  clientSwitchedSelector,
  getShortenedSearchesFailedSelector,
  getShortenedSearchesResponseSelector,
  getShortenedSearchesSuccessfulSelector,
  postShortenedSearchesSuccessfulSelector,
  shortenedSearchesIdSelector,
} from "@store/shortenedSearches/selectors";
import {
  predefinedFiltersSelector,
  getPredefinedFiltersFailedSelector,
  getPredefinedFiltersPendingSelector,
  getPredefinedFiltersSuccessfulSelector,
} from "@store/savedFilters/selectors";
import { clientDataSelector, meSelector } from "@store/me/selectors";

import { IClientData } from "@store/me/types";
import { IFilters } from "@store/filters/types";
import { getDefaultFilterValue } from "./filters";
import { createUrl, removeItemUrlSearch } from "@bbdevcrew/bb_ui_kit_fe";
import { ISavedFilter } from "@components/_common/AppFilter/AppFilters.type";

interface IQueryProps {
  id?: string;
  saved_filter?: string;
}

export interface ILocationState {
  saved_filter?: string;
  filters?: IFilters;
}

const filterAssets = (clientData: IClientData, queryObj: IFilters) => {
  if (clientData && queryObj.asset_ids) {
    queryObj.asset_ids = queryObj.asset_ids.filter(ass_id =>
      clientData.assets.some(ass => ass.id === ass_id),
    );

    return queryObj;
  }

  return queryObj;
};

const updateUrlSearch = (obj: IQueryProps) => {
  // clear id and saved_filters params, id and saved_filters cannot be together
  removeItemUrlSearch("id");
  removeItemUrlSearch("saved_filter");

  const url = new URL(window.location.href);

  if (obj.saved_filter) {
    url.searchParams.set("saved_filter", obj.saved_filter);
  } else if (obj.id) {
    url.searchParams.set("id", obj.id);
  }

  createUrl(url);
};

const getUrlSearchItem = (name: string) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(name);
};

const updateUrl = (
  data: IFilters,
  param: string | ISavedFilter | undefined,
  generateId: (data: IFilters) => void,
) => {
  if (typeof param !== "string") {
    // if the param is a saved filter
    param?.saved_filter
      ? updateUrlSearch({
          saved_filter: param.saved_filter,
        })
      : removeItemUrlSearch("saved_filter");
  }
  // Every changes made will Generate a new Id or Update saved Filter
  // If the param or data does not exist, clear the URL
  else if (!param || Object.values(data).length === 0) {
    removeItemUrlSearch("id");
    removeItemUrlSearch("saved_filter");
  } else if (param && param === "id") {
    // if the param is an ID, fetch the Request
    generateId(data);
  }
};

export const useAppFilters = (shouldInitData = true) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const me = useSelector(meSelector);
  const clientData = useSelector(clientDataSelector);
  const isClientSwitched = useSelector(clientSwitchedSelector);
  const predefinedFilters = useSelector(predefinedFiltersSelector);
  const shortenedSearchesId = useSelector(shortenedSearchesIdSelector);
  const shortenedSearchesFail = useSelector(getShortenedSearchesFailedSelector);
  const shortenedSearchesData = useSelector(getShortenedSearchesResponseSelector);
  const getShortenedSearchesFetched = useSelector(getShortenedSearchesSuccessfulSelector);
  const postShortenedSearchesFetched = useSelector(postShortenedSearchesSuccessfulSelector);
  const predefinedFiltersFetched = useSelector(getPredefinedFiltersSuccessfulSelector);
  const fetchingPredefinedFilters = useSelector(getPredefinedFiltersPendingSelector);
  const predefinedFiltersFetchFailed = useSelector(getPredefinedFiltersFailedSelector);

  const getShortenedSearches = useCallback(
    (id: string) => dispatch(getShortenedSearchesAction(id)),
    [dispatch],
  );

  const triggerFiltering = useCallback(
    (data: IFilters) => dispatch(triggerFilteringAction(data)),
    [dispatch],
  );

  const getPredefinedFilters = useCallback(
    () => dispatch(getPredefinedFiltersAction()),
    [dispatch],
  );

  const updateSearchDataAndUrl = useCallback(
    (data: IFilters, param?: string | ISavedFilter) => {
      // Create a new state object so it triggers child useEffects
      // Check useEffect obj. reference comparison
      triggerFiltering({ ...data });
      updateUrl(data, param, (appFilters: IFilters) =>
        dispatch(postShortenedSearchesAction({ filters: appFilters })),
      );
      dispatch(resetShortenedSearchesAction());
    },
    [dispatch, triggerFiltering],
  );

  useEffect(() => {
    if (
      !predefinedFilters.length &&
      !predefinedFiltersFetched &&
      !fetchingPredefinedFilters &&
      !predefinedFiltersFetchFailed
    )
      getPredefinedFilters();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getShortenedSearchesFetched && isClientSwitched) window.location.reload();
    // eslint-disable-next-line
  }, [getShortenedSearchesFetched]);

  const initData = () => {
    const searchItemId = getUrlSearchItem("id");
    const searchItemSavedFilter = getUrlSearchItem("saved_filter");

    if (searchItemId) {
      getShortenedSearches(searchItemId);
    } else if (searchItemSavedFilter) {
      const savedFilters = predefinedFilters.find(({ id }) => id === "savedFilters");
      const savedFilter = savedFilters?.items.find(
        ({ id: savedFilterId }) => savedFilterId === Number(searchItemSavedFilter),
      );
      triggerFiltering(savedFilter?.request || getDefaultFilterValue(me));
    } else {
      updateSearchDataAndUrl(getDefaultFilterValue(me));
    }
  };

  // Handle history effect
  useEffect(() => {
    const state = location.state as ILocationState | undefined;
    const savedFilterUrlParam = getUrlSearchItem("saved_filter");

    if (state?.filters) {
      updateSearchDataAndUrl(state.filters, "id");
    } else if (state?.saved_filter || savedFilterUrlParam) {
      const savedFilters = predefinedFilters.find(({ id }) => id === "savedFilters");
      const savedFilter = savedFilters?.items.find(
        ({ id: savedFilterId }) =>
          savedFilterId === Number(state?.saved_filter || savedFilterUrlParam),
      );

      if (savedFilter) triggerFiltering(savedFilter?.request || getDefaultFilterValue(me));
    } else if (shouldInitData) {
      initData();
    }
    // eslint-disable-next-line
  }, [history, predefinedFilters.length, shouldInitData]);

  // Shortened searces
  useEffect(() => {
    if (getShortenedSearchesFetched && clientData) {
      updateSearchDataAndUrl(filterAssets(clientData, shortenedSearchesData));
    }
    // eslint-disable-next-line
  }, [getShortenedSearchesFetched, clientData]);

  useEffect(() => {
    if (postShortenedSearchesFetched) {
      updateUrlSearch({
        id: shortenedSearchesId,
      });
      dispatch(resetShortenedSearchesAction());
    }
  }, [dispatch, postShortenedSearchesFetched, shortenedSearchesId]);

  useEffect(() => {
    if (shortenedSearchesFail) {
      updateSearchDataAndUrl(getDefaultFilterValue(me));
    }
    // eslint-disable-next-line
  }, [shortenedSearchesFail, clientData, updateSearchDataAndUrl]);

  return { updateSearchDataAndUrl };
};

import { useTranslation } from "react-i18next";
import React, { useEffect, FC, useRef } from "react";
import { useSelector } from "react-redux";

import { Form } from "antd";
import Filters from "../../Filters";
import { ColorPicker, Input, ScrollView, SimpleSelectSingle } from "@bbdevcrew/bb_ui_kit_fe";

import { clientDataSelector } from "@store/me/selectors";
import { filterDataSelector } from "@store/filters/selectors";

import {
  defaultInitialValues,
  defaultPresets,
  filterAccessOptions,
} from "./SaveFilterModal.helpers";

import s from "./SavedFilters.module.less";

import { IFormHandler } from "../../Filters.type";
import { IFilters } from "@store/filters/types";
import { ISaveFilterModalProps } from "./types";

const SaveFilterModal: FC<ISaveFilterModalProps> = ({ onSaveFilter, savedFilterItem, form }) => {
  const { t } = useTranslation();

  const filtersRef = useRef<IFormHandler>(null);
  const appFilters = useSelector(filterDataSelector);

  const clientData = useSelector(clientDataSelector);

  const onFinish = () => {
    if (filtersRef.current) filtersRef.current.submit();
  };

  const submitFilters = (filters: IFilters) => {
    onSaveFilter(form.getFieldsValue(), filters);
  };

  const initialFilterData = savedFilterItem ? savedFilterItem.request : appFilters || {};

  useEffect(() => {
    if (savedFilterItem) {
      form.setFieldsValue({
        id: savedFilterItem.id || undefined,
        name: savedFilterItem.name,
        color: savedFilterItem.color,
        access_scope: savedFilterItem.access_scope.id,
      });
    }
  }, [savedFilterItem, form]);

  const accessOptions = filterAccessOptions.map(value => ({
    label: t(`pages:dashboard:modals:savesSearch:form:access_scope:options:${value}`),
    value,
  }));

  return (
    <ScrollView className={s.bbSavedFiltersModalBody}>
      <>
        <Form
          form={form}
          layout="vertical"
          requiredMark="optional"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={defaultInitialValues}
        >
          <Form.Item name="id">
            <input type="hidden" name="id" />
          </Form.Item>
          <Form.Item
            name="name"
            className={s.bbSavedFilterFormItem}
            label={t("pages:dashboard:modals:savesSearch:form:name:label")}
            rules={[
              {
                required: true,
                message: t("pages:dashboard:modals:savesSearch:form:name:message"),
              },
            ]}
          >
            <Input name="label" _size="md" />
          </Form.Item>
          <div className={s.bbSavedFiltersModalFormGroup}>
            <Form.Item
              name="access_scope"
              label={t("pages:dashboard:modals:savesSearch:form:access_scope:label")}
              required
            >
              <SimpleSelectSingle options={accessOptions} placeholder={t("generic:select")} />
            </Form.Item>
            <Form.Item
              name="color"
              label={t("pages:dashboard:modals:savesSearch:form:color:label")}
              valuePropName="hex"
              required
            >
              <ColorPicker
                key="color"
                onChange={color => {
                  form.setFieldsValue({ color });
                }}
                presets={[
                  {
                    label: t("pages:dashboard:modals:savesSearch:form:color:presets"),
                    open: true,
                    colors: defaultPresets,
                  },
                ]}
              />
            </Form.Item>
          </div>
          <hr className={s.bbSavedFiltersModalRule} />
          <h3 className={s.bbSavedFiltersModalReview}>
            {t("pages:dashboard:modals:savesSearch:form:reviewFilters")}
          </h3>
          {initialFilterData && (
            <Filters
              ref={filtersRef}
              initialFilterData={initialFilterData}
              onFilter={filters => submitFilters(filters)}
              clientPlatformTypes={clientData?.platform_types || []}
            />
          )}
        </Form>
      </>
    </ScrollView>
  );
};

export default SaveFilterModal;

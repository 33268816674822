import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useRef, useState, useEffect, ChangeEvent, useCallback, useMemo } from "react";
import { position as getCaretPosition } from "caret-pos";

import {
  Button,
  AlertWarningAccent,
  InlineAlert,
  Tooltip,
  EmojiSelect,
} from "@bbdevcrew/bb_ui_kit_fe";
import { Input } from "antd";
import AIPrompts from "../AIPrompts/AIPrompts";
import ReplierName from "./replierName/ReplierName";
import SavedReplies from "./savedReplies/SavedReplies";
import ReplyBottomActions from "./ReplyBottomActions";
import AIPromptsForm from "./aiPromptForm/AIPromptForm";
import SuggestedRepliesCarousel from "./SuggestedRepliesCarousel";
import { PublicToPrivateReplyDisclaimer } from "./publicToPrivateReplyDisclaimer";
import UploadImageButton from "./UploadImageButton/UploadImageButton";
import { ReplyImagePreview } from "./replyImagePreview/ReplyImagePreview";
import { SharePostSelector } from "./SharePostSelector/SharePostSelector";
import { ReplySharePostPreview } from "./replySharePostPreview/ReplySharePostPreview";

import {
  getPromptsAction,
  deletePromptAction,
  setPromptAsDefaultAction,
  getAIPromptSuggestionAction,
  clearAIPromptSuggestion,
} from "@store/repliesAIPrompts/actions";
import { clearReplyImageAction, uploadReplyImageAction } from "@store/replies/actions";
import {
  AIPromptsSelector,
  AIPromptSuggestionSelector,
  fetchedAIPromptSuggestionSelector,
} from "@store/repliesAIPrompts/selectors";
import { meSelector } from "@store/me/selectors";
import {
  repliedThreadCommentSelector,
  uploadingReplyImageSelector,
} from "@store/replies/selectors";
import { fetchingAIPromptSuggestionSelector } from "@store/repliesAIPrompts/selectors";
import {
  repliesSelector,
  replyingThreadCommentSelector,
  replyImageSelector,
} from "@store/replies/selectors";

import s from "./Reply.module.less";

import { useDraftReply } from "./helpers";
import { EmojiData, BaseEmoji } from "emoji-mart";
import { IReplyProps, TAG_OFFSET, DEFAULT_MAX_LENGTH } from "./Reply.types";
import { IAIPromptCommentPayload } from "@store/repliesAIPrompts/types";
import { IPost, PlatformType, isLinkedinPlatform, isTiktokPlatform } from "@bbdevcrew/bb_ui_kit_fe";

import { FILE_SIZE_LIMIT } from "./UploadImageButton/helpers";

import { ISavedReply, ISuggestedReply } from "@store/replies/types";
import { SendButtonType } from "./replySendButton/ReplySendButton.types";

import {
  ClearIcon,
  IObjectOption,
  ChevronUpIcon,
  ChevronDownIcon,
  WarningIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);
const { TextArea } = Input;

const Reply: FC<IReplyProps> = ({
  comment,
  replyUser,
  replierInfo,
  isPrivateReply,
  disabledMentions,
  maxMessageLength,
  isReplyBtnLoading,
  replyingCommentId,
  canSendDMMedia,
  canSendDMMediaTooltip,
  onClickReply,
  replyCapability,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tagRef = useRef<HTMLSpanElement>(null);
  const textareaRef = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useDraftReply(replyingCommentId);
  const [rawMessage, setRawMessage] = useState("");

  const [replyImageSizeError, setReplyImageSizeError] = useState(false);
  const [userTagOffset, setUserTagOffset] = useState(0);
  const [userTag, setUserTag] = useState(replyUser.username);
  const [savedReplyModalOpen, setSavedReplyModalOpen] = useState(false);
  const [replySharePost, setReplySharePost] = useState<IPost>();
  const [replyingAsset, setReplyingAsset] = useState<IObjectOption | undefined>();
  const [suggestedReplyStats, setSuggestedReplyStats] = useState<{
    count_asset: number;
    count_post: number;
  }>();

  const [caretPosition, setCaretPosition] = useState({ top: 0, left: 0 });
  const [showClearButton, setShowClearButton] = useState(false);

  const maxReplyMessageLength = maxMessageLength || DEFAULT_MAX_LENGTH;
  const showUserTag = userTag && !isPrivateReply && !isLinkedinPlatform(replyUser.platform.id);
  // count space between user tag and the main message if user tag exists
  const userTagLength = showUserTag ? userTag.length + 1 : 0;
  const replyMessageLength = userTagLength + message.length;

  const isTiktokDM = isTiktokPlatform(replyUser.platform.id) && isPrivateReply;

  const me = useSelector(meSelector);
  const repliesSelectorData = useSelector(repliesSelector);
  const AIPromptSuggestion = useSelector(AIPromptSuggestionSelector);
  const fetchingAIPromptSuggestion = useSelector(fetchingAIPromptSuggestionSelector);
  const fetchedAIPromptSuggestion = useSelector(fetchedAIPromptSuggestionSelector);
  const aIPrompts = useSelector(AIPromptsSelector);
  const replyingThreadComment = useSelector(replyingThreadCommentSelector);
  const repliedThreadComment = useSelector(repliedThreadCommentSelector);
  const replyImage = useSelector(replyImageSelector);
  const uploadingReplyImage = useSelector(uploadingReplyImageSelector);
  const suggestedReplies = repliesSelectorData?.suggestions;

  const promptAISuggestion = useCallback(
    (payload: IAIPromptCommentPayload) => dispatch(getAIPromptSuggestionAction(payload)),
    [dispatch],
  );

  const fetchAIPrompts = useCallback(
    (query?: string) => {
      dispatch(getPromptsAction(query));
    },
    [dispatch],
  );

  const deleteAIPrompt = useCallback(
    (id: string) => {
      dispatch(deletePromptAction(id));
    },
    [dispatch],
  );

  const setDefaultAIPrompt = useCallback(
    (id: string) => {
      dispatch(setPromptAsDefaultAction(id));
    },
    [dispatch],
  );

  useEffect(() => {
    if (fetchedAIPromptSuggestion && AIPromptSuggestion) {
      setMessage(AIPromptSuggestion);
      setShowClearButton(true);
    }

    return () => {
      dispatch(clearAIPromptSuggestion());
    };
  }, [fetchedAIPromptSuggestion, AIPromptSuggestion, dispatch, setMessage]);

  useEffect(() => {
    if (!userTag) {
      setUserTagOffset(0);
    } else {
      setUserTagOffset(tagRef.current?.offsetWidth || 0);
    }
  }, [userTag]);

  const textarea = textareaRef.current?.querySelector("textarea");

  useEffect(() => {
    if (localStorage.getItem("AutoNavigation") === null) {
      localStorage.setItem("AutoNavigation", "true");
    }

    if (textarea) {
      textarea.focus();
      textarea.selectionStart = textarea.selectionEnd = textarea.value.length;
    }
  }, [textarea]);

  useEffect(() => {
    if (textarea && showClearButton) {
      const coords = getCaretPosition(textarea);
      setCaretPosition({
        top: coords.top,
        left: coords.left + (showUserTag ? userTagOffset + TAG_OFFSET : 0),
      });
    }
  }, [message, textarea, showClearButton, showUserTag, userTagOffset]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setSuggestedReplyStats(undefined);
      setRawMessage(event.target.value);
      setMessage(event.target.value.slice(0, maxReplyMessageLength - userTagLength));
      setShowClearButton(false);
    },
    [
      setSuggestedReplyStats,
      setMessage,
      setRawMessage,
      setShowClearButton,
      maxReplyMessageLength,
      userTagLength,
    ],
  );

  const removeTag = useCallback(() => {
    setUserTag("");
    setUserTagOffset(0);
  }, [setUserTag, setUserTagOffset]);

  const onEmojiSelect = (emoji: EmojiData) => {
    const native = (emoji as BaseEmoji).native;
    if (message.length + native.length >= (maxMessageLength || DEFAULT_MAX_LENGTH)) return;

    setMessage(message + native);
  };

  const clearReplyImage = () => {
    setReplyImageSizeError(false);
    dispatch(clearReplyImageAction());
  };

  const onInternalReplyClick = (type: SendButtonType, userIds: string[]) => {
    const tag = showUserTag ? userTag : "";
    const attachment = replyImage
      ? {
          type: "image",
          url: replyImage.fileName,
        }
      : replySharePost
        ? {
            type: "post",
            id: replySharePost?.id,
          }
        : undefined;
    onClickReply(
      tag,
      message.trim(),
      rawMessage,
      replyingCommentId,
      comment,
      attachment,
      replyingAsset?.id,
      !!replyingThreadComment,
      type === "send_approval" ? userIds : undefined,
    );
    setMessage("");
    clearReplyImage();
    setReplySharePost(undefined);
  };

  const onSavedReplyClick = () => {
    setSavedReplyModalOpen(!savedReplyModalOpen);
  };

  const replaceUserPlaceholder = useCallback(
    (suggestedReply: ISuggestedReply) => {
      let userPlaceholderValue = replyUser.user_placeholder;
      if (!userPlaceholderValue) {
        if (suggestedReply.username_option === "skip") {
          userPlaceholderValue = "";
        } else if (suggestedReply.username_option === "use_word") {
          userPlaceholderValue = suggestedReply.username_placeholder_word || "";
        }
      }
      setRawMessage(suggestedReply.message || "");
      return suggestedReply.message?.replace("{{user}}", userPlaceholderValue) || "";
    },
    [replyUser.user_placeholder, setRawMessage],
  );

  const setSuggestedReply = useCallback(
    (suggestedReply: ISuggestedReply) => {
      removeTag();
      setUserTagOffset(0);
      const nextMessage = replaceUserPlaceholder(suggestedReply);
      setMessage(nextMessage);
      setShowClearButton(true);
      setSuggestedReplyStats({
        count_asset: suggestedReply.count_asset || 0,
        count_post: suggestedReply.count_post || 0,
      });
    },
    [replaceUserPlaceholder, setMessage, removeTag, setUserTagOffset, setSuggestedReplyStats],
  );

  const handleCloseSavedRepliesModal = () => {
    setSavedReplyModalOpen(false);
  };

  const getAIPromptSuggestion = (promptId?: string) => {
    if (comment?.id)
      promptAISuggestion({
        comment_id: comment?.id,
        prompt_id: promptId,
      });
  };

  const onGenerateDefaultPromptClick = () => {
    repliesSelectorData &&
      promptAISuggestion({
        comment_id: repliesSelectorData?.replying_comment_id,
      });
  };

  const onUploadReplyImage = (selectedImage: File) => {
    dispatch(uploadReplyImageAction(selectedImage));
    setReplySharePost(undefined);
  };

  const onSelectSharePost = (post: IPost) => {
    setReplySharePost(post);
    clearReplyImage();
  };

  const setSavedReply = useCallback(
    (reply: ISavedReply) => {
      setMessage(replaceUserPlaceholder(reply));
      setSavedReplyModalOpen(false);
      setShowClearButton(true);
    },
    [replaceUserPlaceholder, setMessage, setSavedReplyModalOpen],
  );

  const clearMessage = useCallback(() => {
    setMessage("");
    setRawMessage("");
    setShowClearButton(false);
    textarea?.focus();
  }, [setMessage, textarea]);

  const replyingCommentPlatformId = useMemo(() => {
    return comment?.platform_type.id || replyingThreadComment?.platform_type?.id;
  }, [comment, replyingThreadComment]);

  const shouldDisableReplyButton =
    !!(isPrivateReply && repliedThreadComment?.id) ||
    (!message.trim().length && !replyImage && !replySharePost);

  return (
    <div className={s.bbReplyBoxContainer} data-testid="reply-box">
      {!!suggestedReplies?.length && (
        <SuggestedRepliesCarousel
          onSelectSuggestedReply={setSuggestedReply}
          suggestedReplies={suggestedReplies}
        />
      )}
      {!!replyingThreadComment && !repliedThreadComment && (
        <PublicToPrivateReplyDisclaimer
          commentId={replyingThreadComment?.id}
          text={t("components:reply:replyPrivately:disclaimerResponding")}
        />
      )}

      <div className={s.bbReplyInputContainer}>
        <div className={cx(s.bbReplyInputActions, s.bbReplyInputActionsTop)}>
          {replyingCommentPlatformId && (
            <ReplierName
              replierInfo={replierInfo}
              replyingAsset={replyingAsset}
              setReplyingAsset={setReplyingAsset}
              platformId={replyingCommentPlatformId}
            />
          )}
          <div className={s.bbReplyInputContainerHeader}>
            <EmojiSelect
              onEmojiSelect={onEmojiSelect}
              platform={replyUser.platform.id as PlatformType}
              useDropdownPortal
            />
            {isTiktokDM && (
              <>
                <UploadImageButton
                  canSendDMMedia={canSendDMMedia}
                  canSendDMMediaTooltip={canSendDMMediaTooltip}
                  onSelectFile={onUploadReplyImage}
                  onFileSizeLimitError={() => setReplyImageSizeError(true)}
                />
                <SharePostSelector
                  pageId={repliesSelectorData?.post_preview?.page_id}
                  onSelectPost={onSelectSharePost}
                />
              </>
            )}
            {me?.client?.ai_generated_reply_enabled && (
              <AIPrompts
                aIPrompts={aIPrompts}
                aIPromptsForm={AIPromptsForm}
                fetchAIPrompts={fetchAIPrompts}
                deleteAIPrompt={deleteAIPrompt}
                setDefaultAIPrompt={setDefaultAIPrompt}
                getAIPromptSuggestion={getAIPromptSuggestion}
                onGenerateDefaultPromptClick={onGenerateDefaultPromptClick}
                generateDefaultPromptLoading={fetchingAIPromptSuggestion}
                mainBtnTooltipText={t("components:AIPrompts:button:firstPartTooltip")}
                listBtnTooltipText={t("components:AIPrompts:button:secondPartTooltip")}
              />
            )}
            <Button _size="sm" onClick={onSavedReplyClick} className={s.bbSavedReplyButton}>
              {t("generic:savedReplies")}
              {savedReplyModalOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Button>
          </div>
        </div>
        <div className={s.bbReplyInput} ref={textareaRef}>
          {!replyImage && !replySharePost && (
            <div className={s.bbTextAreaWrapper}>
              <TextArea
                onInput={onChange}
                autoSize={{ minRows: 2 }}
                placeholder={t("components:reply:writeReply")}
                maxLength={maxReplyMessageLength - userTagLength}
                value={message}
                style={{
                  textIndent: userTagOffset ? userTagOffset + TAG_OFFSET : 0,
                }}
              />
              {showClearButton && (
                <div
                  className={s.bbClearButtonWrapper}
                  style={{
                    top: caretPosition.top,
                    left: caretPosition.left,
                  }}
                >
                  <Tooltip title={t("components:reply:clearReply")}>
                    <>
                      <button className={s.bbClearButton} onClick={clearMessage}>
                        <ClearIcon />
                      </button>
                    </>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
          {replyImage && (
            <ReplyImagePreview
              isUploadingReplyImage={uploadingReplyImage}
              replyImageURL={replyImage.url}
              clearReplyImage={clearReplyImage}
            />
          )}
          {replySharePost && (
            <ReplySharePostPreview
              replySharePost={replySharePost}
              clearSharePost={() => setReplySharePost(undefined)}
            />
          )}
          {showUserTag && (
            <span className={s.bbUserTag} ref={tagRef}>
              {userTag}
              {replyUser.mandatory === false && (
                <span className={s.bbRemoveUserTag} onClick={removeTag}></span>
              )}
            </span>
          )}
        </div>
        <ReplyBottomActions
          comment={comment}
          isPrivateReply={isPrivateReply}
          isReplyBtnLoading={isReplyBtnLoading}
          isReplyBtnDisabled={shouldDisableReplyButton}
          replyMessageLength={replyMessageLength}
          maxReplyMessageLength={maxReplyMessageLength}
          onInternalReplyClick={onInternalReplyClick}
          replyCapability={replyCapability}
        />
        {suggestedReplyStats && (
          <InlineAlert className={s.bbSuggestedReplyAlert}>
            {t("components:reply:suggestedReplyStats", {
              post: suggestedReplyStats.count_post,
              day: suggestedReplyStats.count_asset,
            })}
          </InlineAlert>
        )}
        {disabledMentions && (
          <AlertWarningAccent
            className={s.bbAlertWarning}
            message={t("components:reply:disabledMentionsWarning")}
          />
        )}
        {savedReplyModalOpen && (
          <SavedReplies
            setReply={setSavedReply}
            commentId={comment?.id}
            onCancel={handleCloseSavedRepliesModal}
          />
        )}
        {replyImageSizeError && (
          <div className={s.bbUploadFileError}>
            <WarningIcon />
            <span>
              {t("components:reply:fileSizeLimitErrorMessage", {
                limit: FILE_SIZE_LIMIT / 1000000,
              })}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Reply;

import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { IInboxStats, SideNav, numberToQuantity } from "@bbdevcrew/bb_ui_kit_fe";

import { inboxStatsSelector } from "@store/inboxStats/selectors";

import { IInboxMenuProps } from "./types";
import { mapNestedOptionsToChildren } from "./helpers";
import { IInboxOptions, useDefaultInboxOptions } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./InboxMenu.module.less";

const defaultTabValue = "care_highlights";

export const InboxMenu: FC<IInboxMenuProps> = ({
  value = defaultTabValue,
  onTabChange,
  useInboxOptions = useDefaultInboxOptions,
}) => {
  const { t } = useTranslation();
  const stats = useSelector(inboxStatsSelector);

  const { options } = useInboxOptions();

  useEffect(() => {
    // Jump to the default tab, if all errors were dismissed
    if (stats?.stats.errors === 0 && value === "errors") onTabChange(defaultTabValue);
    // eslint-disable-next-line
  }, [stats?.stats.errors]);

  const items = useMemo(() => {
    return mapNestedOptionsToChildren(
      options.map(({ statKey, nested, label, icon, showBadge }: IInboxOptions) => {
        const key = statKey as keyof IInboxStats;
        const inboxStats = stats?.stats ?? {};
        const count = typeof inboxStats[key] === "number" ? (inboxStats[key] as number) : 0;

        return {
          key,
          label: String(label || t(`components:inboxMenu:tabs:${key}Label`)),
          icon,
          badge: numberToQuantity(count),
          showBadge: true,
          showUnread: showBadge && count > 0,
          active: value === statKey,
          onClick() {
            onTabChange(key);
          },
          nested,
        };
      }),
    );
  }, [value, options, onTabChange, stats, t]);

  return <SideNav items={items} className={s.bbInboxMenu} />;
};

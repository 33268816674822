import React, { FC } from "react";

import EngagementCard from "./engagementCard/EngagementCard";
import EngagementProgressCard from "./engagementCard/EngagementProgressCard";

import s from "./engagementCard/EngagementCard.module.less";

import { IOverviewPerformanceItem } from "@store/dashboard/types";
import { IPerformanceUnit } from "../../_common/performanceCard/types";

import { EyeOffIcon, ReplyIcon, CommentIcon, SafetyIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IEngagementsCardsProps {
  loading: boolean;
  data: IOverviewPerformanceItem[];
}

const EngagementsCards: FC<IEngagementsCardsProps> = ({ data, loading }) => {
  const getEngagementIcon = (type: string) => {
    switch (type) {
      case "replies_issued":
        return <ReplyIcon data-icon="replies-issued-icon" />;
      case "conversations_hidden":
        return <EyeOffIcon data-icon="conversations-hidden-icon" />;
      case "harmful_conversations":
        return <SafetyIcon data-icon="harmful_conversations-icon" />;
      case "engageable_conversations":
        return <CommentIcon data-icon="engageable_conversations-icon" />;
    }
  };

  const navigateWithTab = (path: string, tab: string) => {
    const url = new URL(window.location.href);

    url.pathname = path;
    url.searchParams.set("tab", tab);

    window.location.href = url.href;
  };

  const getEngagementDataByType = (type: string) =>
    data.find(({ id }) => id === type) as IPerformanceUnit;

  return (
    <div
      id="comment-engagement-cards"
      data-cy="engagements-trends-container"
      className={s.bbEngagementCardsContainer}
    >
      <div className={s.bbEngagementCardsRow}>
        <EngagementProgressCard
          isLoading={loading}
          type="harmful_conversations"
          data={getEngagementDataByType("harmful_conversations")}
          icon={getEngagementIcon("harmful_conversations")}
          onClick={() => navigateWithTab("/care/smart-inbox", "ai_potentially_harmful")}
        />
        <EngagementProgressCard
          isLoading={loading}
          type="engageable_conversations"
          data={getEngagementDataByType("engageable_conversations")}
          icon={getEngagementIcon("engageable_conversations")}
          onClick={() => navigateWithTab("/care/smart-inbox", "ai_response_needed")}
        />
        <div className={s.bbEngagementCardsWrapper}>
          <EngagementCard
            type="conversations_hidden"
            isLoading={loading}
            engagement={getEngagementDataByType("conversations_hidden")}
            icon={getEngagementIcon("conversations_hidden")}
            onClick={() => navigateWithTab("/care/smart-inbox", "hidden")}
          />
          <EngagementCard
            type="replies_issued"
            isLoading={loading}
            engagement={getEngagementDataByType("replies_issued")}
            icon={getEngagementIcon("replies_issued")}
            onClick={() => navigateWithTab("/care/smart-inbox", "replied")}
          />
        </div>
      </div>
    </div>
  );
};

export default EngagementsCards;

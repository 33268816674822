import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { StateObservable } from "redux-observable";
import { catchError, filter, map, retry, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import { RootState } from "../";
import {
  ReportsActions,
  IPDFReportState,
  IGetScheduledDocumentsResponse,
  IScheduledReport,
} from "./types";
import { documentUrl, generatePdf, scheduledReports } from "@utils/paths";
import * as actions from "./actions";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { getHeaders } from "@utils/headers";

const PDF_STATUS_CHECK_INTERVAL = 5000;
const PDF_STATUS_CHECK_RETRIES = 10;

export const generatePDFDocumentEpic = (
  action$: Observable<ReportsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.generatePDFDocumentAction)),
    switchMap(action =>
      ajax
        .post<{ id: string }>(
          generatePdf,
          action.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          switchMap(response => {
            const checkStatusUrl = documentUrl(response.response.id);
            return ajax
              .get<IPDFReportState>(
                checkStatusUrl,
                getHeaders({
                  Authorization: state$.value.auth.session.accessToken.jwtToken,
                }),
              )
              .pipe(
                retry({
                  count: PDF_STATUS_CHECK_RETRIES,
                  delay: PDF_STATUS_CHECK_INTERVAL,
                  resetOnSuccess: true,
                }),
                map(e => e.response),
                map(data =>
                  actions.generatePDFDocumentSuccessAction({
                    ...data,
                    search_id: action.payload.search_id,
                  }),
                ),
              );
          }),
          catchError(err =>
            handleError(err, () =>
              actions.generatePDFDocumentFailureAction(action.payload.search_id),
            ),
          ),
        ),
    ),
  );

export const generateEmailReportDocument = (
  action$: Observable<ReportsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.generateEmailReportDocumentAction)),
    switchMap(action => {
      return ajax
        .get<IPDFReportState>(
          documentUrl(action.payload.id),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data =>
            actions.generatePDFDocumentSuccessAction({
              ...data,
              search_id: action.payload.id,
            }),
          ),
          catchError(err =>
            handleError(err, () => actions.generatePDFDocumentFailureAction(action.payload.id)),
          ),
        );
    }),
  );

export const getScheduledReportEpic = (
  action$: Observable<ReportsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getScheduledDocumentsAction)),
    switchMap(() => {
      return ajax
        .get<IGetScheduledDocumentsResponse>(
          scheduledReports,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getScheduledDocumentsSuccessAction(data)),
          catchError(e => handleError(e, actions.getScheduledDocumentsFailureAction)),
        );
    }),
  );

export const postScheduledReportEpic = (
  action$: Observable<ReportsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.postScheduledReportsAction)),
    switchMap(a =>
      ajax
        .post<IScheduledReport>(
          scheduledReports,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.postScheduledReportsSuccess(data)),
          catchError(err => handleError(err, actions.postScheduledReportsFailure)),
        ),
    ),
  );

export const updateScheduledReportsEpic = (
  action$: Observable<ReportsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateScheduledReportsAction)),
    switchMap(a =>
      ajax
        .put<IScheduledReport>(
          `${scheduledReports}/${a.payload.id}`,
          { ...a.payload, id: undefined },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.updateScheduledReportsSuccess(data)),
          catchError(err => handleError(err, actions.updateScheduledReportsFailure)),
        ),
    ),
  );

export const deleteScheduledReportEpic = (
  action$: Observable<ReportsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.deleteScheduledReportAction)),
    switchMap(a => {
      return ajax
        .delete(
          `${scheduledReports}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => actions.deleteScheduledReportSuccessAction(a.payload)),
          catchError(e => handleError(e, actions.deleteScheduledReportFailureAction)),
        );
    }),
  );

/* eslint-disable max-len */
import React from "react";

import { goToPath } from "./AgentPerformance.helpers";

import { TFunction } from "i18next";
import { IExplorerAgentConfig } from "./AgentPerformance.types";

import { TimeIcon, UserCommentIcon } from "@assets/index";
import { CommentIcon, ReplyIcon, TagIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const getEngageConfig = (t: TFunction): IExplorerAgentConfig => ({
  sectionDivider: {
    icon: <CommentIcon />,
    title: t("pages:overviewSections:care:title"),
    description: t("pages:overviewSections:care:description"),
    action: t("pages:overviewSections:care:action"),
    onActionClick: () => goToPath("/care"),
  },
  performanceWidgets: [
    {
      type: "total",
      title: t("components:agentPerformance:repliesIssued:title"),
      titleTooltipProps: {
        title: t("components:agentPerformance:repliesIssued:titleTooltip"),
      },
      icon: <ReplyIcon />,
    },
    {
      type: "total",
      title: t("components:agentPerformance:responseNeeded:title"),
      icon: <TagIcon />,
    },
    {
      type: "negative",
      title: t("components:agentPerformance:responseTime:title"),
      titleTooltipProps: {
        title: t("components:agentPerformance:responseTime:titleTooltip"),
        style: { width: 180 },
      },
      icon: <TimeIcon />,
    },
  ],
  userStats: {
    title: {
      repliesIssued: t("components:agentPerformance:repliesIssued:title"),
      responseTime: t("components:agentPerformance:responseTime:title"),
    },
    subTitle: t("components:agentPerformance:responseIssuedAndTime:subTitle"),
    colors: ["#0049BE", "#A56EFF"],
    legends: [
      t("components:agentPerformance:responseIssuedAndTime:legend:repliesIssued"),
      t("components:agentPerformance:responseIssuedAndTime:legend:responseTime"),
    ],
  },
});

export const getManagedAgentsConfig = (t: TFunction): IExplorerAgentConfig => ({
  sectionDivider: {
    icon: <UserCommentIcon />,
    title: t("pages:overviewSections:agents:title"),
    description: t("pages:overviewSections:agents:description"),
  },
  performanceWidgets: [
    {
      type: "total",
      title: t("components:agentPerformance:repliesIssued:titleManaged"),
      titleTooltipProps: {
        title: t("components:agentPerformance:repliesIssued:titleTooltip"),
      },
      icon: <ReplyIcon />,
    },
    {
      type: "negative",
      title: t("components:agentPerformance:responseTime:titleManaged"),
      titleTooltipProps: {
        title: t("components:agentPerformance:responseTime:titleTooltip"),
        style: { width: 180 },
      },
      icon: <TimeIcon />,
    },
  ],
  userStats: {
    title: {
      repliesIssued: t("components:agentPerformance:repliesIssued:titleManaged"),
      responseTime: t("components:agentPerformance:responseTime:titleManaged"),
    },
    subTitle: t("components:agentPerformance:responseIssuedAndTime:subTitle"),
    colors: ["#0049BE", "#A56EFF"],
    legends: [
      t("components:agentPerformance:responseIssuedAndTime:legend:repliesIssued"),
      t("components:agentPerformance:responseIssuedAndTime:legend:responseTime"),
    ],
  },
});

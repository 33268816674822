import React, { forwardRef, useContext } from "react";
import classNames from "classnames";

import AppFilters from "@components/_common/AppFilter/AppFilters";
import { FilterContext } from "@components/_common/AppLayout/FilterContext";

import s from "@components/_common/AppLayout/AppLayout.module.less";

import { IFormHandler } from "@components/_common/AppFilter/Filters.type";

export const AppFiltersContainer = forwardRef<IFormHandler>((_, ref) => {
  const filterContext = useContext(FilterContext);

  if (!filterContext || !filterContext.filters) {
    return null;
  }

  const {
    clientData,
    filters,
    hasNewSidenav,
    stickyFilterValue,
    isBottomReached,
    onFilterInternal,
    onResetFilters,
    customFilters,
    onSavedStickyChange,
    onSavedFilterChange,
    setStickyFilterValue,
    isBatchModeActive,
    hideSavedFilters,
  } = filterContext;

  return (
    <AppFilters
      ref={ref}
      clientData={clientData}
      initialFilterData={filters}
      hasNewSidenav={hasNewSidenav}
      isBottomReached={isBottomReached}
      stickyFilterValue={stickyFilterValue}
      onFilter={onFilterInternal}
      hideSavedFilters={hideSavedFilters}
      onResetFilters={onResetFilters}
      customFilters={customFilters}
      onSavedStickyChange={onSavedStickyChange}
      onSavedFilterChange={onSavedFilterChange}
      setStickyFilterValue={setStickyFilterValue}
      className={classNames({
        [s.bbAppFiltersContainerDisabled]: isBatchModeActive,
      })}
    />
  );
});

AppFiltersContainer.displayName = "AppFiltersContainer";

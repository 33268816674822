import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError, mergeMap } from "rxjs/operators";

import * as actions from "./actions";
import * as repliesActions from "../replies/actions";

import { RootState } from "..";
import { integrations, integrationUsers, ticketReply, tickets, ticketUsers } from "@utils/paths";
import { getAuthAPIHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import {
  IIntegrationsListResponse,
  IIntegrationUsersListResponse,
  Integration,
  IntegrationsActions,
  ISendNewInternalCommentResponse,
  IZendeskTicket,
} from "./types";

const integrationPlatform = "zendesk";
const integrationsUrl = integrations(integrationPlatform);
const ticketsUrl = tickets(integrationPlatform);
const integrationUsersUrl = (ticketId?: string, keyword?: string) =>
  integrationUsers(integrationPlatform, ticketId, keyword);
const ticketUsersUrl = (ticketId: string) => ticketUsers(integrationPlatform, ticketId);

// Integrations
export const getIntegrationsList = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getIntegrationsListAction)),
    switchMap(() =>
      ajax.get<IIntegrationsListResponse>(integrationsUrl, getAuthAPIHeaders(state$)).pipe(
        map(({ response }) => actions.getIntegrationsListSuccessAction(response)),
        catchError(e => handleError(e, actions.getIntegrationsListFailureAction)),
      ),
    ),
  );

export const createIntegration = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.createIntegrationAction)),
    mergeMap(({ payload }) =>
      ajax.post(integrationsUrl, payload, getAuthAPIHeaders(state$)).pipe(
        map(() => actions.createIntegrationSuccessAction()),
        catchError(e => handleError(e, actions.createIntegrationFailureAction)),
      ),
    ),
  );

export const updateIntegration = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateIntegrationAction)),
    mergeMap(a => {
      const { id, ...payload } = a.payload;

      return ajax
        .put<Integration>(`${integrationsUrl}/${id}`, payload, getAuthAPIHeaders(state$))
        .pipe(
          map(() => actions.updateIntegrationSuccessAction()),
          catchError(e => handleError(e, actions.updateIntegrationFailureAction)),
        );
    }),
  );

export const deleteIntegration = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.deleteIntegrationAction)),
    switchMap(({ payload: id }) =>
      ajax.delete(`${integrationsUrl}/${id}`, getAuthAPIHeaders(state$)).pipe(
        map(() => actions.deleteIntegrationSuccessAction()),
        catchError(e => handleError(e, actions.deleteIntegrationFailureAction)),
      ),
    ),
  );

// Users
export const getIntegrationUsersList = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getIntegrationUsersListAction)),
    switchMap(({ payload }) =>
      ajax
        .get<IIntegrationUsersListResponse>(
          integrationUsersUrl(payload.ticketId, payload.keyword),
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(({ response }) => actions.getIntegrationUsersListSuccessAction(response)),
          catchError(e => handleError(e, actions.getIntegrationUsersListFailureAction)),
        ),
    ),
  );

export const createIntegrationUser = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.createOrUpdateIntegrationUserAction)),
    mergeMap(({ payload }) =>
      ajax.post(integrationUsersUrl(), payload, getAuthAPIHeaders(state$)).pipe(
        map(() => actions.createOrUpdateIntegrationUserSuccessAction()),
        catchError(e => handleError(e, actions.createOrUpdateIntegrationUserFailureAction)),
      ),
    ),
  );

// Tickets
export const createTicket = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.createTicketAction)),
    mergeMap(({ payload }) =>
      ajax.post<IZendeskTicket>(ticketsUrl, payload, getAuthAPIHeaders(state$)).pipe(
        map(e => e.response),
        switchMap(response => [
          repliesActions.setZendeskTicketOnReplyDataAction(response),
          actions.createTicketSuccessAction(),
        ]),
        catchError(e => handleError(e, actions.createTicketFailureAction)),
      ),
    ),
  );

export const updateTicket = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateTicketAction)),
    mergeMap(({ payload }) =>
      ajax
        .patch(
          tickets(integrationPlatform, payload.id),
          { status: payload.status, priority: payload.priority },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(() => actions.updateTicketSuccessAction()),
          catchError(e => handleError(e, actions.updateTicketFailureAction)),
        ),
    ),
  );

export const sendNewInternalComment = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.sendNewInternalCommentAction)),
    mergeMap(({ payload }) =>
      ajax
        .post<ISendNewInternalCommentResponse>(
          ticketReply(integrationPlatform, payload.ticketId),
          { message: payload.message, is_public: false },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          switchMap(response => [
            repliesActions.setZendeskCommentOnReplyDataAction(response),
            actions.sendNewInternalCommentSuccessAction(),
          ]),
          catchError(e => handleError(e, actions.sendNewInternalCommentFailureAction)),
        ),
    ),
  );

export const linkIntegrationUserToTicket = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.linkIntegrationUserToTicketAction)),
    mergeMap(({ payload }) =>
      ajax
        .patch(
          ticketUsersUrl(payload.ticketId),
          { linked_user: payload.linked_user },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(() => actions.linkIntegrationUserToTicketSuccessAction()),
          catchError(e => handleError(e, actions.linkIntegrationUserToTicketFailureAction)),
        ),
    ),
  );

export const unlinkIntegrationUserFromTicket = (
  action$: Observable<IntegrationsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.unlinkIntegrationUserToTicketAction)),
    mergeMap(({ payload }) =>
      ajax.delete(ticketUsersUrl(payload.ticketId), getAuthAPIHeaders(state$)).pipe(
        map(() => actions.unlinkIntegrationUserToTicketSuccessAction()),
        catchError(e => handleError(e, actions.unlinkIntegrationUserToTicketFailureAction)),
      ),
    ),
  );

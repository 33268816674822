import { t } from "../../../../App";
import { IBreakdownUnit, IBreakdownUnitItemDetails } from "@store/aggregations/types";
import { ICSVSentimentsData } from "../../../_common/DownloadCSV";
import dayjs from "dayjs";
import { IPost } from "@bbdevcrew/bb_ui_kit_fe";

export const CHART_MAX_HEIGHT = 385;
export const CHART_HEIGHT_OFFSET = 6;
export const BAR_HEIGHT = 22;
export const BAR_PADDING = 14;
export const LABEL_SMALL = 150;
export const LABEL_LARGE = 200;

export const getCSVChartData = (breakdownUnits: IBreakdownUnit[], currentTab: string) => {
  let data: ICSVSentimentsData[] = [];
  const selectedDataSet = breakdownUnits.find(unit => unit.id === currentTab);

  if (selectedDataSet) {
    data = selectedDataSet.items.map(item => {
      const comment_stats: { [key: string]: string } = {};
      let total = 0;

      Object.values(item.comment_stats).forEach(el => {
        comment_stats[el.label] = el.count.toString();
        total += el.count;
      });

      return {
        name: item.label,
        Total: total.toString(),
        ...comment_stats,
      };
    });
  }

  return data;
};

export const getCSVTableHeaders = (columnName: string) => {
  return [
    columnName,
    t("components:searchPanel:columnTitles:totalComments"),
    t("components:searchPanel:columnTitles:positiveComments"),
    t("components:searchPanel:columnTitles:negativeComments"),
    t("components:searchPanel:columnTitles:userTags"),
    t("components:searchPanel:columnTitles:neutralComments"),
  ];
};

export const generatePostItem = (postDetails?: IBreakdownUnitItemDetails, id?: string) => {
  return {
    id: id,
    object_id: id,
    author_name: postDetails?.author_name,
    message: postDetails?.message,
    page_id: postDetails?.page_id,
    page_name: postDetails?.page_name,
    permalink: postDetails?.permalink,
    owned: true,
    picture: postDetails?.picture,
    attachments: [],
    created_time: postDetails?.created_time,
    created_time_pretty: postDetails?.created_time
      ? dayjs.unix(postDetails?.created_time).format()
      : "",
    reactions: postDetails?.reactions,
    post_type: postDetails?.post_type,
    type: postDetails?.type,
    platform: {
      id: postDetails?.platform_type,
      label: postDetails?.platform_type,
    },
    platform_type: {
      id: postDetails?.platform_type,
      label: postDetails?.platform_type,
    },
    post_category_type: {
      id: postDetails?.post_category,
      label: postDetails?.post_category,
    },
    comment_metrics: postDetails?.comment_metrics,
    post_metrics: postDetails?.post_metrics,
    post_tags: [],
  } as IPost;
};

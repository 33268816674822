import { t } from "../../../../App";
import { getBreakdownChartBarColor } from "@components/_common/BreakdownChart/helpers";
import { PerformanceCardType } from "@components/insights/_common/performanceCard/types";
import { IReviewsWidgetDistributionProps } from "./ReviewsWidget.types";
import { RecommendationType, DistributionDataOrderEnum } from "@bbdevcrew/bb_ui_kit_fe";
import { IPlatformDistribution, IReviewWidgetDistribution } from "@store/dashboard/types";

export const getPercentageType = (
  sentiment?: "POSITIVE" | "NEGATIVE",
  percentageValue?: number,
) => {
  if (!sentiment || !percentageValue) return "total";
  if (percentageValue < 0 && sentiment === "NEGATIVE") return "positive";
  if (percentageValue < 0 && sentiment === "POSITIVE") return "negative";
  return sentiment.toLowerCase() as PerformanceCardType;
};

export const normalizeReviewWidgetData = (
  distributionData: IReviewsWidgetDistributionProps["data"],
) => {
  return distributionData.map(item => {
    return {
      value: item.reviewCount,
      rawName: item.type,
      name: item.label,
      color: getBreakdownChartBarColor(item.type),
      change_percentage: item.percentageChange?.value,
      percentage_of_total: item.percentage,
      type: getPercentageType(item.percentageChange?.display, item.percentageChange?.value),
    };
  });
};

export const getReviewTypes = (hasFacebook: boolean, hasStarredReviewAssets: boolean) => {
  const reviewTypes: { type: RecommendationType; label: string }[] = [];
  if (hasFacebook) {
    reviewTypes.push(
      {
        type: "POSITIVE",
        label: t("components:reviewWidget:reviewTypes:positive"),
      },
      {
        type: "NEGATIVE",
        label: t("components:reviewWidget:reviewTypes:negative"),
      },
    );
  }
  if (hasStarredReviewAssets) {
    reviewTypes.push(
      {
        type: "FIVE_STAR",
        label: t("components:reviewWidget:reviewTypes:5_star"),
      },
      {
        type: "FOUR_STAR",
        label: t("components:reviewWidget:reviewTypes:4_star"),
      },
      {
        type: "THREE_STAR",
        label: t("components:reviewWidget:reviewTypes:3_star"),
      },
      {
        type: "TWO_STAR",
        label: t("components:reviewWidget:reviewTypes:2_star"),
      },
      {
        type: "ONE_STAR",
        label: t("components:reviewWidget:reviewTypes:1_star"),
      },
    );
  }
  return reviewTypes;
};

export const sortedRatingDistribution = <
  T extends IReviewWidgetDistribution | IPlatformDistribution,
>(
  distributionData: T[],
): T[] =>
  distributionData.sort((a, b) => {
    return DistributionDataOrderEnum[a.type] - DistributionDataOrderEnum[b.type];
  });

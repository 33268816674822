import * as actions from "./actions";
import { ActionType } from "typesafe-actions";

import {
  IComment,
  IPost,
  AllowedCommentActionsType,
  ICommentReaction,
} from "@bbdevcrew/bb_ui_kit_fe";
import { IFilters } from "../filters/types";
import { IInboxStats } from "../inboxStats/types";

export type CommentActionTypes = ActionType<typeof actions>;

export type IGenericAction = {
  type: string;
  payload: any; // eslint-disable-line
};

export interface IImage {
  height: number;
  src: string;
  width: number;
}

export interface IMedia {
  image: IImage;
}

export const DEFAULT_COMMENT_LIST_KEY = "legacy_all_comments";

export type CommentIDType = string;

export interface IComments {
  comments: CommentIDType[];
  cursor?: string;
  navigation_id?: string;
  info?: string;
  fetchingComments: boolean;
  fetchedComments: boolean;
  fetchedCommentsFail: boolean;
}

export type CommentListType = string;

export type ICommentRootState = {
  cache: {
    [commentId: CommentIDType]: IComment;
  };
  lists: {
    [listKey: CommentListType]: IComments;
  };
};

export interface ICommentHistory {
  phrase: string;
  timestamp_pretty: string;
}

export interface ICommentHistoryPayload {
  items: ICommentHistory[];
}

export interface ICommentsState extends ICommentRootState {
  comment?: ICommentWithPostProps;
  replyType?: keyof IInboxStats;
  actionsProcessing: AllowedCommentActionsType[];
  triggerNextBatch?: string;
  performingBatchActions: boolean;
  performedBatchActions: boolean;
  performedBatchActionsFailed: boolean;
  fetchingComment: boolean;
  fetchedComment: boolean;
  fetchedCommentFail: boolean;
  fetchingCommentsAttachments: boolean;
  fetchedCommentsAttachments: boolean;
  fetchedCommentsAttachmentsFail: boolean;
  fetchingPostUnhideComments: boolean;
  fetchedPostUnhideComments: boolean;
  fetchedPostUnhideCommentsFail: boolean;
  commentsCSV?: Blob;
  fetchingCommentsCSV: boolean;
  fetchedCommentsCSV: boolean;
  fetchedCommentsCSVFail: boolean;
  previouslyAssignedUsers: string[];
  previousReactions: { [key: string]: ICommentReaction | undefined };
  history: ICommentHistory[];
  fetchingCommentHistory: boolean;
  fetchedCommentHistory: boolean;
  fetchedCommentHistoryFailed: boolean;
}

export interface IPostCommentBody {
  filters?: IFilters;
  cursor?: string;
  navigation_id?: string;
  sort?: string;
  sort_field?: string;
  reply_platform_type?: ReplyPlatformType;
  replacePrevPage?: boolean;
  limit?: number;
  isReplyModal?: boolean;
  conversation_list_id?: CommentListType;
}

export interface IPostCommentsSearchBody extends IPostCommentBody {
  conversation_list_id?: CommentListType;
}

export type AssignedUserFilterType = "assigned" | "unassigned" | "assigned_to_me";

export interface IGetInboxRepliesPayload extends IPostCommentBody {
  list_type?: keyof IInboxStats;
  sort_field?: string;
  assigned_options?: AssignedUserFilterType;
}

export interface ICommentActionPayload {
  id: string;
  value: boolean;
  filters?: IFilters;
  action: AllowedCommentActionsType;
  listId?: CommentListType;
}

export interface ICommentsResponse {
  items: IComment[];
  cursor?: string;
  info?: string;
  navigation_id?: string;
}

export type ReplyPlatformType = "all" | "brandbastion" | "platform";

export interface ICommentsCSVPayload {
  filters?: IFilters;
  sort?: "asc" | "desc";
  sort_field?: string;
  cursor?: string;
  limit?: number;
}

export interface IBulkCommentActionPayload {
  actions?: AllowedCommentActionsType[];
  comment_ids: string[];
  custom_tags_to_add?: string[];
}

export interface ICommentWithPostProps extends IComment {
  post?: IPost;
}

import cn from "classnames/bind";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import PostView from "../PostView/PostView";
import CommentPostPreview from "./CommentPostPreview";
import BrandReply from "../CommentCard/brandReply/BrandReply";
import { CommentCard, FollowersTag } from "@bbdevcrew/bb_ui_kit_fe";
import BatchModeCommentCard from "../CommentCard/batchModeCommentCard/BatchModeCommentCard";
import CommentTagsList from "../CommentCard/commentTags/CommentTagsList";
import AiHighlightTags from "../CommentCard/aiHighlightTags/AiHighlightTags";
import CustomTagsList from "../CommentCard/customTags/CustomTagsList";
import CustomTagsDropdown from "../CommentCard/customTags/CustomTagsDropdown";
import BrandBastionTags from "../CommentCard/brandBastionTags/BBTags";
import MessageTags from "../CommentCard/messageTags/MessageTags";
import AssignUserContainer from "@containers/AssignUser/AssignUserContainer";

import {
  fetchedRepliesAttachmentsSelector,
  fetchingRepliesAttachmentsSelector,
  updatingReplyApprovalSelector,
} from "@store/replies/selectors";
import {
  fetchedCommentsAttachmentsSelector,
  fetchingCommentsAttachmentsSelector,
} from "@store/comments/selectors";
import { canManageBBTagsSelector } from "@store/me/selectors";
import { openedAttachmentSelector } from "@store/attachment/selectors";
import { deleteCustomTagFromCommentAction } from "@store/customTags/actions";
import { closeAttachmentAction, openAttachmentAction } from "@store/attachment/actions";
import { deleteCommentTagAction, postCommentTagAction } from "@store/commentTags/actions";

import s from "./CommentsGrid.module.less";

import {
  IComment,
  ICommentTag,
  IAttachment,
  IPrivateAttachment,
  PostPlacementEnum,
  isInstagramPlatform,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ICommentsGridItemProps } from "./types";
import { getCommentGridSize } from "@utils/commentCard";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { getSubFeatureName } from "@utils/feature";
import useApprovalAction from "@components/_common/ReplyModal/useApprovalAction";

const cx = cn.bind(s);

export const COMMENT_MAX_LENGTH = 550;

const CommentsGridItem: FC<ICommentsGridItemProps> = React.memo(
  ({
    comment,
    commentIndex,
    withPostPreview,
    userTimezone,
    withFooter,
    onReplyClick,
    onTranslateClick,
  }) => {
    const dispatch = useDispatch();
    const { isBatchModeActive } = useBatchMode();
    const showPostPreview = withPostPreview && comment.post_placement !== PostPlacementEnum.REVIEW;
    const showFollowers = comment && isInstagramPlatform(comment.platform_type.id);
    const { onApprovalAction } = useApprovalAction(comment.id, onReplyClick);

    const canManageBBTag = useSelector(canManageBBTagsSelector);
    const openedAttachment = useSelector(openedAttachmentSelector);
    const isFetchingRepliesAttachments = useSelector(fetchingRepliesAttachmentsSelector);
    const fetchedRepliesAttachments = useSelector(fetchedRepliesAttachmentsSelector);
    const isFetchingCommentsAttachments = useSelector(fetchingCommentsAttachmentsSelector);
    const fetchedCommentsAttachments = useSelector(fetchedCommentsAttachmentsSelector);
    const updatingReplyApproval = useSelector(updatingReplyApprovalSelector);

    const onAttachmentOpen = (attachment: IAttachment | IPrivateAttachment) => {
      dispatch(openAttachmentAction(attachment));
    };

    const onAttachmentClose = () => dispatch(closeAttachmentAction());

    const handleDeleteCustomTag = (_comment: IComment, tagId?: string) => {
      tagId &&
        dispatch(
          deleteCustomTagFromCommentAction({
            id: tagId,
            comment_id: _comment.id,
          }),
        );
    };

    const handleDeleteBBTag = (_comment: IComment, tagId: string) => {
      dispatch(deleteCommentTagAction({ tagId, commentId: _comment.id }));
    };

    const handleAddBBTag = (_comment: IComment, tag: ICommentTag) => {
      dispatch(postCommentTagAction({ tagId: tag.id, commentId: _comment.id }));
    };

    return (
      <div
        key={`comment-grid-item-${comment.id}`}
        className={cx({
          [s.bbCommentsGridItem]: !isBatchModeActive,
          [getCommentGridSize(comment)]: !isBatchModeActive,
          [s.bbCommentGridItemBatchMode]: isBatchModeActive,
          [s.bbCommentsGridItemWithPostPreview]: showPostPreview,
        })}
      >
        {isBatchModeActive ? (
          <BatchModeCommentCard
            comment={comment}
            userTimezone={userTimezone}
            headerPostView={PostView}
            commentIndex={commentIndex}
            maxLengthComment={COMMENT_MAX_LENGTH}
            withFooter={withFooter}
            onReplyClick={onReplyClick}
            onTranslateClick={onTranslateClick}
            onAttachmentOpen={onAttachmentOpen}
            onAttachmentClose={onAttachmentClose}
            openedAttachment={openedAttachment}
            isFetchingRepliesAttachments={isFetchingRepliesAttachments}
            fetchedRepliesAttachments={fetchedRepliesAttachments}
            isFetchingCommentsAttachments={isFetchingCommentsAttachments}
            fetchedCommentsAttachments={fetchedCommentsAttachments}
            withTags={(_comment: IComment) => (
              <>
                <CommentTagsList
                  tags={_comment.tags || []}
                  premiumTag={_comment.tooltips?.premium}
                />

                <AiHighlightTags tags={_comment.ai_tags} />
              </>
            )}
          />
        ) : (
          <>
            <CommentCard
              comment={comment}
              userTimezone={userTimezone}
              commentIndex={commentIndex}
              headerPostView={PostView}
              brandReply={BrandReply}
              maxLengthComment={COMMENT_MAX_LENGTH}
              withFooter={withFooter}
              onReplyClick={onReplyClick}
              onTranslateClick={onTranslateClick}
              onAttachmentOpen={onAttachmentOpen}
              onAttachmentClose={onAttachmentClose}
              onApprovalActionClick={onApprovalAction}
              openedAttachment={openedAttachment}
              isFetchingRepliesAttachments={isFetchingRepliesAttachments}
              fetchedRepliesAttachments={fetchedRepliesAttachments}
              isFetchingCommentsAttachments={isFetchingCommentsAttachments}
              fetchedCommentsAttachments={fetchedCommentsAttachments}
              loadingApprovalActions={updatingReplyApproval}
              assignUser={
                getSubFeatureName() !== "dashboard" ? (
                  <AssignUserContainer
                    assigningCommentId={comment.id}
                    value={comment.assigned_users}
                    triggerClassName={s.bbCommentCardHeaderAssignUserTrigger}
                  />
                ) : undefined
              }
              withTags={(_comment: IComment) => (
                <>
                  <CommentTagsList
                    commentId={_comment.id}
                    tags={_comment.tags || []}
                    premiumTag={_comment.tooltips?.premium}
                  />

                  <AiHighlightTags tags={_comment.ai_tags} />

                  {showFollowers && comment.author_followers && (
                    <FollowersTag
                      followers={comment.author_followers.follower_tag}
                      lastUpdatedAt={comment.author_followers.last_updated_at}
                    />
                  )}

                  <CustomTagsList
                    customTagsAutomated={_comment.custom_tags_by_automation}
                    customTagsByUser={_comment.custom_tags_by_user}
                    onDeleteTag={tagId => handleDeleteCustomTag(_comment, tagId)}
                  />

                  <CustomTagsDropdown
                    commentId={_comment.id}
                    customTagsByUser={_comment.custom_tags_by_user || []}
                    customTagsAutomated={_comment.custom_tags_by_automation || []}
                    hasTags={!!(_comment.tags?.length || _comment.ai_tags?.length)}
                  />

                  {canManageBBTag && (
                    <BrandBastionTags
                      tags={_comment.tags || []}
                      onAddTag={tag => handleAddBBTag(_comment, tag)}
                      onDeleteTag={tagId => handleDeleteBBTag(_comment, tagId)}
                    />
                  )}

                  {!!_comment.message_tags?.count && <MessageTags tags={_comment.message_tags} />}
                </>
              )}
            />
            {!!showPostPreview && <CommentPostPreview comment={comment} />}
          </>
        )}
      </div>
    );
  },
);

CommentsGridItem.displayName = "CommentsGridItem";

export default CommentsGridItem;

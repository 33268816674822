import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ReplyModal.module.less";

interface IBlockUserConfirmationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const BlockUserConfirmationModal: FC<IBlockUserConfirmationModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      responsive
      centered
      title={t("components:reply:userActions:blockUser")}
      open={isOpen}
      onCancel={onCancel}
      onOk={onConfirm}
      confirmLabel={t("components:reply:userActions:blockUser")}
      okButtonProps={{ _type: "danger" }}
    >
      <p>{t("components:reply:userActions:blockUserConfirmationModal:primaryText")}</p>
      <p className={s.bbBlockUserConfirmationModalSecondaryText}>
        {t("components:reply:userActions:blockUserConfirmationModal:secondaryText")}
      </p>
    </Modal>
  );
};

export default BlockUserConfirmationModal;

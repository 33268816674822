import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import { CommentAction } from "@bbdevcrew/bb_ui_kit_fe";

import { getHideUnhideTooltip } from "@utils/comments";
import { CommentClickFunctionType } from "../CommentActions.type";
import { IComment, PlatformType, useKeyPress, keyCodes } from "@bbdevcrew/bb_ui_kit_fe";

import { EyeIcon, EyeOffIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IActionHideProps {
  hideLabel?: boolean;
  className?: string;
  comment: IComment;
  onHideClick?: CommentClickFunctionType;
  useShortcuts?: boolean;
}

const ActionHide: FC<IActionHideProps> = ({
  comment,
  onHideClick,
  className = "",
  hideLabel = false,
  useShortcuts = false,
}) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const onClickHide = () => {
    onHideClick && comment.actions.can_hide_unhide && onHideClick(comment.id, !comment.is_hidden);
  };

  if (useShortcuts) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useKeyPress(keyCodes.h, onClickHide);
  }

  return (
    <CommentAction
      className={className}
      onClick={onClickHide}
      loading={comment.actionsProcessing?.includes("hide")}
      type={!comment.actions.can_hide_unhide ? "disabled" : "primary"}
      tooltipProps={{
        title: getHideUnhideTooltip(
          comment.is_hidden,
          comment.platform_type.id as PlatformType,
          comment.actions.hide_unhide_tooltip,
        ),
      }}
    >
      <>
        {comment.is_hidden ? (
          <>
            <EyeIcon />
            {screens.sm && !hideLabel && <span>{t("components:comments:actions:unhide")}</span>}
          </>
        ) : (
          <>
            <EyeOffIcon />
            {screens.sm && !hideLabel && <span>{t("components:comments:actions:hide")}</span>}
          </>
        )}
      </>
    </CommentAction>
  );
};

export default ActionHide;

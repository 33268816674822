import { RootState } from "../";
import { selectFromCache, selectAllFromCache } from "@bbdevcrew/bb_ui_kit_fe";

export const fromPosts = (state: RootState) => state.posts;

export const getPostsSelector = (state: RootState) => {
  const postIds = fromPosts(state).postIds;
  const cache = fromPosts(state).cache;
  return selectAllFromCache(cache, postIds);
};

export const getPaginationSelector = (state: RootState) => fromPosts(state).pagination;
export const getPostsTotalsSelector = (state: RootState) => fromPosts(state).totals;
export const getPostsAveragesSelector = (state: RootState) => fromPosts(state).averages;

export const getPostsPendingSelector = (state: RootState) => fromPosts(state).fetchingPosts;
export const getPostsSuccessfulSelector = (state: RootState) => fromPosts(state).fetchedPosts;
export const getPostsFailureSelector = (state: RootState) => fromPosts(state).fetchedPostsFail;

export const postSelector = (state: RootState) => {
  const postId = fromPosts(state).postId;
  const cache = fromPosts(state).cache;
  return postId ? selectFromCache(cache, postId) : undefined;
};

export const getPostSuccessfulSelector = (state: RootState) => fromPosts(state).fetchedPost;
export const getPostProcessingSelector = (state: RootState) => fromPosts(state).fetchingPost;
export const getPostFailedSelector = (state: RootState) => fromPosts(state).fetchedPostFail;
export const postClearedSelector = (state: RootState) => fromPosts(state).clearPost;

export const postAdsSelector = (state: RootState) => fromPosts(state).ads;
export const getPostAdsProcessingSelector = (state: RootState) => fromPosts(state).fetchingPostAds;
export const getPostAdsSuccessfulSelector = (state: RootState) => fromPosts(state).fetchedPostAds;
export const getPostAdsFailedSelector = (state: RootState) => fromPosts(state).fetchedPostAdsFail;
export const postAdsClearedSelector = (state: RootState) => fromPosts(state).clearPostAds;

export const getPostsCSVSelector = (state: RootState) => fromPosts(state).postsCSV;
export const getPostsCSVSelectorPending = (state: RootState) => fromPosts(state).fetchingPostsCSV;
export const getPostsCSVSelectorSuccessful = (state: RootState) => fromPosts(state).fetchedPostsCSV;
export const getPostsCSVSelectorFailure = (state: RootState) =>
  fromPosts(state).fetchedPostsCSVFail;

import { useTranslation } from "react-i18next";
import React, { FC, useCallback, useMemo, useState } from "react";

import { Grid } from "antd";
import GoogleLogin from "./GoogleLogin";
import FacebookLogin from "./FacebookLogin";
import { AssetTab } from "./assetTab/AssetTab";
import PermissionsAndAssets from "./PermissionsAndAssets";
import { Loading, TabPane, TabsResponsive } from "@bbdevcrew/bb_ui_kit_fe";
import TrustpilotLoginButton from "../SocialLoginButton/trustpilotLoginButton";
import TiktokLoginButton from "../SocialLoginButton/tiktokLoginButton/TiktokLoginButton";
import TwitterLoginButton from "../SocialLoginButton/twitterLoginButton/TwitterLoginButton";
import LinkedinLoginButton from "../SocialLoginButton/linkedinLoginButton/LinkedinLoginButton";

import s from "./AssetTabs.module.less";

import {
  isFacebookPlatform,
  isGooglePlatform,
  isLinkedinPlatform,
  isTiktokPlatform,
  isTwitterPlatform,
  isTrustpilotPlatform,
  isGoogleBusinessPlatform,
} from "@bbdevcrew/bb_ui_kit_fe";
import { getCurrentModuleName } from "@utils/paths";
import { IAssetTabsProps } from "./AssetTabs.type";
import { PlatformType } from "@bbdevcrew/bb_ui_kit_fe";

import { UserIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AssetTabs: FC<IAssetTabsProps> = ({
  platforms,
  isLoading,
  platformTypes,
  selectedAssets,
  isWebhookEnabled,
  selectedPlatform,
  title,
  disclaimerText,
  hidePermissionsAndAssets = false,
  onTabChange,
  onLoginClick,
  onLoginFail,
  onAssetSelect,
  onLoginSuccess,
  onWebhookChange,
  hideMonitorToggler = false,
}) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const [isLoginButtonLoading, setIsLoginButtonLoading] = useState(false);

  const getSelectedPlatformData = useCallback(
    () => platforms.find(({ id }) => id === selectedPlatform),
    [platforms, selectedPlatform],
  );

  const getSortedPlatforms = useCallback(() => {
    if (getCurrentModuleName() === "login") {
      return platforms.slice().sort((p1, p2) => {
        return p2.id === "tiktok" || p2.id === "tiktok_ads" ? -1 : 1;
      });
    } else return platforms;
  }, [platforms]);

  const showPlatformDisclaimer = useCallback(() => {
    const platform = getSelectedPlatformData();

    return (
      platform?.permissions?.status === "complete" &&
      platform?.id !== "linkedin" &&
      platform?.id !== "tiktok" &&
      platform?.id !== "tiktok_ads"
    );
  }, [getSelectedPlatformData]);

  const shouldRelogin = useMemo(
    () => getSelectedPlatformData()?.permissions?.status === "complete",
    [getSelectedPlatformData],
  );

  const getUserProfile = () => getSelectedPlatformData()?.permissions?.user_profile;

  const replacePlatformAuthUrl = (platform: string, url?: string) => {
    const redirectUrl = encodeURIComponent(
      `${window.location.origin}${window.location.pathname}?social_platform=${platform}`,
    );
    return url?.replace(":REDIRECT_URI", redirectUrl);
  };

  const renderLoginButton = (
    id: PlatformType,
    app_id?: string,
    authorization_url?: string,
    scopes?: string[],
  ) => (
    <div className={s.bbUserProfileAndBtnWrapper}>
      {!!onLoginSuccess && !!getUserProfile() && (
        <div className={s.bbUserProfile}>
          {getSelectedPlatformData()?.id !== "tiktok_ads" &&
            (getUserProfile()?.picture ? (
              <img alt="user_pic" src={getUserProfile()?.picture} className={s.bbTabPaneUserImg} />
            ) : (
              <div className={s.bbTabPaneUserImg}>
                <UserIcon />
              </div>
            ))}
          <span className={s.bbTabPaneUserName}>{getUserProfile()?.name}</span>
        </div>
      )}

      {isFacebookPlatform(id) && !!app_id && !!onLoginSuccess && (
        <FacebookLogin
          app_id={app_id}
          scopes={scopes || []}
          shouldRelogin={shouldRelogin}
          isLoading={isLoginButtonLoading}
          setIsLoading={setIsLoginButtonLoading}
          onLoginFail={onLoginFail}
          onLoginSuccess={onLoginSuccess}
        />
      )}

      {isGooglePlatform(id) && !!app_id && !!onLoginSuccess && (
        <GoogleLogin
          id={id}
          app_id={app_id}
          scopes={scopes || []}
          isLoading={isLoginButtonLoading}
          setIsLoading={setIsLoginButtonLoading}
          onLoginFail={onLoginFail}
          onLoginSuccess={onLoginSuccess}
        />
      )}

      {isTwitterPlatform(id) && !!onLoginClick && (
        <TwitterLoginButton
          isLoading={isLoginButtonLoading}
          onClickLogin={() => {
            setIsLoginButtonLoading(true);
            onLoginClick && onLoginClick(id);
          }}
        />
      )}

      {isTiktokPlatform(id) && !!onLoginClick && (
        <TiktokLoginButton
          isLoading={isLoginButtonLoading}
          onClickLogin={() => {
            setIsLoginButtonLoading(true);
            onLoginClick(id, replacePlatformAuthUrl("tiktok", authorization_url));
          }}
        />
      )}

      {isLinkedinPlatform(id) && !!onLoginClick && (
        <LinkedinLoginButton
          isLoading={isLoginButtonLoading}
          onClickLogin={() => {
            setIsLoginButtonLoading(true);
            onLoginClick(id, replacePlatformAuthUrl("linkedin", authorization_url));
          }}
        />
      )}

      {isTrustpilotPlatform(id) && !!onLoginClick && (
        <TrustpilotLoginButton
          isLoading={isLoginButtonLoading}
          onClickLogin={() => {
            setIsLoginButtonLoading(true);
            onLoginClick(id, replacePlatformAuthUrl("trustpilot", authorization_url));
          }}
        />
      )}

      {isGoogleBusinessPlatform(id) && !!app_id && !!onLoginClick && (
        <GoogleLogin
          id={id}
          app_id={app_id}
          scopes={scopes || []}
          isLoading={isLoginButtonLoading}
          setIsLoading={setIsLoginButtonLoading}
          onLoginFail={onLoginFail}
          onLoginSuccess={onLoginSuccess}
        />
      )}

      {screens.md && onLoginSuccess && showPlatformDisclaimer() && (
        <span className={s.bbAssetTabsDisclaimer}>
          {t(`components:addAssets:${getSelectedPlatformData()?.id}:disclaimer`)}
        </span>
      )}
    </div>
  );

  return (
    <>
      {!screens.md && (
        <>
          <h1 className={s.bbAssetTabsViewTitle}>{title}</h1>
          {renderLoginButton(
            selectedPlatform,
            getSelectedPlatformData()?.app_id,
            getSelectedPlatformData()?.authorization_url,
            getSelectedPlatformData()?.scopes,
          )}
        </>
      )}

      <TabsResponsive
        centered
        noUnderline
        activeKey={selectedPlatform}
        onChange={(activeKey: string) => onTabChange(activeKey as PlatformType)}
        tabBarPrefix={
          title && screens.md ? <h1 className={s.bbAssetTabsViewTitle}>{title}</h1> : undefined
        }
        className={s.bbAssetTabs}
      >
        {getSortedPlatforms().map(
          ({ id, label, description, scopes, app_id, authorization_url }) => (
            <TabPane
              key={id}
              tabKey={id}
              disabled={isLoading}
              className={s.bbTabPane}
              tab={
                <AssetTab
                  id={id}
                  label={label}
                  description={description}
                  isActive={selectedPlatform === id}
                />
              }
            >
              {isLoading ? (
                <Loading type="bb" isLoading />
              ) : (
                <>
                  {screens.md && renderLoginButton(id, app_id, authorization_url, scopes)}

                  {!hidePermissionsAndAssets && (
                    <PermissionsAndAssets
                      permissions={getSelectedPlatformData()?.permissions}
                      platformTypes={platformTypes}
                      selectedAssets={selectedAssets}
                      isWebhookEnabled={isWebhookEnabled}
                      selectedPlatform={selectedPlatform}
                      hideMonitorToggler={hideMonitorToggler}
                      onAssetSelect={onAssetSelect}
                      onWebhookChange={onWebhookChange}
                      disclaimerText={disclaimerText}
                    />
                  )}
                </>
              )}
            </TabPane>
          ),
        )}
      </TabsResponsive>
    </>
  );
};

export default AssetTabs;

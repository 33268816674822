import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useMemo, MouseEvent, useState, useEffect } from "react";

import { Tooltip, Chip, ChipAction } from "@bbdevcrew/bb_ui_kit_fe";

import { canManageBBTagsSelector } from "@store/me/selectors";
import { deleteReplyBBTagAction } from "@store/replies/actions";
import { deleteCommentTagAction } from "@store/commentTags/actions";
import { deletingCommentTagSelector } from "@store/commentTags/selectors";

import { ICommentTagsListProps } from "./CommentTags.type";
import { tagIcon, maxChipCount } from "./commentTagsConfig";
import { ChipType, ICommentTag, ITagsCustomTooltip, ITagType } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CommentTagsList.module.less";

import { LoadingOutlined } from "@ant-design/icons";
import { CloseIcon, ChartIcon, PremiumIcon } from "@bbdevcrew/bb_ui_kit_fe";

const CommentTagsList: FC<ICommentTagsListProps> = ({
  tags,
  commentId,
  isReply,
  premiumTag,
  customAction: CustomActionComponent,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [deletedTagId, setDeletedTagId] = useState("");

  const isDeletingCommentTag = useSelector(deletingCommentTagSelector);
  const canManageBBTag = useSelector(canManageBBTagsSelector);

  let tooltip = t(`components:comments:tooltips:tagsContainerGeneric`);

  useEffect(() => {
    if (!isDeletingCommentTag) setDeletedTagId("");
  }, [isDeletingCommentTag]);

  const customTooltips = useMemo(
    () => [
      {
        category: "no_category",
        showTooltipIfSingle: true,
        tooltip: t("components:comments:tooltips:tagsContainerNoCategory"),
      },
    ],
    [t],
  );

  if (customTooltips && customTooltips.length > 0 && tags.length > 0) {
    customTooltips.forEach((ttp: ITagsCustomTooltip) => {
      if (ttp.showTooltipIfSingle && tags.length === 1 && tags[0].category === ttp.category) {
        tooltip = ttp.tooltip;
      } else if (
        tags.some(tag => {
          return tag.category === ttp.category;
        }) &&
        !ttp.showTooltipIfSingle
      ) {
        tooltip = ttp.tooltip;
      }
    });
  }

  const deleteTag = (event: MouseEvent<HTMLButtonElement>, tagId: string) => {
    event.preventDefault();
    setDeletedTagId(tagId);

    if (commentId) {
      dispatch(
        deleteCommentTagAction({
          commentId,
          tagId,
        }),
      );
    }

    if (isReply) {
      dispatch(deleteReplyBBTagAction(tagId));
    }
  };

  const renderActionIcon = (tag: ICommentTag) => {
    if (!!CustomActionComponent) {
      return <CustomActionComponent tag={tag} />;
    }

    if (canManageBBTag) {
      const isDeleting = isDeletingCommentTag && tag.id === deletedTagId;

      return isDeleting ? <LoadingOutlined spin className={s.bbLoading} /> : <CloseIcon />;
    }

    return null;
  };

  return !!tags?.length || !!premiumTag ? (
    <>
      {tags &&
        tags.slice(0, maxChipCount).map(tag => {
          const category = tag.category as keyof ITagType;
          const Icon = tagIcon[category] || ChartIcon;
          const type = category ? "default" : ("grey" as ChipType);

          return (
            <Tooltip key={tag.id} title={tooltip}>
              <span className={classNames({ [s.bbCommentTagWrapper]: !!tooltip })}>
                <ChipAction
                  key={tag.id || tag.label}
                  _size="xs"
                  _type={type}
                  text={tag.label}
                  leftIcon={
                    <div className={s.bbCommentTagIconWrapper}>
                      <Icon />
                    </div>
                  }
                  iconClassName={classNames(s.bbCommentTagIcon, s[`bbCommentTagIcon-${category}`])}
                  className={classNames(s.bbCommentTag, s[`bbCommentTag-${category}`])}
                  wrapperClassName={s.bbCommentTagChipActionWrapper}
                  onActionClick={
                    canManageBBTag && !CustomActionComponent ? e => deleteTag(e, tag.id) : undefined
                  }
                  actionIcon={renderActionIcon(tag)}
                />
              </span>
            </Tooltip>
          );
        })}
      {tags?.length > maxChipCount && (
        <Tooltip
          title={tags.slice(maxChipCount).map((tag, idx) => (
            <span key={idx} className={s.bbTagLabel}>
              {tag.label}
            </span>
          ))}
        >
          <Chip
            _size="xs"
            _type="grey"
            className={s.bbChipCount}
            text={`+${tags?.length - maxChipCount}`}
          />
        </Tooltip>
      )}
      {premiumTag && (
        <Tooltip title={premiumTag}>
          <span className={s.bbPremiumTag}>
            <PremiumIcon />
          </span>
        </Tooltip>
      )}
    </>
  ) : null;
};

export default CommentTagsList;

import React from "react";
import { useTranslation } from "react-i18next";

import { CommentStats } from "./CommentStats";
import { numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./MostCommentedPostFooter.module.less";

import { ITrendingPostProps } from "@store/overview/types";

import { CommentIcon, ExportIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IMostCommentedPostFooterProps {
  chartWidth: number;
  postData: ITrendingPostProps;
  showPostIdAndLink?: boolean;
}

const MostCommentedPostFooter: React.FC<IMostCommentedPostFooterProps> = ({
  postData,
  chartWidth,
  showPostIdAndLink = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbMostCommentedPostFooter} data-cy="most-commented-post-footer">
      <div className={s.bbMostCommentedPostFooterTopSection}>
        <div className={s.bbPostTotal} data-cy="most-commented-post-total">
          <span className={s.bbPostTotalIcon}>
            <CommentIcon />
          </span>
          <span>{numberFormatter(postData?.comment_stats?.total || 0)}</span>
        </div>
        {!postData.hide_sentiment_bar ? (
          <CommentStats chartWidth={chartWidth} commentStats={postData.comment_stats} />
        ) : null}
      </div>
      {showPostIdAndLink && (
        <>
          <div className={s.bbMostCommentedPostFooterPostIdWrapper}>
            {t("components:pdfReport:postCard:postId")}
            <span className={s.bbMostCommentedPostFooterPostId}>{postData.post.id}</span>
          </div>
          <a href={postData.post.permalink} className={s.bbMostCommentedPostFooterLink}>
            {t("components:pdfReport:postCard:linkToPost")}
            <ExportIcon />
          </a>
        </>
      )}
    </div>
  );
};

export default MostCommentedPostFooter;

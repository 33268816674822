import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Divider } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./PostPreviewTooltip.module.less";

import { IPostPreviewTooltipFooterProps } from "./PostPreviewTooltip.types";
import { formatPostMetric, ICON_MAP } from "./helpers";

const MetricDisplay: FC<{
  id: string;
  value?: number | string;
}> = ({ id, value }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbPostTooltipFooterMetric}>
      <div>
        {ICON_MAP[id]}
        <span className={s.bbPostTooltipFooterMetricText}>
          {t(`components:postPreview:metrics:${id}`)}
        </span>
      </div>
      <span className={s.bbPostTooltipFooterMetricValue}>
        {id === "engagements" && value && value !== "N/A" ? `${value} %` : formatPostMetric(value)}
      </span>
    </div>
  );
};
export const PostPreviewTooltipFooter: FC<IPostPreviewTooltipFooterProps> = ({ metrics }) => {
  const metricsConfig = [
    {
      id: "comments",
      value: metrics.comments,
    },
    { id: "reactions", value: metrics.reactions },
    {
      id: "impressions",
      value: metrics.impressions,
    },
    {
      id: "engagements",
      value: metrics.engagements,
    },
  ];

  return (
    <div className={s.bbPostTooltipFooter}>
      <div className={s.bbPostTooltipFooterSection}>
        {metricsConfig.slice(0, 2).map(metric => (
          <MetricDisplay key={metric.id} {...metric} />
        ))}
      </div>
      <Divider type="vertical" />
      <div className={s.bbPostTooltipFooterSection}>
        {metricsConfig.slice(2).map(metric => (
          <MetricDisplay key={metric.id} {...metric} />
        ))}
      </div>
    </div>
  );
};

import React from "react";
import cx from "classnames/bind";

import s from "./Notifications.module.less";

import { INotificationIconProps } from "./Notifications.types";
import { MAX_UNREAD_COUNT } from "./Notifications.helpers";

import { NotificationsIcon, NotificationsRingIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const NotificationTrigger: React.FC<INotificationIconProps> = ({ count, isPanelOpen }) => (
  <>
    <div
      className={cx(s.bbNotificationsTrigger, {
        [s.bbNotificationsTriggerActive]: isPanelOpen,
      })}
    >
      <div className={s.bbNotificationsTriggerIcon}>
        {count > 0 ? (
          <div className={s.bbNotificationsTriggerUnread}>
            {count > MAX_UNREAD_COUNT ? `${MAX_UNREAD_COUNT}+` : count}
          </div>
        ) : null}
        {count ? <NotificationsRingIcon /> : <NotificationsIcon />}
      </div>
    </div>
  </>
);

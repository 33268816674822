import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Empty } from "antd";
import { LinearProgressBar } from "../../../_common/linearProgressBar";
import { Card, Loading, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./EngagementCard.module.less";

import { IPerformanceUnit } from "../../../_common/performanceCard/types";

import { ProcessorIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IEngagementTrendsProgressProps {
  type: string;
  isLoading: boolean;
  data: IPerformanceUnit;
  onClick: () => void;
  icon: React.ReactNode;
}

const EngagementTrendsProgress: FC<IEngagementTrendsProgressProps> = ({
  type,
  icon,
  data,
  onClick,
  isLoading,
}) => {
  const { t } = useTranslation();

  const title = useMemo(() => {
    return t(`components:engagementsTrends:${type}:title`);
  }, [type, t]);

  return (
    <div className={s.bbEngagementTrendsProgressWrapper}>
      <Card data-stonly-target={`engagement-overview__top-boxes--${type}`}>
        {isLoading ? (
          <div className={s.bbEngagementCardLoading}>
            <Loading isLoading />
          </div>
        ) : data ? (
          <div className={s.bbEngagementProgressCard}>
            <div
              data-cy="engagement-progress-card-header"
              className={s.bbEngagementProgressCardHeader}
            >
              <span
                className={s.bbEngagementProgressCardTitle}
                data-cy="engagement-progress-card-title"
              >
                {title}
              </span>
              <span className={s.bbEngagementCardAIIcon}>
                <ProcessorIcon /> AI
              </span>
            </div>
            <div
              onClick={onClick}
              data-cy="engagement-progress-card-body"
              className={s.bbEngagementProgressCardBody}
            >
              <span className={s.bbEngagementProgressCardBodyLeftIcon}>{icon}</span>
              <span className={s.bbEngagementProgressCardBodyText}>
                <p>
                  <strong>{data.count}</strong>
                  {t(`components:engagementsTrends:${type}:context`)}
                </p>
              </span>
              <span className={s.bbEngagementProgressCardBodyRightIcon}>
                <ChevronRightIcon />
              </span>
            </div>

            <div
              className={s.bbEngagementProgressCardFooter}
              data-cy="engagement-progress-card-footer"
            >
              <LinearProgressBar percentage={data?.percentage_of_total} />
              {data?.percentage_of_total ? (
                <div className={s.bbEngagementProgressCardFooterPercentage}>
                  {data?.percentage_of_total}
                  {t("components:engagementsTrends:progressBar")}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("components:empty:description")}
            imageStyle={{
              height: 40,
            }}
          />
        )}
      </Card>
    </div>
  );
};

export default EngagementTrendsProgress;

import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { Tooltip, ChipIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./YTNotAvailableChip.module.less";

import { AlertTriangleIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IYTNotAvailableChipProps {
  className?: string;
}

const YTNotAvailableChip: FC<IYTNotAvailableChipProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t("components:analysisTrends:ytNotAvailableTooltip")}
      overlayInnerStyle={{
        width: 300,
      }}
    >
      <ChipIcon
        _size="xs"
        _type="default"
        iconPosition="left"
        icon={AlertTriangleIcon}
        iconClassName={s.bbYTNotAvailableChipIcon}
        text={t("components:analysisTrends:ytNotAvailable")}
        className={classNames(s.bbYTNotAvailableChip, className)}
      />
    </Tooltip>
  );
};

export default YTNotAvailableChip;

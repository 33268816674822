import { ISideNavItemNested } from "./types";
import { ISideNavItem } from "@bbdevcrew/bb_ui_kit_fe";

export function mapNestedOptionsToChildren(input: ISideNavItemNested[]): ISideNavItem[] {
  const result = input.reduce((acc: ISideNavItem[], item) => {
    if (item.nested) {
      const lastNonNestedItem = acc[acc.length - 1];
      if (!lastNonNestedItem.children) {
        lastNonNestedItem.children = [];
      }
      lastNonNestedItem.children.push(item);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  return result;
}

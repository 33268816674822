import React from "react";
import classNames from "classnames";

import s from "../CommentCard/commentActions/CommentActions.module.less";

import { TFunction } from "i18next";
import { PostMediaType, IObjectOption, MediaTypeIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const getSentimentOptions = (t: TFunction): IObjectOption[] => [
  {
    id: "positive",
    value: "positive",
    label: t("components:comments:actions:sentiments:positive"),
    iconPosition: "left",
    icon: (
      <span className={classNames(s.bbActionCircleClass, s[`bbActionCircleClass-positive`])}>
        {" "}
      </span>
    ),
  },
  {
    id: "negative",
    value: "negative",
    label: t("components:comments:actions:sentiments:negative"),
    iconPosition: "left",
    icon: (
      <span className={classNames(s.bbActionCircleClass, s[`bbActionCircleClass-negative`])}>
        {" "}
      </span>
    ),
  },
  {
    id: "neutral",
    value: "neutral",
    label: t("components:comments:actions:sentiments:neutral"),
    iconPosition: "left",
    icon: (
      <span className={classNames(s.bbActionCircleClass, s[`bbActionCircleClass-neutral`])}> </span>
    ),
  },
];

export const getShortcutTooltipTitle = (t: TFunction): React.ReactNode => {
  return (
    <>
      <div className={s.bbShortcutsTooltip}>
        {`${t("components:comments:details:shortcutTooltip:shortcut:previous")}${""} 
      ${t("components:comments:details:shortcutTooltip:shortcut:next")}${""} 
      ${t("components:comments:details:shortcutTooltip:shortcut:hide")}${""} 
      ${t("components:comments:details:shortcutTooltip:shortcut:star")}${""} 
      ${t("components:comments:details:shortcutTooltip:shortcut:archive")}${""} 
      `}
      </div>

      {t("components:comments:details:shortcutTooltip:shortcut:windows:description")}
      <ul>
        <li className={s.bbShortcutsTooltipItem}>
          {t("components:comments:details:shortcutTooltip:shortcut:windows:altKey")}
        </li>

        <li className={s.bbShortcutsTooltipItem}>
          {t("components:comments:details:shortcutTooltip:shortcut:windows:controlKey")}
        </li>
      </ul>
    </>
  );
};

export const getMediaTypeIcon = (post_media_id: PostMediaType) => {
  const Icon = MediaTypeIcon[post_media_id as keyof typeof MediaTypeIcon];

  if (!Icon) return null;
  return <Icon />;
};

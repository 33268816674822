import { IComment } from "@bbdevcrew/bb_ui_kit_fe";
import { REPLY_MAX_LENGTH } from "@components/_common/CommentCard/brandReply/helpers";

const REPLY_MSG = 80;
const MSG_XS = 71;
const MSG_SM = 230;
const MSG_MD = 400;

export const getCommentGridSize = (comment: IComment) => {
  const message = comment.message || "";
  let gapSize = 8;

  if (message.length <= MSG_XS) {
    gapSize = 8;
  } else if (message.length > MSG_XS && message.length <= MSG_SM) {
    gapSize = 9;
  } else if (message.length > MSG_SM && message.length <= MSG_MD) {
    gapSize = 10;
  } else if (message.length > MSG_MD) {
    gapSize = 14;
  }

  if (!!comment?.attachments?.length) gapSize += 2;

  if (comment?.reply?.message?.length) {
    comment?.reply?.message?.length < REPLY_MSG ? (gapSize += 4) : (gapSize += 5);

    const lineSpaces =
      comment?.reply?.message?.slice(0, REPLY_MAX_LENGTH).match(/\n/g)?.length || 0;
    gapSize += lineSpaces;
  }

  return `bbCommentsGridItemGap${gapSize}`;
};

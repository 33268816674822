import React from "react";
import { t } from "i18next";

import { XAxisProps } from "recharts";
import { numberToQuantity } from "@bbdevcrew/bb_ui_kit_fe";
import { IBreakdownChartDataItem, CommentStatsUnionType } from "./BreakdownChart.type";

import chartColors from "./colors.module.less";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

export const LABEL_WIDTH = 70;
export const chartAxisColor = "#dedfe7";

export const breakdownChartMargins = {
  top: 5,
  left: 40,
  right: 40,
  bottom: 5,
};

export const xAxisProps = {
  type: "number",
  orientation: "top",
  tickLine: false,
  axisLine: { stroke: "#dedfe7" },
  tickFormatter: numberToQuantity,
} as XAxisProps;

export const totalProps = {
  color: palette.colorBlue900,
  name: t("components:postTrends:charts:total"),
};

export const normalizeData = (data: IBreakdownChartDataItem[]) => {
  let normalizedData = [];

  normalizedData = data.map(item => {
    const obj = {
      id: item.id,
      label: item.label,
    };

    if (Array.isArray(item.comment_stats)) {
      item.comment_stats.forEach(stat => {
        obj[stat.label as keyof typeof obj] = stat.count.toString();
      });
    } else {
      Object.entries(item.comment_stats).forEach(([key, value]) => {
        obj[key as keyof typeof obj] = value.toString();
      });
    }

    return obj;
  });

  return normalizedData;
};

export const getBreakdownChartBarColor = (id: string, index?: number) => {
  return chartColors[id] || chartColors["color" + index];
};

export const generateBars = (comment_stats: CommentStatsUnionType) => {
  if (Array.isArray(comment_stats)) {
    return comment_stats.map((stat, index) => {
      return {
        stackId: "stack",
        dataKey: stat.label,
        fill: getBreakdownChartBarColor(stat.id, index),
        shape:
          stat.id === "not_applicable" ? (
            <rect mask="url(#mask-stripe)" x={10} y={10} width={10} height={10} />
          ) : null,
      };
    });
  } else {
    return Object.keys(comment_stats).map((key, index) => {
      return {
        stackId: "stack",
        dataKey: key,
        fill: getBreakdownChartBarColor(key, index),
      };
    });
  }
};

export const stripePattern = (
  <>
    <pattern
      id="pattern-stripe"
      width="8"
      height="8"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45)"
    >
      <rect width="4" height="8" transform="translate(0,0)" fill="white"></rect>
    </pattern>
    <mask id="mask-stripe">
      <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
    </mask>
  </>
);

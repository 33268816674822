import { action } from "typesafe-actions";
import { UserSignupPayloadType } from "./types";
import { IUserSignupAPIResponse } from "../tiktokApp/types";
import { SIGN_USER_UP, SIGN_USER_UP_SUCCESS, SIGN_USER_UP_FAILURE } from "./actionTypes";

export const signUserUpAction = (data: UserSignupPayloadType) =>
  action(SIGN_USER_UP, data, { public: true });
export const signUserUpSuccessAction = (data: IUserSignupAPIResponse) =>
  action(SIGN_USER_UP_SUCCESS, data, { public: true });
export const signUserUpFailureAction = () => action(SIGN_USER_UP_FAILURE, { public: true });

import { switchMap, map, filter, catchError, mergeMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";

import * as actions from "./actions";
import { RootState } from "..";
import { CompanySettingsActions, ICompanySettings } from "./types";

import { companySettings, featureVisibilityFlags, integrationFlags } from "@utils/paths";

import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";

const getHeadersFunc = (state$: StateObservable<RootState>) =>
  getHeaders({
    Authorization: state$.value.auth.session.accessToken.jwtToken,
  });

export const getCompanySettings = (
  action$: Observable<CompanySettingsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getCompanySettingsAction)),
    switchMap(() =>
      ajax.get<ICompanySettings>(companySettings, getHeadersFunc(state$)).pipe(
        map(({ response }) => actions.getCompanySettingsSuccessAction(response)),
        catchError(e => handleError(e, actions.getCompanySettingsFailureAction)),
      ),
    ),
  );

export const updateFeatureVisibilityFlags = (
  action$: Observable<CompanySettingsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateFeatureVisibilityFlagsAction)),
    mergeMap(({ payload: items }) =>
      ajax.post(featureVisibilityFlags, { items }, getHeadersFunc(state$)).pipe(
        map(() => actions.updateFeatureVisibilityFlagsSuccessAction()),
        catchError(e => handleError(e, actions.updateFeatureVisibilityFlagsFailureAction)),
      ),
    ),
  );

export const updateIntegrationsFlags = (
  action$: Observable<CompanySettingsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateIntegrationsFlagsAction)),
    mergeMap(({ payload }) => {
      const items = payload.map(({ id, checked }) => ({ field: id, value: checked }));

      return ajax.put(integrationFlags, { items }, getHeadersFunc(state$)).pipe(
        map(() => actions.updateIntegrationsFlagsSuccessAction()),
        catchError(e => handleError(e, actions.updateIntegrationsFlagsFailureAction)),
      );
    }),
  );

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ItemCard, ScrollView, Tooltip, ModalHeaderActions } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SavedFiltersList.module.less";

import { useSavedFiltersList } from "@utils/useSavedFiltersList";
import { ISavedFiltersListProps } from "./SavedFiltersList.types";

export const SavedFiltersList: React.FC<ISavedFiltersListProps> = ({
  onAddClick,
  onEditClick,
  onDeleteClick,
  onSelectClick,
}) => {
  const { t } = useTranslation();
  const savedFiltersList = useSavedFiltersList();
  const [query, setQuery] = useState<string>("");

  const filteredFilters = useMemo(() => {
    return savedFiltersList.filter(({ name }) =>
      name.trim().toLowerCase().includes(query.trim().toLowerCase()),
    );
  }, [savedFiltersList, query]);

  return (
    <div className={s.bbSavedFiltersList}>
      <ModalHeaderActions
        title={t("pages:dashboard:modals:savesSearch:filterSelector:title")}
        onAddClick={onAddClick}
        onSearch={value => setQuery(value)}
        placeholder={t("generic:search")}
        className={s.bbSavedFiltersListHeader}
      />
      <ScrollView className={s.bbSavedFiltersListScrollView}>
        <>
          <ItemCard
            name={t("pages:dashboard:modals:savesSearch:filterSelector:allConversations")}
            color="all"
          />
          <hr className={s.bbSavedFiltersListRule} />
          {filteredFilters.map(filterItem => {
            const { id, name, color } = filterItem;

            return (
              <Tooltip
                key={id}
                title={t("pages:dashboard:modals:savesSearch:filterSelector:applyTooltip")}
              >
                <div>
                  <ItemCard
                    name={name}
                    color={color}
                    onItemClick={() => onSelectClick(id.toString())}
                    actions={[
                      {
                        key: "edit",
                        label: "generic:edit",
                        onActionClick: () => onEditClick(filterItem),
                      },
                      {
                        key: "delete",
                        label: "generic:delete",
                        onActionClick: () => onDeleteClick(filterItem),
                      },
                    ]}
                  />
                </div>
              </Tooltip>
            );
          })}
        </>
      </ScrollView>
    </div>
  );
};

export default SavedFiltersList;

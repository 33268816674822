import React from "react";

import { t } from "../../../../App";
import { IActionDropdownItem, useToggle } from "@bbdevcrew/bb_ui_kit_fe";

import { DeleteIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const useBrandReplyDropdownItems = () => {
  const { isToggleSelected, toggleSelected } = useToggle(false);

  const brandReplyDropdownItems: IActionDropdownItem[] = [
    {
      id: "deleteBrandReply",
      icon: <DeleteIcon />,
      label: t("generic:delete"),
      onClick: toggleSelected,
    },
  ];

  return {
    brandReplyDropdownItems,
    showDeleteReplyAlert: isToggleSelected,
    toggleDeleteReplyAlert: toggleSelected,
  };
};

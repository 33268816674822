import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "../";
import { AssetsActions, IReconnectAssetsResponse } from "./types";
import {
  GET_ASSETS,
  DELETE_ASSET,
  PATCH_RECONNECT_ASSETS,
  CONFIGURE_ASSET_MONITORING,
} from "./actionTypes";

import { assets, assetsOnboarding, requestRemoval } from "@utils/paths";

import {
  deleteAssetActionFailure,
  deleteAssetActionSuccess,
  getAssetsActionFailure,
  getAssetsActionSuccess,
  patchReconnectAssetsActionFailure,
  patchReconnectAssetsActionSuccess,
  configureAssetMonitoringActionSuccess,
  configureAssetMonitoringActionFailure,
} from "./actions";
import { IAsset } from "@components/_common/AssetTabs/AssetTabs.type";
import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";

const getRegisterUri = (platformId: string) => {
  if (platformId === "linkedin" || platformId === "trustpilot")
    return window.location.origin + window.location.pathname + `?social_platform=${platformId}`;

  return window.location.origin;
};

export const getAssetsEpic = (
  action$: Observable<AssetsActions>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(GET_ASSETS)),
    switchMap(() => {
      return ajax
        .get<IAsset[]>(
          assets,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getAssetsActionSuccess(data)),
          catchError(err => handleError(err, getAssetsActionFailure)),
        );
    }),
  );
};

// CONFIGURE ASSET MONITORING
export const configureAssetMonitoringEpic = (
  action$: Observable<AssetsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(CONFIGURE_ASSET_MONITORING)),
    switchMap(a => {
      return ajax
        .post(
          `${assets}/onboarding/monitoring`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(() => configureAssetMonitoringActionSuccess(a.payload)),
          catchError(err => handleError(err, configureAssetMonitoringActionFailure)),
        );
    }),
  );

// REQUEST REMOVAL
export const requestRemovalEpic = (
  action$: Observable<AssetsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_ASSET)),
    switchMap(a => {
      return ajax
        .post(
          requestRemoval(a.payload),
          null,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteAssetActionSuccess(a.payload)),
          catchError(err => handleError(err, deleteAssetActionFailure)),
        );
    }),
  );

export const reconnectAssets = (
  action$: Observable<AssetsActions>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(PATCH_RECONNECT_ASSETS)),
    switchMap(a => {
      return ajax
        .patch<IReconnectAssetsResponse>(
          `${assetsOnboarding}/${a.payload.social_platform}/tokens`,
          {
            app_id: a.payload.app_id,
            access_token: a.payload.access_token,
            authorization_code: a.payload.authorization_code,
            redirect_uri: getRegisterUri(a.payload.social_platform),
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => patchReconnectAssetsActionSuccess(data)),
          catchError(e => handleError(e, patchReconnectAssetsActionFailure)),
        );
    }),
  );
};

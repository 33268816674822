import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip, Chip, ChipIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./PermissionList.module.less";

import { IPermission } from "./AssetTabs.type";
import { PlatformType, isFacebookPlatform } from "@bbdevcrew/bb_ui_kit_fe";

import { CheckIcon, CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IPermissionListProps {
  platform: PlatformType;
  permissions: IPermission[];
  isTokenMissing: boolean;
}

export const PermissionList: FC<IPermissionListProps> = ({
  platform,
  permissions,
  isTokenMissing,
}) => {
  const { t } = useTranslation();

  const getPermissionTooltip = (granted: boolean, description: string) => {
    if (isFacebookPlatform(platform) && !granted) {
      return (
        <div className={s.bbPermissionTooltip}>
          {description}
          <div className={s.bbTooltipDivider}></div>
          <div>{t("pages:addAssets:missingPermissionTooltip")}</div>
        </div>
      );
    }

    return description;
  };

  return (
    <div className={s.bbPermissionList}>
      <h2 className={s.bbPermissionListTitle}>{t("components:permissionList:title")}:</h2>
      <div className={s.bbPermissionListItems}>
        {permissions.map(({ id, label, description, granted }) => (
          <span key={id}>
            <Tooltip title={getPermissionTooltip(granted, description)} style={{ fontSize: 12 }}>
              {!isTokenMissing ? (
                <span className={s.bbPermissionListItem}>
                  {granted ? (
                    <span className={s.bbPermissionListItemSuccess}>
                      <ChipIcon
                        _size="xs"
                        _type="white"
                        iconPosition="left"
                        text={label}
                        icon={CheckIcon}
                      />
                    </span>
                  ) : (
                    <span className={s.bbPermissionListItemWarning}>
                      <ChipIcon
                        _size="xs"
                        _type="white"
                        iconPosition="left"
                        text={label}
                        icon={CloseIcon}
                      />
                    </span>
                  )}
                </span>
              ) : (
                <span className={s.bbPermissionListItem}>
                  <Chip _size="sm" _type="white" text={label} />
                </span>
              )}
            </Tooltip>
          </span>
        ))}
      </div>
    </div>
  );
};

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PostsTableRow from "./PostsTableRow";
import PostsTablePrefix from "./PostsTablePrefix";
import { Table, SortType, ITableColumn, debounce } from "@bbdevcrew/bb_ui_kit_fe";
import { Grid } from "antd";

import {
  getPostsSelector,
  getPaginationSelector,
  getPostsPendingSelector,
  getPostsAveragesSelector,
  getPostsTotalsSelector,
} from "@store/posts/selectors";
import { getPostsAction } from "@store/posts/actions";

import s from "./PostsTable.module.less";

import { IPost } from "@bbdevcrew/bb_ui_kit_fe";
import { IPostsTableProps, IPostsTableRef } from "./PostsTable.types";
import { getPostsPageAllowedFilters, getPostsTableColumns, PAGE_SIZE_OPTIONS } from "./helpers";
import { IPostSort, IPostsPayload, PostColumnSortType } from "@store/posts/types";

const defaultSorting: IPostSort = { field: "created_time", order: "desc" };

const PostsTable = forwardRef<IPostsTableRef, IPostsTableProps>(({ filters }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();

  const columns = useMemo(() => getPostsTableColumns(), []);
  const showSizeChanger = !!screens.lg;

  // eslint-disable-next-line
  const getPosts = useCallback(
    debounce((data: IPostsPayload) => {
      dispatch(getPostsAction(data));
    }, 500),
    [dispatch],
  );

  const [sort, setSort] = useState<IPostSort>(defaultSorting);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>();

  const posts = useSelector(getPostsSelector);
  const pagination = useSelector(getPaginationSelector);
  const postsPending = useSelector(getPostsPendingSelector);
  const postsTotals = useSelector(getPostsTotalsSelector);
  const postsAverages = useSelector(getPostsAveragesSelector);

  useImperativeHandle(ref, () => ({
    getSortValue: () => sort,
  }));

  useEffect(() => {
    if (!postsPending) {
      const filtersData = getPostsPageAllowedFilters(filters);

      getPosts({
        filters: filtersData,
        sort,
        pagination: { page, size },
        include_totals: true,
      });
    }
    // eslint-disable-next-line
  }, [sort, page, size, filters]);

  const onSort = (column: ITableColumn, _sort?: SortType) => {
    if (!_sort) {
      setSort(defaultSorting);
    } else {
      setSort({
        order: _sort,
        field: column.id_name as PostColumnSortType,
      });
    }
  };

  const onChangePage = (_page: number, pageSize?: number) => {
    setPage(_page);
    setSize(pageSize);
  };

  return (
    <div className={s.bbPostsWrapper} data-cy="posts-table">
      <Table<IPost>
        data={posts}
        onSort={onSort}
        tableRow={PostsTableRow}
        columns={columns}
        loading={postsPending}
        pagination={{
          total: pagination?.total || 0,
          pageSize: pagination?.size,
          currentPage: pagination?.page,
          onChangePage,
          showSizeChanger,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          pageSizeTitle: t("components:sortableTable:pageSizeTitle"),
        }}
        rowsPrefix={
          <>
            <PostsTablePrefix data={postsTotals} type="totals" />
            <PostsTablePrefix data={postsAverages} type="averages" />
          </>
        }
        withStickyHeader
        emptyTableText={t("generic:emptyTable")}
        pageOutOfText={[
          t("components:sortableTable:pageOutOfPart1"),
          t("components:sortableTable:pageOutOfPart2"),
        ]}
        tableClassName={s.bbPostsTable}
        containerClassName={s.bbPostsTableContainer}
        stonlySelector="intelligence-posts_table"
      />
    </div>
  );
});

PostsTable.displayName = "PostsTable";

export default PostsTable;

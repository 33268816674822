import { PostMentionType } from "@bbdevcrew/bb_ui_kit_fe";

export const isEarnedMessage = (postType: PostMentionType) => {
  return (
    postType === "CAPTION_MENTION" ||
    postType === "COMMENT_MENTION" ||
    postType === "HASHTAG_MENTION" ||
    postType === "QUOTE_MENTION" ||
    postType === "MENTION" ||
    postType === "REVIEW"
  );
};

export const isMentionMessage = (postType: PostMentionType) => {
  return (
    postType === "CAPTION_MENTION" ||
    postType === "COMMENT_MENTION" ||
    postType === "HASHTAG_MENTION" ||
    postType === "QUOTE_MENTION" ||
    postType === "MENTION"
  );
};

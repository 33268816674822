import React, { FC } from "react";

import { Grid } from "antd";

import s from "./Compare.module.less";

import { cropText } from "@bbdevcrew/bb_ui_kit_fe";
import { ICompareChartLabelProps } from "./Compare.type";

const CompareChartLabel: FC<ICompareChartLabelProps> = ({ data, width, tickProps }) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const yAxisMargin = tickProps.y - 12;
  const maxTextLength = screens.lg ? 30 : 20;

  return (
    <foreignObject
      x={5}
      y={yAxisMargin}
      cursor="pointer"
      style={{ width: width }}
      className={s.bbCompareChartLabel}
    >
      <div className={s.bbCompareChartLabelContainer}>
        <div className={s.bbCompareChartLabelContent}>
          <g transform={`translate(${tickProps.x},${yAxisMargin})`}>
            <div className={s.bbLabel}>{cropText(data?.label || "", maxTextLength)}</div>
            <div className={s.bbDetails}>{data?.id}</div>
          </g>
        </div>
      </div>
    </foreignObject>
  );
};

export default CompareChartLabel;

export const GET_ME = "ME/GET_ME";
export const GET_ME_SUCCESS = "ME/GET_ME_SUCCESS";
export const GET_ME_FAILURE = "ME/GET_ME_FAILURE";

// PATCH ME
export const PATCH_ME = "ME/PATCH_ME";
export const PATCH_ME_SUCCESS = "ME/PATCH_ME_SUCCESS";
export const PATCH_ME_FAILURE = "ME/PATCH_ME_FAILURE";

// PATCH ME CLIENT
export const PATCH_ME_CLIENT = "ME/PATCH_ME_CLIENT";
export const PATCH_ME_CLIENT_SUCCESS = "ME/PATCH_ME_CLIENT_SUCCESS";
export const PATCH_ME_CLIENT_FAILURE = "ME/PATCH_ME_CLIENT_FAILURE";

// PATCH ME SESSION CLIENT
export const PATCH_ME_SESSION_CLIENT = "ME/PATCH_ME_SESSION_CLIENT";
export const PATCH_ME_SESSION_CLIENT_SUCCESS = "ME/PATCH_ME_SESSION_CLIENT_SUCCESS";
export const PATCH_ME_SESSION_CLIENT_FAILURE = "ME/PATCH_ME_SESSION_CLIENT_FAILURE";

// POST ME EMAIL
export const POST_ME_EMAIL = "ME/POST_ME_EMAIL";
export const POST_ME_EMAIL_SUCCESS = "ME/POST_ME_EMAIL_SUCCESS";
export const POST_ME_EMAIL_FAILURE = "ME/POST_ME_EMAIL_FAILURE";

// PATCH ME EMAIL
export const PATCH_ME_EMAIL = "ME/PATCH_ME_EMAIL";
export const PATCH_ME_EMAIL_SUCCESS = "ME/PATCH_ME_EMAIL_SUCCESS";
export const PATCH_ME_EMAIL_FAILURE = "ME/PATCH_ME_EMAIL_FAILURE";

// CANCEL ME EMAIL
export const CANCEL_ME_EMAIL = "ME/CANCEL_ME_EMAIL";

// CANCEL ME PASSWORD
export const POST_ME_PASSWORD = "ME/POST_ME_PASSWORD";
export const POST_ME_PASSWORD_SUCCESS = "ME/POST_ME_PASSWORD_SUCCESS";
export const POST_ME_PASSWORD_FAILURE = "ME/POST_ME_PASSWORD_FAILURE";

// DELETE ME AVATAR
export const DELETE_ME_AVATAR = "ME/DELETE_ME_AVATAR";
export const DELETE_ME_AVATAR_SUCCESS = "ME/DELETE_ME_AVATAR_SUCCESS";
export const DELETE_ME_AVATAR_FAILURE = "ME/DELETE_ME_AVATAR_FAILURE";

// PATCH DEMO MODE
export const PATCH_DEMO_MODE = "ME/PATCH_DEMO_MODE";

// PATCH DEMO STORY
export const PATCH_DEMO_STORY = "ME/PATCH_DEMO_STORY";

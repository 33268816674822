import React from "react";
import classNames from "classnames";

import s from "./Assets.module.less";

import {
  platformIconName,
  isTwitterPlatform,
  isYoutubePlatform,
  getPlatformIconBasicColor,
  PlatformTypeIconBasicColor,
} from "@bbdevcrew/bb_ui_kit_fe";
import { IAutocompleteOption } from "@store/autocomplete/types";

import { TwitterIcon } from "@bbdevcrew/bb_ui_kit_fe";

const getPlatformIconBasicColorLight = (platformTypeId: string) => {
  const Icon = isTwitterPlatform(platformTypeId)
    ? TwitterIcon
    : PlatformTypeIconBasicColor[platformTypeId as keyof typeof PlatformTypeIconBasicColor];

  return Icon ? <Icon /> : null;
};

const getTransformedAssetOptions = (
  assets: IAutocompleteOption[],
  getPlatformIcon: (platformTypeId: string) => JSX.Element | null,
  additionalLabelFields?: string[],
  selectedValue?: string,
) =>
  assets.map(item => ({
    leftLabel: item.platform ? (
      <span className={s.bbAssetWrapper}>
        <span
          className={classNames(s.bbAsset, {
            [s.bbAssetYoutube]: isYoutubePlatform(item.platform),
            [s.bbAssetSelected]: selectedValue === item.id,
          })}
        >
          {getPlatformIcon(platformIconName(item.platform))}
        </span>
      </span>
    ) : null,
    rightLabel: (
      <span
        className={classNames(s.bbAssetRightLabel, {
          [s.bbAssetRightLabelSelected]: selectedValue === item.id,
        })}
      >
        {item.type}
      </span>
    ),
    additionalLabel: additionalLabelFields?.map(field => {
      const value = field as keyof typeof item;

      if (value) return item[value] as string;
      else return "";
    }),
    ...item,
  }));

export const getTransformedAssetOptionsSingle = (
  assets: IAutocompleteOption[],
  additionalLabelFields?: string[],
  selectedValue?: string,
) =>
  getTransformedAssetOptions(
    assets,
    getPlatformIconBasicColorLight,
    additionalLabelFields,
    selectedValue,
  );

export const getTransformedAssetOptionsMulti = (
  assets: IAutocompleteOption[],
  additionalLabelFields?: string[],
) => getTransformedAssetOptions(assets, getPlatformIconBasicColor, additionalLabelFields);

import React, { FC } from "react";
import classNames from "classnames";

import { Icon, Tooltip, UserAvatar } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ReplyPhrase.module.less";

import { formatWorkflow } from "./ReplyPhrase.helpers";
import { IReplyWorkflowInfo, IPlatformUser } from "@bbdevcrew/bb_ui_kit_fe";

import { FlowIcon, BbLogoColorIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IReplyPhraseProps {
  phrase: string;
  tooltip?: string;
  platform_user?: IPlatformUser;
  workflow?: IReplyWorkflowInfo;
}

const ReplyPhrase: FC<IReplyPhraseProps> = ({ phrase, tooltip, platform_user, workflow }) => (
  <p data-testid="message" className={s.bbReplyPhrase}>
    <Tooltip title={tooltip} placement="top">
      <span
        className={classNames(s.bbReplyPhraseIconWrapper, {
          [s.bbReplyPhraseIconWrapperWithTooltip]: !!tooltip,
        })}
      >
        {workflow ? (
          <span className={s.bbWorkflowIcon}>
            <FlowIcon />
          </span>
        ) : platform_user ? (
          <UserAvatar
            className={s.bbUserAvatar}
            img={platform_user?.avatar}
            lastName={platform_user?.last_name}
            firstName={platform_user?.first_name}
          />
        ) : (
          <Icon _size="lg" icon={BbLogoColorIcon} className={s.bbReplyPhraseIcon} />
        )}
        {workflow ? formatWorkflow(phrase, workflow?.category || "reply") : phrase}
      </span>
    </Tooltip>
  </p>
);

export default ReplyPhrase;

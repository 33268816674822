import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { GoogleBusinessStars } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./GoogleBusinessMessage.module.less";

import { IReviewMessageProps } from "./types";
import { formatDateEnUs } from "@bbdevcrew/bb_ui_kit_fe";

export const GoogleBusinessMessage: FC<IReviewMessageProps> = ({ stars, title, date, message }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbGbMessageCard}>
      {<GoogleBusinessStars stars={stars} />}
      {!!title && <p className={s.bbMessageTitle}>{title}</p>}
      <p data-testid="message" className={s.bbGbMessageCardText}>
        {message}
      </p>
      {!!date && (
        <div className={s.bbCommentsDate}>
          <span>{t("components:comments:cardBody:date")}:</span>
          <span>{formatDateEnUs(date, "dd MMM, yyyy")}</span>
        </div>
      )}
    </div>
  );
};

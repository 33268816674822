import cn from "classnames/bind";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useCallback, useEffect, ChangeEvent } from "react";

import Carousel from "react-multi-carousel";
import { Row, Col, Empty, Grid } from "antd";
import Ads from "../../../_common/Social/Ads/Ads";
import PostModal from "../../../_common/Social/Post/PostModal";
import MostCommentedPost from "./mostCommentedPost/MostCommentedPost";
import { Modal, Loading, Input, Card } from "@bbdevcrew/bb_ui_kit_fe";

import {
  getTrendingPostsSelector,
  getTrendingPostsSelectorPending,
  getTrendingPostsSelectorSuccessful,
} from "@store/overview/selectors";
import {
  postAdsSelector,
  postAdsClearedSelector,
  getPostAdsFailedSelector,
  getPostAdsSuccessfulSelector,
} from "@store/posts/selectors";
import {
  addPostTagResultSelector,
  addPostTagSuccessfulSelector,
  addingPostTagLabelSelector,
  addingPostTagSelector,
  deleteTagFromPostSuccessfulSelector,
  deletedTagIdSelector,
} from "@store/postTags/selectors";

import { getTrendingPostsAction } from "@store/overview/actions";
import { getPredefinedFiltersSuccessfulSelector } from "@store/savedFilters/selectors";
import { getPostAdsAction, clearPostAction, clearPostAdsAction } from "@store/posts/actions";

import s from "./MostCommentedPosts.module.less";
import carouselStyles from "./Carousel.module.less";

import { IFilters } from "@store/filters/types";
import { IPostTag } from "@bbdevcrew/bb_ui_kit_fe";
import { IAd } from "../../../_common/Social/Ads/Ads.type";
import { getSentimentBars, generateStatsData } from "../helpers";
import { responsive, IMostCommentedPostsProps } from "./helpers";
import { ITrendingPostProps, ITrendingPlatformPostsProps } from "@store/overview/types";

const cx = cn.bind(s);

const MostCommentedPosts: React.FC<IMostCommentedPostsProps> = ({
  filters,
  containerWidth,
  filterToSpecificItem,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();

  const [adsLoading, setAdsLoading] = useState(false);
  const [isOpenedAds, setOpenAds] = useState(false);
  const [postAds, setPostAds] = useState<IAd[]>();
  const [searchValue, setSearchValue] = useState("");
  const [searchedAds, setSearchedAds] = useState<IAd[]>([]);
  const [activePlatformIndex, setActivePlatformIndex] = useState(0);
  const [list, setList] = useState<ITrendingPlatformPostsProps[]>([]);
  const [postSelected, setPostSelected] = useState<ITrendingPostProps>();

  const trendingPostsList = useSelector(getTrendingPostsSelector);
  const trendingPostsLoading = useSelector(getTrendingPostsSelectorPending);
  const trendingPostsListFetched = useSelector(getTrendingPostsSelectorSuccessful);
  const adsData = useSelector(postAdsSelector);
  const adsCleared = useSelector(postAdsClearedSelector);
  const adsFetchFailed = useSelector(getPostAdsFailedSelector);
  const adsFetched = useSelector(getPostAdsSuccessfulSelector);
  const tagCreationResult = useSelector(addPostTagResultSelector);
  const addingPostTag = useSelector(addingPostTagSelector);
  const tagAddedSuccessfully = useSelector(addPostTagSuccessfulSelector);
  const tagDeletedSuccessfully = useSelector(deleteTagFromPostSuccessfulSelector);
  const deletedTagId = useSelector(deletedTagIdSelector);
  const addingPostTagLabel = useSelector(addingPostTagLabelSelector);
  const predefinedFiltersFetched = useSelector(getPredefinedFiltersSuccessfulSelector);

  const generateTrendingPostsList = useCallback(
    (filterData: IFilters) => dispatch(getTrendingPostsAction(filterData)),
    [dispatch],
  );
  const getAds = useCallback((id: string) => dispatch(getPostAdsAction(id)), [dispatch]);
  const clearPost = useCallback(() => dispatch(clearPostAction()), [dispatch]);
  const clearAds = useCallback(() => dispatch(clearPostAdsAction()), [dispatch]);

  useEffect(() => {
    if (!trendingPostsLoading && predefinedFiltersFetched) generateTrendingPostsList(filters);
    // eslint-disable-next-line
  }, [filters, predefinedFiltersFetched]);

  useEffect(() => {
    if (adsFetched && adsLoading) {
      setPostAds(adsData);
      setOpenAds(true);
      setAdsLoading(false);
    }
    // eslint-disable-next-line
  }, [adsFetched]);

  useEffect(() => {
    if (adsCleared) {
      setPostAds(undefined);
      setAdsLoading(false);
    }
  }, [adsCleared, adsFetchFailed]);

  useEffect(() => {
    if (trendingPostsListFetched) {
      setList(trendingPostsList);

      const activeIndex = trendingPostsList.findIndex(({ is_active }) => is_active);
      setActivePlatformIndex(activeIndex);
    }
  }, [trendingPostsList, trendingPostsListFetched]);

  useEffect(() => {
    if (addingPostTag && !!addingPostTagLabel && postSelected?.post) {
      const updatedPostTags = [
        ...(postSelected?.post.post_tags || []),
        { label: addingPostTagLabel } as IPostTag,
      ];

      setPostSelected({
        ...postSelected,
        post: {
          ...postSelected.post,
          post_tags: updatedPostTags,
        },
      });
    }
    // eslint-disable-next-line
  }, [addingPostTag, addingPostTagLabel]);

  useEffect(() => {
    if (tagAddedSuccessfully && !!tagCreationResult && postSelected?.post.post_tags?.length) {
      const updatedTagIndex = postSelected.post.post_tags.findIndex(
        tag => tag.label === tagCreationResult.label,
      );
      const updatedPostTags = [...postSelected.post.post_tags];
      updatedPostTags[updatedTagIndex] = tagCreationResult;

      setPostSelected({
        ...postSelected,
        post: {
          ...postSelected.post,
          post_tags: updatedPostTags,
        },
      });
    }
    // eslint-disable-next-line
  }, [tagAddedSuccessfully, tagCreationResult]);

  useEffect(() => {
    if (tagDeletedSuccessfully && !!deletedTagId && postSelected?.post.post_tags?.length) {
      const updatedPostTags = postSelected.post.post_tags.filter(tag => tag.id !== deletedTagId);

      setPostSelected({
        ...postSelected,
        post: {
          ...postSelected.post,
          post_tags: updatedPostTags,
        },
      });
    }
    // eslint-disable-next-line
  }, [tagDeletedSuccessfully, deletedTagId]);

  const onCloseAds = () => {
    clearAds();
    setPostAds(undefined);
    setOpenAds(false);
  };

  const handleClosePostModal = () => {
    clearPost();
    setPostSelected(undefined);
  };

  const search = (value: string) => {
    if (postAds?.length && (value.length >= 2 || value.length === 0)) {
      const data = postAds.filter(o =>
        (Object.keys(o) as Array<keyof IAd>).some(k =>
          String(o[k]).toLowerCase().includes(value.toLowerCase()),
        ),
      );
      setSearchValue(value);
      setSearchedAds(value.length >= 2 ? data : postAds);
    }
  };

  const onPostClick = (postId: string) => {
    const newFilters = {
      ...filters,
      post_ids: [postId],
    };
    filterToSpecificItem?.(newFilters);
    navigate(
      {
        search: "?section=listen-sentiment_details",
      },
      {
        state: {
          filters: newFilters,
        },
      },
    );
  };

  return (
    <>
      {!filters.saved_filter && (
        <div className={s.bbPostTrendsWrapper}>
          <Card className={s.bbPostTrendsCard}>
            <div className={s.bbPostTrends}>
              <div className={s.bbPostTrendsTitle}>
                {t("pages:overviewSections:content:subTitle")}
              </div>
              <div
                data-cy="most-commented-posts-container"
                data-stonly-target="intelligence-overview__most-commented-posts"
              >
                <Row>
                  <Col xs={24}>
                    <div
                      style={{ width: containerWidth - 40 || 0 }}
                      className={cx({
                        [s.bbCenteredCarouselItems]:
                          list[activePlatformIndex]?.items?.length < 4 && screens.lg,
                      })}
                    >
                      {trendingPostsLoading ? (
                        <div className={s.bbPostTrendsLoader}>
                          <Loading isLoading />
                        </div>
                      ) : list[activePlatformIndex]?.items?.length ? (
                        <Carousel
                          ssr
                          deviceType="desktop"
                          responsive={responsive}
                          itemClass={s.carouselItem}
                          containerClass={carouselStyles.carouselContainer}
                        >
                          {list[activePlatformIndex].items?.map((trendingPost, index) => (
                            <MostCommentedPost
                              key={index}
                              postData={trendingPost}
                              onPostClick={onPostClick}
                              onSeePostClick={post => setPostSelected(post)}
                              onSeeAdsClick={postId => {
                                setAdsLoading(true);
                                getAds(postId);
                              }}
                            />
                          ))}
                        </Carousel>
                      ) : (
                        <Empty
                          className={s.bbPostTrendsEmpty}
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={t("components:empty:description")}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Card>

          <PostModal
            showPostTags
            post={postSelected?.post}
            isOpen={!!postSelected}
            onClose={handleClosePostModal}
            bars={getSentimentBars()}
            stats={postSelected && generateStatsData(postSelected.comment_stats)}
          />

          <Modal
            centered
            responsive
            hideFooter
            open={isOpenedAds}
            title={t("generic:ads")}
            onCancel={() => onCloseAds()}
            titleSufix={
              <Input
                _size="md"
                className={s.bbAdsSearchInput}
                wrapperClassName={s.bbAdsSearchInputWrapper}
                placeholder={t("generic:search")}
                value={searchValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => search(e.target.value)}
              />
            }
          >
            {!!postAds && (
              <Ads
                ads={searchValue.length > 2 ? searchedAds : postAds}
                isLoading={adsLoading}
                texts={{
                  adLabel: t("components:searchPanel:comments:ads:table:adLabel"),
                  adSetLabel: t("components:searchPanel:comments:ads:table:adSetLabel"),
                  campaignLabel: t("components:searchPanel:comments:ads:table:campaignLabel"),
                  spinText: t("pages:loading:spinText"),
                }}
              />
            )}
          </Modal>
        </div>
      )}
    </>
  );
};

export default MostCommentedPosts;

import { useTranslation } from "react-i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import { updatedReplyApprovalSelector } from "@store/replies/selectors";

import { updateReplyApprovalAction } from "@store/replies/actions";

import s from "./ReplyModal.module.less";

import { addToast } from "@bbdevcrew/bb_ui_kit_fe";

const TOAST_DURATION_TIME = 7_000;

function useApprovalAction(commentId?: string, onClick?: (commentId: string) => void) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isApprovalRejected, setIsApprovalRejected] = useState(false);

  const updatedReplyApproval = useSelector(updatedReplyApprovalSelector);

  useEffect(() => {
    if (updatedReplyApproval && isApprovalRejected) {
      addToast({
        type: "success",
        title: t("components:reply:approve:toast:title"),
        duration: TOAST_DURATION_TIME,
        message: (
          <div className={s.bbApprovalToast}>
            <span>{t("components:reply:approve:toast:message")}</span>
            {onClick ? (
              <Button
                _size="sm"
                _type="secondary"
                onClick={commentId && onClick ? () => onClick(commentId) : undefined}
              >
                {t("components:reply:approve:toast:action")}
              </Button>
            ) : null}
          </div>
        ),
      });
      setIsApprovalRejected(false);
    }
    // eslint-disable-next-line
  }, [updatedReplyApproval]);

  const onApprovalAction = (actionId: string, isPrivateReply?: boolean) => {
    if (!commentId) return;
    setIsApprovalRejected(actionId === "reject");
    dispatch(
      updateReplyApprovalAction({
        commentId,
        id: actionId,
        isPrivateReply,
      }),
    );
  };

  return {
    onApprovalAction,
  };
}

export default useApprovalAction;

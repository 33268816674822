import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";

import { RootState } from "../";
import { AccountsActionsType } from "./types";
import { accounts } from "@utils/paths";
import { DELETE_ACCOUNT } from "./actionTypes";
import { deleteAccountFailure, deleteAccountSuccess } from "./actions";
import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";

export const deleteAccount = (
  action$: Observable<AccountsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_ACCOUNT)),
    switchMap(a =>
      ajax({
        method: "DELETE",
        url: accounts,
        body: a.payload,
        headers: getHeaders({
          Authorization: state$.value.auth.session.accessToken.jwtToken,
        }),
      }).pipe(
        map(e => e.response),
        map(() => deleteAccountSuccess()),
        catchError(err => handleError(err, deleteAccountFailure)),
      ),
    ),
  );

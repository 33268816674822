import { TFunction } from "i18next";

const getMessageStatusesLabels = (t: TFunction): Record<string, string> => ({
  archived: t("components:filters:message_statuses:options:archived"),
  starred: t("components:filters:message_statuses:options:starred"),
  replied: t("components:filters:message_statuses:options:replied"),
  replied_in_platform: t("components:filters:message_statuses:options:replied_in_platform"),
  replied_by_bb: t("components:filters:message_statuses:options:replied_by_bb"),
  reply_pending_approval: t("components:filters:message_statuses:options:reply_pending_approval"),
  reply_rejected: t("components:filters:message_statuses:options:reply_rejected"),
});

const getMessageStatuses = (t: TFunction) => [
  {
    id: "archived",
    label: getMessageStatusesLabels(t).archived,
  },
  {
    id: "starred",
    label: getMessageStatusesLabels(t).starred,
  },
  {
    id: "replied",
    label: getMessageStatusesLabels(t).replied,
    items: [
      {
        id: "replied_in_platform",
        label: getMessageStatusesLabels(t).replied_in_platform,
      },
      {
        id: "replied_by_bb",
        label: getMessageStatusesLabels(t).replied_by_bb,
      },
    ],
  },
  {
    id: "reply_pending_approval",
    label: getMessageStatusesLabels(t).reply_pending_approval,
  },
  {
    id: "reply_rejected",
    label: getMessageStatusesLabels(t).reply_rejected,
  },
];

export { getMessageStatusesLabels, getMessageStatuses };

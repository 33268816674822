import { TFunction } from "i18next";

export const getPriorityOptions = (t: TFunction) => [
  {
    label: t("components:zendeskForm:priority:options:urgent"),
    id: "urgent",
  },
  {
    label: t("components:zendeskForm:priority:options:high"),
    id: "high",
  },
  {
    label: t("components:zendeskForm:priority:options:normal"),
    id: "normal",
  },
  {
    label: t("components:zendeskForm:priority:options:low"),
    id: "low",
  },
];

export const getTypeOptions = (t: TFunction) => [
  {
    label: t("components:zendeskForm:type:options:problem"),
    id: "problem",
  },
  {
    label: t("components:zendeskForm:type:options:incident"),
    id: "incident",
  },
  {
    label: t("components:zendeskForm:type:options:question"),
    id: "question",
  },
  {
    label: t("components:zendeskForm:type:options:task"),
    id: "task",
  },
];

export const getStatusOptions = (t: TFunction) => [
  {
    label: t("components:zendeskForm:status:options:new"),
    id: "new",
  },
  {
    label: t("components:zendeskForm:status:options:open"),
    id: "open",
  },
  {
    label: t("components:zendeskForm:status:options:pending"),
    id: "pending",
  },
  {
    label: t("components:zendeskForm:status:options:hold"),
    id: "hold",
  },
  {
    label: t("components:zendeskForm:status:options:solved"),
    id: "solved",
  },
  {
    label: t("components:zendeskForm:status:options:closed"),
    id: "closed",
  },
];

import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { MessageListParent } from "./MessageListParent";
import { MessageListChildren } from "./MessageListChildren";

import s from "./MessageList.module.less";

import { IMessageListPublicProps } from "./MessageList.type";
import { useReplyingComment } from "@utils/comments";

export const MessageListPublic: FC<IMessageListPublicProps> = ({
  filters,
  replyData,
  isReplyBtnLoading,
  onInternalReply,
  onBottomReached,
  isReplyingComment,
  onClose,
  listId,
  onApprovalAction,
}) => {
  const { t } = useTranslation();
  const parent = replyData?.parent;
  const comment = useReplyingComment(replyData, listId);

  const showReplyBox = (commentId: string) => {
    return isReplyingComment(commentId) && !replyData.reply_capability?.disabled;
  };

  return (
    <>
      {parent && (
        <MessageListParent
          parent={parent}
          comment={comment}
          filters={filters}
          replyData={replyData}
          onInternalReply={onInternalReply}
          isReplyBtnLoading={isReplyBtnLoading}
          showReplyBox={showReplyBox(parent.id)}
          isReplyingComment={isReplyingComment(parent.id)}
          onClose={onClose}
          onApprovalAction={onApprovalAction}
        />
      )}

      {replyData.total_count > 0 && (
        <span className={s.bbMessageListCount}>
          {t("components:reply:messageCount", {
            count: replyData.total_count,
          })}
        </span>
      )}

      <MessageListChildren
        listId={listId}
        comment={comment}
        filters={filters}
        replyData={replyData}
        showReplyBox={showReplyBox}
        onInternalReply={onInternalReply}
        onBottomReached={onBottomReached}
        isReplyingComment={isReplyingComment}
        isReplyBtnLoading={isReplyBtnLoading}
        onClose={onClose}
      />
    </>
  );
};

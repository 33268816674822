import React, { useCallback, useState } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Dropdown } from "@bbdevcrew/bb_ui_kit_fe";

import { deleteInternalNoteAction } from "@store/internalNotes/actions";

import s from "./InternalNotes.module.less";

import { IInternalNotesItemProps } from "./InternalNotes.types";
import { getStyledMessage } from "./InternalNotes.helpers";
import { getUserInitials } from "@bbdevcrew/bb_ui_kit_fe";

import { DeleteIcon, MoreActionsIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const InternalNotesItem: React.FC<IInternalNotesItemProps> = ({
  id,
  date_diff_pretty,
  message,
  author,
  mentions,
  userId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [showAction, setShowAction] = useState(false);

  const deleteInternalNote = useCallback(
    noteId => {
      dispatch(deleteInternalNoteAction(noteId));
    },
    [dispatch],
  );

  const showDeleteAction = () => {
    if (author.id === userId) setShowAction(true);
  };

  return (
    <div
      className={s.bbNotesItem}
      onMouseEnter={showDeleteAction}
      onMouseLeave={() => setShowAction(false)}
    >
      <div className={s.bbNotesItemHeader}>
        <div className={s.bbNotesItemHeaderLeft}>
          {author.picture ? (
            <img alt="user_pic" src={author.picture} className={s.bbNotesItemHeaderImg} />
          ) : (
            <div className={s.bbNotesItemHeaderImg}>{getUserInitials(author.pretty)}</div>
          )}
          <div className={s.bbNotesItemHeaderTitle}>
            <span className={s.bbNotesItemTitle}>{author.pretty}</span>
            <span className={s.bbNotesItemTime}>{date_diff_pretty}</span>
          </div>
        </div>
        <Dropdown
          setOpen={setOpen}
          open={open}
          placement="bottomRight"
          trigger={
            <div
              className={classNames(s.bbNotesItemAction, {
                [s.bbNotesItemActionHovered]: showAction,
              })}
            >
              <MoreActionsIcon />
            </div>
          }
        >
          <div className={s.bbNotesItemDelete} onClick={() => deleteInternalNote(id)}>
            <DeleteIcon />
            {t("generic:delete")}
          </div>
        </Dropdown>
      </div>
      <div className={s.bbNotesItemMessage}>{getStyledMessage(message, mentions, userId)}</div>
    </div>
  );
};

import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import {
  getCommentTagsSuccessAction,
  getCommentTagsFailureAction,
  postCommentTagSuccessAction,
  postCommentTagFailureAction,
  deleteCommentTagSuccessAction,
  deleteCommentTagFailureAction,
} from "./actions";
import { DELETE_COMMENT_TAG, GET_COMMENT_TAGS, POST_COMMENT_TAG } from "./actionTypes";

import { RootState } from "../";
import { CommentTagsActions, IPostCommentTagPayload } from "./types";
import { clientTags, commentsActions } from "@utils/paths";
import { ICommentTagFilterItem } from "../commentTags/types";
import { ICommentTag } from "@bbdevcrew/bb_ui_kit_fe";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { getHeaders } from "@utils/headers";

export const getCommentTags = (
  action$: Observable<CommentTagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_COMMENT_TAGS)),
    switchMap(() => {
      return ajax
        .get<{ items: ICommentTagFilterItem[] }>(
          clientTags,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getCommentTagsSuccessAction(data)),
          catchError(e => handleError(e, getCommentTagsFailureAction)),
        );
    }),
  );

export const postCommentTag = (
  action$: Observable<CommentTagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_COMMENT_TAG)),
    switchMap(a => {
      return ajax
        .post<ICommentTag>(
          `${commentsActions}/${a.payload.commentId}/tags/${a.payload.tagId}`,
          null,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => [postCommentTagSuccessAction(a.payload, data)]),
          catchError(e => {
            return [
              postCommentTagFailureAction(a.payload),
              handleError(e, postCommentTagFailureAction),
            ];
          }),
        );
    }),
  );

export const deleteCommentTag = (
  action$: Observable<CommentTagsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_COMMENT_TAG)),
    switchMap(a => {
      return ajax
        .delete<IPostCommentTagPayload>(
          `${commentsActions}/${a.payload.commentId}/tags/${a.payload.tagId}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(() => [deleteCommentTagSuccessAction(a.payload)]),
          catchError(e => {
            return [
              deleteCommentTagFailureAction(a.payload),
              handleError(e, deleteCommentTagFailureAction),
            ];
          }),
        );
    }),
  );

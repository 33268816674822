import { StateObservable } from "redux-observable";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import * as actions from "./actions";
import { GET_HIDING_CONFIG, UPDATE_HIDING_CONFIG } from "./actionTypes";

import { Actions, IConfigData } from "./types";

import { moderationHidingConfig } from "@utils/paths";
import { RootState } from "..";
import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";

export const getHidingConfigEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_HIDING_CONFIG)),
    switchMap(() => {
      return ajax
        .get<IConfigData>(
          moderationHidingConfig,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getHidingConfigActionSuccess(data)),
          catchError(e => handleError(e, actions.getHidingConfigActionFailure)),
        );
    }),
  );

export const updateHidingConfigEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(UPDATE_HIDING_CONFIG)),
    switchMap(a => {
      return ajax
        .put<IConfigData>(
          moderationHidingConfig,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.updateHidingConfigActionSuccess(data)),
          catchError(e => handleError(e, actions.updateHidingConfigActionFailure)),
        );
    }),
  );

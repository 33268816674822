import { TFunction } from "react-i18next";

const instagramFollowersLabels = (t: TFunction): Record<string, string> => {
  return {
    less_than_1k: t("components:filters:author_followers:options:less_than_1k"),
    from_1k_to_2500: t("components:filters:author_followers:options:from_1k_to_2500"),
    from_2_5k_to_5k: t("components:filters:author_followers:options:from_2_5k_to_5k"),
    from_5k_to_10k: t("components:filters:author_followers:options:from_5k_to_10k"),
    from_10k_to_25k: t("components:filters:author_followers:options:from_10k_to_25k"),
    from_25k_to_50k: t("components:filters:author_followers:options:from_25k_to_50k"),
    from_50k_to_100k: t("components:filters:author_followers:options:from_50k_to_100k"),
    from_100k_to_250k: t("components:filters:author_followers:options:from_100k_to_250k"),
    from_250k_to_500k: t("components:filters:author_followers:options:from_250k_to_500k"),
    from_500k_to_1m: t("components:filters:author_followers:options:from_500k_to_1m"),
    from_1m_to_5m: t("components:filters:author_followers:options:from_1m_to_5m"),
    from_5m_to_10m: t("components:filters:author_followers:options:from_5m_to_10m"),
    over_10m: t("components:filters:author_followers:options:over_10m"),
  };
};

const instagramFollowers = (t: TFunction) => [
  {
    id: "less_than_1k",
    label: instagramFollowersLabels(t).less_than_1k,
  },
  {
    id: "from_1k_to_2500",
    label: instagramFollowersLabels(t).from_1k_to_2500,
  },
  {
    id: "from_2_5k_to_5k",
    label: instagramFollowersLabels(t).from_2_5k_to_5k,
  },
  {
    id: "from_5k_to_10k",
    label: instagramFollowersLabels(t).from_5k_to_10k,
  },
  {
    id: "from_10k_to_25k",
    label: instagramFollowersLabels(t).from_10k_to_25k,
  },
  {
    id: "from_25k_to_50k",
    label: instagramFollowersLabels(t).from_25k_to_50k,
  },
  {
    id: "from_50k_to_100k",
    label: instagramFollowersLabels(t).from_50k_to_100k,
  },
  {
    id: "from_100k_to_250k",
    label: instagramFollowersLabels(t).from_100k_to_250k,
  },
  {
    id: "from_250k_to_500k",
    label: instagramFollowersLabels(t).from_250k_to_500k,
  },
  {
    id: "from_500k_to_1m",
    label: instagramFollowersLabels(t).from_500k_to_1m,
  },
  {
    id: "from_1m_to_5m",
    label: instagramFollowersLabels(t).from_1m_to_5m,
  },
  {
    id: "from_5m_to_10m",
    label: instagramFollowersLabels(t).from_5m_to_10m,
  },
  {
    id: "over_10m",
    label: instagramFollowersLabels(t).over_10m,
  },
];

export { instagramFollowersLabels, instagramFollowers };

import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { meSelector } from "@store/me/selectors";

import { InternalNotesContent } from "./InternalNotesContent";
import { TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";
import { CommentHistory } from "./CommentHistory";

import s from "./InternalNotes.module.less";

import { IInternalNotesProps } from "./InternalNotes.types";
import { getInternalNotesAction } from "@store/internalNotes/actions";
import { resetCommentHistoryAction } from "@store/comments/actions";

export const InternalNotes: FC<IInternalNotesProps> = ({ commentId, collapsed, count }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("internal_notes");

  const me = useSelector(meSelector);

  const getInternalNotes = useCallback(
    id => {
      dispatch(getInternalNotesAction(id));
    },
    [dispatch],
  );

  const resetCommentHistory = useCallback(() => {
    dispatch(resetCommentHistoryAction());
  }, [dispatch]);

  useEffect(() => {
    getInternalNotes(commentId);
  }, [commentId, getInternalNotes]);

  const onChangeTab = (tab: string) => {
    if (tab === "history") {
      resetCommentHistory();
    }
    setActiveTab(tab);
  };

  return (
    <TabsScrollable activeKey={activeTab} onChange={onChangeTab} className={s.bbNotesTabsContainer}>
      <TabPane
        tabKey="internal_notes"
        tab={
          <div className={s.bbNotesTabTitle}>
            <span>{t("components:comments:activity:internalNotes:title")}</span>
            {!collapsed && !!count && <span className={s.bbNotesCount}>{count}</span>}
          </div>
        }
      >
        <InternalNotesContent commentId={commentId} collapsed={collapsed} />
      </TabPane>
      {!!me?.is_bb_user && (
        <TabPane
          tabKey="history"
          tab={
            <span className={s.bbNotesTabTitle}>
              {t("components:comments:activity:history:title")}
            </span>
          }
        >
          <CommentHistory commentId={commentId} />
        </TabPane>
      )}
    </TabsScrollable>
  );
};

import React from "react";
import { t } from "../../../App";

import { Tooltip, UserAvatar } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AgentPerformance.module.less";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

import {
  IAgentPerformanceResponseStat,
  IAgentPerformanceUserStat,
  IOverviewPerformanceChartItem,
} from "@store/dashboard/types";
import { BarProps } from "recharts";
import { IFilters } from "@store/filters/types";
import { IStatsProps } from "@store/overview/types";
import { cropText, formatDateEnUs } from "@bbdevcrew/bb_ui_kit_fe";

export const stripePattern = (
  <>
    <pattern
      id="pattern-stripe"
      width="8"
      height="8"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45)"
    >
      <rect width="4" height="8" transform="translate(0,0)" fill="white"></rect>
    </pattern>
    <mask id="mask-stripe">
      <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
    </mask>
  </>
);

export const getSentimentBars = () => [
  {
    dataKey: t<keyof BarProps>("components:postTrends:sentiments:positive"),
    fill: palette.colorPositive,
    stackId: "stack",
  },
  {
    dataKey: t<keyof BarProps>("components:postTrends:sentiments:negative"),
    fill: palette.colorNegative,
    stackId: "stack",
  },
  {
    dataKey: t<keyof BarProps>("components:postTrends:sentiments:user_tags"),
    fill: palette.colorUserTags,
    stackId: "stack",
  },
  {
    dataKey: t<keyof BarProps>("components:postTrends:sentiments:neutral"),
    fill: palette.colorNeutral,
    stackId: "stack",
  },
  {
    dataKey: t<keyof BarProps>("components:postTrends:sentiments:not_applicable"),
    fill: palette.colorNotApplicable,
    shape: <rect mask="url(#mask-stripe)" x={10} y={10} width={10} height={10} />,
    stackId: "stack",
  },
];

export const generateStatsData = (data: IStatsProps, includeTitle = true) => {
  const statsData: { [key: string]: string | number | undefined } = {
    name: includeTitle ? (t("components:postTrends:titleGraphPost") as string) : undefined,
  };

  for (const key in data) {
    if (key !== "total")
      statsData[t(`components:postTrends:sentiments:${key}`)] = data[key as keyof IStatsProps];
  }

  return [statsData];
};

export const calculateDateRange = (range: string) => {
  const today = new Date();
  let from: Date | undefined = new Date();
  let to: Date | undefined = new Date();

  if (range === "last_30_days") {
    from.setDate(today.getDate() - 29);
  } else if (range === "last_7_days") {
    from.setDate(today.getDate() - 6);
  } else if (range === "last_12_months") {
    from.setFullYear(today.getFullYear() - 1);
    from.setMonth(today.getMonth());
    from.setDate(today.getDate());
  } else if (range === "current_day") {
    to = undefined;
  } else if (range === "yesterday") {
    from.setDate(today.getDate() - 1);
    to = undefined;
  } else {
    from = undefined;
    to = undefined;
  }

  const fromDate = from ? formatDateEnUs(from) : from;
  const toDate = to ? formatDateEnUs(to) : to;

  return { fromDate, toDate };
};

export const getPDFName = (filters?: IFilters, clientName?: string) => {
  let from,
    to,
    fromString = "",
    toString = "";

  if (filters?.start_time && filters?.end_time) {
    from = formatDateEnUs(filters.start_time);
    to = formatDateEnUs(filters.end_time);
  } else if (filters?.data_range_option) {
    const { fromDate, toDate } = calculateDateRange(filters?.data_range_option);
    from = fromDate;
    to = toDate;
  }

  fromString = from ? "_" + from : "";
  toString = to ? "_" + to : "";

  return `Report_${clientName}${fromString}${toString}`;
};

const MAX_USERNAME_LENGTH = 18;

export const goToPath = (path: string) => {
  window.location.href = `${window.location.origin}${path}`;
};

export const dateToNumber = (date: string) => {
  let days = 0;
  let splitHours;
  const splitDays = date.split("d");

  if (splitDays.length === 2) {
    days = parseFloat(splitDays[0]);
    splitHours = splitDays[1].split("h");
  } else {
    days = 0;
    splitHours = splitDays[0].split("h");
  }

  const hours = parseFloat(splitHours[0]) || 0;
  const splitMinutes = splitHours[1].split("m");
  const minutes = parseFloat(splitMinutes[0]) || 0;

  return days * 24 + hours + minutes / 60;
};

export const normalizePerformanceData = (stats?: IAgentPerformanceResponseStat) => {
  return {
    change_percentage: stats?.change_percentage || 0,
    percentage_of_total: 0,
    count: stats?.pretty_count || stats?.count || 0,
  };
};

export const normalizeUserStatsCommentsData = (
  color: string,
  userStats?: IAgentPerformanceUserStat[],
  showName?: boolean,
) => {
  if (userStats) {
    return userStats?.map(userStat => {
      const isUsernameLong = userStat.name.length > MAX_USERNAME_LENGTH;

      return {
        id: userStat.id,
        name: (
          <>
            <UserAvatar
              img={userStat.avatar}
              lastName={userStat.name.split(" ")[0]}
              firstName={userStat.name.split(" ")[1]}
              className={s.bbUserStatsAvatar}
            />
            {showName && (
              <Tooltip title={showName && isUsernameLong ? userStat.name : ""}>
                {cropText(userStat.name, MAX_USERNAME_LENGTH)}
              </Tooltip>
            )}
          </>
        ),
        count: userStat.count,
        change_percentage: userStat.count_change_percentage,
        percentage_of_total: userStat.count_percentage_of_total,
        category: userStat.id,
        category_name: userStat.name,
        color: color,
      } as IOverviewPerformanceChartItem;
    });
  }
  return [];
};

export const normalizeUserStatsTimeData = (
  color: string,
  userStats?: IAgentPerformanceUserStat[],
  showName?: boolean,
) => {
  if (userStats) {
    return userStats
      ?.map(userStat => {
        const isUsernameLong = userStat.name.length > MAX_USERNAME_LENGTH;

        return {
          id: userStat.id,
          name: (
            <>
              <UserAvatar
                img={userStat.avatar}
                lastName={userStat.name.split(" ")[0]}
                firstName={userStat.name.split(" ")[1]}
                className={s.bbUserStatsAvatar}
              />
              {showName && (
                <Tooltip title={showName && isUsernameLong ? userStat.name : ""}>
                  {cropText(userStat.name, MAX_USERNAME_LENGTH)}
                </Tooltip>
              )}
            </>
          ),
          count: dateToNumber(userStat.duration),
          count_pretty: userStat.duration,
          change_percentage: userStat.duration_change_percentage,
          category: userStat.id,
          category_name: userStat.name,
          color: color,
        };
      })
      .sort((a, b) => b.count - a.count);
  }
  return [];
};

export const getUserStatsCommentsLegend = (color: string) => [
  {
    id: "repliesIssued",
    label: t("components:agentPerformance:responseIssuedAndTime:legend:repliesIssued"),
    color: color,
  },
];

export const getUserStatsTimeLegend = (color: string) => [
  {
    id: "responseTime",
    label: t("components:agentPerformance:responseIssuedAndTime:legend:responseTime"),
    color: color,
  },
];

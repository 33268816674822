import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { InternalNotesInput } from "./InternalNotesInput";
import { Button, Chip, Tooltip, EmojiSelect } from "@bbdevcrew/bb_ui_kit_fe";

import { internalNotesSelector } from "@store/internalNotes/selectors";
import { meSelector } from "@store/me/selectors";

import { postInternalNotesAction } from "@store/internalNotes/actions";
import {
  getAutocompleteOptionsSearchAction,
  updateAutocompleteOptionsAction,
} from "@store/autocomplete/actions";

import s from "./InternalNotes.module.less";

import { debounce } from "@bbdevcrew/bb_ui_kit_fe";
import { IMention, IInternalNotesContentProps } from "./InternalNotes.types";
import { BaseEmoji, EmojiData } from "emoji-mart";
import {
  calculateLengthWithMentions,
  DEFAULT_MAX_LENGTH,
  NOTES_MAX_COUNT,
  replaceLabelsWithIds,
} from "./InternalNotes.helpers";
import { InternalNotesItem } from "./InternalNotesItem";
import { IGetAutocompleteOptionsPayload } from "@store/autocomplete/types";

export const InternalNotesContent: FC<IInternalNotesContentProps> = ({ commentId, collapsed }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState<string>("");
  const [mentionMap, setMentionMap] = useState<IMention[]>([]);

  const calculatedInputLength = calculateLengthWithMentions(inputValue, mentionMap);

  const hasError = calculatedInputLength > DEFAULT_MAX_LENGTH;

  const internalNotes = useSelector(internalNotesSelector);
  const me = useSelector(meSelector);

  const isDisabled = hasError || !inputValue.length || internalNotes.length === NOTES_MAX_COUNT;

  const postInternalNotes = useCallback(
    (id, message) => {
      dispatch(postInternalNotesAction({ commentId: id, message }));
    },
    [dispatch],
  );

  const getInitialAutocompleteOptions = useCallback(
    payload => {
      dispatch(getAutocompleteOptionsSearchAction(payload));
    },
    [dispatch],
  );

  // eslint-disable-next-line
  const updateAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(updateAutocompleteOptionsAction(payload));
    }, 500),
    [dispatch],
  );

  const resetState = () => {
    setInputValue("");
    setMentionMap([]);
  };

  useEffect(() => {
    resetState();
  }, [collapsed]);

  const fetchUsers = (term: string) => {
    const payload: IGetAutocompleteOptionsPayload = {
      field: "user",
      query: term,
    };

    if (term) updateAutocompleteOptions(payload);
    else getInitialAutocompleteOptions(payload);
  };

  const addInternalNote = () => {
    postInternalNotes(commentId, replaceLabelsWithIds(inputValue, mentionMap));
    resetState();
  };

  const onInputChange = (value: string) => {
    setInputValue(value);
  };

  const onMentionSelect = (data: IMention) => {
    setMentionMap(prev => [...prev, data]);
  };
  const onEmojiSelect = (emoji: EmojiData) => {
    const native = (emoji as BaseEmoji).native;
    setInputValue(prev => prev + native);
  };

  return (
    <div className={s.bbNotes}>
      <div className={s.bbNotesInputWrapper}>
        <InternalNotesInput
          onSearch={fetchUsers}
          onChange={onInputChange}
          value={inputValue}
          onSelect={onMentionSelect}
        />
        <div className={s.bbNotesInputFooter}>
          <Chip
            className={s.bbNotesChip}
            _type={hasError ? "error" : "default"}
            _size="xxs"
            text={`${calculatedInputLength}/${DEFAULT_MAX_LENGTH}`}
          />
          <div className={s.bbNotesInputFooterRight}>
            <EmojiSelect onEmojiSelect={onEmojiSelect} useDropdownPortal />
            <Tooltip
              title={
                internalNotes.length === NOTES_MAX_COUNT
                  ? t("components:comments:activity:internalNotes:buttonTooltip", {
                      count: NOTES_MAX_COUNT,
                    })
                  : undefined
              }
            >
              <div>
                <Button _size="sm" disabled={isDisabled} _type="primary" onClick={addInternalNote}>
                  {t("components:comments:activity:internalNotes:button")}
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={s.bbNotesList}>
        {internalNotes.map(item => (
          <InternalNotesItem key={item.id} {...item} userId={me?.id} />
        ))}
      </div>
    </div>
  );
};

import cn from "classnames/bind";
import { useSelector } from "react-redux";
import React, { FC, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Empty } from "antd";
import CommentsGridItem from "./CommentsGridItem";

import { meSelector } from "@store/me/selectors";
import { performingBatchActionsPendingSelector } from "@store/comments/selectors";

import s from "./CommentsGrid.module.less";

import { ICommentsGridProps } from "./types";
import { useBatchMode } from "@containers/BatchMode/BatchMode";

const CommentsGrid: FC<ICommentsGridProps> = ({
  comments,
  loading,
  withPostPreview,
  commentsPageCursor,
  className = "",
  EmptyState,
  withFooter,
  onReplyClick,
  onBottomReached,
  onTranslateClick,
}) => {
  const { t } = useTranslation();
  const { isBatchModeActive, selectedComments, selectAllCommentsByDefault } = useBatchMode();

  const layout = document.getElementById("layout-content");

  const me = useSelector(meSelector);
  const performingBatchActions = useSelector(performingBatchActionsPendingSelector);

  const onWindowScroll = useCallback(() => {
    if (layout && layout.scrollHeight - (layout.scrollTop + layout.clientHeight) < 50) {
      onBottomReached?.();
    }
  }, [onBottomReached, layout]);

  useEffect(() => {
    if (!isBatchModeActive) layout?.addEventListener("scroll", onWindowScroll);
    return () => {
      layout?.removeEventListener("scroll", onWindowScroll);
    };
  }, [isBatchModeActive, onWindowScroll, layout]);

  useEffect(() => {
    if (comments.length === 0 && commentsPageCursor) {
      onBottomReached?.();
    }

    const noCommentsSelected = !selectedComments?.length;
    const isPrevBatchSelected = selectedComments.filter(
      ({ id: id1 }) => !comments.some(({ id: id2 }) => id2 === id1),
    ).length;

    if (
      isBatchModeActive &&
      !performingBatchActions &&
      (noCommentsSelected || isPrevBatchSelected)
    ) {
      selectAllCommentsByDefault([...comments]);
    }
    // eslint-disable-next-line
  }, [comments.length, isBatchModeActive]);

  return (
    <>
      {comments.length ? (
        <div className={cn(s.bbCommentsGrid, className)}>
          {comments.map((comment, index) => (
            <CommentsGridItem
              key={comment.id}
              comment={comment}
              commentIndex={index}
              userTimezone={me?.timezone || "GMT"}
              withPostPreview={withPostPreview}
              withFooter={withFooter}
              onReplyClick={onReplyClick}
              onTranslateClick={onTranslateClick}
            />
          ))}
        </div>
      ) : loading ? null : (
        EmptyState || (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>{t("components:comments:tableEmpty")}</span>}
          />
        )
      )}
    </>
  );
};

export default CommentsGrid;

import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CustomTags.module.less";

import { ICustomTagsTriggerButtonProps } from "./CustomTags.type";

import { TagIcon, PlusIcon } from "@bbdevcrew/bb_ui_kit_fe";

const CustomTagsTriggerButton: FC<ICustomTagsTriggerButtonProps> = ({
  type,
  hasCustomTags,
  trigger: TriggerComponent,
  hasLabel = true,
}) => {
  const { t } = useTranslation();

  const defaultButtonClassNames = classNames(s.bbTriggerButtonDefault, {
    [s.bbTriggerButtonDefaultWithTags]: hasCustomTags || !hasLabel,
  });

  const content =
    type === "custom" ? (
      <TagIcon />
    ) : (
      <>
        <PlusIcon />
        <span
          className={classNames(s.bbTriggerIcon, {
            [s.bbTriggerIconWithNoTags]: !hasCustomTags && hasLabel,
          })}
        >
          {!hasCustomTags && hasLabel && t("components:customTags:addBtn")}
        </span>
      </>
    );

  return (
    <>
      {TriggerComponent ? (
        TriggerComponent
      ) : (
        <Button
          _size="sm"
          type="button"
          className={classNames(s.bbTriggerButton, {
            [defaultButtonClassNames]: type === "default",
            [s.bbTriggerButtonCustom]: type === "custom",
          })}
        >
          {content}
        </Button>
      )}
    </>
  );
};

export default CustomTagsTriggerButton;

import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useState } from "react";

import { Grid, Row, Col, Form } from "antd";
import FormHeader from "../../formHeader/FormHeader";
import { PasswordChecker } from "../../passwordChecker/PasswordChecker";
import { TermsConditions } from "../../termsConditions/TermsConditions";
import AgencyLogoBadge from "../../../_common/AgencyLogoBadge/AgencyLogoBadge";
import { Button, Input, InputPassword } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Step1.module.less";
import globalStyles from "../../index.module.less";

import { IStep1Props, IFormStep1 } from "./Step1.type";
import { validatePsw } from "../../passwordChecker/helpers";

import {
  CommentIcon,
  ChartIcon,
  SafetyIcon,
  TrustedByIcon,
  ChevronRightIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import {
  SignInCover as CoverImg,
  SignInCoverRegular as RegularCoverImg,
} from "../../SignInCover/SignInCover";
import Logo from "@assets/LogoNoBeta.svg";

const cx = cn.bind(s);

const Step1View: React.FC<IStep1Props> = ({
  isPilotView,
  isAgencyView,
  isSalesAnalysisView,
  setIsAgencyView,
  onStateChange,
  onFinish,
}) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm<IFormStep1>();

  const [passwordValue, setPasswordValue] = useState("");

  const onInternalFinish = () => {
    onFinish({
      email: form.getFieldValue("email"),
      password: form.getFieldValue("password"),
      terms_accepted: form.getFieldValue("terms_accepted"),
    });
    return;
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPasswordValue(event.target.value);

  const onGoToLoginClick = () => {
    window.history.pushState(null, "", "/login" + window.location.search);
    onStateChange && onStateChange("signIn");
  };

  const onGoToSignupClick = () => {
    setIsAgencyView(false);
    window.history.pushState(null, "", "/login/signup");
  };

  const pageTitle = isPilotView
    ? t("pages:signup:pilot:step1")
    : isAgencyView
      ? t("pages:signup:agency:title")
      : !screens.sm
        ? t("pages:signup:step1:titleShort")
        : t("pages:signup:step1:title");

  return (
    <Row className={s.bbStep1}>
      <Col xs={24} xl={16} className={s.bbStep1Content}>
        <div className={globalStyles.bbInfoSectionLogo}>
          <Logo />
          {isAgencyView && <AgencyLogoBadge />}
        </div>
        <FormHeader
          title={pageTitle}
          subtitle={t(
            "pages:signup:step1:" +
              `subtitle${isPilotView ? "Pilot" : ""}${isSalesAnalysisView ? "Analysis" : ""}`,
          )}
        />
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          data-rewardful={true}
          onFinish={onInternalFinish}
          className={cx(s.bbStep1Form, globalStyles.bbFormXL, {
            [globalStyles.bbFormSM]: !screens.md,
          })}
        >
          <Form.Item
            name="email"
            validateTrigger="onBlur"
            className={s.bbEmailInput}
            label={t("pages:signup:step1:left:form:email:label")}
            tooltip={t("pages:signup:step1:left:form:email:tooltip")}
            rules={[
              {
                required: true,
                message: t("pages:signup:step1:left:form:email:required"),
              },
              {
                type: "email",
                message: t("pages:signup:step1:left:form:email:invalidEmail"),
              },
            ]}
          >
            <Input _size="md" type="email" name="email" autoComplete="new-email" />
          </Form.Item>
          <Form.Item
            validateFirst
            name="password"
            validateTrigger="onBlur"
            label={t("pages:signup:step1:left:form:password:label")}
            rules={[
              {
                required: true,
                type: "string",
                message: t("pages:signup:step1:left:form:password:required"),
              },
              {
                validator: (rule, value) => {
                  const { isValid } = validatePsw(value);

                  if (isValid) {
                    return Promise.resolve();
                  }

                  return Promise.reject(t("pages:signup:step1:left:form:password:pattern"));
                },
              },
            ]}
          >
            <InputPassword
              _size="md"
              onChange={onPasswordChange}
              autoComplete="new-password"
              hideMaxLengthChip
              maxLength={100}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {_form => {
              return (
                passwordValue && (
                  <PasswordChecker
                    value={passwordValue}
                    isValidated={!!_form.getFieldError("password")[0]}
                  />
                )
              );
            }}
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label={t("pages:signup:step1:left:form:confirm_password:label")}
            validateTrigger="onBlur"
            validateFirst
            rules={[
              {
                required: true,
                type: "string",
                message: t("pages:signup:step1:left:form:confirm_password:required"),
              },
              {
                validator: (rule, value, cb) => {
                  if (value !== form.getFieldValue("password")) {
                    cb(t("pages:signup:step1:left:form:confirm_password:passwordMatch"));
                  } else {
                    cb();
                  }
                },
              },
            ]}
          >
            <InputPassword
              _size="md"
              maxLength={100}
              autoComplete="confirm_password"
              hideMaxLengthChip
            />
          </Form.Item>
          <Form.Item>
            <Button
              _type="primary"
              type="submit"
              className={cx(s.bbStep1Button, globalStyles.buttonXL, globalStyles.buttonGradient, {
                [globalStyles.buttonSM]: !screens.md,
              })}
            >
              {isAgencyView
                ? t("pages:signup:agency:submitButtonLabel")
                : t("pages:signup:step1:left:form:button:label")}
              <span className={s.bbSubmitBtnIcon}>
                <ChevronRightIcon />
              </span>
            </Button>
          </Form.Item>
        </Form>
        <TermsConditions />
        <div className={s.bbLoginSection}>
          {isAgencyView && (
            <div className={s.bbRightStep1RightSubtitle}>
              <span>{t("pages:signup:agency:notAnAgency")}</span>
              <Button
                _size="lg"
                _type="custom"
                type="submit"
                onClick={onGoToSignupClick}
                className={s.bbRightStep1Button}
              >
                {t("pages:signup:agency:signupGeneric")}
              </Button>
            </div>
          )}
          <span className={s.bbRightStep1RightSubtitle}>
            {t("pages:signup:step1:left:subTitle")}
          </span>
          <Button
            _size="lg"
            _type="custom"
            type="submit"
            onClick={onGoToLoginClick}
            className={s.bbRightStep1Button}
          >
            {t("generic:logIn")}
          </Button>
        </div>
      </Col>

      {screens.xl ? (
        <Col xs={8} className={globalStyles.bbInfoSection}>
          <div
            className={cx(globalStyles.bbInfoSectionCopy, {
              [globalStyles.bbInfoSectionCopyManagedPilot]: isPilotView,
            })}
          ></div>
          {!isPilotView && !isSalesAnalysisView && (
            <div>
              <p className={s.bbRightIconWrapper}>
                <i className={globalStyles.rightIcons}>
                  <ChartIcon />
                </i>
                <span>
                  <span className={s.bbRightIconText}>{t("generic:analyze")}</span>{" "}
                  {t("pages:signup:step1:right:analyze")}
                </span>
              </p>
              <p className={s.bbRightIconWrapper}>
                <i className={globalStyles.rightIcons}>
                  <SafetyIcon />
                </i>
                <span>
                  <span className={s.bbRightIconText}>{t("generic:moderate")}</span>{" "}
                  {t("pages:signup:step1:right:moderate")}
                </span>
              </p>
              <p className={s.bbRightIconWrapper}>
                <i className={globalStyles.rightIcons}>
                  <CommentIcon />
                </i>
                <span>
                  <span className={s.bbRightIconText}>{t("generic:engage")}</span>{" "}
                  {t("pages:signup:step1:right:engage")}
                </span>
              </p>
            </div>
          )}
          <div className={globalStyles.bbInfoSectionTrustedBy}>
            <TrustedByIcon />
          </div>
          <div>{!isPilotView && !isSalesAnalysisView ? <RegularCoverImg /> : <CoverImg />}</div>
        </Col>
      ) : null}
    </Row>
  );
};

export default Step1View;

import React, { FC, useCallback } from "react";

import { LinkedInReactionButton } from "@bbdevcrew/bb_ui_kit_fe";

import { IComment } from "@bbdevcrew/bb_ui_kit_fe";
import { LinkedInReactionClickFunctionType } from "../CommentActions.type";

interface IActionLinkedInReactionProps {
  comment: IComment;
  onLikeClick?: LinkedInReactionClickFunctionType;
}

const ActionLinkedInReaction: FC<IActionLinkedInReactionProps> = ({ comment, onLikeClick }) => {
  const value = comment.reaction && comment.reaction.is_sent ? comment.reaction.type : undefined;

  const onChangeHandler = useCallback(
    reaction => {
      if (reaction) onLikeClick?.(comment.id, reaction);
      else onLikeClick?.(comment.id);
    },
    [onLikeClick, comment.id],
  );

  return <LinkedInReactionButton value={value} onChange={onChangeHandler} />;
};

export default ActionLinkedInReaction;

import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { ChipIcon, RocketIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IAgencyLogoBadgeProps {
  className?: string;
}

const AgencyLogoBadge: FC<IAgencyLogoBadgeProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <ChipIcon
      _size="xs"
      iconPosition="left"
      icon={RocketIcon}
      text={t("generic:agency")}
      wrapperClassName={className}
    />
  );
};

export default AgencyLogoBadge;

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { FC, useCallback } from "react";

import { SelectStyled, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { updateCommentSentimentAction } from "@store/comments/actions";

import { getSentimentOptions } from "./helpers";
import { isYoutubePlatform, IComment } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ChangeSentiment.module.less";

interface IChangeSentimentProps {
  comment?: IComment;
}

const ChangeSentiment: FC<IChangeSentimentProps> = ({ comment }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isDisabledForYoutube =
    isYoutubePlatform(comment?.platform_type?.id || "") &&
    comment?.actions.sentiment_change_capability?.capability === "disabled";
  const sentimentValue = isDisabledForYoutube ? "neutral" : comment?.sentiment || "negative";

  const onChangeCommentSentiment = useCallback(
    sentiment => comment?.id && dispatch(updateCommentSentimentAction(comment.id, { sentiment })),
    [dispatch, comment?.id],
  );

  return (
    <div className={s.bbChangeSentiment}>
      <Tooltip title={comment?.actions.sentiment_change_capability?.tooltip}>
        <SelectStyled
          id="sentiment_change"
          value={sentimentValue}
          disabled={isDisabledForYoutube}
          prefixEl={
            getSentimentOptions(t).find(
              sentimentOption => sentimentOption.value === comment?.sentiment,
            )?.icon
          }
          options={getSentimentOptions(t)}
          onChange={onChangeCommentSentiment}
          wrapperClassName={s.bbChangeSentimentSelect}
        />
      </Tooltip>
    </div>
  );
};

export default ChangeSentiment;

import { createReducer, ActionType } from "typesafe-actions";

import { IUserState } from "./types";
import { signUserUpSuccessAction } from "./actions";
import { tiktokConnectSuccessAction } from "../tiktokApp/actions";
import { SIGN_USER_UP, SIGN_USER_UP_SUCCESS, SIGN_USER_UP_FAILURE } from "./actionTypes";
import { TIKTOK_CONNECT_SUCCESS, TIKTOK_SIGNUP_SUCCESS } from "../tiktokApp/actionTypes";

const initialState = {
  user: undefined,
  cookies: undefined,
  access_token: undefined,
  signingUserUp: false,
  signedUserUp: false,
  signedUserUpFail: false,
};

export const userReducer = createReducer<IUserState>(initialState, {
  [SIGN_USER_UP]: (state: IUserState) => ({
    ...state,
    signingUserUp: true,
    signedUserUp: false,
    signedUserUpFail: false,
  }),
  [SIGN_USER_UP_SUCCESS]: (
    state: IUserState,
    action: ActionType<typeof signUserUpSuccessAction>,
  ) => ({
    ...state,
    access_token: action.payload.access_token,
    cookies: action.payload.cookies,
    signingUserUp: false,
    signedUserUp: true,
    signedUserUpFail: false,
  }),
  [SIGN_USER_UP_FAILURE]: (state: IUserState) => ({
    ...state,
    signingUserUp: false,
    signedUserUp: false,
    signedUserUpFail: true,
  }),
  [TIKTOK_SIGNUP_SUCCESS]: (
    state: IUserState,
    action: ActionType<typeof signUserUpSuccessAction>,
  ) => ({
    ...state,
    access_token: action.payload.access_token,
    cookies: action.payload.cookies,
  }),
  [TIKTOK_CONNECT_SUCCESS]: (
    state: IUserState,
    action: ActionType<typeof tiktokConnectSuccessAction>,
  ) => {
    if (action.payload.action === "load_app") {
      return {
        ...state,
        access_token: action.payload.session?.access_token,
        cookies: action.payload.session?.cookies,
      };
    }

    return state;
  },
});

import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import React, { FC, useMemo, useCallback, useEffect } from "react";

import { meSelector } from "@store/me/selectors";
import { IRestrictedRoutesProps } from "./Routes.type";
import { deleteAllCookies } from "@bbdevcrew/bb_ui_kit_fe";
import AppLayout from "@components/_common/AppLayout/AppLayout";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import { IMenuUserInfo, getExtraMenuItems, getRestrictedMenuItems } from "@utils/extraMenu";

const Company = loadable(() => import("@components/settings/company/Company"));
const Profile = loadable(() => import("@components/settings/profile/ProfileView"));
const TrialEnded = loadable(() => import("@components/settings/trialEnded/TrialEnded"));
const AddAssets = loadable(() => import("@components/settings/addAssets/AddAssetsView"));
const Subscription = loadable(() => import("@components/settings/subscription/Subscription"));
const SavedReplies = loadable(() => import("@components/settings/savedReplies/SavedReplies"));
const PaymentCancel = loadable(() => import("@components/settings/paymentCancel/PaymentCancel"));
const PaymentFailed = loadable(() => import("@components/settings/paymentFailed/PaymentFailed"));
const PaymentSuccess = loadable(() => import("@components/settings/paymentSuccess/PaymentSuccess"));
const DocumentCenter = loadable(() => import("@components/settings/documentCenter/DocumentCenter"));
const Integrations = loadable(() => import("@components/settings/integrations"));
const AssetManagement = loadable(
  () => import("@components/settings/assetManagement/AssetManagementView"),
);
const UserManagement = loadable(
  () => import("@components/settings/userManagement/UserManagementView"),
);
const AssetGroupsManagement = loadable(
  () => import("@components/settings/assetGroupsManagement/AssetGroupsManagement"),
);

const SettingsRoutes: FC<IRestrictedRoutesProps> = ({ menu, path }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const me = useSelector(meSelector);

  const isTrialSuspended = useCallback(
    () =>
      me?.client?.subscription?.type === "trial" &&
      me?.client?.subscription?.status === "suspended",
    [me],
  );

  const isPaidSuspended = useCallback(
    () =>
      me?.client?.subscription?.type === "paid" && me?.client?.subscription?.status === "suspended",
    [me],
  );

  const restrictedAccess = useCallback(() => {
    return (
      path === "/checkout/success" ||
      path === "/checkout/cancel" ||
      path === "/trial-ended" ||
      path === "/payment-failed" ||
      isTrialSuspended() ||
      isPaidSuspended()
    );
  }, [path, isPaidSuspended, isTrialSuspended]);

  const hasAccessPaidSuspendedUser = useCallback(() => {
    return (
      path === "/company" ||
      path === "/user-management" ||
      path === "/saved-replies" ||
      path === "/document-center" ||
      path === "/subscription" ||
      path === "/payment-failed" ||
      path === "/checkout/success" ||
      path === "/trial-ended" ||
      path === "/profile"
    );
  }, [path]);

  useEffect(() => {
    if (isTrialSuspended() && !hasAccessPaidSuspendedUser()) {
      navigate("/trial-ended");
    }

    if (isPaidSuspended() && !hasAccessPaidSuspendedUser()) {
      navigate("/payment-failed");
    }
  }, [
    me,
    path,
    navigate,
    isPaidSuspended,
    isTrialSuspended,
    restrictedAccess,
    hasAccessPaidSuspendedUser,
  ]);

  const redirectCallback = () => {
    window.location.href = `${process.env.BASE_URL}`;
  };

  const signOut = () => {
    Auth.currentUserInfo().then(user => {
      if (user.username) {
        Auth.signOut({ global: true })
          .then(() => {
            localStorage.removeItem("query");
            window.location.href = `${process.env.BASE_URL}/login`;
          })
          .catch(() => {
            deleteAllCookies();
            localStorage.removeItem("query");
            window.location.reload();
          });
      } else {
        deleteAllCookies();
        window.location.reload();
      }
    });
  };

  const getUserInfo = useCallback((): IMenuUserInfo => {
    return {
      username: `${me?.first_name || ""} ${me?.last_name || ""}`,
      iconUrl: me?.avatar || "",
    };
  }, [me]);

  const isMultipleAvailableClients = useMemo(() => {
    return me?.available_clients && me?.available_clients?.length > 1;
  }, [me]);

  const extraMenuItems = useMemo(() => {
    const items = getExtraMenuItems(t, signOut, getUserInfo());

    return isMultipleAvailableClients ? items : items.filter(item => item.id !== "change-client");
  }, [isMultipleAvailableClients, t, getUserInfo]);

  const restrictedMenuItems = useMemo(() => {
    const items = getRestrictedMenuItems(t, signOut, getUserInfo());

    return isMultipleAvailableClients ? items : items.filter(item => item.id !== "change-client");
  }, [isMultipleAvailableClients, t, getUserInfo]);

  const hasFooter = () => {
    return (
      path !== "/checkout/success" &&
      path !== "/checkout/cancel" &&
      path !== "/trial-ended" &&
      path !== "/payment-failed"
    );
  };

  const getMenuItems = () => {
    if (path === "/checkout/cancel" || path === "/trial-ended" || path === "/payment-failed")
      return [];
    return menu;
  };

  return (
    <AppLayout
      menu={getMenuItems()}
      hasHeader
      hasFooter={hasFooter()}
      basename="settings"
      defaultSelectedKey="user-management"
      extra={restrictedAccess() ? restrictedMenuItems : extraMenuItems}
      selectedKey={(path && path.replace(/\//g, "")) || "user-management"}
      avatarUrl={me?.client?.logo || undefined}
    >
      {{
        content: (
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={{
                    pathname: "asset-management",
                    search: window.location.search,
                  }}
                />
              }
            />
            <Route path="company" element={<Company />} />
            <Route path="user-management" element={<UserManagement isAdmin={!!me?.is_admin} />} />
            <Route
              path="subscription"
              element={
                <>
                  {me?.client?.subscription ? (
                    <Subscription />
                  ) : (
                    <NotFoundView type="403" redirectCallback={redirectCallback} />
                  )}
                </>
              }
            />
            <Route path="asset-management" element={<AssetManagement />} />
            <Route path="asset-groups" element={<AssetGroupsManagement />} />
            <Route path="asset-management/add-assets" element={<AddAssets />} />
            <Route path="profile" element={<Profile />} />
            <Route path="saved-replies" element={<SavedReplies />} />
            {!!me?.client?.zd_integration_enabled && (
              <Route path="integrations" element={<Integrations />} />
            )}
            <Route path="document-center" element={<DocumentCenter />} />
            <Route path="trial-ended" element={<TrialEnded />} />
            <Route path="payment-failed" element={<PaymentFailed />} />
            <Route path="checkout/success" element={<PaymentSuccess />} />
            <Route path="checkout/cancel" element={<PaymentCancel />} />
            <Route path="*" element={<Navigate to="asset-management" />} />
          </Routes>
        ),
      }}
    </AppLayout>
  );
};

export default SettingsRoutes;

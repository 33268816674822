import React from "react";

import {
  SignInCover as SignInCoverImg,
  SignInCoverX2,
  SignInCoverRegular as SignInCoverRegularImg,
  SignInCoverRegularX2,
} from "@bbdevcrew/bb_ui_kit_fe";

export const SignInCover: React.FC = () => (
  <img
    src={SignInCoverImg}
    srcSet={`${SignInCoverImg} 1x, ${SignInCoverX2} 2x`}
    width={440}
    height={356}
  />
);

export const SignInCoverRegular: React.FC = () => (
  <img
    src={SignInCoverRegularImg}
    srcSet={`${SignInCoverRegularImg} 1x, ${SignInCoverRegularX2} 2x`}
    width={440}
    height={342}
  />
);

import React from "react";
import dayjs from "dayjs";

import { INotification } from "@store/notifications/types";

import {
  UserPlusIcon,
  CalendarIcon,
  MentionIcon,
  NotificationsIcon,
  AlertTriangleFilledIcon,
  CloseIcon,
  CheckIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import { ApproveIcon } from "@assets/index";

export const MAX_UNREAD_COUNT = 99;

export const splitNotificationMessage = (text: string) => text.split(/(\{\{.*?\}\})/);

export function getNotificationIcon(
  type: INotification["type"],
  alertLevel: INotification["alert_level"],
) {
  const iconMap = {
    assigned_to: <UserPlusIcon />,
    publishing: <CalendarIcon />,
    internal_note: <MentionIcon />,
    reply_requested: <ApproveIcon />,
    reply_approved: <CheckIcon />,
    reply_rejected: <CloseIcon />,
  };

  if (type in iconMap) {
    return iconMap[type as keyof typeof iconMap];
  }

  if (alertLevel === "regular") {
    return <NotificationsIcon />;
  }

  return <AlertTriangleFilledIcon />;
}

export const customRelativeTime = (inputDate: dayjs.Dayjs) => {
  const now = dayjs();
  const diffInMinutes = now.diff(inputDate, "minute");
  const diffInHours = Math.ceil(diffInMinutes / 60);
  const diffInDays = Math.ceil(diffInMinutes / (60 * 24));

  if (diffInMinutes < 60) {
    return `${Math.max(1, diffInMinutes)}m`;
  } else if (diffInHours < 24) {
    return `${Math.max(1, diffInHours)}h`;
  } else if (diffInDays < 7) {
    return `${Math.max(1, diffInDays)}d`;
  } else if (diffInDays < 14) {
    return "1w";
  } else if (diffInDays < 21) {
    return "2w";
  } else if (diffInDays < 29) {
    return "3w";
  }

  return "4w";
};

import { ChartIcon, SafetyIcon, CommentIcon, AlertTriangleIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const maxChipCount = 4;

export const tagIcon = {
  universally_harmful: SafetyIcon,
  sensitive: SafetyIcon,
  non_harmful: ChartIcon,
  alerts: AlertTriangleIcon,
  customer_engagement: CommentIcon,
};

import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import {
  getPostFailureAction,
  getPostSuccessAction,
  getPostAdsFailureAction,
  getPostAdsSuccessAction,
  getPostsCSVSuccessAction,
} from "./actions";
import { getCommentsCSVFailureAction } from "@store/comments/actions";
import { getPostsFailureAction, getPostsSuccessAction } from "./actions";

import { GET_POSTS, GET_POSTS_CSV } from "./actionTypes";
import { GET_POST, GET_POST_ADS } from "./actionTypes";

import { RootState } from "..";
import { posts, insightsPosts } from "@utils/paths";
import { PostActions, IPostsAPIResponse } from "./types";
import { IAd } from "@components/_common/Social/Ads/Ads.type";
import { IPost } from "@bbdevcrew/bb_ui_kit_fe";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { getHeaders } from "@utils/headers";

const normalizePostAdsResponse = (data: IAd[]) =>
  data.map((item, index) => ({
    ...item,
    key: index,
  }));

export const getPosts = (action$: Observable<PostActions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(GET_POSTS)),
    switchMap(a => {
      return ajax
        .post<IPostsAPIResponse>(
          insightsPosts,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getPostsSuccessAction(data)),
          catchError(e => handleError(e, getPostsFailureAction)),
        );
    }),
  );

export const getPost = (action$: Observable<PostActions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(GET_POST)),
    switchMap(a => {
      return ajax
        .get<IPost>(
          `${posts}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getPostSuccessAction(data)),
          catchError(e => handleError(e, getPostFailureAction)),
        );
    }),
  );

export const getPostAds = (action$: Observable<PostActions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(GET_POST_ADS)),
    switchMap(a => {
      return ajax
        .get<{ items: IAd[] }>(
          `${posts}/${a.payload}/ads`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getPostAdsSuccessAction(normalizePostAdsResponse(data.items))),
          catchError(e => handleError(e, getPostAdsFailureAction)),
        );
    }),
  );

export const getPostsCSV = (action$: Observable<PostActions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(GET_POSTS_CSV)),
    switchMap(a => {
      return ajax<Blob>({
        method: "POST",
        url: `${posts}/csv`,
        body: a.payload,
        headers: getHeaders({
          Authorization: state$.value.auth.session.accessToken.jwtToken,
        }),
        responseType: "arraybuffer",
      }).pipe(
        map(e => e.response),
        map(data => getPostsCSVSuccessAction(data)),
        catchError(e => handleError(e, getCommentsCSVFailureAction)),
      );
    }),
  );

import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { SIGN_USER_UP } from "./actionTypes";
import { UserActionsType } from "./types";
import { signUserUpFailureAction, signUserUpSuccessAction } from "./actions";
import { users } from "@utils/paths";
import { IUserSignupAPIResponse } from "../user/types";
import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";

export const signUserUp = (action$: Observable<UserActionsType>) =>
  action$.pipe(
    filter(isOfType(SIGN_USER_UP)),
    switchMap(a => {
      return ajax.post<IUserSignupAPIResponse>(`${users}/signup`, a.payload, getHeaders({})).pipe(
        map(e => e.response),
        map(signUserUpSuccessAction),
        catchError(e => handleError(e, signUserUpFailureAction)),
      );
    }),
  );

import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { markAsReadAction } from "@store/notifications/actions";

import s from "./Notifications.module.less";

import { INotification } from "@store/notifications/types";
import {
  customRelativeTime,
  getNotificationIcon,
  splitNotificationMessage,
} from "./Notifications.helpers";

export const NotificationsItem: React.FC<INotification> = ({
  alert_level,
  message,
  created_at,
  id,
  status,
  navigation_link,
  type,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onItemClick = () => {
    dispatch(markAsReadAction(id));

    const params = type === "assigned_to" || type === "internal_note" ? "&openReply=true" : "";

    if (navigation_link) window.open(`${navigation_link}${params}`, "_blank");
  };

  return (
    <div className={s.bbNotificationsItem} onClick={onItemClick}>
      <div
        className={classNames(
          s.bbNotificationsItemIcon,
          s[`bbNotificationsItemIcon-${alert_level || "regular"}`],
          s[`bbNotificationsItemIcon-${type}`],
        )}
      >
        {getNotificationIcon(type, alert_level)}
      </div>
      <div className={s.bbNotificationsItemMessages}>
        {alert_level && (
          <span
            className={classNames(s.bbNotificationsItemText, {
              [s.bbNotificationsItemBoldText]: alert_level,
            })}
          >
            {t(`header:notifications:item:${alert_level}`)}
          </span>
        )}
        <div className={s.bbNotificationsItemDesc}>
          <span
            className={classNames(s.bbNotificationsItemText, {
              [s.bbNotificationsItemBoldText]: alert_level,
            })}
          >
            <span>
              {splitNotificationMessage(message).map((word, index) =>
                word.startsWith("{{") && word.endsWith("}}") ? (
                  <span
                    key={index}
                    className={classNames(s.bbNotificationsItemBoldText, {
                      [s.bbNotificationsItemBoldTextWithLink]: [
                        "{{conversation}}",
                        "{{post}}",
                        "{{internal note}}",
                        "{{reply}}",
                      ].includes(word),
                    })}
                  >
                    {word.replace(/[\{\{-\}\}]/g, "")}
                  </span>
                ) : (
                  word
                ),
              )}
            </span>

            <Tooltip
              title={dayjs(created_at).format("MMM DD, YYYY [at] HH:mm A")}
              overlayClassName={s.bbNotificationsDateTooltip}
            >
              <span className={s.bbNotificationsItemTime}>
                {customRelativeTime(dayjs(created_at))}
              </span>
            </Tooltip>
          </span>
        </div>
      </div>
      {status === "new" && <div className={s.bbNotificationsItemUnread} />}
    </div>
  );
};

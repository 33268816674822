import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Button, ChevronLeftIcon, debounce, Modal, Textarea } from "@bbdevcrew/bb_ui_kit_fe";
import { AIInsightsModalMessage } from "./AIInsightsModalMessage";
import { Grid } from "antd";
import { AIInsightsModalIcon } from "./AIInsightsModalIcon";

import { filtersOpenSelector } from "@store/filters/selectors";

import {
  aiInsightsInitialState,
  aiInsightsLoading,
  aiInsightsModalIdSelecor,
  aiInsightsModalMessagesSelecor,
  aiInsightsModalStateSelecor,
  aiInsightsModalTitleSelecor,
  aiInsightsPreviousThreadSelector,
} from "@store/aiInsights/selectors";

import {
  fetchThreadMessagesAction,
  fetchThreadsAction,
  sendThreadMessageAction,
  toggleOpenAIModalAction,
} from "@store/aiInsights/actions";

import s from "./AIInsightsModal.module.less";

import { AIInsightsModalStateEnum, IAIInsightsMessage } from "@store/aiInsights/types";

import { useGetSearchesIdForSavedFilters } from "./AIInsightsModal.helpers";

import AstraImg from "@assets/astra.png";
import { DrawIcon } from "@assets/index";
import { LoadingIcon, CloseIcon, SendIcon } from "@bbdevcrew/bb_ui_kit_fe";

const cx = classNames.bind(s);

export const AIInsightsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const textAreaRef = useRef<HTMLDivElement>(null);
  const hasCalledPostThreads = useRef(false);

  const isMobile = !screens.md;

  const [inputValue, setInputValue] = useState("");
  const [footerHeight, setFooterHeight] = useState<number>();

  const filtersPanelOpen = useSelector(filtersOpenSelector);
  const aiModalState = useSelector(aiInsightsModalStateSelecor);
  const aiModaltitle = useSelector(aiInsightsModalTitleSelecor);
  const aiModalId = useSelector(aiInsightsModalIdSelecor);
  const messages = useSelector(aiInsightsModalMessagesSelecor);
  const loading = useSelector(aiInsightsLoading);
  const initialState = useSelector(aiInsightsInitialState);
  const previousThread = useSelector(aiInsightsPreviousThreadSelector);

  const aiModalOpen = aiModalState !== AIInsightsModalStateEnum.Closed;

  const searchId = useGetSearchesIdForSavedFilters(aiModalOpen);

  const setAiModalState = useCallback(
    (open: AIInsightsModalStateEnum) => dispatch(toggleOpenAIModalAction(open)),
    [dispatch],
  );

  const postThreads = useCallback(id => dispatch(fetchThreadsAction(id)), [dispatch]);

  const getMessagesList = useCallback(
    id => dispatch(fetchThreadMessagesAction({ id, isPreviousThread: true })),
    [dispatch],
  );

  const sendMessage = useCallback(
    (id, message) =>
      dispatch(
        sendThreadMessageAction({
          id,
          message,
        }),
      ),
    [dispatch],
  );

  const offsetUpdateDebounced = useMemo(
    () => debounce(() => setFooterHeight(textAreaRef.current?.offsetHeight)),
    [],
  );

  useEffect(() => {
    if (aiModalOpen && searchId && !hasCalledPostThreads.current) {
      postThreads(searchId);
      hasCalledPostThreads.current = true;
    }
    // eslint-disable-next-line
  }, [aiModalOpen, searchId]);

  useEffect(() => {
    if (textAreaRef.current) {
      offsetUpdateDebounced();
    }
  }, [inputValue, offsetUpdateDebounced]);

  const onClose = () => {
    setAiModalState(AIInsightsModalStateEnum.Collapsed);
  };

  const onSendInput = () => {
    setInputValue("");
    sendMessage(aiModalId, inputValue);
  };

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setInputValue(event.target.value);

  return (
    <>
      {aiModalState === AIInsightsModalStateEnum.Collapsed && (
        <AIInsightsModalIcon
          className={cx(s.bbAIInsightsIcon, {
            [s.bbAIInsightsIconWithFilters]: filtersPanelOpen && !isMobile,
          })}
          onClick={() => setAiModalState(AIInsightsModalStateEnum.Expanded)}
        />
      )}

      <Modal
        open={aiModalOpen}
        hideCloseIcon
        hideFooter
        hideHeader
        mask={false}
        classes={{
          wrapper: cx(s.bbAIModalWrapper, {
            [s.bbAIModalWrapperCollapsed]: aiModalState === AIInsightsModalStateEnum.Collapsed,
          }),
          root: s.bbAIModalRoot,
          modal: s.bbAIModal,
          body: s.bbAIModalBody,
        }}
      >
        <div className={s.bbAIModalContent}>
          <div className={s.bbAIModalHeader}>
            <Button className={s.bbAIModalClose} onClick={onClose}>
              <CloseIcon />
            </Button>
            <div className={s.bbAIModalHeaderTexts}>
              <div>
                <img src={AstraImg} className={s.bbAIModalHeaderIcon} />
                <span>{t("components:aiInsights:header:title")}</span>
              </div>
              <p>{aiModaltitle}</p>
              {!initialState && (
                <Button
                  className={s.bbAIModalHeaderStartButton}
                  onClick={() => postThreads(searchId)}
                  _size="sm"
                >
                  <DrawIcon /> <span>{t("components:aiInsights:start_new_button")}</span>
                </Button>
              )}
            </div>
          </div>

          <div
            className={cx(s.bbAIModalInteraction, {
              [s.bbAIModalInteractionWithPadding]: !initialState,
            })}
            style={{
              height: !initialState
                ? `calc(100% - 140px - ${footerHeight}px)`
                : `calc(100% - 100px - ${footerHeight}px)`,
            }}
          >
            <div className={s.bbAIModalMessagesList}>
              {messages.map((message: IAIInsightsMessage, index: number) => (
                <AIInsightsModalMessage
                  message={message.message}
                  key={`${message.id}-${index}`}
                  id={aiModalId}
                  isOwned={message.owner === "user"}
                  actions={message.call_to_actions_options}
                  loading={loading}
                />
              ))}
              {loading && (
                <div className={s.bbAIModalLoading}>
                  <LoadingIcon />
                  <p>{t("components:aiInsights:loading")}</p>
                </div>
              )}
              {previousThread && (
                <div
                  className={s.bbAIModalPreviousThread}
                  onClick={() => getMessagesList(previousThread.id)}
                >
                  <ChevronLeftIcon />
                  <div className={s.bbAIModalPreviousThreadText}>
                    <span className={s.bbAIModalPreviousThreadTitle}>
                      {t("components:aiInsights:previousThread")}
                    </span>
                    <span>{previousThread.title}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={s.bbAIModalInputWrapper} ref={textAreaRef}>
            <Textarea
              value={inputValue}
              className={s.bbAIModalInput}
              placeholder={t("components:aiInsights:input:placeholder")}
              onInput={onInputChange}
              autoSize={{ minRows: 1 }}
            />
            <Button
              className={s.bbAIModalSendButton}
              onClick={onSendInput}
              disabled={loading || !inputValue}
            >
              <SendIcon />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

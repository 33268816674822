import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Tooltip, ChipAction } from "@bbdevcrew/bb_ui_kit_fe";

import { AiHighlightTagsProps, AITagProps } from "./AiHighlightTags.type";

import s from "./AiHighlightTags.module.less";

import { ProcessorIcon } from "@bbdevcrew/bb_ui_kit_fe";

const Tag = ({ tag, customAction: CustomActionComponent }: AITagProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={tag.tooltip}>
      <ChipAction
        _size="xs"
        leftIcon={ProcessorIcon}
        text={t(`components:comments:aiHighlightFlags:${tag.id}`)}
        className={classNames(s.bbAiHightlightTag, s[`bbAiHightlightTagFlag-${tag.id}`], {
          [s.bbAiHightlightTagWithTooltip]: tag.tooltip,
        })}
        iconClassName={classNames(s.bbAiHightlightTag, s[`bbAiHightlightTagIcon-${tag.id}`], {
          [s.bbAiHightlightTagWithTooltip]: tag.tooltip,
        })}
        actionIcon={!!CustomActionComponent ? <CustomActionComponent tag={tag} /> : null}
      />
    </Tooltip>
  );
};

const AiHighlightTags: FC<AiHighlightTagsProps> = ({ tags, className, customAction }) => (
  <>
    {tags?.map(tag => (
      <span className={className} key={tag.id}>
        <Tag tag={tag} customAction={customAction} />
      </span>
    ))}
  </>
);

export default AiHighlightTags;

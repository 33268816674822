/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable, from } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError, exhaustMap } from "rxjs/operators";

import { RootState } from "..";
import { AuthActionsType } from "./types";
import {
  GET_MFA,
  SET_MFA,
  SETUP_TOTP,
  VERIFY_TOTP,
  CURRENT_SESSION,
  REFRESH_CURRENT_SESSION,
  CURRENT_AUTHENTICATE_USER,
} from "./actionTypes";
import {
  getCurrentSessionFailureAction,
  getCurrentSessionSuccessAction,
  refreshCurrentSessionFailureAction,
  refreshCurrentSessionSuccessAction,
  currentAuthenticateUserFailureAction,
  currentAuthenticateUserSuccessAction,
  setMFASuccessAction,
  setMFAFailureAction,
  getMFASuccessAction,
  getMFAFailureAction,
  setupTOTPSuccessAction,
  setupTOTPFailureAction,
  verifyTOTPSuccessAction,
  verifyTOTPFailureAction,
} from "./actions";
import { Auth } from "aws-amplify";
import { i18nextInstance } from "../../App";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";

const api = {
  currentAuthenticatedUser: () => {
    const user = Auth.currentAuthenticatedUser()
      .then(u => u)
      .catch(err => err);
    return from(user);
  },
  currentSession: () => {
    const currentSession = Auth.currentSession()
      .then(session => session)
      .catch(err => {
        if (err.code === "NotAuthorizedException") window.location.href = `${process.env.BASE_URL}`;
        else return err;
      });
    return from(currentSession);
  },
  refreshSession: (session: any, user: any) => {
    const refreshCurrentSession = new Promise((resolve, reject) => {
      user.refreshSession(session.refreshToken, (err: any, refreshedSession: any) => {
        if (err) {
          if (err.code === "NotAuthorizedException")
            window.location.href = `${process.env.BASE_URL}`;
          else reject(err);
        }
        resolve(refreshedSession);
      });
    });
    return from(refreshCurrentSession);
  },
  getMFA: (user: any) => {
    const getMFA = Auth.getPreferredMFA(user)
      .then(data => data)
      .catch(err => err);
    return from(getMFA);
  },
  setMFA: (user: any, method: "TOTP" | "SMS" | "NOMFA") => {
    const setMFA = Auth.setPreferredMFA(user, method)
      .then(data => data)
      .catch(err => err);
    return from(setMFA);
  },
  setupTOTP: (user: any) => {
    const setupTOTP = Auth.setupTOTP(user)
      .then(data => data)
      .catch(err => err);
    return from(setupTOTP);
  },
  verifyTotpToken: (user: any, challengeAnser: string) => {
    const verifyTotpToken = new Promise<void>((resolve, reject) => {
      Auth.verifyTotpToken(user, challengeAnser)
        .then(() => {
          resolve();
        })
        .catch(err => {
          // Custom mismatch error
          if (err && err.message.includes("mismatch")) {
            reject({
              ...err,
              code: i18nextInstance.t("components:profileUser:mfa:mismatch:title"),
              message: i18nextInstance.t("components:profileUser:mfa:mismatch:message"),
            });
          }
          reject({
            ...err,
            code: i18nextInstance.t("generic:error"),
            message: i18nextInstance.t("components:profileUser:mfa:genericError"),
          });
        });
    });
    return from(verifyTotpToken);
  },
};

export const currentAuthenticatedUser = (action$: Observable<AuthActionsType>) =>
  action$.pipe(
    filter(isOfType(CURRENT_AUTHENTICATE_USER)),
    switchMap(() => {
      return api.currentAuthenticatedUser().pipe(
        map(user => currentAuthenticateUserSuccessAction(user)),
        catchError(e => handleError(e, currentAuthenticateUserFailureAction, "auth")),
      );
    }),
  );

export const currentSession = (action$: Observable<AuthActionsType>) =>
  action$.pipe(
    filter(isOfType(CURRENT_SESSION)),
    switchMap(() => {
      return api.currentSession().pipe(
        map(session => getCurrentSessionSuccessAction(session)),
        catchError(e => handleError(e, getCurrentSessionFailureAction, "auth")),
      );
    }),
  );

export const refreshCurrentSession = (
  action$: Observable<AuthActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(REFRESH_CURRENT_SESSION)),
    exhaustMap(() => {
      return from(api.refreshSession(state$.value.auth.session, state$.value.auth.user)).pipe(
        map(session => refreshCurrentSessionSuccessAction(session)),
        catchError(e => handleError(e, refreshCurrentSessionFailureAction, "auth")),
      );
    }),
  );

export const setMFAEpic = (
  action$: Observable<AuthActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(SET_MFA)),
    switchMap(a => {
      return api.setMFA(state$.value.auth.user, a.payload).pipe(
        map(session => setMFASuccessAction(session)),
        catchError(e => handleError(e, setMFAFailureAction, "auth")),
      );
    }),
  );

export const getMFAEpic = (
  action$: Observable<AuthActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_MFA)),
    switchMap(() => {
      return api.getMFA(state$.value.auth.user).pipe(
        map(session => getMFASuccessAction(session)),
        catchError(e => handleError(e, getMFAFailureAction, "auth")),
      );
    }),
  );

export const setupTOTPEpic = (
  action$: Observable<AuthActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(SETUP_TOTP)),
    switchMap(() => {
      return api.setupTOTP(state$.value.auth.user).pipe(
        map(code => setupTOTPSuccessAction(code)),
        catchError(e => handleError(e, setupTOTPFailureAction, "auth")),
      );
    }),
  );

export const verifyTOTPEpic = (
  action$: Observable<AuthActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(VERIFY_TOTP)),
    switchMap(a => {
      return api.verifyTotpToken(state$.value.auth.user, a.payload).pipe(
        map(() => verifyTOTPSuccessAction()),
        catchError(e => handleError(e, verifyTOTPFailureAction, "auth")),
      );
    }),
  );

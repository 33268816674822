import React, { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";

import {
  fetchingCommentHistorySelector,
  getCommentHistorySelector,
} from "@store/comments/selectors";

import { getCommentHistoryAction } from "@store/comments/actions";

import s from "./InternalNotes.module.less";

import { splitBoldText } from "./InternalNotes.helpers";
interface ICommentHistoryProps {
  commentId: string;
}

export const CommentHistory: FC<ICommentHistoryProps> = ({ commentId }) => {
  const dispatch = useDispatch();

  const commentHistory = useSelector(getCommentHistorySelector);
  const fetchingCommentHistory = useSelector(fetchingCommentHistorySelector);

  const getCommentHistory = useCallback(
    id => {
      dispatch(getCommentHistoryAction(id));
    },
    [dispatch],
  );

  useEffect(() => {
    getCommentHistory(commentId);
  }, [commentId, getCommentHistory]);

  return (
    <div
      className={classNames(s.bbNotesHistory, {
        [s.bbNotesHistoryLoading]: fetchingCommentHistory,
      })}
    >
      {fetchingCommentHistory ? (
        <Loading />
      ) : (
        commentHistory?.map((item, index) => (
          <div key={index} className={s.bbNotesHistoryItem}>
            <span className={s.bbNotesHistoryDate}>{item.timestamp_pretty}</span>

            <span>{splitBoldText(item.phrase)}</span>
          </div>
        ))
      )}
    </div>
  );
};

import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";
import { StateObservable } from "redux-observable";

import {
  getTrendingHashtagsFiltersSuccessAction,
  getTrendingHashtagsSuccessAction,
  getTrendingHashtagsFailureAction,
  getTrendingHashtagsFiltersFailureAction,
  getTrendingHashtagVideosSuccessAction,
  getTrendingHashtagVideosFailureAction,
} from "./actions";
import {
  TIKTOK_GET_TRENDING_HASHTAGS,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS,
  TIKTOK_GET_TRENDING_HASHTAG_VIDEOS,
} from "./actionTypes";
import {
  TiktokHashtagsActionsType,
  ITrendingHashtagsFilters,
  ITrendingHashtagsItem,
  ITrendingHashtagsVideoPayload,
} from "./types";
import { RootState } from "..";

import { trendingHashtags, trendingHashtagsFilters } from "@utils/paths";
import { getAuthAPIHeaders } from "@utils/headers";
import { handleError, generateQueryParams } from "@bbdevcrew/bb_ui_kit_fe";

export const getTrendingHashtagVideos = (
  action$: Observable<TiktokHashtagsActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(TIKTOK_GET_TRENDING_HASHTAG_VIDEOS)),
    switchMap(({ payload: { hashtagIds, filters } }) => {
      const hashTagIdParams = hashtagIds.map(hashtagId => `hashtagIds=${hashtagId}`).join("&");

      const getVideosUrl =
        `${trendingHashtags}/videos?${hashTagIdParams}&` +
        `countryId=${filters.countryId}&` +
        `dateRangeId=${filters.dateRangeId}`;

      return ajax
        .get<{ items: ITrendingHashtagsVideoPayload[] }>(getVideosUrl, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(videos => {
            return getTrendingHashtagVideosSuccessAction(videos.items);
          }),
          catchError(e => handleError(e, getTrendingHashtagVideosFailureAction)),
        );
    }),
  );
};

export const getTrendingHashtags = (
  action$: Observable<TiktokHashtagsActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(TIKTOK_GET_TRENDING_HASHTAGS)),
    switchMap(a => {
      return ajax
        .get<
          ITrendingHashtagsItem[]
        >(`${trendingHashtags}${generateQueryParams(a.payload)}`, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(data => getTrendingHashtagsSuccessAction(data, a.payload)),
          catchError(e => handleError(e, getTrendingHashtagsFailureAction)),
        );
    }),
  );
};

export const getTrendingHashtagsFilters = (
  action$: Observable<TiktokHashtagsActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(TIKTOK_GET_TRENDING_HASHTAGS_FILTERS)),
    switchMap(() => {
      return ajax
        .get<ITrendingHashtagsFilters>(trendingHashtagsFilters, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(data => getTrendingHashtagsFiltersSuccessAction(data)),
          catchError(e => handleError(e, getTrendingHashtagsFiltersFailureAction)),
        );
    }),
  );
};

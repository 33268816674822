import React from "react";
import cn from "classnames/bind";

import { Actions } from "./actions/Actions";
import MostCommentedPostCard from "../../../_common/postCard/PostCard";
import MostCommentedPostFooter from "./footer/MostCommentedPostFooter";

import s from "./MostCommentedPost.module.less";

import {
  getPostSubtext,
  getPostImageUrl,
  PostPlacementEnum,
  getPlatformIconBasicColor,
} from "@bbdevcrew/bb_ui_kit_fe";
import { IMostCommentedPostProps } from "./MostCommentedPost.type";

const cx = cn.bind(s);

const MostCommentedPost: React.FC<IMostCommentedPostProps> = ({
  postData,
  onPostClick,
  onSeeAdsClick,
  onSeePostClick,
  className,
  showPostIdAndLink,
}) => {
  const { post } = postData;

  const generateCardFooter = (width: number) => (
    <MostCommentedPostFooter
      showPostIdAndLink={showPostIdAndLink}
      chartWidth={width}
      postData={postData}
    />
  );

  const getPlatformIcon = (platformId: string) => {
    return (
      <span className={cx(s.bbPlatformIcon, `bbPlatformIcon-${platformId}`)}>
        {getPlatformIconBasicColor(platformId)}
      </span>
    );
  };

  const showHeaderActions = !!(onSeePostClick && onSeeAdsClick);

  return (
    <MostCommentedPostCard
      message={post.message}
      postTypeText={getPostSubtext(post)}
      renderCardFooter={generateCardFooter}
      stonlyTarget={"intelligence-overview__most-commented-posts--" + postData.key}
      pageImageProps={{
        pageName: post.page_name,
        pageImage: getPlatformIcon(post.platform_type.id),
      }}
      postImageProps={{
        imageUrl: getPostImageUrl(post) || "",
        isPortraitImg: post.post_placement === PostPlacementEnum.STORY,
        onImageClick: () => onPostClick?.(post.object_id),
        showTextAsImage: post.platform_type.id === "twitter" && !getPostImageUrl(post),
      }}
      className={className}
      headerActionItem={
        showHeaderActions ? (
          <Actions
            showAdsOption
            onSeePostClick={() => onSeePostClick?.(postData)}
            onSeeAdsClick={() => onSeeAdsClick?.(post.id)}
          />
        ) : undefined
      }
    />
  );
};

export default MostCommentedPost;

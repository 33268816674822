import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { MeActionsType, IMe, IClient } from "./types";
import { RootState } from "..";
import {
  deleteMeAvatarFailureAction,
  deleteMeAvatarSuccessAction,
  getMeFailureAction,
  getMeSuccessAction,
  patchMeClientFailureAction,
  patchMeClientSuccessAction,
  patchMeSessionClientSuccessAction,
  patchMeSessionClientFailureAction,
  patchMeEmailFailureAction,
  patchMeEmailSuccessAction,
  patchMeFailureAction,
  patchMeSuccessAction,
  postMeEmailFailureAction,
  postMeEmailSuccessAction,
  postMePasswordFailureAction,
  postMePasswordSuccessAction,
} from "./actions";
import {
  GET_ME,
  PATCH_DEMO_STORY,
  PATCH_DEMO_MODE,
  PATCH_ME,
  PATCH_ME_CLIENT,
  PATCH_ME_SESSION_CLIENT,
  POST_ME_EMAIL,
  PATCH_ME_EMAIL,
  POST_ME_PASSWORD,
  DELETE_ME_AVATAR,
} from "./actionTypes";

import { me } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";

// GET ME
export const getMe = (action$: Observable<MeActionsType>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(GET_ME)),
    switchMap(() => {
      return ajax
        .get<IMe>(
          me,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getMeSuccessAction(data)),
          catchError(e => handleError(e, getMeFailureAction)),
        );
    }),
  );

// PATCH ME
export const patchMe = (action$: Observable<MeActionsType>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(PATCH_ME)),
    switchMap(a => {
      return ajax
        .patch<IMe>(
          me,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => patchMeSuccessAction(data)),
          catchError(e => handleError(e, patchMeFailureAction)),
        );
    }),
  );

// PATCH ME CLIENT
export const patchMeClientEpic = (
  action$: Observable<MeActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PATCH_ME_CLIENT)),
    switchMap(a => {
      return ajax
        .patch<IClient>(
          `${me}/client`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(client => patchMeClientSuccessAction(client)),
          catchError(e => {
            if (a.payload.auto_action_config) {
              return [];
            }
            return handleError(e, patchMeClientFailureAction);
          }),
        );
    }),
  );

// PATCH ME SESSION CLIENT
export const patchMeSessionClientEpic = (
  action$: Observable<MeActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PATCH_ME_SESSION_CLIENT)),
    switchMap(a => {
      return ajax
        .patch<IMe>(
          `${me}/session-client/${a.payload}`,
          {},
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => patchMeSessionClientSuccessAction(data)),
          catchError(e => handleError(e, patchMeSessionClientFailureAction)),
        );
    }),
  );

// POST ME EMAIL
export const postMeEmailEpic = (
  action$: Observable<MeActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_ME_EMAIL)),
    switchMap(() => {
      return ajax
        .post(
          `${me}/email-change-confirmation`,
          {},
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => postMeEmailSuccessAction()),
          catchError(e => handleError(e, postMeEmailFailureAction)),
        );
    }),
  );

// PATCH ME EMAIL
export const patchMeEmailEpic = (
  action$: Observable<MeActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PATCH_ME_EMAIL)),
    switchMap(a => {
      return ajax
        .patch<IMe>(
          `${me}/email`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => patchMeEmailSuccessAction(data)),
          catchError(e => handleError(e, patchMeEmailFailureAction)),
        );
    }),
  );

// POST ME PASSWORD
export const postMePasswordEpic = (
  action$: Observable<MeActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_ME_PASSWORD)),
    switchMap(() => {
      return ajax
        .post(
          `${me}/password`,
          {},
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => postMePasswordSuccessAction()),
          catchError(e => handleError(e, postMePasswordFailureAction)),
        );
    }),
  );

// DELETE ME AVATAR
export const deleteMeAvatarEpic = (
  action$: Observable<MeActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_ME_AVATAR)),
    switchMap(() => {
      return ajax
        .delete<IMe>(
          `${me}/avatar`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => deleteMeAvatarSuccessAction(data)),
          catchError(e => handleError(e, deleteMeAvatarFailureAction)),
        );
    }),
  );

// PATCH DEMO MODE
export const patchDemoMode = (
  action$: Observable<MeActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PATCH_DEMO_MODE)),
    switchMap(a => {
      return ajax
        .patch<IMe>(
          `${me}/demo-mode`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => patchMeSuccessAction(data)),
          catchError(e => handleError(e, patchMeFailureAction)),
        );
    }),
  );

// PATCH DEMO STORY
export const patchDemoStory = (
  action$: Observable<MeActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PATCH_DEMO_STORY)),
    switchMap(a => {
      return ajax
        .patch<IMe>(
          `${me}/demo-story`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => patchMeSuccessAction(data)),
          catchError(e => handleError(e, patchMeFailureAction)),
        );
    }),
  );

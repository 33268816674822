import { useTranslation } from "react-i18next";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { DropdownMenu, DropdownMenuItem, DropdownMenuTrigger } from "@bbdevcrew/bb_ui_kit_fe";

import { getDashboardCSVAction } from "@store/dashboard/actions";

import { IFilters } from "@store/filters/types";
import { AgentPerformanceReportType } from "@store/dashboard/types";

import { DownloadIcon } from "@assets/index";

export const DownloadReportButton: React.FC<{ filters: IFilters }> = ({ filters }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const downloadCSV = useCallback(
    (report_type: AgentPerformanceReportType) => {
      dispatch(getDashboardCSVAction(filters, report_type));
    },
    [dispatch, filters],
  );

  return (
    <>
      <DropdownMenu
        trigger={({ open }) => (
          <DropdownMenuTrigger
            open={open}
            label={
              <>
                <DownloadIcon />
              </>
            }
          />
        )}
      >
        {closeMenu => (
          <>
            <DropdownMenuItem
              onClick={() => {
                downloadCSV("summary");
                closeMenu();
              }}
            >
              {t("components:agentPerformance:export:summary")}
            </DropdownMenuItem>

            <DropdownMenuItem
              onClick={() => {
                downloadCSV("detailed");
                closeMenu();
              }}
            >
              {t("components:agentPerformance:export:detailed")}
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenu>
    </>
  );
};

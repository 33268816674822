import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { StateObservable } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import { RootState } from "../";
import * as actions from "./actions";
import { DocumentActions, IDocumentPayload, IDocumentUrlPayload } from "./types";
import { document, documentUrl } from "@utils/paths";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { getHeaders } from "@utils/headers";

export const getDocument = (
  action$: Observable<DocumentActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getDocumentsAction)),
    switchMap(() =>
      ajax
        .get<IDocumentPayload["items"]>(
          document,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getDocumentsSuccess(data)),
          catchError(err => handleError(err, actions.getDocumentsFailure)),
        ),
    ),
  );

export const getDocumentUrl = (
  action$: Observable<DocumentActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getDocumentsUrlAction)),
    switchMap(action =>
      ajax
        .get<IDocumentUrlPayload>(
          documentUrl(action.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getDocumentsUrlSuccess(data)),
          catchError(err => handleError(err, actions.getDocumentsUrlFailure)),
        ),
    ),
  );

import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import s from "./ListeningChip.module.less";

import { HeadphoneIcon, ChipIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IListeningChipProps {
  className?: string;
  iconClassName?: string;
  wrapperClassName?: string;
}

const ListeningChip: FC<IListeningChipProps> = ({ className, iconClassName, wrapperClassName }) => {
  const { t } = useTranslation();

  return (
    <ChipIcon
      _size="xs"
      icon={HeadphoneIcon}
      iconPosition="left"
      text={t("components:comments:listeningChip:title")}
      className={classNames(s.bbPostPreviewListeningChip, className)}
      iconClassName={classNames(s.bbPostPreviewListeningChipIcon, iconClassName)}
      wrapperClassName={classNames(s.bbPostPreviewListeningChipWrapper, wrapperClassName)}
    />
  );
};

export default ListeningChip;

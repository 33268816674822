import React from "react";

import { getBreakdownChartBarColor } from "@components/_common/BreakdownChart/helpers";
import {
  IDashboardEngagementsPlatform,
  IDashboardFollowersPlatform,
  IDashboardImpressionsPlatform,
} from "@store/dashboard/types";

import { UserIcon, ThumbsUpIcon, EyeIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const normalizePlatformData = <
  T extends
    | IDashboardFollowersPlatform
    | IDashboardImpressionsPlatform
    | IDashboardEngagementsPlatform,
>(
  platformData: T[],
  valueKey: keyof T,
  growthKey: keyof T,
) => {
  return platformData
    .map((item, index) => {
      return {
        value: Number(item[valueKey]),
        rawName: item.id,
        name: item.name,
        color: getBreakdownChartBarColor(item.id, index),
        change_percentage: Number(item[growthKey]),
        percentage_of_total: Number(item.percentage),
      };
    })
    .sort((a, b) => (b.rawName > a.rawName ? -1 : 1));
};

export const PROFILE_SECTION_ICONS = {
  followers: <UserIcon />,
  impressions: <EyeIcon />,
  engagements: <ThumbsUpIcon />,
};

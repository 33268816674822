import { useSelector } from "react-redux";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import AgentPerformanceWidgets from "./AgentPerformanceWidgets";
import Breadcrumbs from "@components/_common/breadcrumbs";
import { DownloadReportButton } from "./DownloadReportButton";

import { meSelector } from "@store/me/selectors";
import { filterDataSelector } from "@store/filters/selectors";

import { getEngageConfig, getManagedAgentsConfig } from "./AgentPerformance.config";
import { useDashboardData } from "@utils/useDashboardData";

import s from "./AgentPerformance.module.less";

import { OverviewComponentType } from "@store/modules/types";

export const AgentPerformance: FC = () => {
  const { t } = useTranslation();

  const me = useSelector(meSelector);
  const filters = useSelector(filterDataSelector);

  const components = useMemo<OverviewComponentType[]>(() => ["explorer_agent_performance"], []);

  const { data, isFetching } = useDashboardData(components);

  const config = me?.client?.is_care_managed ? getManagedAgentsConfig(t) : getEngageConfig(t);

  return data ? (
    <div className={s.bbAgentPerformanceWrapper}>
      <div className={s.bbAgentPerformanceHeader}>
        <Breadcrumbs />
        <DownloadReportButton filters={filters || {}} />
      </div>
      <AgentPerformanceWidgets
        loading={isFetching}
        data={data?.explorer_agent_performance}
        config={config}
        filters={filters || {}}
      />
    </div>
  ) : null;
};

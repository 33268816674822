import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { predefinedFiltersSelector } from "@store/savedFilters/selectors";
import { getPredefinedFiltersAction } from "@store/savedFilters/actions";

export const useSavedFiltersList = () => {
  const dispatch = useDispatch();

  const predefinedFilters = useSelector(predefinedFiltersSelector);

  useEffect(() => {
    dispatch(getPredefinedFiltersAction());
  }, [dispatch]);

  return useMemo(() => {
    return predefinedFilters.filter(({ id }) => id === "savedFilters")[0]?.items || [];
  }, [predefinedFilters]);
};

import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CommentAction } from "@bbdevcrew/bb_ui_kit_fe";

import { CommentClickFunctionType } from "../CommentActions.type";
import { useKeyPress, keyCodes, IComment } from "@bbdevcrew/bb_ui_kit_fe";

import { ArchiveIcon, ArchiveFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IActionArchiveProps {
  label?: string;
  className?: string;
  comment: IComment;
  onArchiveClick?: CommentClickFunctionType;
  useShortcuts?: boolean;
}

const ActionArchive: FC<IActionArchiveProps> = ({
  label,
  comment,
  useShortcuts,
  className = "",
  onArchiveClick,
}) => {
  const { t } = useTranslation();

  const tooltipTitle: string | JSX.Element = useMemo(() => {
    if (comment.reply.is_ignored) {
      if (comment.tooltips?.reply_ignore) {
        return (
          <>
            <div>{comment.tooltips?.reply_ignore}</div>
            <div>{t("components:comments:tooltips:clickToRemoveArchived")}</div>
          </>
        );
      } else return t("components:comments:tooltips:archiveRemove");
    } else {
      return t("components:comments:tooltips:archive");
    }
  }, [comment, t]);

  const onClickArchive = () => {
    onArchiveClick && onArchiveClick(comment.id, !comment.reply.is_ignored);
  };

  if (useShortcuts) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useKeyPress(keyCodes.a, onClickArchive);
  }

  return (
    <CommentAction
      type="primary"
      className={className}
      onClick={onClickArchive}
      active={comment.reply.is_ignored}
      loading={comment.actionsProcessing?.includes("ignore")}
      tooltipProps={{ title: tooltipTitle }}
    >
      {comment.reply.is_ignored ? <ArchiveFilledIcon /> : <ArchiveIcon />}
      {label}
    </CommentAction>
  );
};

export default ActionArchive;

import React from "react";

import { ITagOption } from "@store/me/types";
import { IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./tagOption.module.less";

import { PremiumIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const tagToOption = (tagOpt: ITagOption) => {
  const option: IObjectOption = {
    id: tagOpt.value,
    label: tagOpt.title,
    options: tagOpt.children?.map(tagToOption),
  };

  if (tagOpt.is_premium) {
    option.rightLabel = (
      <span className={s.bbTagPremiumIcon}>
        <PremiumIcon />
      </span>
    );
  }

  return option;
};

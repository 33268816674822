import React from "react";
import classNames from "classnames";

import s from "./ReplySendButton.module.less";

import { IApprovalItem, IUserApprovalOption } from "./ReplySendButton.types";
import { getUserInitials } from "@bbdevcrew/bb_ui_kit_fe";
import { TFunction } from "react-i18next";

export const getApprovalUser = (users: IUserApprovalOption[]) => {
  return users.map(user => ({
    id: user.id,
    label: user.label,
    leftLabel: (
      <div key={user.id}>
        {user.picture ? (
          <img alt="user_pic" src={user.picture} className={s.bbUserOptionAvatar} />
        ) : (
          <div className={s.bbUserOptionAvatar}>{getUserInitials(user.label)}</div>
        )}
      </div>
    ),
  }));
};

export const getApprovalButtonText = (value: IApprovalItem[], t: TFunction) => {
  if (!value.length) return t("components:reply:sumbitForApprovalAny");

  return (
    <span className={s.bbApprovalButton}>
      {t("components:reply:sumbitForApproval")}
      <div className={s.bbApprovalButtonAvatar}>
        {value.map((v, index) =>
          index <= 1 ? (
            <span
              style={{
                zIndex: value.length - index,
              }}
              className={classNames(s.bbUserOptionAvatar, s.bbUserAvatar)}
              key={v.id}
            >
              {getUserInitials(v.label || "")}
            </span>
          ) : index === 2 ? (
            <span key={v.id} className={classNames(s.bbUserOptionAvatar, s.bbUserAvatar)}>
              +{value.length - 2}
            </span>
          ) : null,
        )}
      </div>
    </span>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import { useBatchMode } from "./BatchMode";

import s from "./BatchMode.module.less";

import { CloseIcon, BatchStackIcon } from "@bbdevcrew/bb_ui_kit_fe";

export default function BatchModeButton() {
  const { isBatchModeActive, toggleBatchMode } = useBatchMode();
  const { t } = useTranslation();

  return (
    <Button _size="sm" onClick={toggleBatchMode} className={s.bbBatchModeButton}>
      {isBatchModeActive ? (
        <>
          <CloseIcon />
          {t("containers:batchMode:button:exitBatchMode")}
        </>
      ) : (
        <>
          <BatchStackIcon />
          {t("containers:batchMode:button:batchMode")}
        </>
      )}
    </Button>
  );
}

import { t } from "../App";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { meSelector } from "@store/me/selectors";
import { deleteAllCookies } from "@bbdevcrew/bb_ui_kit_fe";
import { IMenuUserInfo, getExtraMenuItems } from "./extraMenu";

export const useRoutes = (path: string) => {
  const me = useSelector(meSelector);

  useEffect(() => {
    if (
      me?.client?.subscription?.type === "trial" &&
      me?.client?.subscription?.status === "suspended"
    ) {
      window.location.href = `${process.env.BASE_URL}/settings/trial-ended`;
    }

    if (
      me?.client?.subscription?.type === "paid" &&
      me?.client?.subscription?.status === "suspended"
    ) {
      window.location.href = `${process.env.BASE_URL}/settings/payment-failed`;
    }
  }, [me, path]);

  const redirectCallback = () => {
    window.location.href = `${process.env.BASE_URL}`;
  };

  const signOut = () => {
    Auth.currentUserInfo().then(user => {
      if (user.username) {
        Auth.signOut({ global: true })
          .then(() => {
            localStorage.removeItem("query");
            window.location.href = `${process.env.BASE_URL}`;
          })
          .catch(() => {
            deleteAllCookies();
            localStorage.removeItem("query");
            window.location.reload();
          });
      } else {
        deleteAllCookies();
        window.location.reload();
      }
    });
  };

  const extraMenuItems = useMemo(() => {
    const getUserInfo = (): IMenuUserInfo => {
      return {
        username: `${me?.first_name || ""} ${me?.last_name || ""}`,
        iconUrl: me?.avatar || "",
      };
    };

    const items = getExtraMenuItems(t, signOut, getUserInfo());

    const isMultipleAvailableClients = me?.available_clients && me?.available_clients.length > 1;

    return isMultipleAvailableClients ? items : items.filter(item => item.id !== "change-client");
  }, [me]);

  return {
    redirectCallback,
    extraMenuItems,
  };
};

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Avatar } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ListeningChip from "../ListeningChip/ListeningChip";
import { Tooltip, Loading, Dropdown } from "@bbdevcrew/bb_ui_kit_fe";
import PostViewTooltip from "./PostViewTooltip";
import PostModal from "../Social/Post/PostModal";

import { postSelector, getPostProcessingSelector } from "@store/posts/selectors";

import { clearPostAction, getPostAction } from "@store/posts/actions";

import s from "./PostView.module.less";

import {
  camelize,
  getPlatformIconBasicColor,
  isYoutubePlatform,
  extractDate,
  PostPlacementEnum,
  getCountriesFlags,
} from "@bbdevcrew/bb_ui_kit_fe";
import { isEarnedMessage } from "../CommentsGrid/helpers";
import { getMediaTypeIcon } from "../CommentDetail/helpers";
import { IPostPreviewProps } from "../CommentDetail/CommentDetail.type";
import { MAX_SHOW_FLAG_COUNT } from "../CommentDetail/CommentDetailsConfig";

import { CommentIcon, MoreActionsIcon, PostIcon } from "@bbdevcrew/bb_ui_kit_fe";

const PostView: FC<IPostPreviewProps> = ({
  post,
  post_id,
  type = "default",
  imageSize = 72,
  onExploreClick,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);

  const _post = useSelector(postSelector);
  const isPostLoading = useSelector(getPostProcessingSelector);
  const postData = post || _post;

  const isYTPost = postData?.platform_type.id
    ? isYoutubePlatform(postData?.platform_type.id)
    : false;

  const fetchPost = useCallback(id => dispatch(getPostAction(id)), [dispatch]);

  useEffect(() => {
    dispatch(clearPostAction());

    if (post_id && !post) {
      fetchPost(post_id);
    }
    // eslint-disable-next-line
  }, []);

  const onOpenPostClick = () => {
    setIsPostModalOpen(true);
    setIsDropdownOpen(false);
  };

  const renderTags = () => {
    return (
      <div className={s.bbPostViewTagsWrapper} data-cy="post-tags">
        {postData?.post_category_type && (
          <Tooltip
            placement="top"
            title={t(
              `components:posts:table:tableHeader:tooltips:tags:${
                postData?.post_category_type?.id ? camelize(postData.post_category_type.id) : ""
              }`,
            )}
          >
            <span className={classNames(s.bbPostTag, s.bbPostTagClickable)}>
              {postData?.post_category_type.label}
            </span>
          </Tooltip>
        )}
        {postData?.post_media_type && (
          <span className={classNames(s.bbPostTag, s.bbPostTagMediaType)}>
            <span className={s.bbPostMediaType}>
              {getMediaTypeIcon(postData?.post_media_type?.id)}
            </span>
            <span>{postData?.post_media_type?.label}</span>
          </span>
        )}
        {postData?.targetings?.length ? (
          <span className={classNames(s.bbPostTag, s.bbPostTagClickable)}>
            {t("tags:targetings")} {getCountriesFlags(postData?.targetings, MAX_SHOW_FLAG_COUNT)}
          </span>
        ) : null}
      </div>
    );
  };

  return (
    <>
      {!!postData && !isPostLoading && (
        <div>
          <div className={s.bbPostViewTitleWrapper}>
            <span className={s.bbPostViewTitle}>
              {t("components:comments:details:postPreview")}
            </span>
            {post && isEarnedMessage(post.post_type) && (
              <ListeningChip
                className={s.bbPostViewListeningChip}
                iconClassName={s.bbPostViewListeningChipIcon}
                wrapperClassName={s.bbPostViewListeningChipWrapper}
              />
            )}
          </div>

          {type === "comment_details" && (
            <div className={s.bbPostViewDetailsWrapper}>
              <div
                data-cy="post-platform"
                className={classNames({
                  [s.bbPlatformIconLarge]: isYTPost,
                  [s.bbPlatformIconSmall]: !isYTPost,
                })}
              >
                {postData?.platform_type.id && getPlatformIconBasicColor(postData.platform_type.id)}
              </div>
              {post && <PostViewTooltip post={post} />}
              <Dropdown
                setOpen={setIsDropdownOpen}
                open={isDropdownOpen}
                placement="bottomRight"
                menuClassName={s.bbPostViewDropdown}
                trigger={
                  <div
                    className={classNames(s.bbPostViewMoreActions, {
                      [s.bbPostViewMoreActionsHovered]: isDropdownOpen,
                    })}
                  >
                    <MoreActionsIcon />
                  </div>
                }
              >
                <div className={s.bbPostViewDropdownContent}>
                  <div className={s.bbPostViewDropdownItem} onClick={onExploreClick}>
                    <CommentIcon />
                    {t("components:comments:actions:exploreComments")}
                  </div>
                  <div className={s.bbPostViewDropdownItem} onClick={onOpenPostClick}>
                    <PostIcon />
                    {t("components:comments:actions:showPost")}
                  </div>
                </div>
              </Dropdown>
            </div>
          )}

          {type === "default" && (
            <>
              <div className={s.bbPostViewWrapper} data-cy="post-wrapper">
                <div>
                  <Avatar
                    size={imageSize}
                    shape="square"
                    style={{ fontSize: "12px" }}
                    src={postData?.picture || ""}
                    className={classNames(s.bbPostViewImagePreview, {
                      [s.bbPortraitImage]: postData?.post_placement === PostPlacementEnum.STORY,
                    })}
                  >
                    {t("generic:noImage")}
                  </Avatar>
                </div>
                {type === "default" && (
                  <div data-cy="post-right-section">
                    <div
                      data-cy="post-platform"
                      className={classNames({
                        [s.bbPlatformIconLarge]: isYTPost,
                        [s.bbPlatformIconSmall]: !isYTPost,
                      })}
                    >
                      {postData?.platform_type.id &&
                        getPlatformIconBasicColor(postData.platform_type.id)}
                    </div>
                    <div className={s.bbPostAuthorName}>{postData?.author_name}</div>
                    <div className={s.bbPostAuthorDate}>
                      {extractDate(postData?.created_time_pretty)}
                    </div>
                    <div data-cy="post-text" className={s.bbPostMessage}>
                      {postData?.message}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className={s.bbPostTagsListing}>{renderTags()}</div>
        </div>
      )}
      {isPostLoading && (
        <div className={s.bbPostViewloading}>
          <Loading
            isLoading
            type="spinning"
            tip={t("pages:loading:spinText")}
            indicator={<LoadingOutlined />}
          />
        </div>
      )}
      <PostModal
        post={post}
        showPostTags
        isOpen={isPostModalOpen}
        onClose={() => setIsPostModalOpen(false)}
      />
    </>
  );
};

export default PostView;

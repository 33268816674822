import { t } from "i18next";
import { Observable, from, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "../";
import {
  getRepliesSuccessAction,
  getRepliesFailureAction,
  getThreadRepliesSuccessAction,
  getThreadRepliesFailureAction,
  getRepliesPageSuccessAction,
  postCommentReplyFailureAction,
  postCommentReplySuccessAction,
  postSavedRepliesSuccessAction,
  postSavedRepliesFailureAction,
  postSavedReplySuccessAction,
  postSavedReplyFailureAction,
  putSavedReplySuccessAction,
  putSavedReplyFailureAction,
  deleteSavedRepliesSuccessAction,
  deleteSavedRepliesFailureAction,
  deleteBrandReplySuccessAction,
  deleteBrandReplyFailureAction,
  generateAISuggestedReplySuccessAction,
  generateAISuggestedReplyFailureAction,
  uploadReplyImageSuccessAction,
  uploadReplyImageFailureAction,
  postCommentReplyRegionIsNotAllowedFailureAction,
  getAccountPostsSuccessAction,
  getAccountPostsFailureAction,
  getRepliesAttachmentsSuccessAction,
  getRepliesAttachmentsFailureAction,
  getSavedRepliesVariationsSuccessAction,
  getSavedRepliesVariationsFailureAction,
  blockUserSuccessAction,
  blockUserFailureAction,
  unblockUserFailureAction,
  unblockUserSuccessAction,
  postCommentReplyApprovalSuccessAction,
  postCommentReplyApprovalFailureAction,
  updateCommentReplyApprovalSuccessAction,
  updateCommentReplyApprovalFailureAction,
} from "./actions";

import {
  GET_REPLIES,
  GET_THREAD_REPLIES,
  GET_REPLIES_PAGE,
  GET_REPLIES_AI_SUGGESTION,
  POST_COMMENT_REPLY,
  POST_SAVED_REPLIES,
  DELETE_SAVED_REPLIES,
  POST_SAVED_REPLY,
  PUT_SAVED_REPLY,
  DELETE_BRAND_REPLY,
  UPLOAD_REPLY_IMAGE,
  GET_ACCOUNT_POSTS,
  GET_REPLIES_ATTACHMENTS,
  GET_BB_REPLIES_VARIATIONS,
  BLOCK_USER,
  UNBLOCK_USER,
  POST_COMMENT_REPLY_APPROVAL,
  UPDATE_COMMENT_REPLY_APPROVAL,
} from "./actionTypes";

import { insightsPosts, platformMediaImage, replies, blockUser } from "@utils/paths";

import {
  IMessage,
  IReplyData,
  ISavedReplies,
  ReplyActions,
  IReplyPageResponse,
  IPostsResult,
  ISavedRepliesData,
  IUpdateReplyApprovalSuccessPayload,
} from "./types";
import {
  handleError,
  fetchData,
  fetchUpload,
  isTiktokPlatform,
  isTwitterPlatform,
  IComment,
} from "@bbdevcrew/bb_ui_kit_fe";
import { getAuthAPIHeaders } from "@utils/headers";
import { IGenericAction } from "@store/comments/types";

export const getCommentReplies = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_REPLIES)),
    switchMap(a => {
      const commentsNavigationId = a.payload.commentsNavigationId;
      const urlSearchParams = new URLSearchParams(
        commentsNavigationId ? { navigation_id: commentsNavigationId } : {},
      );

      return ajax
        .get<IReplyData>(
          `${replies}/comments/${a.payload.commentId}?${urlSearchParams.toString()}`,
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(getRepliesSuccessAction),
          catchError(e => handleError(e, getRepliesFailureAction)),
        );
    }),
  );

export const getCommentRepliesAttachments = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_REPLIES_ATTACHMENTS)),
    switchMap(() => {
      const data = state$.value.replies.data as IReplyData;
      const items = data?.items || [];
      const pageId = data?.post_preview?.page_id;
      const platform = data.post_preview?.platform_type.id as string;
      const isTiktok = isTiktokPlatform(platform);
      const isTwitter = isTwitterPlatform(platform);
      const fetchRepliesAttachments = async () => {
        if (!isTiktok && !isTwitter) return data;

        const replyItemsPromises = items.map(async item => {
          const attachment = item.attachments?.[0];
          const base64Regex = /data:image\/(jpeg|png);base64,/;

          if (
            !attachment ||
            attachment.type !== "image" ||
            (attachment.url && base64Regex.test(attachment.url))
          ) {
            return Promise.resolve(item);
          }

          const params = new URLSearchParams({
            url: attachment.url,
            ...(item.id && isTiktok && { comment_id: item.id }),
            ...(pageId && { asset_id: pageId }),
          });
          const apiUrl = `${platformMediaImage(platform)}?${params.toString()}`;
          const headers = getAuthAPIHeaders(state$);
          const imageResponse = await fetch(isTwitter ? attachment.url : apiUrl, { headers });
          const { image } = await imageResponse.json();

          return { ...item, attachments: [{ ...attachment, url: image }] };
        });
        const replyItems = await Promise.all(replyItemsPromises);

        return { ...data, items: replyItems };
      };

      return from(fetchRepliesAttachments()).pipe(
        map(getRepliesAttachmentsSuccessAction),
        catchError(e => handleError(e, getRepliesAttachmentsFailureAction)),
      );
    }),
  );

export const getCommentThreadReplies = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_THREAD_REPLIES)),
    switchMap(a => {
      return ajax
        .get<IReplyData>(
          `${replies}/comments/${a.payload.threadId}${
            a.payload.usePrivateReplyId
              ? `?private_reply_comment_id=${a.payload.privateReplyCommentId}`
              : ""
          }`,
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(data => getThreadRepliesSuccessAction(data)),
          catchError(e => handleError(e, getThreadRepliesFailureAction)),
        );
    }),
  );

export const getCommentRepliesPage = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_REPLIES_PAGE)),
    switchMap(a => {
      return ajax
        .get<IReplyPageResponse>(`${replies}/threads/${a.payload}`, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(data => getRepliesPageSuccessAction(data)),
          catchError(e => handleError(e, getRepliesFailureAction)),
        );
    }),
  );

export const postCommentReply = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_COMMENT_REPLY)),
    switchMap(a => {
      return ajax
        .post<IMessage>(
          `${replies}/comments/${a.payload.commentId}`,
          {
            message: a.payload.message,
            raw_message: a.payload.raw_message,
            replier_asset_id: a.payload.replyingAssetId,
            is_private_reply: !!a.payload.isPrivateReply,
            ...(a.payload.attachment?.url || a.payload.attachment?.id
              ? { attachment: a.payload.attachment }
              : {}),
          },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => {
            return [
              postCommentReplySuccessAction(
                data,
                a.payload.commentId,
                a.payload.is_ignored,
                a.payload.isPrivateReply,
              ),
            ];
          }),
          catchError(e => {
            if (e.message.includes("region is not allowed")) {
              return of(postCommentReplyRegionIsNotAllowedFailureAction());
            }

            return handleError(e, postCommentReplyFailureAction);
          }),
        );
    }),
  );

export const postCommentReplyApproval = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_COMMENT_REPLY_APPROVAL)),
    switchMap(a => {
      return ajax
        .post<IComment["reply_approval"]>(
          `${replies}/comments/${a.payload.commentId}/approval`,
          {
            message: a.payload.message,
            raw_message: a.payload.raw_message,
            replier_asset_id: a.payload.replyingAssetId,
            is_private_reply: !!a.payload.isPrivateReply,
            user_ids: a.payload.user_ids,
            can_anyone_approve: a.payload.can_anyone_approve,
            ...(a.payload.attachment?.url || a.payload.attachment?.id
              ? { attachment: a.payload.attachment }
              : {}),
          },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(data => {
            return postCommentReplyApprovalSuccessAction(data);
          }),
          catchError(e => {
            return handleError(e, postCommentReplyApprovalFailureAction);
          }),
        );
    }),
  );

export const updateCommentReplyApproval = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(UPDATE_COMMENT_REPLY_APPROVAL)),
    switchMap(a => {
      return ajax
        .patch<IUpdateReplyApprovalSuccessPayload>(
          `${replies}/comments/${a.payload.commentId}/approval/${a.payload.id}`,
          {},
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => {
            const actions: IGenericAction[] = [
              updateCommentReplyApprovalSuccessAction({
                id: a.payload.id,
                data,
                commentId: a.payload.commentId,
              }),
            ];

            if (data.item_created) {
              actions.push(
                postCommentReplySuccessAction(
                  data.item_created,
                  a.payload.commentId,
                  false,
                  a.payload.isPrivateReply,
                ),
              );
            }

            return actions;
          }),
          catchError(e => {
            return handleError(e, updateCommentReplyApprovalFailureAction);
          }),
        );
    }),
  );

export const generateAISuggestedReply = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_REPLIES_AI_SUGGESTION)),
    switchMap(a => {
      return ajax
        .get<{
          text: string;
        }>(`${replies}/comments/${a.payload.commentId}/ai-suggestion`, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(data => generateAISuggestedReplySuccessAction(data.text)),
          catchError(e => handleError(e, generateAISuggestedReplyFailureAction)),
        );
    }),
  );

export const postSavedReplies = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_SAVED_REPLIES)),
    switchMap(a => {
      return ajax
        .post<ISavedReplies>(
          `${replies}/templates-list`,
          {
            query: a.payload?.query,
            preset_query: a.payload?.presetQuery,
            comment_id: a.payload?.commentId,
          },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(data =>
            postSavedRepliesSuccessAction(data, a.payload?.query, a.payload?.presetQuery),
          ),
          catchError(e => handleError(e, postSavedRepliesFailureAction)),
        );
    }),
  );

export const postSavedReply = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_SAVED_REPLY)),
    switchMap(a => {
      return ajax
        .post<ISavedRepliesData>(`${replies}/templates`, a.payload, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(data => postSavedReplySuccessAction(a.payload.type, data)),
          catchError(e => handleError(e, postSavedReplyFailureAction)),
        );
    }),
  );

export const putSavedReply = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PUT_SAVED_REPLY)),
    switchMap(a => {
      const { id, type, ...payloadRest } = a.payload;

      return ajax
        .put<ISavedRepliesData>(
          `${replies}/templates/${id}`,
          payloadRest,
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(data => putSavedReplySuccessAction(type, data)),
          catchError(e => handleError(e, putSavedReplyFailureAction)),
        );
    }),
  );

export const deleteSavedReplies = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_SAVED_REPLIES)),
    switchMap(a => {
      return ajax
        .delete<ISavedReplies>(`${replies}/templates/${a.payload.id}`, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(() => deleteSavedRepliesSuccessAction(a.payload.id, a.payload.type)),
          catchError(e => handleError(e, deleteSavedRepliesFailureAction)),
        );
    }),
  );

export const deleteBrandReply = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_BRAND_REPLY)),
    switchMap(a => {
      return ajax
        .delete<ISavedReplies>(`${replies}/comments/${a.payload.id}`, getAuthAPIHeaders(state$))
        .pipe(
          map(e => e.response),
          map(() =>
            deleteBrandReplySuccessAction(
              a.payload.id,
              a.payload.isIgnored,
              a.payload.isAIHighlighted,
            ),
          ),
          catchError(e => handleError(e, deleteBrandReplyFailureAction)),
        );
    }),
  );

export const uploadReplyImage = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(UPLOAD_REPLY_IMAGE)),
    switchMap(({ payload: file }) =>
      from(
        fetchData<{ url: string; file_name: string }>(
          "upload/signed-url",
          "POST",
          state$.value.auth.session.accessToken.jwtToken,
          { content_type: file.type, upload_type: "reply" },
        ).then(({ url, file_name }) =>
          fetchUpload(url, file).then(({ status }) => {
            if (status !== 200) {
              throw new Error(t("generic:error"));
            }

            const cleanURL = url.replace(/\?.*$/, "");

            return { url: cleanURL, file_name };
          }),
        ),
      ).pipe(
        map(({ url, file_name }) => uploadReplyImageSuccessAction({ url, fileName: file_name })),
        catchError((error: Error) => [uploadReplyImageFailureAction(error.message)]),
      ),
    ),
  );

export const getAccountPosts = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_ACCOUNT_POSTS)),
    switchMap(a =>
      ajax.post<IPostsResult>(insightsPosts, a.payload, getAuthAPIHeaders(state$)).pipe(
        map(e => e.response),
        map(data => getAccountPostsSuccessAction(data)),
        catchError(e => handleError(e, getAccountPostsFailureAction)),
      ),
    ),
  );

export const getSavedRepliesVariationsEpic = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_BB_REPLIES_VARIATIONS)),
    switchMap(a => {
      return ajax
        .post<{ items: string[] }>(
          `${replies}/template-variations`,
          {
            message: a.payload,
          },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(data => getSavedRepliesVariationsSuccessAction(data.items)),
          catchError(e => handleError(e, getSavedRepliesVariationsFailureAction)),
        );
    }),
  );

export const blockUserEpic = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(BLOCK_USER)),
    switchMap(a =>
      ajax
        .post<ISavedReplies>(
          blockUser(a.payload.assetId),
          { user_id: a.payload.userId, action: "block" },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(() => blockUserSuccessAction()),
          catchError(e => handleError(e, blockUserFailureAction)),
        ),
    ),
  );

export const unblockUserEpic = (
  action$: Observable<ReplyActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(UNBLOCK_USER)),
    switchMap(a =>
      ajax
        .post<ISavedReplies>(
          blockUser(a.payload.assetId),
          { user_id: a.payload.userId, action: "unblock" },
          getAuthAPIHeaders(state$),
        )
        .pipe(
          map(e => e.response),
          map(() => unblockUserSuccessAction()),
          catchError(e => handleError(e, unblockUserFailureAction)),
        ),
    ),
  );

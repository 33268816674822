import React, { FC } from "react";
import { useSelector } from "react-redux";

import MessageCard from "../MessageCard/MessageCard";
import ReplyPhrase from "../MessageCard/replyPhrase/ReplyPhrase";
import MessageCardWrapper from "./MessageCardWrapper";
import DeleteReplyAlert from "../MessageCard/replyDeleteAlert/ReplyDeleteAlert";

import { deletedReplyIdSelector, deletingBrandReplySelector } from "@store/replies/selectors";

import { IBrandReply } from "@store/replies/types";
import { IActionDropdownItem, IReplyWorkflowInfo } from "@bbdevcrew/bb_ui_kit_fe";

interface IBrandReplyProps {
  brand_reply: IBrandReply;
  workflow?: IReplyWorkflowInfo;
  actionDropdownItems: IActionDropdownItem[];
  showDeleteReplyAlert: boolean;
  onDeleteReply: () => void;
  onCancelDeleteReply: () => void;
}

export const BrandReply: FC<IBrandReplyProps> = ({
  workflow,
  brand_reply,
  actionDropdownItems,
  showDeleteReplyAlert,
  onDeleteReply,
  onCancelDeleteReply,
}) => {
  const deletedReplyId = useSelector(deletedReplyIdSelector);
  const isDeletingBrandReply = useSelector(deletingBrandReplySelector);

  return (
    <MessageCardWrapper isChildMessage isClientReply>
      <MessageCard
        type="highlighted"
        message={brand_reply.message}
        isHidden={brand_reply.is_hidden}
        key={`message-${brand_reply.id}`}
        date={brand_reply.created_time_pretty}
        actionDropdownItems={actionDropdownItems}
        author={brand_reply.replied_as?.name || brand_reply.username}
        footer={
          <>
            {brand_reply.reply_phrase && (
              <ReplyPhrase
                workflow={workflow}
                tooltip={brand_reply.reply_phrase.timestamp}
                phrase={brand_reply.reply_phrase?.phrase || ""}
                platform_user={brand_reply.reply_phrase?.platform_user}
              />
            )}
            {showDeleteReplyAlert && (
              <DeleteReplyAlert
                onApprove={onDeleteReply}
                onCancel={onCancelDeleteReply}
                isDeleting={isDeletingBrandReply && deletedReplyId === brand_reply.id}
              />
            )}
          </>
        }
      />
    </MessageCardWrapper>
  );
};

import Animate from "rc-animate";
import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { Row, Col, Result } from "antd";
import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./NotFound.module.less";

import { ResultStatusType } from "antd/lib/result";

interface INotFoundViewProps {
  type?: ResultStatusType;
  redirectCallback?: () => void;
}

const NotFoundView: FC<INotFoundViewProps> = ({ redirectCallback, type = "404" }) => {
  const { t } = useTranslation();

  const backToHome = (e: MouseEvent) => {
    e.preventDefault();
    redirectCallback?.();
  };

  return (
    <div className={s.bbRootNotFoundPage}>
      <Row justify="center" align="middle">
        <Col
          xs={{
            span: 22,
            offset: 1,
          }}
        >
          <Animate transitionName="fade" transitionAppear>
            <div className={s.bbContainerNotFound}>
              <Row justify="center" align="middle">
                <Col>
                  <Result
                    title={type}
                    status={type}
                    subTitle={t(`pages:${type}:message`)}
                    extra={
                      <Button className={s.bbBackToHomeBtn} onClick={backToHome}>
                        {t(`pages:${type}:button`)}
                      </Button>
                    }
                  />
                </Col>
              </Row>
            </div>
          </Animate>
        </Col>
      </Row>
    </div>
  );
};

export default NotFoundView;

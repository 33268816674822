import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "..";
import {
  ITwitterUrlResponse,
  IRegisterTokenAPIResponse,
  OnboardingPlatformsActionsType,
} from "./types";
import {
  GET_ONBOARDING_PLATFORM,
  GET_ONBOARDING_PLATFORMS,
  REGISTER_TOKEN,
  DELETE_TOKEN,
  GET_TWITTER_REGISTER_TOKEN_URL,
} from "./actionTypes";
import {
  getOnboardingPlatformsSuccessAction,
  getOnboardingPlatformsFailureAction,
  getOnboardingPlatformSuccessAction,
  getOnboardingPlatformFailureAction,
  registerTokenFailureAction,
  deleteTokenSuccessAction,
  deleteTokenFailureAction,
  getTwitterRegisterTokenUrlSuccessAction,
  getTwitterRegisterTokenUrlFailureAction,
} from "./actions";

import { getHeaders } from "@utils/headers";
import { handleError } from "@bbdevcrew/bb_ui_kit_fe";
import { assetsOnboarding, getCurrentModuleName } from "@utils/paths";
import { IOnboardingPlatform } from "@components/_common/AssetTabs/AssetTabs.type";
import { PlatformType, getNormalizedTiktokPlatformId } from "@bbdevcrew/bb_ui_kit_fe";
import { getRegisterTokenSuccessAction, getRegisterUri, getUserAccessToken } from "./helpers";

const getAssetOnboardingPlatformUrl = (platform: PlatformType | "tt_ads") => {
  const onboardingPlatform = getNormalizedTiktokPlatformId(platform);
  const base = `${assetsOnboarding}/platforms/${onboardingPlatform}`;

  if (
    (onboardingPlatform === "tiktok" || onboardingPlatform === "tiktok_ads") &&
    getCurrentModuleName() === "login"
  ) {
    return `${base}?form_type=signup`;
  }

  return base;
};

export const getOnboardingPlatforms = (
  action$: Observable<OnboardingPlatformsActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(GET_ONBOARDING_PLATFORMS)),
    switchMap(() => {
      return ajax
        .get<{ items: IOnboardingPlatform[] }>(
          `${assetsOnboarding}/platforms`,
          getHeaders({
            Authorization: getUserAccessToken(state$),
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getOnboardingPlatformsSuccessAction(data.items)),
          catchError(e => handleError(e, getOnboardingPlatformsFailureAction)),
        );
    }),
  );
};

export const getOnboardingPlatform = (
  action$: Observable<OnboardingPlatformsActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(GET_ONBOARDING_PLATFORM)),
    switchMap(a => {
      return ajax
        .get<IOnboardingPlatform>(
          getAssetOnboardingPlatformUrl(a.payload),
          getHeaders({
            Authorization: getUserAccessToken(state$),
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getOnboardingPlatformSuccessAction(data, a.payload)),
          catchError(e => handleError(e, getOnboardingPlatformFailureAction)),
        );
    }),
  );
};

export const registerToken = (
  action$: Observable<OnboardingPlatformsActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(REGISTER_TOKEN)),
    switchMap(a => {
      return ajax
        .post<IRegisterTokenAPIResponse>(
          `${assetsOnboarding}/${a.payload.platformId}/tokens`,
          {
            ...a.payload.tokenPayload,
            redirect_uri: getRegisterUri(a.payload.platformId),
          },
          getHeaders({
            Authorization: getUserAccessToken(state$),
          }),
        )
        .pipe(
          map(e => e.response),
          getRegisterTokenSuccessAction(a),
          catchError(e => handleError(e, registerTokenFailureAction)),
        );
    }),
  );
};

export const deleteToken = (
  action$: Observable<OnboardingPlatformsActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(DELETE_TOKEN)),
    switchMap(a => {
      return ajax
        .delete(
          `${assetsOnboarding}/${a.payload.social_platform}/tokens`,
          getHeaders({
            Authorization: getUserAccessToken(state$),
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteTokenSuccessAction(a.payload.social_platform)),
          catchError(e => handleError(e, deleteTokenFailureAction)),
        );
    }),
  );
};

export const getTwitterRegisterTokenUrl = (
  action$: Observable<OnboardingPlatformsActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(GET_TWITTER_REGISTER_TOKEN_URL)),
    switchMap(a => {
      return ajax
        .post<ITwitterUrlResponse>(
          `${assetsOnboarding}/twitter/request-token`,
          {
            callback_url: `${
              window.location.origin + window.location.pathname
            }?social_platform=${a.payload}`,
          },
          getHeaders({
            Authorization: getUserAccessToken(state$),
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getTwitterRegisterTokenUrlSuccessAction(data)),
          catchError(e => handleError(e, getTwitterRegisterTokenUrlFailureAction)),
        );
    }),
  );
};
